import { NewRelicEum } from './new-relic';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare let NREUM: NewRelicEum;

export const loadNewRelic = (config: {
  enabled: boolean;
  accountID?: string;
  trustKey?: string;
  agentID?: string;
  licenseKey?: string;
  applicationID?: string;
  beacon?: string;
  errorBeacon?: string;
  sa?: number;
}) => {
  if (config.enabled) {
    NREUM.loader_config = {
      accountID: config.accountID,
      trustKey: config.trustKey,
      agentID: config.agentID,
      licenseKey: config.licenseKey,
      applicationID: config.applicationID
    };
    NREUM.info = {
      beacon: config.beacon,
      errorBeacon: config.errorBeacon,
      licenseKey: config.licenseKey,
      applicationID: config.applicationID,
      sa: config.sa
    };
  }
};
