import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ContentHeaderService } from '@shared/content-header/content-header.service';
import { Flow } from '@shared/navigation/flow.enum';
import { CustomCookieService } from '@shared/storage/custom-cookie.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { SessionKey } from '../../shared/storage/storage.model';
import { AUTHORIZATION_PATHS } from '../authorization-paths';
import { AuthorizationBaseComponent } from '../shared/authorization-base.component';
import {
  AuthMethod,
  Authorization,
  Destination,
  DestinationType,
  findAuthorization,
  findDestination
} from '../shared/authorization.model';

@Component({
  selector: 'ciam-authorization',
  templateUrl: './authorize.component.html',
  styleUrls: ['./authorize.component.scss']
})
export class AuthorizeComponent extends AuthorizationBaseComponent implements OnInit {
  protected readonly className = 'AuthorizeComponent';

  constructor(
    private _route: ActivatedRoute,
    private _contentHeaderService: ContentHeaderService,
    private _cookieService: CustomCookieService,
    private _oauthService: OAuthService
  ) {
    super();
  }

  ngOnInit() {
    this.spinnerService.show();
    this._authorize();
  }

  _authorize() {
    const queryParams = this._route.snapshot.queryParamMap;
    if (
      !queryParams ||
      !queryParams.has('method') ||
      !queryParams.has('systemId') ||
      (queryParams.get('method') !== AuthMethod.CONTEXT_CACHE && !queryParams.has('destination'))
    ) {
      throw new Error('Missing minimum required query params.');
    }

    const authorization = findAuthorization(queryParams.get('method'));
    this._validateAuthorization(queryParams, authorization);
    this._validateDestination(queryParams, authorization);
    this._validateAndSetSystemId(queryParams);

    this._checkForNonInstantRegistration(authorization, this.destination, queryParams);
    this._storePingToken(queryParams);

    if (queryParams.has('type')) {
      this.sessionService.set(SessionKey.TYPE, queryParams.get('type'));
    }

    if (queryParams.has('partnerSpId')) {
      this.sessionService.set(SessionKey.PARTNER_SP_ID, queryParams.get('partnerSpId'));
    }

    if (queryParams.has('loginType')) {
      this.sessionService.set(SessionKey.LOGIN_TYPE, queryParams.get('loginType'));
    }

    this._contentHeaderService.reset(this.contentHeaderModel);

    this._oauthService.redirectUri = this._buildRedirectUri(this.destination);
    this._oauthService.customQueryParams = this._buildCustomQueryParams(authorization, queryParams);

    if (this.destination) {
      this._oauthService.initLoginFlow(btoa(this.destination.type));
    } else {
      this._oauthService.initLoginFlow();
    }
  }

  private _buildRedirectUri(destination: Destination) {
    if (
      destination &&
      [
        DestinationType.PROXY_REGISTRATION,
        DestinationType.PROXY_REGISTRATION_EDIT,
        DestinationType.OTL_VERIFY,
        DestinationType.OTL_REGISTRATION,
        DestinationType.OTL_RECOVERY
      ].includes(destination.type)
    ) {
      return `${window.location.origin}/${Flow.AUTHORIZATION}/${AUTHORIZATION_PATHS.callback}?destination=${DestinationType.PROXY_REGISTRATION}`;
    }

    return `${window.location.origin}/${Flow.AUTHORIZATION}/${AUTHORIZATION_PATHS.callback}`;
  }

  private _checkForNonInstantRegistration(
    authorization: Authorization,
    destination: Destination,
    queryParams: ParamMap
  ) {
    if (this.destination?.type === DestinationType.NON_INSTANT_ACTIVATION_SUCCESS) {
      if (queryParams.has('activationKey')) {
        authorization.params.push('activationKey');
      } else {
        throw new Error(`Missing required query params for destination: ${this.destination?.type}`);
      }
    } else if (this.destination?.type === DestinationType.NON_INSTANT_ACTIVATION) {
      if (queryParams.has('contextId2') && queryParams.has('otp2') && queryParams.has('token')) {
        this.sessionService.set('contextIdL2', queryParams.get('contextId2'));
        this.sessionService.set('otpL2', queryParams.get('otp2'));
        this.sessionService.set(SessionKey.PING_TOKEN, queryParams.get('token'));
      } else {
        throw new Error(`Missing required query params for destination: ${this.destination?.type}`);
      }
    }
  }

  private _storePingToken(queryParams: ParamMap) {
    if (this.destination?.type === DestinationType.SET_PREFERENCES) {
      if (queryParams.has('pingToken')) {
        this.sessionService.set(SessionKey.PING_TOKEN, queryParams.get('pingToken'));
      } else {
        throw new Error(`Missing required query params for destination: ${this.destination?.type}`);
      }
    }
  }

  private _buildCustomQueryParams(authorization: Authorization, queryParams: ParamMap): Record<string, string> {
    const authParams: any = {};
    authorization.params.forEach(param => {
      authParams[`auth_id_${param}`] = queryParams.get(param);
    });
    if (this.destination?.type === DestinationType.NON_INSTANT_ACTIVATION_SUCCESS) {
      authorization.params.pop();
    }

    return {
      realm: authorization.realm,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      auth_method: authorization.method,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      message_id: this._cookieService.flowId,
      ...authParams
    };
  }

  private _validateAuthorization(queryParams: ParamMap, authorization: Authorization) {
    if (authorization) {
      authorization.params?.forEach(param => {
        if (!queryParams.has(param)) {
          throw new Error(`Missing required query params for method: ${authorization.method}`);
        }
      });
    } else {
      throw new Error(`Invalid method: ${queryParams.get('method')}`);
    }
  }

  private _validateDestination(queryParams: ParamMap, authorization: Authorization) {
    const destination = queryParams.get('destination');
    if (destination) {
      this.destination = findDestination(destination);
      if (this.destination) {
        if (!this.destination.allowedAuthMethods.includes(authorization.method)) {
          throw new Error(`Invalid method for destination: ${authorization.method}, ${this.destination.type}`);
        }
      } else {
        throw new Error(`Invalid destination: ${destination}`);
      }
    }
  }

  private _validateAndSetSystemId(queryParams: ParamMap) {
    const systemId = queryParams.get('systemId');
    if (systemId.match(/^[a-zA-Z0-9 _-]+$/i)) {
      this.sessionService.setPartnerId(systemId);
    } else {
      throw new Error(`Invalid systemId: ${systemId}`);
    }
  }
}
