import { Component, OnInit } from '@angular/core';
import { ERROR_PATHS } from '@app/error/error-paths';
import { ENVIRONMENT } from '@environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AdaBaseComponent } from '@shared/base-component/ada-base.component';
import { NavigationService } from '@shared/navigation/navigation.service';
import { CustomCookieService } from '@shared/storage/custom-cookie.service';
import { SessionKey } from '@shared/storage/storage.model';
import { UpdateAccountService } from './update-account.service';

@UntilDestroy()
@Component({
  selector: 'update-account',
  templateUrl: './update-account.component.html',
  styleUrls: ['./update-account.component.scss']
})
export class UpdateAccountComponent extends AdaBaseComponent implements OnInit {
  activationStartDate: Date;
  daysInDifference: number;
  hideSpinnerOnLoad = false;
  protected readonly className = 'UpdateAccountComponent';

  constructor(
    private _updateAccountService: UpdateAccountService,
    private _navigationService: NavigationService,
    private _customCookieService: CustomCookieService
  ) {
    super();
  }

  ngOnInit(): void {
    this.spinnerService.show();
    this.activationStartDate = this.sessionService.get('activationStartDate');
    this._validateActivationExpiration(this.activationStartDate);
  }

  public _validateActivationExpiration(dateSent: string | number | Date) {
    const currentDate = new Date();
    dateSent = new Date(dateSent);
    this.daysInDifference = Math.floor(
      (Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) -
        Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) /
        (1000 * 60 * 60 * 24)
    );
    if (this.daysInDifference > 90) {
      this._navigationService.navigateToError(
        this.className,
        new Error(),
        ERROR_PATHS.accountIssue,
        'updateAccount failed due to Activation key expired.'
      );
      this.spinnerService.hide();
    } else {
      this._upgradeUser();
    }
  }

  public _upgradeUser() {
    this._updateAccountService
      .updateAccountStatusAndAccessLevel()
      .pipe(untilDestroyed(this))
      .subscribe(
        response => {
          if (response.status === 200) {
            this._routeToIamSuccessPage(response);
          }

          if (response.status === 207) {
            this.logger.error(
              this.className,
              'UpdateAccountService : Error occurred while updating account - Partial Success.'
            );
            this._navigationService.navigateToError(
              this.className,
              new Error(),
              ERROR_PATHS.systemError,
              'UpdateAccountService updateAccountStatusAndAccessLevel failed due to partial success.'
            );
            this.spinnerService.hide();
          }
        },
        error => {
          this.logger.error(this.className, 'Error occurred while updating account.');
          this._navigationService.navigateToError(
            this.className,
            error,
            ERROR_PATHS.systemError,
            'UpdateAccountService updateAccountStatusAndAccessLevel failed.'
          );
          this.spinnerService.hide();
        }
      );
  }
  private _routeToIamSuccessPage(response: any) {
    if (response.device?.deviceToken) {
      this._customCookieService.deviceToken = response.device.deviceToken;
    }
    if (this.sessionService.has(SessionKey.PING_TOKEN)) {
      const pingToken = this.sessionService.get(SessionKey.PING_TOKEN);
      this.sessionService.remove(SessionKey.PING_TOKEN);
      this._navigationService.navigateExternal(
        `${ENVIRONMENT.externalUrls.iam.basePath}${ENVIRONMENT.externalUrls.iam.unprotectedLogin}?redirectURL=${ENVIRONMENT.externalUrls.iam.basePath}${ENVIRONMENT.externalUrls.iam.ciamRedirect}&pingToken=${pingToken}&type=mylogin`
      );
    }
  }
}
