import { UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ContactPointType } from '../shared/contact-point.model';
import { CONTROLS } from './send-code.model';

export const validateNewPhone: ValidatorFn = (formGroup: UntypedFormGroup): ValidationErrors | null => {
  const phoneNumber = formGroup.get('phoneNumber');

  if (formGroup.get('availableContactTypes').value === ContactPointType.NEW_PHONE) {
    if (!phoneNumber.value) {
      phoneNumber.setErrors({ required: true });
    } else if (!CONTROLS.phoneNumber.patterns[0].test(phoneNumber.value)) {
      phoneNumber.setErrors({ pattern: true });
    }
  }

  return null;
};
