import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@environments/environment';
import {
  ChallengesService as CustomerOtpService,
  CustomerChallengeOtpRequest,
  CustomerChallengeOtpResponse,
  GetCustomerContactPointsResponse,
  GetCustomerContactPointsResponseContactPoints
} from '@nationwide/api-client-customer-otp-v2';
import {
  ChallengesService as MfaUserauthExternalLoginService,
  VerifyOtpRequest,
  VerifyOtpResponse
} from '@nationwide/api-client-mfa-userauth-external-login-v1';
import { CustomCookieService } from '@shared/storage/custom-cookie.service';
import { CustomSessionService, CUSTOM_SESSION } from '@shared/storage/custom-session.service';
import { CookieKey, Partner, SessionKey } from '@shared/storage/storage.model';
import { OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import * as jwtDecode from 'jwt-decode';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ContactPoint, ContactPointType } from './contact-point.model';
import { MfaModel } from './mfa.model';

// rsa.js
// eslint-disable-next-line @typescript-eslint/naming-convention
declare function encode_deviceprint(): string;

@Injectable()
export class MfaService {
  private readonly _channelIndicator = 'WEB';

  private _model: MfaModel;

  constructor(
    @Inject(CUSTOM_SESSION) private _sessionService: CustomSessionService,
    private _cookieService: CustomCookieService,
    private _oauthService: OAuthService,
    private _oauthStorage: OAuthStorage,
    private _customerOtpService: CustomerOtpService,
    private _mfaUserauthExternalLoginService: MfaUserauthExternalLoginService
  ) {
    this.reset();
  }

  reset() {
    this.model = {
      hasRetrievedContactPoints: false,
      user: { contactPoints: [], requestedNewCode: false },
      transaction: {}
    };
    this._customerOtpService.configuration.accessToken = this._oauthService.getAccessToken();
    this._mfaUserauthExternalLoginService.configuration.accessToken = this._oauthService.getAccessToken();
  }

  getContactPoints(uuid: string): Observable<ContactPoint[]> {
    this.model.user.uuid = uuid;
    return this._customerOtpService
      .challengesPartyTypeUuidContactPointsGet(
        ENVIRONMENT.apiConfig.clientId,
        this._cookieService.flowId,
        'member',
        uuid,
        (this._sessionService.get(SessionKey.PARTNER) as Partner)?.id
      )
      .pipe(map(this._parseContactPointsResponse.bind(this)));
  }

  sendOtp(contactPoint: ContactPoint): Observable<CustomerChallengeOtpResponse> {
    const request: CustomerChallengeOtpRequest = {
      channelIndicator: this._channelIndicator,
      devicePrint: encode_deviceprint() //NOSONAR
    };
    if (this._cookieService.check(CookieKey.DEVICE_TOKEN) || this._cookieService.check(CookieKey.CIAM_DEVICE_TOKEN)) {
      request.deviceToken = this._cookieService.deviceToken;
    }
    if (contactPoint.type === ContactPointType.NEW_PHONE) {
      request.phoneNumber = contactPoint.value;
    }

    return this._customerOtpService
      .challengesUuidOtpContactIdPost(
        request,
        ENVIRONMENT.apiConfig.clientId,
        this._cookieService.flowId,
        this.model.user.uuid,
        contactPoint.id,
        (this._sessionService.get(SessionKey.PARTNER) as Partner)?.id
      )
      .pipe(
        tap(this._parseOtpResponse.bind(this, contactPoint)),
        catchError(err => {
          if (err.error?.userId) {
            this.model.transaction.userId = err.error.userId;
          }
          if (err.error?.transactionId) {
            this.model.transaction.transactionId = err.error.transactionId;
          }
          if (err.error?.sessionId) {
            this.model.transaction.sessionId = err.error.sessionId;
          }
          if (err.error?.deviceToken) {
            this._cookieService.deviceToken = err.error.deviceToken;
          }
          return throwError(err);
        })
      );
  }

  validateOtp(otp: string): Observable<VerifyOtpResponse> {
    const request: VerifyOtpRequest = {
      channelIndicator: this._channelIndicator,
      devicePrint: encode_deviceprint(), //NOSONAR
      transactionId: this.model.transaction.transactionId,
      sessionId: this.model.transaction.sessionId,
      otp
    };

    if (this._cookieService.check(CookieKey.DEVICE_TOKEN) || this._cookieService.check(CookieKey.CIAM_DEVICE_TOKEN)) {
      request.deviceToken = this._cookieService.deviceToken;
    }

    return this._mfaUserauthExternalLoginService
      .authorizePartytypePartyTypeUuidOtpPost(
        request,
        ENVIRONMENT.apiConfig.clientId,
        this._cookieService.flowId,
        'member',
        this.model.user.uuid
      )
      .pipe(
        tap(this._parseValidationResponse.bind(this)),
        catchError(err => {
          if (err.error?.device?.transactionId) {
            this.model.transaction.transactionId = err.error.device.transactionId;
          }
          if (err.error?.device?.sessionId) {
            this.model.transaction.sessionId = err.error.device.sessionId;
          }
          if (err.error?.device?.deviceToken) {
            this._cookieService.deviceToken = err.error.device.deviceToken;
          }
          return throwError(err);
        })
      );
  }

  private _parseContactPointsResponse(contactPointsResponse: GetCustomerContactPointsResponse): ContactPoint[] {
    return contactPointsResponse.contactPoints.map(contactPoint => ({
      type: this._selectContactPointType(contactPoint),
      value: contactPoint.emailAddress || contactPoint.phoneNumber || contactPoint.postalAddress,
      id: contactPoint.contactId
    }));
  }

  private _selectContactPointType(contactPoint: GetCustomerContactPointsResponseContactPoints): ContactPointType {
    if (contactPoint.contactId === 'newPhone') {
      return ContactPointType.NEW_PHONE;
    }
    if (contactPoint.emailAddress) {
      return ContactPointType.EMAIL;
    }
    if (contactPoint.phoneNumber) {
      return ContactPointType.TEXT;
    }
    return ContactPointType.POSTAL_ADDRESS;
  }

  private _parseOtpResponse(contactPoint: ContactPoint, otpResponse: CustomerChallengeOtpResponse) {
    this.model.user.selectedContactPoint = contactPoint;
    this.model.transaction.transactionId = otpResponse.transactionId;
    this.model.transaction.sessionId = otpResponse.sessionId;
    this._cookieService.deviceToken = otpResponse.deviceToken;
  }

  private _parseValidationResponse(validationResponse: VerifyOtpResponse) {
    // TODO: MFA-UserAuth-External-Login does not support the Implicit flow and the Token flow is not supported
    // by angular-oauth2-oidc (and SPA architecture in general), so we have to manually manage authorization
    // outside of the lib here. If/when the API changes to be more "SPA friendly", we can update this.
    this._oauthService.logOut();
    this._oauthStorage.setItem('access_token', validationResponse.access_token);
    this._oauthStorage.setItem('id_token', validationResponse.id_token);
    this._oauthStorage.setItem('id_token_claims_obj', JSON.stringify(jwtDecode(validationResponse.id_token)));

    if (validationResponse.device?.deviceToken) {
      this._cookieService.deviceToken = validationResponse.device.deviceToken;
    }
  }

  get model(): MfaModel {
    return this._model;
  }

  set model(model: MfaModel) {
    this._model = model;
  }
}
