import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@environments/environment';
import {
  CustomerIdentificationService,
  GetInternetRegistrationByEcnResponse,
  PostEligibilityResponse,
  RegistrationEligibilityService,
  RegistrationManagementService
} from '@nationwide/api-client-internet-registration-v3';
import { CustomCookieService } from '@shared/storage/custom-cookie.service';
import { CustomSessionService, CUSTOM_SESSION } from '@shared/storage/custom-session.service';
import { Partner, SessionKey } from '@shared/storage/storage.model';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';

@Injectable()
export class EligibilityService {
  constructor(
    @Inject(CUSTOM_SESSION) private _sessionService: CustomSessionService,
    private _cookieService: CustomCookieService,
    private _oauthService: OAuthService,
    private _customerIdentificationService: CustomerIdentificationService,
    private _registrationManagementService: RegistrationManagementService,
    private _registrationEligibilityService: RegistrationEligibilityService
  ) {
    this.reset();
  }

  reset() {
    this._customerIdentificationService.configuration.accessToken = this._oauthService.getAccessToken();
    this._registrationManagementService.configuration.accessToken = this._oauthService.getAccessToken();
  }

  checkCustomerEligibility(
    ecn: string,
    customerAgreementNumber?: string
  ): Observable<HttpResponse<PostEligibilityResponse>> {
    let body = {};
    if (customerAgreementNumber) {
      body = {
        commercialController: {
          agreementNumber: customerAgreementNumber
        }
      };
    }

    return this._registrationEligibilityService.customersEcnEligibilitiesPost(
      body,
      ENVIRONMENT.apiConfig.clientId,
      this._cookieService.flowId,
      ecn,
      (this._sessionService.get(SessionKey.PARTNER) as Partner)?.id,
      false,
      'response'
    );
  }

  getExistingRegistrations(ecn: string): Observable<HttpResponse<GetInternetRegistrationByEcnResponse>> {
    return this._registrationManagementService.internetRegistrationsGet(
      ENVIRONMENT.apiConfig.clientId,
      this._cookieService.flowId,
      ecn,
      (this._sessionService.get(SessionKey.PARTNER) as Partner)?.id,
      false,
      'response',
      false
    );
  }
}
