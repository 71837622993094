import { ControlErrors, Controls } from '@shared/base-component/base.model';
import { CONTROL_ERROR_MESSAGES } from '@shared/validation/control-constants';

export const CONTROLS: Controls = {
  multipleTypesSelection: {
    id: 'multiple-types-selection',
    ariaDescribedByErrors: 'multiple-types-selection-errors'
  }
};

export const CONTROL_ERRORS: ControlErrors = {
  multipleTypesSelection: {
    required: CONTROL_ERROR_MESSAGES.multipleTypesSelectionRequired
  }
};
