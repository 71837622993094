import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { ELIGIBILITY_PATHS } from '@common/eligibility/eligibility-route-paths';
import { ENVIRONMENT } from '@environments/environment';
import { PostSearchPotentialMatchResponse } from '@nationwide/api-client-internet-registration-v3';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Control, ControlErrors, Controls } from '@shared/base-component/base.model';
import { CommonFlow } from '@shared/navigation/flow.enum';
import { filter } from 'rxjs/operators';
import { SEARCH_PATHS } from '../search-paths';
import { SearchBaseComponent } from '../shared/search-base.component';
import { AdditionalInformationType, SearchFlowType, SearchType } from '../shared/search.model';
import { CONTROLS, CONTROL_ERRORS } from './additional-information.model';

@UntilDestroy()
@Component({
  selector: 'ciam-additional-information',
  templateUrl: './additional-information.component.html',
  styleUrls: ['./additional-information.component.scss']
})
export class AdditionalInformationComponent extends SearchBaseComponent implements OnInit {
  @ViewChild('individualAdditionalInfoFormParent', { static: true }) individualAdditionalInfoFormParent: NgForm;
  individualAdditionalInfoForm: UntypedFormGroup;
  individualAdditionalInfoControl: Control;
  individualAdditionalInfoControls: Controls = CONTROLS;
  individualAdditionalInfoControlErrors: ControlErrors = CONTROL_ERRORS;

  notFoundSearchAttempts = 0;

  additionalInformationType: AdditionalInformationType;

  readonly additionalInfoErrorMessage =
    'We cannot locate an account associated with this information. Please check your information and try again';
  protected readonly className = 'AdditionalInformationComponent';

  constructor(private _formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit() {
    this.additionalInformationType = this.searchService.model.additionalInformationType;
    this.individualAdditionalInfoControl = this.individualAdditionalInfoControls[this.additionalInformationType];

    this.logger.info(this.className, 'Entering additional information.', {
      previousSearch: this.searchService.model?.previousSearch,
      additionalInfo: this.additionalInformationType
    });

    this._initializeForm();
  }

  navigateToIdentifier() {
    this.navigationService.navigate(`${this.baseRoute}/${SEARCH_PATHS.identifier}`);
  }

  _findAccount() {
    const searchCriteria = this.searchService.model.searchCriteria;
    searchCriteria.input[this.additionalInformationType] = this.individualAdditionalInfoForm.get(
      this.additionalInformationType
    ).value;

    this._performSearch(searchCriteria, this.additionalInformationType);
  }

  _handlePotentialMatch(response: HttpResponse<PostSearchPotentialMatchResponse>) {
    this._logSearchOperation(this._potentialMatchResultType(response.body.message.code));
    this._proceedForwardFromNonSingleMatch();
  }

  _handleNoMatch() {
    this.notFoundSearchAttempts++;

    if (
      this.notFoundSearchAttempts <
      ENVIRONMENT.features.search.maxAttempts.additionalInfo[
        this.additionalInformationType as keyof typeof ENVIRONMENT.features.search.maxAttempts.additionalInfo
      ]
    ) {
      this.spinnerService.hide();
    } else {
      this._proceedForwardFromNonSingleMatch();
    }
  }

  _proceedForwardFromNonSingleMatch() {
    delete this.searchService.model.additionalInformationType;

    if (this.searchFlowType === SearchFlowType.PERSONAL) {
      const searchPath =
        this.searchService.model?.previousSearch === SearchType.PERSONAL_IDENTIFIER
          ? SEARCH_PATHS.startOver
          : SEARCH_PATHS.identifier;
      this.searchService.model.previousSearch = this.searchType;
      this.navigationService.navigate(`${this.baseRoute}/${searchPath}`);
    } else if (this.searchFlowType === SearchFlowType.CONTROLLER) {
      this.navigationService.navigate(`${this.flow}/${CommonFlow.ELIGIBILITY}/${ELIGIBILITY_PATHS.controller}`);
    } else if (this.searchFlowType === SearchFlowType.BUSINESS) {
      this.searchService.model.previousSearch = this.searchType;
      this.navigationService.navigate(`${this.baseRoute}/${SEARCH_PATHS.startOver}`);
    } else {
      throw new Error(`Cannot determine next step for searchFlowType: ${this.searchFlowType}.`);
    }
  }

  private _initializeForm() {
    this.individualAdditionalInfoForm = this._formBuilder.group(
      {
        [this.additionalInformationType]: [
          '',
          [Validators.required, ...this._buildPatternValidators(this.individualAdditionalInfoControl.patterns)]
        ]
      },
      {
        updateOn: 'submit'
      }
    );

    this.individualAdditionalInfoForm.statusChanges
      .pipe(
        untilDestroyed(this),
        filter(() => this.individualAdditionalInfoForm.valid)
      )
      .subscribe(() => {
        this._findAccount();
      });
  }

  get errorMessage(): string {
    if (this.individualAdditionalInfoFormParent.submitted && this.individualAdditionalInfoForm.invalid) {
      return 'Please correct the following to continue.';
    } else if (this.notFoundSearchAttempts > 0) {
      return this.searchFlowType === SearchFlowType.PERSONAL &&
        this.searchService.model?.previousSearch !== SearchType.PERSONAL_IDENTIFIER
        ? `${this.additionalInfoErrorMessage} or`
        : `${this.additionalInfoErrorMessage}.`;
    }
    return null;
  }

  get showIdentifierLink(): boolean {
    return (
      this.searchFlowType === SearchFlowType.PERSONAL &&
      this.searchService.model?.previousSearch !== SearchType.PERSONAL_IDENTIFIER &&
      this.notFoundSearchAttempts > 0
    );
  }

  get searchType(): SearchType {
    return SearchType.PERSONAL_ADDITIONAL_INFORMATION;
  }

  get nwpiiClass(): string {
    if (
      [AdditionalInformationType.PHONE_NUMBER, AdditionalInformationType.EMAIL].includes(this.additionalInformationType)
    ) {
      return null;
    }

    return 'nwpii';
  }

  protected get formGroup(): UntypedFormGroup {
    return this.individualAdditionalInfoForm;
  }

  protected get controls(): Controls {
    return this.individualAdditionalInfoControls;
  }

  protected get controlErrors(): ControlErrors {
    return this.individualAdditionalInfoControlErrors;
  }
}
