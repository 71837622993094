import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SpinnerModel } from './spinner.model';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private readonly _defaultMessage = 'Please wait...';

  private _model: SpinnerModel;

  constructor() {
    this.reset();
  }

  reset() {
    this._model = {
      isEnabled: false,
      message: this._defaultMessage,
      notifications: new BehaviorSubject<boolean>(false)
    };
  }

  show(message?: string) {
    const routeContent = document.getElementById('route-content');
    if (routeContent) {
      routeContent.setAttribute('hidden', null);
    }

    this._model.message = message || this._defaultMessage;
    this._model.isEnabled = true;
    this._model.notifications.next(true);

    window.scrollTo(0, 0);
  }

  hide() {
    const routeContent = document.getElementById('route-content');
    if (routeContent) {
      routeContent.removeAttribute('hidden');
    }

    this._model.message = this._defaultMessage;
    this._model.isEnabled = false;
    this._model.notifications.next(false);
  }

  get model(): SpinnerModel {
    return this._model;
  }

  set model(model: SpinnerModel) {
    this._model = model;
  }
}
