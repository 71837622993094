import { Component, OnInit } from '@angular/core';
import { AppInjector } from '@app/app-injector';
import { CommonBaseComponent } from '@common/shared/common-base.component';
import { InternetRegistration, MfaStatus } from '@nationwide/api-client-internet-registration-v3';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AccountAccess, AccountAccessType, findAccountAccess } from '@shared/account-access/account-access.model';
import { CommonFlow } from '@shared/navigation/flow.enum';
import { NavigationService } from '@shared/navigation/navigation.service';
import { CustomSessionService, CUSTOM_SESSION } from '@shared/storage/custom-session.service';
import { SessionKey } from '@shared/storage/storage.model';
import { EligibilityService } from './eligibility.service';

@UntilDestroy()
@Component({ template: '' })
export abstract class EligibilityBaseComponent extends CommonBaseComponent implements OnInit {
  hideSpinnerOnLoad = false;

  protected className = 'EligibilityBaseComponent';

  protected sessionService: CustomSessionService;
  protected eligibilityService: EligibilityService;
  protected navigationService: NavigationService;

  private readonly _spinnerMessage =
    'Retrieving your information. This could take up to 60 seconds if you have multiple products.';

  private readonly _eligibleAccountAccess = [
    AccountAccessType.ACTIVATED_1,
    AccountAccessType.ACTIVATED_2,
    AccountAccessType.ACTIVE_150,
    AccountAccessType.CSR_RESET_1,
    AccountAccessType.CSR_RESET_110,
    AccountAccessType.ENABLED,
    AccountAccessType.INSTANT_2,
    AccountAccessType.PARTIAL_1,
    AccountAccessType.PROXY_1,
    AccountAccessType.PROXY_RESET_PASSWORD,
    AccountAccessType.RESET_PASSWORD_1,
    AccountAccessType.RESET_PASSWORD_110
  ];

  constructor() {
    super();

    this.sessionService = AppInjector.injector.get(CUSTOM_SESSION) as CustomSessionService;
    this.navigationService = AppInjector.injector.get(NavigationService);
    this.eligibilityService = AppInjector.injector.get(EligibilityService);
  }

  ngOnInit() {
    this.spinnerService.show(this._spinnerMessage);
    this._checkEligibility();
  }

  _processInternetRegistrations(internetRegistrations: Array<InternetRegistration>) {
    if (internetRegistrations.length > 1) {
      throw new Error('Customer has multiple, active internet registrations.');
    }

    if (internetRegistrations.length === 1) {
      const internetRegistration: InternetRegistration = internetRegistrations[0];
      const accountAccess: AccountAccess = findAccountAccess(
        internetRegistration.accountStatus,
        internetRegistration.accessLevel
      );
      const mfaStatus: MfaStatus = internetRegistration.mfaStatus;

      this.logger.info(this.className, 'Current account access after retrieval by ECN.', accountAccess);

      this.sessionService.setModifiedSessionObject(internetRegistration.guid, SessionKey.CUSTOMER, 'guid');
      this.sessionService.setModifiedSessionObject(accountAccess, SessionKey.CUSTOMER, 'accountAccess');
      this.sessionService.setModifiedSessionObject(mfaStatus, SessionKey.CUSTOMER, 'mfaStatus');
    }
  }

  _hasEligibileAccountAccess(customerAccountAccess: AccountAccess): boolean {
    if (!customerAccountAccess) {
      this.logger.error(this.className, 'Customer has an incomplete/invalid registration.');
      return false;
    }

    if (!this._eligibleAccountAccess.includes(customerAccountAccess.type)) {
      this.logger.error(this.className, 'Current account access is invalid.');
      return false;
    }

    return true;
  }

  abstract _checkEligibility(): void;

  get commonFlow(): CommonFlow {
    return CommonFlow.ELIGIBILITY;
  }
}
