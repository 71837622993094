import { ControlErrors, Controls } from '@shared/base-component/base.model';
import { CONTROL_ERROR_MESSAGES, CONTROL_PATTERNS } from '@shared/validation/control-constants';

export const CONTROLS: Controls = {
  username: {
    id: 'reset-username-password-username',
    patterns: CONTROL_PATTERNS.createUsername,
    ariaDescribedByErrors: 'reset-username-errors',
    ariaDescribedBy:
      'username-validations-header username-length-aria-label username-no-spaces-label username-letter-label username-excluded-characters-aria-label'
  },
  password: {
    id: 'reset-username-password-password',
    patterns: CONTROL_PATTERNS.createPassword,
    ariaDescribedByErrors: 'reset-password-errors',
    ariaDescribedBy:
      'password-validations-header password-length-aria-label password-no-spaces-label password-letter-label password-number-or-special-aria-label password-excluded-characters-aria-label'
  },
  passwordConfirmation: {
    id: 'reset-username-password-confirmPassword',
    ariaDescribedByErrors: 'reset-password-password-confirmation-errors'
  }
};

export const CONTROL_ERRORS: ControlErrors = {
  username: {
    pattern: CONTROL_ERROR_MESSAGES.usernamePattern,
    usernameUnavailable: CONTROL_ERROR_MESSAGES.usernameUnavailable
  },
  password: {
    required: CONTROL_ERROR_MESSAGES.passwordRequired,
    pattern: CONTROL_ERROR_MESSAGES.passwordPattern,
    usernamePasswordMatch: CONTROL_ERROR_MESSAGES.usernamePasswordMatch,
    previousPasswordUsed: CONTROL_ERROR_MESSAGES.previousPasswordUsed
  },
  passwordConfirmation: {
    required: CONTROL_ERROR_MESSAGES.passwordConfirmationRequired,
    passwordMismatch: CONTROL_ERROR_MESSAGES.passwordConfirmationPasswordMismatch
  }
};
