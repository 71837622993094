export enum PhoneNumber {
  ANNUITY_LIFE_SUPPORT = '1-888-867-5175',
  BILLING_SUPPORT = '1-877-262-0247',
  CLAIMS_SUPPORT = '1-800-421-3535',
  COMMERCIAL_SERVICE_CENTER = '1-888-508-8622',
  LIFE_INVESTING_SUPPORT = '1-800-848-6331',
  NW_SERVICE_CENTER = '1-877-669-6877',
  RETIREMENT_PLAN_SUPPORT = '1-800-772-2182',
  SMARTRIDE_SUPPORT = '1-855-421-2688',
  WEB_SUPPORT = '1-877-304-1065',
  PET_SUPPORT = '1-855-331-2832'
}
