import { Component, OnInit } from '@angular/core';
import { ENVIRONMENT } from '@environments/environment';
import { ContentHeaderModel } from '@shared/content-header/content-header.model';
import { ContentHeaderService } from '@shared/content-header/content-header.service';
import { NavigationService } from '@shared/navigation/navigation.service';
import { SessionKey } from '@shared/storage/storage.model';
import { ForgotCredentialsBaseComponent } from '../shared/forgot-credentials-base.component';

@Component({
  selector: 'ciam-reset-username-password-confirmation',
  templateUrl: './reset-username-password-confirmation.component.html',
  styleUrls: ['./reset-username-password-confirmation.component.scss']
})
export class ResetUsernamePasswordConfirmationComponent extends ForgotCredentialsBaseComponent implements OnInit {
  userName: string;

  constructor(private _contentHeaderService: ContentHeaderService, private _navigationService: NavigationService) {
    super();
  }

  ngOnInit() {
    this._contentHeaderService.updateProgress(this.contentHeaderModel);
    this.userName = this.sessionService.get(SessionKey.CUSTOMER)?.userName;
  }

  continue() {
    this._navigationService.navigateExternal(
      `${ENVIRONMENT.externalUrls.iam.basePath}${ENVIRONMENT.externalUrls.iam.login}`
    );
  }

  get contentHeaderModel(): ContentHeaderModel {
    return this.fromRegistration ? {} : { progressTitle: 'Confirmation', progressPercent: 100 };
  }
}
