<div class="d-flex flex-column">
  <form
    id="business-info-form"
    class="d-flex flex-column justify-content-between page-content"
    [formGroup]="businessInformationForm"
    #businessInformationFormParent="ngForm"
    (ngSubmit)="resetPageErrorAndFocus()"
  >
    <section id="business-info-content" class="container">
      <div id="business-info-header" class="content-section">
        <bolt-alert-error
          #pageLevelError
          _id="page-level-error-invalid"
          [hidden]="!(businessInformationFormParent.submitted && businessInformationForm.invalid)"
        >
          Please correct the following to continue.
        </bolt-alert-error>
        <bolt-alert-error #pageLevelError _id="page-level-error-try-again" [hidden]="notFoundSearchAttempts === 0">
          <ciam-account-not-found-error></ciam-account-not-found-error>
        </bolt-alert-error>
        <h2 #adaAutoFocus id="business-info-title" class="h5">
          <strong>
            Enter your business information.
          </strong>
        </h2>
        <p id="business-info-message">
          We'll need to look up your business in our records to verify your account and protect your privacy and
          security.
        </p>
      </div>
      <div id="business-info-inputs">
        <fieldset class="content-section">
          <legend>
            <h2 id="your-business-info-title" class="h5 pre-form-content">
              Business information
            </h2>
            <p id="your-business-info-message" class="h6 font-weight-normal pre-form-content">
              All fields are required
            </p>
          </legend>
          <div class="form-group">
            <bolt-textfield
              [_id]="businessInformationControls.accountNumber.id"
              _name="accountNumber"
              [_aria-describedby]="businessInformationControls.accountNumber.ariaDescribedBy"
              maxlength="30"
              [formGroup]="businessInformationForm"
              [parentForm]="businessInformationFormParent"
            >
              Account number
            </bolt-textfield>
            <bolt-field-error
              id="account-number-errors"
              _id="account-number-errors"
              _class="field-error"
              [errors]="businessInformationForm.get('accountNumber').errors"
              [errorMessages]="businessInformationControlErrors.accountNumber"
              *ngIf="businessInformationFormParent.submitted"
            >
            </bolt-field-error>
          </div>
          <div class="mt-3">
            <ciam-help _id="commercial-agreement" buttonContent="Need help finding your account number?">
              <p id="commercial-bill-and-quote-information">
                You can find this on your bill or quote as shown in the blue highlighted area.
              </p>
              <p id="commercial-quote-title">Commercial quote</p>
              <img
                id="commercial-quote-image"
                src="assets/commercial-quote.png"
                alt="On your commercial quote, your account number is upper left, below the Nationwide logo."
                class="account-number-image"
              />
              <p id="commercial-bill-title" class="mt-3">Commercial bill</p>
              <img
                id="commercial-bill-image"
                src="assets/commercial-bill.png"
                alt="On your commercial bill, your account number is upper right, below date prepared."
                class="account-number-image"
              />
            </ciam-help>
          </div>
        </fieldset>
      </div>
    </section>

    <section id="business-info-buttons" class="button-container">
      <div class="container button-stack">
        <bolt-button-primary ciamClickLogger _id="business-info-find-account" _type="submit">
          Find business account
        </bolt-button-primary>
        <input
          ciamClickLogger
          type="button"
          id="business-info-back"
          class="btn nw-btn-inline"
          value="Back"
          (click)="backToAccountType()"
        />
        <input
          ciamClickLogger
          type="button"
          id="business-info-cancel"
          class="btn btn-default"
          value="Cancel"
          data-cancel-trigger
        />
      </div>
    </section>
  </form>
</div>
