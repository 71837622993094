import { UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export const validatePasswordsMatch: ValidatorFn = (formGroup: UntypedFormGroup): ValidationErrors | null => {
  const passwordConfirmation = formGroup.get('passwordConfirmation');

  if (passwordConfirmation.value && passwordConfirmation.value !== formGroup.get('password').value) {
    passwordConfirmation.setErrors({ passwordMismatch: true });
  }

  return null;
};
