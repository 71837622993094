<div class="container mt-3 mb-3">
  <h2 class="d-flex flex-column">Sign up for online access</h2>
</div>
<div class="d-flex flex-column">
  <div class="d-flex flex-column justify-content-between page-content">
    <section id="register-success-content" class="container">
      <span #adaAutoFocus tabindex="-1" class="m-0 p-0 no-outline">
        <!-- This element is used purely for on-load ADA focusing. -->
      </span>
      <div
        [attr.id]="fromForgotCredentials ? 'main-content' : 'register-success-header'"
        class="content-section no-outline"
        tabindex="-1"
      >
        <!-- TODO: The following aria-describedby should probably be an aria-labelledby. -->
        <bolt-alert-confirmation _id="alert-confirmation" _aria-describedby="progress-bar-label">
          <strong>Success!</strong> Your registration is complete.
        </bolt-alert-confirmation>
        <h2 #adaAutoFocus class="h5" id="success-title">
          <strong>Your account is ready</strong>
        </h2>
        <p id="user-info">You now have 24/7 access to your account online. <br /></p>
        <p class="mb-0">
          Your username is: <strong>{{ userName }}</strong>
        </p>
      </div>
    </section>
    <div class="banner">
      <div class="container banner-container">
        <div *ngIf="!isMobile" class="left-content">
          <div class="qr-container"><img alt="Nationwide QR Code" src="assets/banner-appstore-qrcode.png" /></div>
          <div class="qr-code-description">Scan this QR code to download the app and use your new log in</div>
        </div>
        <div class="right-content">
          <h4 class="content-title">
            Get easy 24/7 support on our mobile app
          </h4>
          <div class="content-description">
            Easy way to pay bills. Easy access to policy documents. Easy access to ID cards.
          </div>
          <div *ngIf="isMobile" class="btn-container">
            <button type="button" class="btn nw-btn-inline-inverse" (click)="redirectToAppStore()">
              Download from the app store
            </button>
          </div>
        </div>
      </div>
    </div>
    <section id="register-success-buttons" class="button-container">
      <div class="container button-stack">
        <bolt-button-primary ciamClickLogger _type="button" _id="register-success-continue" (click)="continue()">
          Continue to account
        </bolt-button-primary>
      </div>
    </section>
  </div>
</div>
