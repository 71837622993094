import { Component } from '@angular/core';
import { MfaBaseComponent } from '../shared/mfa-base.component';

@Component({
  selector: 'ciam-expired-code',
  templateUrl: './expired-code.component.html',
  styleUrls: ['./expired-code.component.scss']
})
export class ExpiredCodeComponent extends MfaBaseComponent {
  protected readonly className = 'ExpiredCodeComponent';
}
