import { NgModule } from '@angular/core';
import { RouterModule, Routes, mapToCanActivateChild } from '@angular/router';
import { Flow } from '@shared/navigation/flow.enum';
import { NavigationGuard } from '@shared/navigation/navigation.guard';
import { AUTHORIZATION_PATHS } from './authorization-paths';
import { AuthorizeComponent } from './authorize/authorize.component';
import { CallbackComponent } from './callback/callback.component';

export const AUTHORIZATION_ROUTES: Routes = [
  {
    path: Flow.AUTHORIZATION,
    canActivateChild: mapToCanActivateChild([NavigationGuard]),
    children: [
      { path: '', pathMatch: 'full', redirectTo: AUTHORIZATION_PATHS.authorize },
      {
        path: AUTHORIZATION_PATHS.authorize,
        component: AuthorizeComponent
      },
      {
        path: AUTHORIZATION_PATHS.callback,
        component: CallbackComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(AUTHORIZATION_ROUTES)],
  exports: [RouterModule]
})
export class AuthorizationRoutingModule {}
