import { Component } from '@angular/core';
import { ELIGIBILITY_PATHS } from '@common/eligibility/eligibility-route-paths';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ContentHeaderModel } from '@shared/content-header/content-header.model';
import { CommonFlow } from '@shared/navigation/flow.enum';
import { SearchType } from '../../shared/search.model';
import { IndividualInformationBaseComponent } from '../individual-information-base.component';
import { AlertMessage, AlertType } from '../individual-information.model';

@UntilDestroy()
@Component({
  selector: 'ciam-controller-individual-information',
  templateUrl: '../individual-information-base.component.html',
  styleUrls: ['../individual-information-base.component.scss']
})
export class ControllerIndividualInformationComponent extends IndividualInformationBaseComponent {
  protected readonly className = 'ControllerIndividualInformationComponent';

  _initializeForm() {
    super._initializeForm();
  }

  back() {
    // Nothing to do here; 'Back' button specifically removed from this page.
  }

  protected get notFoundAlert(): AlertMessage {
    return {
      type: AlertType.INFO,
      message:
        "We couldn't find you in our records. Verify that the information below is correct and we'll add you to our system, then link you and your business(es)."
    };
  }

  get contentHeaderModel(): ContentHeaderModel {
    return {
      progressTitle: 'Provide personal information',
      progressPercent: 20
    };
  }

  get individualInfoMessageText(): string {
    return "Next, we'll need to look you up in our records to link you and your business,";
  }

  get yourInfoTitleText(): string {
    return 'Personal information';
  }

  get yourInfoMessageText(): string {
    return null;
  }

  get findAccountButtonText(): string {
    if (this.notFoundSearchAttempts > 0) {
      return 'Confirm information';
    } else {
      return 'Submit';
    }
  }

  get isControllerSearch(): boolean {
    return true;
  }

  get searchType(): SearchType {
    return SearchType.CONTROLLER_INDIVIDUAL_INFORMATION;
  }

  get customerNotMatchedRoute(): string {
    return `${this.flow}/${CommonFlow.ELIGIBILITY}/${ELIGIBILITY_PATHS.controller}`;
  }
}
