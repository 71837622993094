import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@environments/environment';
import {
  ControllerInternetRegistrationResponse,
  IndividualInternetRegistrationResponse,
  InputDevice,
  PostControllerInternetRegistrationRequest,
  PostIndividualInternetRegistrationRequest,
  RegistrationManagementService
} from '@nationwide/api-client-internet-registration-v3';
import { CustomCookieService } from '@shared/storage/custom-cookie.service';
import { CustomSessionService, CUSTOM_SESSION } from '@shared/storage/custom-session.service';
import { Partner, SessionKey } from '@shared/storage/storage.model';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { CreateBusinessAccountModel, CreatePersonalAccountModel } from './registration.model';

// rsa.js
// eslint-disable-next-line @typescript-eslint/naming-convention
declare function encode_deviceprint(): string;

@Injectable()
export class RegistrationService {
  constructor(
    @Inject(CUSTOM_SESSION) private _sessionService: CustomSessionService,
    private _oauthService: OAuthService,
    private _registrationManagementService: RegistrationManagementService,
    private _cookieService: CustomCookieService
  ) {
    this.reset();
  }

  reset() {
    this._registrationManagementService.configuration.accessToken = this._oauthService.getAccessToken();
  }

  createAccount(createAccountModel: CreatePersonalAccountModel): Observable<IndividualInternetRegistrationResponse> {
    const request: PostIndividualInternetRegistrationRequest = {
      ecn: this._sessionService.get(SessionKey.CUSTOMER)?.ecn,
      userName: createAccountModel.userName,
      password: createAccountModel.password,
      acceptedEsa: createAccountModel.acceptedEsa
    };

    if (this._sessionService.get(SessionKey.MFA)?.rememberDevice !== undefined) {
      request.device = this._buildDevice();
    }

    if (createAccountModel.phoneNumber) {
      request.phoneNumber = createAccountModel.phoneNumber;
    }

    if (createAccountModel.email) {
      request.email = createAccountModel.email;
    }

    return this._registrationManagementService.individualInternetRegistrationsPost(
      request,
      ENVIRONMENT.apiConfig.clientId,
      this._cookieService.flowId,
      null,
      (this._sessionService.get(SessionKey.PARTNER) as Partner)?.id
    );
  }

  createBusinessAccount(
    createBusinessAccountModel: CreateBusinessAccountModel
  ): Observable<ControllerInternetRegistrationResponse> {
    const request: PostControllerInternetRegistrationRequest = {
      agreementNumber: createBusinessAccountModel.agreementNumber,
      agreementOwnerEcn: createBusinessAccountModel.agreementOwnerEcn,
      acceptedAttestation: createBusinessAccountModel.acceptedAttestation
    };

    if (createBusinessAccountModel.email) {
      request.email = createBusinessAccountModel.email;
    }

    if (createBusinessAccountModel.userName) {
      request.userName = createBusinessAccountModel.userName;
    }

    if (createBusinessAccountModel.password) {
      request.password = createBusinessAccountModel.password;
    }

    if (createBusinessAccountModel.acceptedEsa) {
      request.acceptedEsa = createBusinessAccountModel.acceptedEsa;
    }

    if (createBusinessAccountModel.controllerEcn) {
      request.controllerEcn = createBusinessAccountModel.controllerEcn;
    } else {
      request.controllerFirstName = createBusinessAccountModel.controllerFirstName;
      request.controllerLastName = createBusinessAccountModel.controllerLastName;
    }

    if (this._sessionService.get(SessionKey.MFA)?.rememberDevice !== undefined) {
      request.device = this._buildDevice();
    }

    if (createBusinessAccountModel.phoneNumber) {
      request.phoneNumber = createBusinessAccountModel.phoneNumber;
    }

    return this._registrationManagementService.controllerInternetRegistrationsPost(
      request,
      ENVIRONMENT.apiConfig.clientId,
      this._cookieService.flowId,
      (this._sessionService.get(SessionKey.PARTNER) as Partner)?.id
    );
  }

  _buildDevice(): InputDevice {
    return {
      deviceBind: this._sessionService.get(SessionKey.MFA).rememberDevice ? 'Y' : 'N',
      devicePrint: encode_deviceprint(), //NOSONAR
      channel: 'WEB',
      deviceToken: this._cookieService.deviceToken
    };
  }
}
