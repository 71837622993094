import { NgModule } from '@angular/core';
import { RouterModule, Routes, mapToCanActivate } from '@angular/router';
import { ENVIRONMENT } from '@environments/environment';
import { CommonFlow } from '@shared/navigation/flow.enum';
import { TimeoutGuard } from '@shared/timeout/timeout.guard';
import { PREFERENCES_PATHS } from './preferences-paths';
import { SetPreferencesComponent } from './set-preferences/set-preferences.component';

export const PREFERENCES_ROUTES: Routes = [
  {
    path: CommonFlow.PREFERENCES,
    canActivate: mapToCanActivate([TimeoutGuard]),
    data: {
      redirectURL: `${ENVIRONMENT.externalUrls.iam.basePath}${ENVIRONMENT.externalUrls.iam.sessionExpired}`
    },
    children: [
      {
        path: PREFERENCES_PATHS.setPreferences,
        component: SetPreferencesComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(PREFERENCES_ROUTES)],
  exports: [RouterModule]
})
export class PreferencesRoutingModule {}
