import { Component } from '@angular/core';
import { oauthConfig } from '@config/oauth.config';
import { ENVIRONMENT } from '@environments/environment';
import { url } from '@shared/document/document.util';
import { SpinnerService } from '@shared/spinner/spinner.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { DestinationType } from './authorization/shared/authorization.model';

@Component({
  selector: 'ciam-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private _oauthService: OAuthService, public spinnerService: SpinnerService) {
    const clientId = url.url().includes(`destination=${DestinationType.PROXY_REGISTRATION}`)
      ? ENVIRONMENT.apiConfig.proxyClientId
      : ENVIRONMENT.apiConfig.clientId;

    this._oauthService.configure(oauthConfig(clientId));
    this._oauthService.loadDiscoveryDocument();
  }

  handleSkipToMainContent(event: Event) {
    event.preventDefault();

    const mainContentElement = document.getElementById('main-content');
    if (mainContentElement) {
      mainContentElement.focus();
    }
  }
}
