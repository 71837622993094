<!-- Main Content -->
<div class="d-flex flex-column">
  <form
    id="verify-code-form"
    class="d-flex flex-column justify-content-between page-content"
    [formGroup]="verifyCodeForm"
    #verifyCodeFormParent="ngForm"
    (ngSubmit)="resetPageErrorAndFocus()"
  >
    <section id="verify-code-content" class="container">
      <div class="row justify-content-center">
        <div class="col-lg">
          <div id="verify-code-header" class="content-section">
            <bolt-alert-error
              #pageLevelError
              _id="page-level-error"
              [hidden]="!(verifyCodeFormParent.submitted && verifyCodeForm.get('code').errors)"
            >
              Please correct the following to continue.
            </bolt-alert-error>
            <h2 #adaAutoFocus id="verify-code-title" class="h5">
              <strong>
                We've sent you a verification code. It can take up to fifteen minutes for this code to arrive.
              </strong>
            </h2>
            <p id="verify-code-message">
              This code will be valid for 15 minutes. Please keep this screen open. You will have to request a new code
              if you close it. Please enter the code below so we can verify your identity.
            </p>
          </div>
          <div id="verify-code-inputs" class="content-section">
            <div class="form-group">
              <bolt-textfield
                [_id]="verifyCodeControls.code.id"
                _name="code"
                _class="code-text-field"
                [_aria-describedby]="verifyCodeControls.code.ariaDescribedBy"
                placeholder="______"
                inputmode="numeric"
                maxlength="6"
                [mask]="codeMask"
                [formGroup]="verifyCodeForm"
                [parentForm]="verifyCodeFormParent"
                (keydown)="applyCodeMask()"
                (keyup)="checkForDigitEntry($event)"
                (paste)="checkForDigitPaste($event)"
                (drop)="checkForDigitDrag($event)"
              >
                Six-digit code
              </bolt-textfield>
              <bolt-field-error
                id="enter-code-errors"
                _id="enter-code-errors"
                _class="field-error"
                [errors]="verifyCodeForm.get('code').errors"
                [errorMessages]="verifyCodeControlErrors.code"
                *ngIf="verifyCodeFormParent.submitted"
              ></bolt-field-error>
              <span *ngIf="showNumbersOnlyHelpText" id="numbers-only-help-message" class="help-text">
                Only enter numbers
              </span>
              <span [ngClass]="{ 'mt-0': showNumbersOnlyHelpText }" id="enter-code-help-message" class="help-text">
                <em>Open the text or email to find the six-digit code.</em>
              </span>
            </div>
          </div>
        </div>
        <div class="col-auto content-section" hidden>
          <ciam-express-links></ciam-express-links>
        </div>
      </div>
    </section>
    <section id="verify-code-buttons" class="button-container">
      <div class="container button-stack">
        <bolt-button-primary ciamClickLogger _id="verify-code-confirm" _type="submit">
          Confirm code
        </bolt-button-primary>
        <input
          #wheresMyCodeModalButton
          ciamClickLogger
          type="button"
          id="verify-code-wheres-my-code"
          class="btn nw-btn-inline"
          value="Where's my code?"
        />
        <input
          ciamClickLogger
          type="button"
          id="verify-code-cancel"
          class="btn btn-default"
          value="Cancel"
          data-cancel-trigger
        />
      </div>
    </section>
  </form>
</div>

<!-- "Where's my code?" Modal -->
<bolt-modal
  _id="wheres-my-code-modal"
  [triggerElements]="[wheresMyCodeModalButton]"
  [buttons]="[
    { label: 'Continue', primary: true },
    { label: 'Send a new code', primary: false }
  ]"
  (buttonClicked)="handleWheresMyCodeModalSelection($event)"
>
  <div id="wheres-my-code-modal-content">
    <p id="wheres-my-code-modal-line-1"><strong>Your code may take up to 15 minutes to arrive.</strong></p>
    <p id="wheres-my-code-modal-line-2">Please keep the "Enter code" window open while you wait.</p>
    <p id="wheres-my-code-modal-line-3">
      To make sure the code is delivered to
      <span class="text-nowrap">{{ selectedContactPoint ? selectedContactPoint.value : '' }}</span
      >:
    </p>
    <ul id="wheres-my-code-modal-line-4">
      <li>Give it a minute to come through</li>
      <li>{{ mfaModalInstructions }}</li>
    </ul>
    <p id="wheres-my-code-modal-line-5">
      If your code still has not arrived after 15 minutes, then we can send a new code by text or email to replace it.
    </p>
  </div>
</bolt-modal>
