import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Flow } from '@shared/navigation/flow.enum';
import { VersionComponent } from './version/version.component';

export const VERSION_ROUTES: Routes = [
  {
    path: Flow.VERSION,
    component: VersionComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(VERSION_ROUTES)],
  exports: [RouterModule]
})
export class VersionRoutingModule {}
