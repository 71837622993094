import { Component, Input } from '@angular/core';
import { SEARCH_PATHS } from '@common/search/search-paths';
import { ENVIRONMENT } from '@environments/environment';
import { CommonFlow } from '@shared/navigation/flow.enum';
import { NavigationService } from '@shared/navigation/navigation.service';
import { SearchBaseComponent } from '../search-base.component';
import { SearchFlowType, SearchType } from '../search.model';

@Component({
  selector: 'ciam-account-not-found-error',
  templateUrl: './account-not-found-error.component.html',
  styleUrls: ['./account-not-found-error.component.scss']
})
export class AccountNotFoundErrorComponent extends SearchBaseComponent {
  @Input() hasStartOverMessage: string;
  searchFlowTypes = SearchFlowType;

  private readonly _defaultTryAgainMessage =
    'Please check your information and try again. For assistance, call web support at:';
  private readonly _defaultBusinessTryAgainMessage =
    'Please check that you are using the account or quote number located on your bill or quote and try again. For assistance, call web support at:';
  private readonly _startOverTryAgainMessage = 'You can try again from the beginning, or call us for assistance at:';

  constructor(private _navigationService: NavigationService) {
    super();
  }

  navigateToBusinessInformation() {
    this._navigationService.navigate(
      `${this.parentFlowBaseRoute}/${CommonFlow.SEARCH}/${SearchFlowType.BUSINESS}/${SEARCH_PATHS.identifier}`
    );
  }

  navigateToPersonalIndividualInformation() {
    this._navigationService.navigate(
      `${this.parentFlowBaseRoute}/${CommonFlow.SEARCH}/${SearchFlowType.PERSONAL}/${SEARCH_PATHS.individualInformation}`
    );
  }

  _handlePotentialMatch() {
    throw new Error('Method not implemented.');
  }

  _handleNoMatch() {
    throw new Error('Method not implemented.');
  }

  get tryAgainMessage(): string {
    if (this.hasStartOverMessage === 'true') {
      return this._startOverTryAgainMessage;
    } else {
      if (this.searchFlowType === SearchFlowType.BUSINESS) {
        return this._defaultBusinessTryAgainMessage;
      } else {
        return this._defaultTryAgainMessage;
      }
    }
  }

  get iamTrustRegistration(): string {
    return `${ENVIRONMENT.externalUrls.iam.basePath}${ENVIRONMENT.externalUrls.iam.trustRegistration}`;
  }

  get searchType(): SearchType {
    throw new Error('Method not implemented.');
  }
}
