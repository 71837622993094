import { ControlErrors, Controls } from '@shared/base-component/base.model';
import { CONTROL_ERROR_MESSAGES } from '@shared/validation/control-constants';

export const CONTROLS: Controls = {
  accountType: {
    id: 'account-type',
    ariaDescribedByErrors: 'account-type-errors'
  }
};

export const CONTROL_ERRORS: ControlErrors = {
  accountType: {
    required: CONTROL_ERROR_MESSAGES.accountTypeRequired
  }
};
