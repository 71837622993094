import { NON_INSTANT_ACTIVATION_PATHS } from '@app/non-instant-activation/non-instant-activation-paths';
import { PREFERENCES_PATHS } from '@app/preferences/preferences-paths';
import { PROXY_PATHS } from '@app/proxy/proxy-paths';
import { ELIGIBILITY_PATHS } from '@common/eligibility/eligibility-route-paths';
import { CommonFlow, Flow } from '@shared/navigation/flow.enum';

export enum AuthMethod {
  REGISTRATION_CUSTOMER_SEARCH = 'registration-customer-search',
  CONTEXT_CACHE = 'context-cache',
  PROXY_REGISTRATION_VERIFY = 'proxy-registration-verify',
  PING = 'ping'
}

export enum Realm {
  UNIDENTIFIED = 'unidentified',
  MEMBER = 'member'
}

export enum IdentityClaim {
  SUB = 'sub',
  ECN = 'ecn'
}

export interface Authorization {
  method: AuthMethod;
  realm: Realm;
  params?: string[];
  data?: {
    [name: string]: {
      claim: IdentityClaim;
      pattern?: RegExp;
    };
  };
}

export const AUTHORIZATIONS: Authorization[] = [
  {
    method: AuthMethod.REGISTRATION_CUSTOMER_SEARCH,
    realm: Realm.UNIDENTIFIED,
    params: ['assertion'],
    data: {
      ecn: {
        claim: IdentityClaim.ECN
      }
    }
  },
  {
    method: AuthMethod.CONTEXT_CACHE,
    realm: Realm.UNIDENTIFIED,
    params: ['contextId', 'otp'],
    data: {
      ecn: {
        claim: IdentityClaim.SUB,
        pattern: /unidentified:(.+)/i
      }
    }
  },
  {
    method: AuthMethod.PROXY_REGISTRATION_VERIFY,
    realm: Realm.UNIDENTIFIED,
    params: ['assertion'],
    data: {
      ecn: {
        claim: IdentityClaim.ECN
      }
    }
  },
  {
    method: AuthMethod.PING,
    realm: Realm.MEMBER,
    params: [],
    data: {
      ecn: {
        claim: IdentityClaim.ECN
      }
    }
  }
];

export enum DestinationType {
  /**
   * @deprecated This value need to remain here until external partners stop using it.
   */
  PERSONAL_REG_MFA = 'personalRegMfa',

  REG_STANDARD_ELIGIBILITY = 'regStandardEligibility',
  REG_CONTROLLER_ELIGIBILITY = 'regControllerEligibility',
  FUP_STANDARD_ELIGIBILITY = 'fupStandardEligibility',
  PROXY_REGISTRATION = 'proxyregistration',
  PROXY_REGISTRATION_EDIT = 'proxyRegistrationEdit',
  REGISTRATION = 'registration',
  RECOVERY = 'recovery',
  NON_INSTANT_ACTIVATION = 'nonInstantActivation',
  NON_INSTANT_ACTIVATION_SUCCESS = 'nonInstantActivationSuccess',
  SET_PREFERENCES = 'setPreferences',
  DIGITAL_REFUNDS = 'digitalRefunds',
  OTL_VERIFY = 'otlVerify',
  OTL_REGISTRATION = 'otlRegistration',
  OTL_RECOVERY = 'otlRecovery'
}

export interface Destination {
  type: DestinationType;
  route: string;
  allowedAuthMethods: AuthMethod[];
}

export const DESTINATIONS: Destination[] = [
  {
    type: DestinationType.REG_STANDARD_ELIGIBILITY,
    route: `${Flow.REGISTRATION}/${CommonFlow.ELIGIBILITY}/${ELIGIBILITY_PATHS.standard}`,
    allowedAuthMethods: [AuthMethod.REGISTRATION_CUSTOMER_SEARCH, AuthMethod.CONTEXT_CACHE]
  },
  {
    type: DestinationType.REG_CONTROLLER_ELIGIBILITY,
    route: `${Flow.REGISTRATION}/${CommonFlow.ELIGIBILITY}/${ELIGIBILITY_PATHS.controller}`,
    allowedAuthMethods: [AuthMethod.REGISTRATION_CUSTOMER_SEARCH]
  },
  {
    type: DestinationType.FUP_STANDARD_ELIGIBILITY,
    route: `${Flow.FORGOT_CREDENTIALS}/${CommonFlow.ELIGIBILITY}/${ELIGIBILITY_PATHS.standard}`,
    allowedAuthMethods: [AuthMethod.REGISTRATION_CUSTOMER_SEARCH, AuthMethod.CONTEXT_CACHE]
  },
  {
    type: DestinationType.PROXY_REGISTRATION,
    route: `${Flow.PROXY_REGISTRATION}/verify/${PROXY_PATHS.personalInformation}`,
    allowedAuthMethods: [AuthMethod.CONTEXT_CACHE]
  },
  {
    type: DestinationType.PROXY_REGISTRATION_EDIT,
    route: `${Flow.PROXY_REGISTRATION}/${PROXY_PATHS.updateUsernamePassword}`,
    allowedAuthMethods: [AuthMethod.PROXY_REGISTRATION_VERIFY]
  },
  {
    type: DestinationType.NON_INSTANT_ACTIVATION,
    route: `${Flow.NON_INSTANT_ACTIVATION}/verify/${NON_INSTANT_ACTIVATION_PATHS.activationKey}`,
    allowedAuthMethods: [AuthMethod.CONTEXT_CACHE]
  },
  {
    type: DestinationType.NON_INSTANT_ACTIVATION_SUCCESS,
    route: `${Flow.NON_INSTANT_ACTIVATION}/${NON_INSTANT_ACTIVATION_PATHS.updateAccount}`,
    allowedAuthMethods: [AuthMethod.CONTEXT_CACHE]
  },
  {
    type: DestinationType.SET_PREFERENCES,
    route: `${CommonFlow.PREFERENCES}/${PREFERENCES_PATHS.setPreferences}`,
    allowedAuthMethods: [AuthMethod.PING]
  },
  {
    type: DestinationType.DIGITAL_REFUNDS,
    route: `${CommonFlow.DIGITAL_REFUNDS}`,
    allowedAuthMethods: [AuthMethod.PING]
  },
  {
    type: DestinationType.OTL_VERIFY,
    route: `${Flow.PROXY_REGISTRATION}/verify/${PROXY_PATHS.personalInformation}`,
    allowedAuthMethods: [AuthMethod.CONTEXT_CACHE]
  },
  {
    type: DestinationType.OTL_REGISTRATION,
    route: `${Flow.REGISTRATION}/${CommonFlow.ELIGIBILITY}/${ELIGIBILITY_PATHS.standard}`,
    allowedAuthMethods: [AuthMethod.PROXY_REGISTRATION_VERIFY]
  },
  {
    type: DestinationType.OTL_RECOVERY,
    route: `${Flow.FORGOT_CREDENTIALS}/${CommonFlow.ELIGIBILITY}/${ELIGIBILITY_PATHS.standard}`,
    allowedAuthMethods: [AuthMethod.PROXY_REGISTRATION_VERIFY]
  }
];

export const findAuthorization = (type: string | AuthMethod): Authorization =>
  AUTHORIZATIONS.find(authorization => type === authorization.method);

export const findDestination = (type: string | DestinationType): Destination => {
  let destinationType: DestinationType | string;
  switch (type) {
    case DestinationType.PERSONAL_REG_MFA:
    case DestinationType.REGISTRATION:
      destinationType = DestinationType.REG_STANDARD_ELIGIBILITY;
      break;
    case DestinationType.RECOVERY:
      destinationType = DestinationType.FUP_STANDARD_ELIGIBILITY;
      break;
    default:
      destinationType = type;
      break;
  }
  return DESTINATIONS.find(destination => destinationType === destination.type);
};
