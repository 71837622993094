import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ENVIRONMENT } from '@environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root'
})
export class LoggingInterceptor implements HttpInterceptor {
  protected readonly className = 'LoggingInterceptor';

  private readonly _requestMessage = 'HTTP request sent.';
  private readonly _responseMessage = 'HTTP response received.';
  private readonly _maskString = '*****';

  constructor(private _logger: LoggingService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map(event => {
        // Blacklist client logging endpoint since this causes an infinite loop of client-logging requests.
        if (
          event instanceof HttpResponse &&
          !request.url.startsWith(ENVIRONMENT.logging.clientLoggingBasePath) &&
          !request.url.startsWith(ENVIRONMENT.apiConfig.services.bankAccountVerification.basePath) &&
          !request.url.startsWith(ENVIRONMENT.apiConfig.services.personalLinesBilling.basePath)
        ) {
          this._logRequest(request);
          this._logResponse(event);
        }

        return event;
      }),
      catchError(errorResponse => {
        // Blacklist client logging endpoint since this causes an infinite loop of client-logging requests.
        if (
          !request.url.startsWith(ENVIRONMENT.logging.clientLoggingBasePath) &&
          !request.url.startsWith(ENVIRONMENT.apiConfig.services.bankAccountVerification.basePath) &&
          !request.url.startsWith(ENVIRONMENT.apiConfig.services.personalLinesBilling.basePath)
        ) {
          this._logRequest(request);
          this._logResponse(errorResponse);
        }

        return throwError(errorResponse);
      })
    );
  }

  _logResponse(response: HttpResponse<any>) {
    const parsedResponse: any = { ...response };

    if (response.body) {
      parsedResponse.body = this._mask(response.body);
    }

    if (response.headers) {
      parsedResponse.headers = this._mask(response.headers);
    }

    if (response instanceof HttpErrorResponse || response.status >= 400) {
      this._logger.error(this.className, this._responseMessage, parsedResponse);
    } else {
      this._logger.info(this.className, this._responseMessage, parsedResponse);
    }
  }

  _logRequest(request: HttpRequest<any>) {
    const result: any = { ...request };

    if (request.body) {
      result.body = this._mask(request.body);
    }

    if (request.headers) {
      result.headers = this._mask(request.headers);
    }

    this._logger.info(this.className, this._requestMessage, result);
  }

  _mask(objectToMask: any) {
    const result = { ...this._parseMapToJson(objectToMask) };

    if (result.identifier) {
      const identifier = result.identifier.replace(/-/g, '');

      if (/^\d{9}$/i.test(identifier)) {
        result.identifier = this._maskString;
      }
    }

    ['sessionId', 'transactionId', 'access_token', 'id_token', 'refresh_token', 'Authorization', 'password'].forEach(
      fieldToMask => {
        if (result[fieldToMask]) {
          result[fieldToMask] = this._maskString;
        }
      }
    );

    return result;
  }

  _parseMapToJson(httpValuesMap: any): { [key: string]: string } {
    if (httpValuesMap instanceof HttpHeaders || httpValuesMap instanceof HttpParams) {
      const results: any = {};

      httpValuesMap.keys().forEach(key => {
        results[key] = httpValuesMap.get(key);
      });

      return results;
    } else {
      return httpValuesMap;
    }
  }
}
