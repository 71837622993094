import { Component, OnInit } from '@angular/core';
import { CommonFlow } from '@shared/navigation/flow.enum';
import { SEARCH_PATHS } from '../search-paths';
import { SearchBaseComponent } from '../shared/search-base.component';
import { AccountType, SearchFlowType, SearchType } from '../shared/search.model';

@Component({
  selector: 'ciam-start-over',
  templateUrl: './start-over.component.html',
  styleUrls: ['./start-over.component.scss']
})
export class StartOverComponent extends SearchBaseComponent implements OnInit {
  protected readonly className = 'StartOverComponent';

  ngOnInit() {
    this.logger.info(this.className, 'No additional search attempts available.', {
      previousSearch: this.searchService.model?.previousSearch
    });
  }

  startOver() {
    if (this.searchService.model.accountType === AccountType.BUSINESS) {
      if (this.searchService.model.previousSearch === SearchType.BUSINESS_IDENTIFIER) {
        this.backToAccountType();
      } else {
        this.navigationService.navigate(
          `${this.parentFlowBaseRoute}/${CommonFlow.SEARCH}/${SearchFlowType.BUSINESS}/${SEARCH_PATHS.identifier}`
        );
      }
    } else {
      this.navigationService.navigate(`${this.baseRoute}/${SEARCH_PATHS.individualInformation}`);
    }
  }

  _handlePotentialMatch() {
    throw new Error('Method not implemented.');
  }

  _handleNoMatch() {
    throw new Error('Method not implemented.');
  }

  get searchType(): SearchType {
    throw new Error('Method not implemented.');
  }
}
