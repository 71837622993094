import { ControlErrors, Controls } from '@shared/base-component/base.model';
import { CONTROL_ERROR_MESSAGES, CONTROL_PATTERNS } from '@shared/validation/control-constants';

export const CONTROLS: Controls = {
  dateOfBirth: {
    id: 'personal-information-dateOfBirth',
    label: 'Date of birth',
    helpText: 'mm/dd/yyyy',
    ariaDescribedByErrors: 'personal-information-dateOfBirth-errors',
    ariaDescribedBy: 'personal-information-dateOfBirth-help-message',
    patterns: CONTROL_PATTERNS.dateOfBirth,
    mask: '00/00/0000'
  },
  zipCode: {
    id: 'your-information-zip-code',
    patterns: CONTROL_PATTERNS.zipCode,
    helpText: 'input',
    ariaDescribedByErrors: 'your-information-zip-code-errors'
  }
};

export const CONTROL_ERRORS: ControlErrors = {
  dateOfBirth: {
    required: CONTROL_ERROR_MESSAGES.dateOfBirthRequired,
    pattern: CONTROL_ERROR_MESSAGES.dateOfBirthPattern
  },
  zipCode: {
    required: CONTROL_ERROR_MESSAGES.zipCodeRequired,
    pattern: CONTROL_ERROR_MESSAGES.zipCodePattern
  }
};
