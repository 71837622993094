<p id="account-not-found-error-msg">
  We cannot locate an account associated with this information. {{ tryAgainMessage }}
</p>
<div id="account-not-found-error-phone-list" class="phone-list">
  <div id="account-not-found-error-commercial-phone-list-item">
    <span class="phone-list-bullet">&bull;</span>
    <span id="account-not-found-error-commercial-phone" class="text-nowrap"
      ><ciam-phone-number key="COMMERCIAL_SERVICE_CENTER"></ciam-phone-number
    ></span>
    (commercial service center)
  </div>
  <div id="account-not-found-error-annuity-life-phone-list-item">
    <span class="phone-list-bullet">&bull;</span>
    <span id="account-not-found-error-annuity-life-phone" class="text-nowrap"
      ><ciam-phone-number key="ANNUITY_LIFE_SUPPORT"></ciam-phone-number
    ></span>
    (annuities &amp; life insurance)
  </div>
  <div id="account-not-found-error-retirement-phone-list-item">
    <span class="phone-list-bullet">&bull;</span>
    <span id="account-not-found-error-retirement-phone" class="text-nowrap"
      ><ciam-phone-number key="RETIREMENT_PLAN_SUPPORT"></ciam-phone-number
    ></span>
    (retirement plans)
  </div>
  <div id="account-not-found-error-smartride-phone-list-item">
    <span class="phone-list-bullet">&bull;</span>
    <span id="account-not-found-error-smartride-phone" class="text-nowrap"
      ><ciam-phone-number key="SMARTRIDE_SUPPORT"></ciam-phone-number
    ></span>
    (SmartRide&reg;)
  </div>
  <div id="account-not-found-error-pet-phone-list-item">
    <span class="phone-list-bullet">&bull;</span>
    <span id="account-not-found-error-pet-phone" class="text-nowrap"
      ><ciam-phone-number key="PET_SUPPORT"></ciam-phone-number
    ></span>
    (pet products)
  </div>
  <div id="account-not-found-error-other-phone-list-item">
    <span class="phone-list-bullet">&bull;</span>
    <span id="account-not-found-error-other-phone" class="text-nowrap"
      ><ciam-phone-number key="WEB_SUPPORT"></ciam-phone-number
    ></span>
    (all other products)
  </div>
</div>
<div *ngIf="isRegistrationFlow" class="mt-3" id="account-not-found-error-registration-links">
  Need to register for online access to a
  <ng-container [ngSwitch]="searchFlowType">
    <ng-container *ngSwitchCase="searchFlowTypes.PERSONAL">
      <a
        ciamClickLogger
        id="business-insurance-link"
        href="javascript:void(0)"
        (click)="navigateToBusinessInformation()"
        >business insurance account</a
      >
    </ng-container>
    <ng-container *ngSwitchCase="searchFlowTypes.BUSINESS">
      <a
        ciamClickLogger
        id="personal-insurance-link"
        href="javascript:void(0)"
        (click)="navigateToPersonalIndividualInformation()"
        >personal insurance account</a
      >
    </ng-container>
  </ng-container>
  or an
  <a id="trust-estate-corporate-asset-link" [attr.href]="iamTrustRegistration"
    >account owned by a trust/estate/corporate asset</a
  >?
</div>
