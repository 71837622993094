import { Component, OnInit } from '@angular/core';
import { ENVIRONMENT } from '@environments/environment';
import { ContentHeaderModel } from '@shared/content-header/content-header.model';
import { ContentHeaderService } from '@shared/content-header/content-header.service';
import { NavigationService } from '@shared/navigation/navigation.service';
import { SessionKey } from '@shared/storage/storage.model';
import { RegistrationBaseComponent } from '../shared/registration-base.component';

@Component({
  selector: 'ciam-instant-success',
  templateUrl: './instant-success.component.html',
  styleUrls: ['./instant-success.component.scss']
})
export class InstantSuccessComponent extends RegistrationBaseComponent implements OnInit {
  userName: string;

  protected readonly className = 'InstantSuccessComponent';

  constructor(private _contentHeaderService: ContentHeaderService, private _navigationService: NavigationService) {
    super();
  }

  ngOnInit() {
    this._contentHeaderService.updateProgress(this.contentHeaderModel);

    this.userName = this.sessionService.get(SessionKey.CUSTOMER)?.userName;
  }

  continue() {
    this._navigationService.navigateExternal(
      `${ENVIRONMENT.externalUrls.iam.basePath}${ENVIRONMENT.externalUrls.iam.login}`
    );
  }

  get contentHeaderModel(): ContentHeaderModel {
    return this.fromForgotCredentials
      ? {}
      : {
          progressTitle: 'Confirmation',
          progressPercent: 100
        };
  }
}
