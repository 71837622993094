import { PartyType } from '@shared/storage/storage.model';

export enum VerifyCustomerType {
  PROXY_ADDITIONAL_IDENTIFIER = 'proxyRegistratonAdditionalIdentifier',
  PROXY_PERSONAL_INFORMATION = 'proxyRegistrationPersonalInformation'
}

export enum IdentifierType {
  SOCIAL = 'social',
  ACCOUNT = 'account'
}

export enum VerifyCustomerResult {
  VERIFIED = 'verified',
  NOT_VERIFIED = 'notVerified',
  ERROR = 'error'
}

export interface VerifyCustomerCriteria {
  input: {
    zipCode?: string;
    taxIdentifier?: string;
    agreementNumber?: string;
    dateOfBirth?: string;
  };

  filters?: {
    partyType?: PartyType;
  };
}

export enum ProxyRegistrationPersonalInformationType {
  DATE_OF_BIRTH = 'dateOfBirth',
  ZIP_CODE = 'zipCode'
}

export interface VerifyCustomerModel {
  identifier?: IdentifierType;
  proxyRegistrationPersonalInformation?: ProxyRegistrationPersonalInformationType;
  verifyCustomerCriteria?: VerifyCustomerCriteria;
  previousVerify?: VerifyCustomerType;
}
