import { Component } from '@angular/core';
import { AppInjector } from '@app/app-injector';
import { BaseComponent } from '@shared/base-component/base.component';
import { ContentHeaderModel } from '@shared/content-header/content-header.model';
import { CustomSessionService, CUSTOM_SESSION } from '@shared/storage/custom-session.service';
import { Authorization, SessionKey } from '@shared/storage/storage.model';
import { Destination, DestinationType } from './authorization.model';

@Component({ template: '' })
export abstract class AuthorizationBaseComponent extends BaseComponent {
  hideSpinnerOnLoad = false;
  destination: Destination;

  protected sessionService: CustomSessionService;

  protected className = 'AuthorizationBaseComponent';

  constructor() {
    super();

    this.sessionService = AppInjector.injector.get(CUSTOM_SESSION) as CustomSessionService;
  }

  get contentHeaderModel(): ContentHeaderModel {
    const header = (this.sessionService.get(SessionKey.AUTHORIZATION) as Authorization)?.contentHeader;
    if (header) {
      return header;
    }

    switch (this.destination?.type) {
      case DestinationType.REG_STANDARD_ELIGIBILITY:
      case DestinationType.REG_CONTROLLER_ELIGIBILITY:
        return {
          title: 'Sign up for online access',
          progressTitle: 'Find account',
          progressPercent: 10
        };
      case DestinationType.FUP_STANDARD_ELIGIBILITY:
        return {
          title: 'Forgot username/password',
          progressTitle: 'Find account',
          progressPercent: 10
        };
      default:
        return {};
    }
  }
}
