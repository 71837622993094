import { NgModule } from '@angular/core';
import {
  BoltAlertConfirmationModule,
  BoltAlertErrorModule,
  BoltAlertWarningModule,
  BoltButtonPrimaryModule,
  BoltButtonStandardModule,
  BoltCheckBoxModule,
  BoltFieldErrorModule,
  BoltRadioButtonModule,
  BoltRadioGroupModule,
  BoltTextFieldModule
} from '@nw/bolt-angular';
import { SharedModule } from '@shared/shared.module';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { ExpiredCodeComponent } from './expired-code/expired-code.component';
import { InvalidPhoneComponent } from './invalid-phone/invalid-phone.component';
import { SendCodeComponent } from './send-code/send-code.component';
import { MfaService } from './shared/mfa.service';
import { UndeliverableCodeComponent } from './undeliverable-code/undeliverable-code.component';
import { VerifyCodeComponent } from './verify-code/verify-code.component';

@NgModule({
  declarations: [
    UndeliverableCodeComponent,
    SendCodeComponent,
    InvalidPhoneComponent,
    VerifyCodeComponent,
    ExpiredCodeComponent,
    ConfirmationComponent
  ],
  imports: [
    SharedModule,
    BoltAlertConfirmationModule,
    BoltAlertErrorModule,
    BoltAlertWarningModule,
    BoltButtonPrimaryModule,
    BoltButtonStandardModule,
    BoltCheckBoxModule,
    BoltFieldErrorModule,
    BoltRadioButtonModule,
    BoltRadioGroupModule,
    BoltTextFieldModule
  ],
  providers: [MfaService]
})
export class MfaModule {}
