import { NgModule } from '@angular/core';
import {
  BoltAlertConfirmationModule,
  BoltAlertErrorModule,
  BoltButtonPrimaryModule,
  BoltFieldErrorModule,
  BoltTextFieldModule
} from '@nw/bolt-angular';
import { SharedModule } from '@shared/shared.module';
import { ForgotCredentialsRoutingModule } from './forgot-credentials-routing.module';
import { ResetUsernamePasswordConfirmationComponent } from './reset-username-password-confirmation/reset-username-password-confirmation.component';
import { ResetUsernamePasswordComponent } from './reset-username-password/reset-username-password.component';
import { ForgotCredentialsService } from './shared/forgot-credentials.service';

@NgModule({
  declarations: [ResetUsernamePasswordComponent, ResetUsernamePasswordConfirmationComponent],
  imports: [
    ForgotCredentialsRoutingModule,
    SharedModule,
    BoltTextFieldModule,
    BoltButtonPrimaryModule,
    BoltFieldErrorModule,
    BoltAlertErrorModule,
    BoltAlertConfirmationModule
  ],
  providers: [ForgotCredentialsService]
})
export class ForgotCredentialsModule {}
