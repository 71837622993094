import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthMethod, DestinationType } from '@app/authorization/shared/authorization.model';
import { oauthConfig } from '@config/oauth.config';
import { ENVIRONMENT } from '@environments/environment';
import { LogLevel } from '@nw/eim-angular-logging';
import { AdaBaseComponent } from '@shared/base-component/ada-base.component';
import { NavigationService } from '@shared/navigation/navigation.service';
import { CustomSessionService, CUSTOM_SESSION } from '@shared/storage/custom-session.service';
import { SessionKey } from '@shared/storage/storage.model';
import { OAuthService } from 'angular-oauth2-oidc';
import { ONE_TIME_LINK_PATHS } from '../one-time-link-paths';

@Component({
  selector: 'ciam-one-time-link',
  templateUrl: './one-time-link.component.html',
  styleUrls: ['./one-time-link.component.scss']
})
export class OneTimeLinkComponent extends AdaBaseComponent implements OnInit {
  constructor(
    private _route: ActivatedRoute,
    private _navigationService: NavigationService,
    @Inject(CUSTOM_SESSION) private _sessionService: CustomSessionService,
    private _oauthService: OAuthService
  ) {
    super();
  }

  ngOnInit(): void {
    const decodedIdSegments = this._decodeValidateAndSplitId();
    if (ENVIRONMENT.features.oneTimeLink.enabled && decodedIdSegments) {
      this._sessionService.setPartnerId('CustomerOTL');
      this._sessionService.set(SessionKey.ONE_TIME_LINK, { usedOneTimeLink: true, contextId: decodedIdSegments[0] });

      this.logger.info(this.className, `OTL used with contextId: ${decodedIdSegments[0]}`);

      this._oauthService.configure(oauthConfig(ENVIRONMENT.apiConfig.proxyClientId));
      this._oauthService.loadDiscoveryDocument();
      this._navigationService.navigateToAuthorization(AuthMethod.CONTEXT_CACHE, {
        contextId: decodedIdSegments[0],
        otp: decodedIdSegments[1],
        destination: DestinationType.OTL_VERIFY
      });
    } else {
      this._navigationService.navigateToError(
        this.className,
        new Error('OTL id could not be processed.'),
        `${this.baseRoute}/${ONE_TIME_LINK_PATHS.invalidLink}`,
        null,
        LogLevel.WARN
      );
    }
  }

  _decodeValidateAndSplitId(): string[] {
    try {
      const decodedId = atob(this._route.snapshot.queryParamMap.get('id')).trim();
      if (decodedId.match(/[a-zA-Z\-\d]+:[a-zA-Z\-\d]+/)) {
        return decodedId.split(':');
      }
    } catch (error) {
      this.logger.error(this.className, 'Error occurred decoding OTL id.', error);
    }

    return null;
  }
}
