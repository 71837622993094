<div class="d-flex flex-column">
  <form
    id="account-type-form"
    class="d-flex flex-column justify-content-between page-content"
    [formGroup]="accountTypeForm"
    #accountTypeFormParent="ngForm"
    (ngSubmit)="resetPageErrorAndFocus()"
  >
    <section id="account-type-content" class="container">
      <div id="account-type-header" class="content-section">
        <bolt-alert-error
          #pageLevelError
          _id="page-level-error"
          [hidden]="!(accountTypeFormParent.submitted && accountTypeForm.invalid)"
        >
          Please correct the following to continue.
        </bolt-alert-error>
        <h2 #adaAutoFocus id="account-type-title" class="h5">
          <strong>
            Please select the type of account you have with Nationwide.
          </strong>
        </h2>
        <p id="account-type-message">
          Nationwide provides different account types depending on your specific needs. In order to retrieve your
          account, we need different information for each account type. This is part of our effort to protect your
          privacy and security.
        </p>
      </div>
      <div id="account-type-inputs" class="content-section">
        <fieldset id="account-type-fieldset">
          <legend class="pre-form-content">
            <h2 id="account-type-form-title" class="h5">
              Which type of account do you have?
            </h2>
          </legend>
          <div [id]="accountTypeControls.accountType.id" class="field-error">
            <div class="form-check mobile-friendly-radio">
              <bolt-radio-button
                [_id]="accountTypeControls.accountType.id + '-' + accountTypes.PERSONAL"
                _name="accountType"
                [checked]="null"
                label="Personal/Individual insurance and financial products"
                [value]="accountTypes.PERSONAL"
                [formGroup]="accountTypeForm"
              >
              </bolt-radio-button>
            </div>
            <div class="form-check mobile-friendly-radio">
              <bolt-radio-button
                [_id]="accountTypeControls.accountType.id + '-' + accountTypes.BUSINESS"
                _name="accountType"
                [checked]="null"
                label="Commercial/Business"
                [value]="accountTypes.BUSINESS"
                [formGroup]="accountTypeForm"
              >
              </bolt-radio-button>
            </div>
            <div class="form-check">
              <bolt-radio-button
                [_id]="accountTypeControls.accountType.id + '-' + accountTypes.TRUST"
                _name="accountType"
                [checked]="null"
                label="Trust/Estate/Corp Asset"
                [value]="accountTypes.TRUST"
                [formGroup]="accountTypeForm"
              >
              </bolt-radio-button>
            </div>
          </div>
          <bolt-field-error
            id="account-type-errors"
            _id="account-type-errors"
            [errors]="accountTypeForm.get('accountType').errors"
            [errorMessages]="accountTypeControlErrors.accountType"
            *ngIf="accountTypeFormParent.submitted"
          ></bolt-field-error>
          <div class="mt-3">
            <ciam-help _id="account-type" buttonContent="Need help choosing?">
              <p id="account-type-help-text-1">
                Here are the different options you can select.
              </p>
              <p id="account-type-help-text-2">
                <strong>Personal/Individual insurance and financial products</strong><br />Select this option to access
                your personal insurance or financial products online, including auto, home, life, annuities, retirement
                plans and pet.
              </p>
              <p id="account-type-help-text-3">
                <strong>Commercial/Business insurance</strong><br />Select this option to access your business insurance
                policy online. This option is only available if you're the business owner or an employee who is
                authorized to manage insurance policies for your company.
              </p>
              <p id="account-type-help-text-4" class="mb-0">
                <strong>Manager of a trust/estate/corporate asset</strong><br />Select this option to access your
                personal insurance or financial products online, including auto, home, life, annuities and retirement
                plans AND the owner of the account is in the name of a trust, custodian, corporation or estate.
              </p>
            </ciam-help>
          </div>
        </fieldset>
      </div>
    </section>
    <section id="account-type-buttons" class="button-container">
      <div class="container button-stack">
        <bolt-button-primary ciamClickLogger _id="account-type-continue" _type="submit">
          Continue
        </bolt-button-primary>
        <input
          ciamClickLogger
          type="button"
          id="account-type-cancel"
          class="btn btn-default"
          value="Cancel"
          data-cancel-trigger
        />
      </div>
    </section>
  </form>
</div>
