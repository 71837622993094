export interface CancelModel {
  modalHeader?: string;
  modalBody?: string;
}

export enum CancelHeaderMessages {
  DEFAULT = 'Are you sure you want to leave this page?'
}

export enum CancelBodyMessages {
  DEFAULT = 'If you leave this page, your information will not be saved.',
  MFA = 'If you leave this page, your information will not be saved, and you will be required to request a new code.'
}
