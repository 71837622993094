<div class="d-flex flex-column">
  <form
    id="create-login-form"
    class="d-flex flex-column justify-content-between page-content"
    [formGroup]="createLoginForm"
    #createLoginFormParent="ngForm"
    (ngSubmit)="resetPageErrorAndFocus()"
  >
    <section id="create-login-content" class="container">
      <div id="create-login-header" class="content-section">
        <div id="create-login-error">
          <bolt-alert-error
            #pageLevelError
            _id="page-level-error"
            [hidden]="!(createLoginFormParent.submitted && createLoginForm.invalid)"
          >
            Please correct the following to continue.
          </bolt-alert-error>
        </div>
        <ng-container *ngIf="fromForgotCredentials; else sameFlow">
          <h1 id="main-content" class="h3">
            OK, we found you in our records, but you haven't registered your account yet.
          </h1>
          <h2 id="create-login-title" class="h5">Let's take care of that now.</h2>
        </ng-container>
        <ng-template #sameFlow>
          <h2 #adaAutoFocus id="create-login-title" class="h5"><strong>Create username and password</strong></h2>
        </ng-template>
      </div>
      <div id="create-login-inputs" class="content-section">
        <!--
          Note: The layout of this page differs slightly from the others - there is no fieldset.
          This is due to an issue with Voiceover in Safari that causes the progress bar to be read when every field is tabbed into.
        -->
        <div class="form-group">
          <bolt-textfield
            ciamDelayedFocusout
            [_id]="createLoginControls.username.id"
            _name="username"
            [_aria-describedby]="createLoginControls.username.ariaDescribedBy"
            _class="nwpii"
            maxlength="60"
            [formGroup]="createLoginForm"
            [parentForm]="createLoginFormParent"
            placeholder="Username or email address"
            (input)="currentUsernameInput = $event.target.value"
            (focusin)="usernameChecklist.hidden = false"
            (delayedFocusout)="usernameChecklist.hidden = true"
          >
            Username
          </bolt-textfield>
          <bolt-field-error
            id="create-login-username-errors"
            _id="create-login-username-errors"
            _class="field-error"
            [errors]="createLoginForm.get('username').errors"
            [errorMessages]="createLoginControlErrors.username"
            *ngIf="createLoginFormParent.submitted"
          ></bolt-field-error>
          <span id="username-help-message" class="help-text">
            <em>
              To make your username easy to remember, use your email address.
            </em>
          </span>
          <div #usernameChecklist id="username-validations" class="mt-2" hidden>
            <span id="username-validations-header">Your username is not case sensitive and must have:</span>
            <ciam-dynamic-checklist [dynamicChecklist]="usernameDynamicChecklist" [currentInput]="currentUsernameInput">
            </ciam-dynamic-checklist>
          </div>
        </div>
        <div class="form-group">
          <bolt-textfield
            ciamDelayedFocusout
            [_id]="createLoginControls.password.id"
            _name="password"
            [_aria-describedby]="createLoginControls.password.ariaDescribedBy"
            _class="nwpii"
            maxlength="30"
            [maskedToggle]="true"
            maskDescription="password"
            [formGroup]="createLoginForm"
            [parentForm]="createLoginFormParent"
            (input)="currentPasswordInput = $event.target.value"
            (focusin)="passwordChecklist.hidden = false"
            (delayedFocusout)="passwordChecklist.hidden = true"
            (buttonClicked)="handleShowHideClick($event)"
          >
            Password
          </bolt-textfield>
          <bolt-field-error
            id="create-login-password-errors"
            _id="create-login-password-errors"
            _class="field-error"
            [errors]="createLoginForm.get('password').errors"
            [errorMessages]="createLoginControlErrors.password"
            *ngIf="createLoginFormParent.submitted"
          ></bolt-field-error>
          <div #passwordChecklist id="password-validations" class="mt-3" hidden>
            <span id="password-validations-header">Passwords are case sensitive and must have:</span>
            <ciam-dynamic-checklist [dynamicChecklist]="passwordDynamicChecklist" [currentInput]="currentPasswordInput">
            </ciam-dynamic-checklist>
          </div>
        </div>
        <div class="form-group">
          <bolt-textfield
            [_id]="createLoginControls.passwordConfirmation.id"
            _name="passwordConfirmation"
            _class="nwpii"
            maxlength="30"
            [maskedToggle]="true"
            maskDescription="password"
            [formGroup]="createLoginForm"
            [parentForm]="createLoginFormParent"
            (buttonClicked)="handleShowHideClick($event)"
          >
            Confirm password
          </bolt-textfield>
          <bolt-field-error
            id="create-login-password-confirmation-errors"
            _id="create-login-password-confirmation-errors"
            _class="field-error"
            [errors]="createLoginForm.get('passwordConfirmation').errors"
            [errorMessages]="createLoginControlErrors.passwordConfirmation"
            *ngIf="createLoginFormParent.submitted"
          >
          </bolt-field-error>
        </div>
        <div class="form-group" *ngIf="!isNonInstantRegistration">
          <bolt-textfield
            [_id]="createLoginControls.phoneNumber.id"
            _name="phoneNumber"
            [_aria-describedby]="createLoginControls.phoneNumber.ariaDescribedBy"
            mask="000-000-0000"
            [formGroup]="createLoginForm"
            [parentForm]="createLoginFormParent"
            [dropSpecialCharacters]="false"
            placeholder="555-555-5555"
          >
            Mobile number <em class="font-weight-lighter"> (optional)</em>
          </bolt-textfield>
          <bolt-field-error
            id="create-login-phone-number-errors"
            _id="create-login-phone-number-errors"
            _class="field-error"
            [errors]="createLoginForm.get('phoneNumber').errors"
            [errorMessages]="createLoginControlErrors.phoneNumber"
            *ngIf="createLoginFormParent.submitted"
          >
          </bolt-field-error>
          <span id="mobile-number-help-message" class="help-text">
            <em>
              This mobile phone number will be used to text you a security code when we need to confirm your identity
              when you're logging in to your account. Message and data rates may apply.
            </em>
          </span>
        </div>
        <div class="form-group" *ngIf="!isNonInstantRegistration">
          <bolt-textfield
            [_id]="createLoginControls.emailAddress.id"
            _name="emailAddress"
            maxlength="80"
            [formGroup]="createLoginForm"
            [parentForm]="createLoginFormParent"
            placeholder="name@domain.com"
            (change)="trimWhiteSpace($event)"
          >
            Email address
          </bolt-textfield>
          <bolt-field-error
            id="create-login-email-address-errors"
            _id="create-login-email-address-errors"
            _class="field-error"
            [errors]="createLoginForm.get('emailAddress').errors"
            [errorMessages]="createLoginControlErrors.emailAddress"
            *ngIf="createLoginFormParent.submitted"
          >
          </bolt-field-error>
        </div>
        <div class="form-group">
          <label id="create-login-esa-document-label" aria-hidden="true">
            <strong>Electronic Services &amp; Document Delivery Agreement</strong>
          </label>
          <div id="esa-link-format">
            <a
              ciamclicklogger
              id="create-login-esa-document-link"
              aria-describedby="create-login-esa-document-link-aria-label"
              class="underline"
              href="https://www.nationwide.com/electronic-delivery-agreement.jsp"
              target="_blank"
              >View full agreement</a
            >
            <span id="create-login-esa-document-link-aria-label" class="sr-only" aria-hidden="true">
              View full Electronic Services and Document Delivery agreement
            </span>
          </div>
          <div class="pre-form-content">
            <ciam-esa-text></ciam-esa-text>
          </div>
          <bolt-check-box [_id]="createLoginControls.acceptedEsa.id" _name="acceptedEsa" [formGroup]="createLoginForm">
            <strong>
              By checking this box, I confirm that I have read and accept the terms and conditions in the Electronic
              Services and Document Delivery Agreement.
            </strong>
          </bolt-check-box>
          <bolt-field-error
            id="create-login-esa-checkbox-errors"
            _id="create-login-esa-checkbox-errors"
            _class="field-error"
            [errors]="createLoginForm.get('acceptedEsa').errors"
            [errorMessages]="createLoginControlErrors.acceptedEsa"
            *ngIf="createLoginFormParent.submitted"
          >
          </bolt-field-error>
        </div>
      </div>
    </section>
    <section id="create-login-buttons" class="button-container">
      <div class="container button-stack">
        <bolt-button-primary ciamClickLogger _id="create-login-submit" _type="submit">
          Submit
        </bolt-button-primary>
        <input
          ciamClickLogger
          type="button"
          id="create-login-cancel"
          class="btn btn-default"
          value="Cancel"
          data-cancel-trigger
        />
      </div>
    </section>
  </form>
</div>
