import { Component } from '@angular/core';
import { SEARCH_PATHS } from '@common/search/search-paths';
import { SearchFlowType } from '@common/search/shared/search.model';
import { AdaBaseComponent } from '@shared/base-component/ada-base.component';
import { CommonFlow, Flow } from '@shared/navigation/flow.enum';
import { NavigationService } from '@shared/navigation/navigation.service';

@Component({
  selector: 'ciam-business-account-not-found.component',
  templateUrl: './business-account-not-found.component.html',
  styleUrls: ['./business-account-not-found.component.scss']
})
export class BusinessAccountNotFoundComponent extends AdaBaseComponent {
  protected readonly className = 'BusinessAccountNotFoundComponent';

  constructor(private _navigationService: NavigationService) {
    super();
  }

  navigateToPersonalIndividualInformation() {
    this._navigationService.navigate(
      `${Flow.REGISTRATION}/${CommonFlow.SEARCH}/${SearchFlowType.PERSONAL}/${SEARCH_PATHS.individualInformation}`
    );
  }
}
