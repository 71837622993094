<div id="content-header" [ngClass]="{ 'pb-3': shouldShowContentHeader, 'header-hidden': !shouldShowContentHeader }">
  <div [ngClass]="{ 'section-separator': shouldShowProgressBar }" *ngIf="shouldShowTitle">
    <div class="container mt-3" [ngClass]="{ 'mb-3': shouldShowProgressBar }">
      <h1 id="main-content" class="h3 m-0 no-outline" tabindex="-1">{{ contentHeaderService.model.title }}</h1>
    </div>
  </div>
  <div id="app-progress-bar" class="progress-bar-container section-separator pt-3 pb-4" *ngIf="shouldShowProgressBar">
    <div class="container" aria-hidden="true">
      <div id="progress-bar-title" class="pb-2">
        <strong>{{ contentHeaderService.model.progressTitle }}</strong>
      </div>
      <bolt-progress-bar
        _id="progress-bar"
        _value="{{ contentHeaderService.model.progressPercent }}"
        class="progress-bar-height"
      ></bolt-progress-bar>
    </div>
    <span id="progress-bar-label" class="d-none" aria-hidden="true">
      progress bar {{ contentHeaderService.model.progressTitle }}
      {{ contentHeaderService.model.progressPercent }} percent,
    </span>
  </div>
</div>

<span id="form-submit-focus" tabindex="-1" class="m-0 p-0 no-outline">
  <!-- This element is used purely for post-form-submit ADA focusing. -->
</span>
