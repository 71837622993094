import { ControlErrors, Controls } from '@shared/base-component/base.model';
import { CONTROL_ERROR_MESSAGES, CONTROL_PATTERNS } from '@shared/validation/control-constants';

export const CONTROLS: Controls = {
  code: {
    id: 'enter-code',
    ariaDescribedByErrors: 'enter-code-errors',
    ariaDescribedBy: 'numbers-only-help-message enter-code-help-message',
    patterns: CONTROL_PATTERNS.code
  }
};

export const CONTROL_ERRORS: ControlErrors = {
  code: {
    required: CONTROL_ERROR_MESSAGES.codeRequired,
    invalid: CONTROL_ERROR_MESSAGES.codeInvalid
  }
};
