import { ControlErrors, Controls } from '@shared/base-component/base.model';
import { CONTROL_ERROR_MESSAGES, CONTROL_PATTERNS } from '@shared/validation/control-constants';

export const CONTROLS: Controls = {
  social: {
    id: `identifier-social`,
    ariaDescribedByErrors: `identifier-social-errors`,
    ariaDescribedBy: `identifier-social-help-message`,
    patterns: CONTROL_PATTERNS.ssn
  },
  account: {
    id: `identifier-account`,
    ariaDescribedByErrors: `identifier-account-errors`,
    ariaDescribedBy: `identifier-account-help-message`,
    patterns: CONTROL_PATTERNS.agreementNumber
  }
};

export const CONTROL_ERRORS: ControlErrors = {
  social: {
    required: CONTROL_ERROR_MESSAGES.ssnRequired,
    pattern: CONTROL_ERROR_MESSAGES.ssnPattern
  },
  account: {
    required: CONTROL_ERROR_MESSAGES.accountRequired,
    pattern: CONTROL_ERROR_MESSAGES.accountPattern
  }
};
