import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AUTHORIZATION_PATHS } from '@app/authorization/authorization-paths';
import { SEARCH_PATHS } from '@common/search/search-paths';
import { ENVIRONMENT } from '@environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LoggingService } from '@shared/logging/logging.service';
import { SpinnerService } from '@shared/spinner/spinner.service';
import { Observable } from 'rxjs';
import { CommonFlow, Flow } from './flow.enum';
import { NavigationService } from './navigation.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class NavigationGuard {
  protected readonly className = 'NavigationGuard';

  private _unprotectedRoutes = [
    `${Flow.AUTHORIZATION}/${AUTHORIZATION_PATHS.authorize}`,
    `${Flow.AUTHORIZATION}/${AUTHORIZATION_PATHS.callback}`,
    `${Flow.REGISTRATION}/${CommonFlow.SEARCH}/${SEARCH_PATHS.accountType}`,
    `${Flow.FORGOT_CREDENTIALS}/${CommonFlow.SEARCH}/${SEARCH_PATHS.accountType}`,
    Flow.VERSION
  ];

  constructor(
    private _logger: LoggingService,
    private _spinner: SpinnerService,
    private _navigationService: NavigationService
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this._checkNavigationState(state.url.substring(1));
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this._checkNavigationState(state.url.substring(1));
  }

  private _checkNavigationState(url: string): boolean {
    if (ENVIRONMENT.features.general.navigationGuard && !this._isRouteValid(url)) {
      (this._logger.warn(
        this.className,
        'Invalid route; redirecting to IAM login.',
        {
          expectedRoute: this._navigationService.model.expectedRoute,
          desiredRoute: url
        },
        true
      ) as Observable<any>)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          // Note: redirecting without waiting on logging will drop the logging request without processing it
          this._navigationService.navigateExternal(
            `${ENVIRONMENT.externalUrls.iam.basePath}${ENVIRONMENT.externalUrls.iam.login}`
          );
        });

      this._spinner.show();
      return false;
    }

    return true;
  }

  private _isRouteValid(navigationUrl: string): boolean {
    const baseNavigationUrl = navigationUrl?.split('?')[0]?.split('#')[0];
    const baseExpectedRoute = this._navigationService.model.expectedRoute?.split('?')[0]?.split('#')[0];

    return (
      (!baseExpectedRoute && this._unprotectedRoutes.includes(baseNavigationUrl)) ||
      baseExpectedRoute === baseNavigationUrl
    );
  }
}
