<div id="express-links" class="express-links text-center p-4">
  <div class="express-links-title">
    <p id="expresslink-first-title">Skip the login.</p>
    <p id="expresslink-second-title">Get right to work.</p>
  </div>
  <div class="row">
    <div class="col-6">
      <a
        ciamClickLogger
        id="express-links-pay-bill"
        href="javascript:void(0)"
        data-cancel-trigger
        data-cancel-url="https://servicing.nationwide.com/policyservicing/quick/quickPayOverview.action"
      >
        <img
          id="express-links-pay-bill-logo"
          src="assets/dollar-bill-icon.svg"
          alt="Dollar sign"
          class="express-links-icon d-block mt-4 mb-3 mr-auto ml-auto"
        />
        <span id="express-links-pay-bill-title" class="express-links-text">Pay a bill</span>
      </a>
    </div>
    <div class="col-6">
      <a
        ciamClickLogger
        id="express-links-id-card"
        href="javascript:void(0)"
        data-cancel-trigger
        data-cancel-url="https://servicing.nationwide.com/policyservicing/quick/quickIDOverview.action"
      >
        <img
          id="express-links-id-card-logo"
          src="assets/id-card-icon.svg"
          alt="ID card"
          class="express-links-icon d-block mt-4 mb-3 mr-auto ml-auto"
        />
        <span id="express-links-id-card-title" class="express-links-text">Get auto ID cards</span>
      </a>
    </div>
  </div>
</div>
