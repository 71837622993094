import { ControlErrors, Controls } from '@shared/base-component/base.model';
import { CONTROL_ERROR_MESSAGES, CONTROL_PATTERNS } from '@shared/validation/control-constants';

export const CONTROLS: Controls = {
  accountNumber: {
    id: `account-number`,
    ariaDescribedByErrors: `account-number-errors`,
    patterns: CONTROL_PATTERNS.agreementNumber
  }
};

export const CONTROL_ERRORS: ControlErrors = {
  accountNumber: {
    required: CONTROL_ERROR_MESSAGES.accountNumberRequired,
    pattern: CONTROL_ERROR_MESSAGES.accountNumberPattern
  }
};
