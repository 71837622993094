import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { AccessLockedComponent } from './access-locked/access-locked.component';
import { AccountIssueComponent } from './account-issue/account-issue.component';
import { ErrorRoutingModule } from './error-routing-module';
import { OnlineAccessErrorComponent } from './online-access-error/online-access-error.component';
import { SnagErrorComponent } from './snag-error/snag-error.component';
import { SystemErrorComponent } from './system-error/system-error.component';

@NgModule({
  declarations: [
    AccessLockedComponent,
    OnlineAccessErrorComponent,
    SnagErrorComponent,
    SystemErrorComponent,
    AccountIssueComponent
  ],
  imports: [SharedModule, ErrorRoutingModule]
})
export class ErrorModule {}
