import { Injectable } from '@angular/core';
import { ENVIRONMENT } from '@environments/environment';
import { LoggingService } from '@shared/logging/logging.service';
import { NavigationService } from '@shared/navigation/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class TimeoutService {
  protected readonly className = 'TimeoutService';

  private _time: any;

  constructor(private _navigationService: NavigationService, private _logger: LoggingService) {
    // TODO: Ideally this would subscribe to and operate on authorization expiry events from the
    // OAuthService, instead of exposing manaul reset() functionality.
  }

  reset(redirectURL?: string) {
    if (ENVIRONMENT.features.general.flowTimeout.enabled) {
      clearTimeout(this._time);
      this._time = setInterval(() => {
        if (redirectURL) {
          this._logger.warn(this.className, `Session timeout expired; redirecting to ${redirectURL}.`);
          this._navigationService.navigateExternal(redirectURL);
        } else {
          this._logger.warn(this.className, 'Session timeout expired; redirecting to IAM Login.');
          this._navigationService.navigateExternal(
            `${ENVIRONMENT.externalUrls.iam.basePath}${ENVIRONMENT.externalUrls.iam.login}`
          );
        }
      }, ENVIRONMENT.features.general.flowTimeout.time);
    }
  }
}
