import { Component, OnInit } from '@angular/core';
import { ENVIRONMENT } from '@environments/environment';
import { BaseComponent } from '@shared/base-component/base.component';
import { ContentHeaderService } from '@shared/content-header/content-header.service';
import packageJson from '../../../../package.json';

@Component({
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent extends BaseComponent implements OnInit {
  version = packageJson.version;
  environmentName = ENVIRONMENT.name;

  constructor(private _contentHeaderService: ContentHeaderService) {
    super();
  }

  ngOnInit() {
    this._contentHeaderService.reset({ title: 'Version information' });
  }
}
