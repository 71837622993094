import { NgModule } from '@angular/core';
import { RouterModule, Routes, mapToCanActivate } from '@angular/router';
import { Flow } from '@shared/navigation/flow.enum';
import { NavigationGuard } from '@shared/navigation/navigation.guard';
import { StorageGuard } from '@shared/storage/storage.guard';
import { InvalidLinkComponent } from './invalid-link/invalid-link.component';
import { ONE_TIME_LINK_PATHS } from './one-time-link-paths';
import { OneTimeLinkComponent } from './one-time-link/one-time-link.component';

export const ONE_TIME_LINK_ROUTES: Routes = [
  {
    path: Flow.ONE_TIME_LINK,
    canActivate: mapToCanActivate([StorageGuard]),
    component: OneTimeLinkComponent
  },
  {
    path: `${Flow.ONE_TIME_LINK}/${ONE_TIME_LINK_PATHS.invalidLink}`,
    canActivate: mapToCanActivate([NavigationGuard]),
    component: InvalidLinkComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(ONE_TIME_LINK_ROUTES)],
  exports: [RouterModule]
})
export class OneTimeLinkRoutingModule {}
