export const REGISTRATION_PATHS: {
  controllerAttestation: string;
  registrationType: string;
  createLogin: string;
  updateLogin: string;
  nonInstantSuccess: string;
  instantSuccess: string;
  personalInformation: string;
  additionalIdentifier: string;
  updateUsernamePassword: string;
  startOver: string;
  activation: string;
  registerSuccess: string;
} = {
  controllerAttestation: 'controller-attestation',
  registrationType: 'registration-type',
  createLogin: 'create-login',
  updateLogin: 'update-login',
  nonInstantSuccess: 'non-instant-success',
  instantSuccess: 'instant-success',
  updateUsernamePassword: 'update-username-password',
  personalInformation: 'personal-information',
  additionalIdentifier: 'additional-identifier',
  startOver: 'start-over',
  activation: 'non-instant-activation',
  registerSuccess: 'register-success'
};
