import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ContentHeaderModel } from '@shared/content-header/content-header.model';
import { SEARCH_PATHS } from '../../search-paths';
import { AccountType, SearchType } from '../../shared/search.model';
import { IndividualInformationBaseComponent } from '../individual-information-base.component';

@UntilDestroy()
@Component({
  selector: 'ciam-personal-individual-information',
  templateUrl: '../individual-information-base.component.html',
  styleUrls: ['../individual-information-base.component.scss']
})
export class PersonalIndividualInformationComponent extends IndividualInformationBaseComponent {
  protected readonly className = 'PersonalIndividualInformationComponent';

  back() {
    this.backToAccountType();
  }

  get contentHeaderModel(): ContentHeaderModel {
    return {
      progressTitle: 'Find account',
      progressPercent: 10
    };
  }

  get individualInfoMessageText(): string {
    return "To protect your privacy and security, we'll need to look you up in our records to verify your identity. If we're unable to find your details, you'll be able to enter the account, policy or contract number listed on your statement.";
  }

  get yourInfoTitleText(): string {
    return 'Your information';
  }

  get yourInfoMessageText(): string {
    if (this.isFupFlow && this.searchService.model.accountType === AccountType.BUSINESS) {
      return "Please provide the name and ZIP code of the person listed as the named insured on the policy, such as the business owner. If you're registered as the authorized representative for your organization's Nationwide online account, use your name and ZIP code exactly as it appears on your Nationwide documents.";
    }

    return 'Enter your name exactly as it appears on Nationwide documents.';
  }

  get findAccountButtonText(): string {
    return 'Find account';
  }

  get isControllerSearch(): boolean {
    return false;
  }

  get searchType(): SearchType {
    return SearchType.PERSONAL_INDIVIDUAL_INFORMATION;
  }

  get customerNotMatchedRoute(): string {
    return `${this.baseRoute}/${SEARCH_PATHS.identifier}`;
  }
}
