import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { LoggingService } from '@shared/logging/logging.service';
import { TimeoutService } from './timeout.service';

@Injectable({
  providedIn: 'root'
})
export class TimeoutGuard {
  private readonly _className = 'TimeoutGuard';

  constructor(private _timeoutService: TimeoutService, private _logger: LoggingService) {}

  canActivate(next: ActivatedRouteSnapshot): boolean {
    this._logger.debug(this._className, `${this._className} activated.`);
    this._timeoutService.reset(next?.data?.redirectURL as string);
    return true;
  }
}
