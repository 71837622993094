import { InjectionToken } from '@angular/core';
import { SessionKey } from '@shared/storage/storage.model';
import { StorageService, WebStorageService } from 'ngx-webstorage-service';

export const customSessionFactory = (): StorageService => new CustomSessionService(sessionStorage);

export const CUSTOM_SESSION = new InjectionToken<StorageService>('CUSTOM_SESSION', {
  providedIn: 'root',
  factory: customSessionFactory
});

export class CustomSessionService extends WebStorageService {
  constructor(protected storageObj: Storage) {
    super(storageObj);
  }

  setModifiedSessionObject(value: any, sessionKey: SessionKey, objectKey: string) {
    const existingSessionObj = this.get(sessionKey);
    if (existingSessionObj) {
      if (value) {
        existingSessionObj[objectKey] = value;
      } else if (existingSessionObj[objectKey]) {
        delete existingSessionObj[objectKey];
      }
      this.set(sessionKey, existingSessionObj);
    } else if (value !== undefined) {
      const newSessionObj: any = {};
      newSessionObj[objectKey] = value;
      this.set(sessionKey, newSessionObj);
    }
  }

  setPartnerId(partnerId: string) {
    this.setModifiedSessionObject(partnerId === 'CIAM' ? null : partnerId, SessionKey.PARTNER, 'id');
  }
}
