import { NgModule } from '@angular/core';
import { RouterModule, Routes, mapToCanActivate } from '@angular/router';
import { CommonFlow } from '@shared/navigation/flow.enum';
import { NavigationGuard } from '@shared/navigation/navigation.guard';
import { TimeoutGuard } from '@shared/timeout/timeout.guard';
import { DigitalRefundsComponent } from './digital-refunds/digital-refunds.component';

export const DIGITAL_REFUNDS_ROUTES: Routes = [
  {
    path: CommonFlow.DIGITAL_REFUNDS,
    canActivate: mapToCanActivate([NavigationGuard, TimeoutGuard]),
    component: DigitalRefundsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(DIGITAL_REFUNDS_ROUTES)],
  exports: [RouterModule]
})
export class DigitalRefundsRoutingModule {}
