<div class="d-flex flex-column">
  <form
    id="controller-attestation-form"
    class="d-flex flex-column justify-content-between page-content"
    [formGroup]="controllerAttestationForm"
    #controllerAttestationFormParent="ngForm"
    (ngSubmit)="resetPageErrorAndFocus()"
  >
    <section id="controller-attestation-content" class="container">
      <div id="controller-attestation-header" class="content-section">
        <bolt-alert-error
          #pageLevelError
          _id="page-level-error-invalid"
          [hidden]="!(controllerAttestationFormParent.submitted && controllerAttestationForm.invalid)"
        >
          Please correct the following to continue.
        </bolt-alert-error>
        <h2 #adaAutoFocus id="controller-attestation-title" class="h5">
          <strong>Next, review and confirm you have permission to manage this account.</strong>
        </h2>
      </div>
      <div id="controller-attestation-input">
        <fieldset class="content-section">
          <legend class="h6 font-weight-normal pre-form-content business-names">
            <ciam-business-names></ciam-business-names>
          </legend>
          <div class="form-group">
            <bolt-field-error
              id="controller-attestation-sa-checkbox-errors"
              _id="controller-attestation-sa-checkbox-errors"
              _class="field-error"
              [errors]="controllerAttestationForm.get('acceptedServiceAgreement').errors"
              [errorMessages]="controllerAttestationControlErrors.acceptedServiceAgreement"
              *ngIf="controllerAttestationFormParent.submitted"
            >
            </bolt-field-error>
            <bolt-check-box
              [_id]="controllerAttestationControls.acceptedServiceAgreement.id"
              _name="acceptedServiceAgreement"
              [formGroup]="controllerAttestationForm"
            >
              I hereby represent and warrant that I am an Owner, Officer, Partner, Manager, Member, or Director of the
              First Named Insured and I have the full power and authority to access the Nationwide Websites on behalf of
              the First Named Insured. The First Named Insured has taken all necessary action to appoint or delegate my
              authority to access the Nationwide Websites.
            </bolt-check-box>
          </div>
        </fieldset>
      </div>
    </section>

    <section id="controller-attestation-buttons" class="button-container">
      <div class="container button-stack">
        <bolt-button-primary ciamClickLogger _id="controller-attestation-submit" _type="submit">
          Submit
        </bolt-button-primary>
        <input
          ciamClickLogger
          type="button"
          id="controller-attestation-cancel"
          class="btn btn-default"
          value="Cancel"
          data-cancel-trigger
        />
      </div>
    </section>
  </form>
</div>
