import { Nora } from './nora';

declare let nwChatbot: Nora;

export const loadNora = (config: { enabled: boolean; baseUrl?: string; webchatJs?: string; chatbotJs?: string }) => {
  const appendContainer = () => {
    // Chatbot container
    const container = document.createElement('div');
    container.id = 'chatbotWindow';
    container.classList.add('chat-window');
    document.body.appendChild(container);
  };

  const appendChatbot = () => {
    // MS Chatbot Framework (Must be loaded fully before NORA)
    appendJS(`${config.baseUrl}${config.webchatJs}`, appendNora);
  };

  const appendNora = () => {
    // NORA
    appendJS(`${config.baseUrl}${config.chatbotJs}`, loadChatbot);
  };

  const loadChatbot = () => {
    nwChatbot.initialize();
  };

  const appendJS = (src: string, callback?: any) => {
    const script = document.createElement('script');
    script.src = src;
    document.head.appendChild(script);
    script.onload = callback;
  };

  if (config.enabled) {
    appendContainer();
    appendChatbot();
  }
};
