import { NgModule } from '@angular/core';
import { RouterModule, Routes, mapToCanActivate, mapToCanActivateChild } from '@angular/router';
import { ELIGIBILITY_ROUTES } from '@common/eligibility/eligibility-routes';
import { MFA_ROUTES } from '@common/mfa/mfa-routes';
import { SEARCH_ROUTES } from '@common/search/search-routes';
import { ENVIRONMENT } from '@environments/environment';
import { MfaStatusGuard } from '@shared/mfa-status/mfa-status.guard';
import { CommonFlow, Flow } from '@shared/navigation/flow.enum';
import { NavigationGuard } from '@shared/navigation/navigation.guard';
import { TimeoutGuard } from '@shared/timeout/timeout.guard';
import { SEARCH_PATHS } from '../common/search/search-paths';
import { ControllerAttestationComponent } from './controller-attestation/controller-attestation.component';
import { CreateLoginComponent } from './create-login/create-login.component';
import { InstantSuccessComponent } from './instant-success/instant-success.component';
import { NonInstantSuccessComponent } from './non-instant-success/non-instant-success.component';
import { RegisterSuccessComponent } from './register-success/register-success.component';
import { REGISTRATION_PATHS } from './registration-paths';
import { RegistrationTypeComponent } from './registration-type/registration-type.component';
import { RegistrationFlowType } from './shared/registration.model';
import { UpdateLoginComponent } from './update-login/update-login.component';

export const REGISTRATION_ROUTES: Routes = [
  {
    path: Flow.REGISTRATION,
    canActivateChild: mapToCanActivateChild([NavigationGuard]),
    children: [
      { path: '', pathMatch: 'full', redirectTo: `${CommonFlow.SEARCH}/${SEARCH_PATHS.accountType}` },
      ...SEARCH_ROUTES,
      ...ELIGIBILITY_ROUTES,
      { path: REGISTRATION_PATHS.registrationType, component: RegistrationTypeComponent },
      {
        path: RegistrationFlowType.PERSONAL,
        children: [
          ...MFA_ROUTES,
          {
            path: REGISTRATION_PATHS.createLogin,
            component: CreateLoginComponent,
            canActivate: mapToCanActivate([TimeoutGuard, MfaStatusGuard])
          },
          { path: REGISTRATION_PATHS.nonInstantSuccess, component: NonInstantSuccessComponent },
          { path: REGISTRATION_PATHS.instantSuccess, component: InstantSuccessComponent }
        ]
      },
      {
        path: RegistrationFlowType.BUSINESS,
        children: [
          ...MFA_ROUTES,
          {
            path: REGISTRATION_PATHS.controllerAttestation,
            component: ControllerAttestationComponent,
            canActivate: mapToCanActivate([TimeoutGuard])
          },
          {
            path: REGISTRATION_PATHS.createLogin,
            component: CreateLoginComponent
          },
          {
            path: REGISTRATION_PATHS.updateLogin,
            component: UpdateLoginComponent
          },
          { path: REGISTRATION_PATHS.nonInstantSuccess, component: NonInstantSuccessComponent },
          { path: REGISTRATION_PATHS.instantSuccess, component: InstantSuccessComponent }
        ]
      },
      {
        path: REGISTRATION_PATHS.registerSuccess,
        canActivate: mapToCanActivate([TimeoutGuard]),
        data: {
          redirectURL: `${ENVIRONMENT.externalUrls.iam.basePath}${ENVIRONMENT.externalUrls.iam.sessionExpired}`
        },
        component: RegisterSuccessComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(REGISTRATION_ROUTES)],
  exports: [RouterModule]
})
export class RegistrationRoutingModule {}
