import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonBaseComponent } from '@common/shared/common-base.component';
import { ENVIRONMENT } from '@environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ControlErrors, Controls } from '@shared/base-component/base.model';
import { ContentHeaderService } from '@shared/content-header/content-header.service';
import { CommonFlow, Flow } from '@shared/navigation/flow.enum';
import { NavigationService } from '@shared/navigation/navigation.service';
import { filter } from 'rxjs/operators';
import { SEARCH_PATHS } from '../search-paths';
import { AccountType, LifecycleStatus, SearchFilter, SearchFlowType } from '../shared/search.model';
import { SearchService } from '../shared/search.service';
import { CONTROLS, CONTROL_ERRORS } from './account-type.model';

@UntilDestroy()
@Component({
  selector: 'ciam-account-type',
  templateUrl: './account-type.component.html',
  styleUrls: ['./account-type.component.scss']
})
export class AccountTypeComponent extends CommonBaseComponent implements OnInit {
  accountTypeForm: UntypedFormGroup;
  accountTypeControls: Controls = CONTROLS;
  accountTypeControlErrors: ControlErrors = CONTROL_ERRORS;

  accountTypes = AccountType;

  protected readonly className = 'AccountTypeComponent';

  constructor(
    private _contentHeaderService: ContentHeaderService,
    private _navigationService: NavigationService,
    private _searchService: SearchService,
    private _formBuilder: UntypedFormBuilder,
    private _route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {
    this.logger.info(this.className, 'Selecting account type.');

    this._contentHeaderService.reset({
      title: this.pageTitle,
      progressTitle: 'Find account',
      progressPercent: 5
    });
    this._searchService.reset();

    this._initializeForm();
    this._processQueryParams();
    this._checkForPreSelectedType();
  }

  continue() {
    this.spinnerService.show();

    this._searchService.model.accountType = this.accountTypeForm.get('accountType').value;

    switch (this._searchService.model.accountType) {
      case AccountType.BUSINESS:
        switch (this.flow) {
          case Flow.REGISTRATION:
            this._handleRegistrationNavigation(
              ENVIRONMENT.features.registration.businessEnabled,
              `${ENVIRONMENT.externalUrls.iam.basePath}${ENVIRONMENT.externalUrls.iam.commercialRegistration}`,
              `${this.baseRoute}/${SearchFlowType.BUSINESS}/${SEARCH_PATHS.identifier}`
            );
            break;
          case Flow.FORGOT_CREDENTIALS:
            this._navigationService.navigate(
              `${this.baseRoute}/${SearchFlowType.PERSONAL}/${SEARCH_PATHS.individualInformation}`
            );
            break;
          default:
            throw new Error(`Unexpected flow: ${this.flow}`);
        }
        break;
      case AccountType.TRUST:
        switch (this.flow) {
          case Flow.REGISTRATION:
            this._handleRegistrationNavigation(
              ENVIRONMENT.features.registration.trustEnabled,
              `${ENVIRONMENT.externalUrls.iam.basePath}${ENVIRONMENT.externalUrls.iam.trustRegistration}`
            );
            break;
          case Flow.FORGOT_CREDENTIALS:
            this._navigationService.navigateExternal(
              `${ENVIRONMENT.externalUrls.iam.basePath}${ENVIRONMENT.externalUrls.iam.trustForgotCredentials}`
            );
            break;
          default:
            throw new Error(`Unexpected flow: ${this.flow}`);
        }
        break;
      default:
        this._handleRegistrationNavigation(
          ENVIRONMENT.features.registration.individualEnabled,
          `${ENVIRONMENT.externalUrls.iam.basePath}${ENVIRONMENT.externalUrls.iam.individualRegistration}`,
          `${this.baseRoute}/${SearchFlowType.PERSONAL}/${SEARCH_PATHS.individualInformation}`
        );
        break;
    }
  }

  private _handleRegistrationNavigation(registrationFlowEnabled: boolean, iamRoute: string, ciamRoute: string = null) {
    if (registrationFlowEnabled) {
      if (ciamRoute) {
        this._navigationService.navigate(ciamRoute);
      } else {
        throw new Error(`CIAM flow not yet implemented.`);
      }
    } else {
      this._navigationService.navigateExternal(iamRoute);
    }
  }

  private _processQueryParams() {
    this.sessionService.setPartnerId(this._route.snapshot.queryParamMap.get('systemId'));

    if (this._route.snapshot.queryParamMap.get('filter') === SearchFilter.NONE) {
      this._searchService.model.lifecycleStatus = LifecycleStatus.ALL;
    }
  }

  private _checkForPreSelectedType() {
    let preSelectedType = this._route.snapshot.queryParamMap.get('type');

    // Internal enum/model changed - translate old values to new ones.
    if (preSelectedType?.toLowerCase() === 'individual') {
      preSelectedType = 'personal';
    }

    if (preSelectedType?.toUpperCase() in AccountType) {
      this.accountTypeForm.get('accountType').setValue(preSelectedType.toLowerCase());
      this._logAccountType(true);
      this.continue();
    }
  }

  private _logAccountType(automatic = false) {
    this.logger.info(this.className, 'Account type selected.', {
      accountType: this.accountTypeForm.get('accountType').value,
      automatic
    });
  }

  private _initializeForm() {
    this.accountTypeForm = this._formBuilder.group(
      {
        accountType: ['', [Validators.required]]
      },
      {
        updateOn: 'submit'
      }
    );

    this.accountTypeForm.statusChanges
      .pipe(
        untilDestroyed(this),
        filter(() => this.accountTypeForm.valid)
      )
      .subscribe(() => {
        this._logAccountType();
        this.continue();
      });
  }

  get commonFlow(): CommonFlow {
    return CommonFlow.SEARCH;
  }

  protected get formGroup(): UntypedFormGroup {
    return this.accountTypeForm;
  }

  protected get controls(): Controls {
    return this.accountTypeControls;
  }
}
