import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AppInjector } from '@app/app-injector';
import { AuthMethod, DestinationType } from '@app/authorization/shared/authorization.model';
import { ERROR_PATHS } from '@app/error/error-paths';
import { ONE_TIME_LINK_PATHS } from '@app/one-time-link/one-time-link-paths';
import { CommonBaseComponent } from '@common/shared/common-base.component';
import { PostVerifyResponse } from '@nationwide/api-client-internet-registration-v3';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ContentHeaderService } from '@shared/content-header/content-header.service';
import { Flow } from '@shared/navigation/flow.enum';
import { NavigationService } from '@shared/navigation/navigation.service';
import { OneTimeLink, SessionKey } from '@shared/storage/storage.model';
import { OAuthService } from 'angular-oauth2-oidc';
import * as JwtDecode from 'jwt-decode';
import { PROXY_PATHS } from '../proxy-paths';
import { IdentifierType, VerifyCustomerCriteria, VerifyCustomerResult } from './verify-customer.model';
import { VerifyCustomerService } from './verify-customer.service';

// TODO: This probably shouldn't extend CommonBaseComponent, as it isn't a base for a common componet - it is specific to the proxy flow.

@UntilDestroy()
@Component({ template: '' })
export abstract class VerifyCustomerBaseComponent extends CommonBaseComponent implements OnInit {
  decodedToken: any;

  protected contentHeaderService: ContentHeaderService;
  protected navigationService: NavigationService;
  protected verifyCustomerService: VerifyCustomerService;
  protected oauthService: OAuthService;
  protected className = 'VerifyCustomerBaseComponent';
  destination: DestinationType;

  private readonly _spinnerMessage = 'Finding your Nationwide account...';

  constructor() {
    super();
    this.oauthService = AppInjector.injector.get(OAuthService);
    this.contentHeaderService = AppInjector.injector.get(ContentHeaderService);
    this.navigationService = AppInjector.injector.get(NavigationService);
    this.verifyCustomerService = AppInjector.injector.get(VerifyCustomerService);
  }

  ngOnInit() {
    this.destination = this._findDestination();
  }

  _performVerify(verifyCustomerCriteria: VerifyCustomerCriteria, identifierType?: IdentifierType) {
    this._logVerifyOperation(null, identifierType);
    this.spinnerService.show(this._spinnerMessage);
    this.verifyCustomerService.verifyCustomer(verifyCustomerCriteria).subscribe(
      response => {
        if (response.status === 200) {
          this._parseVerifyResponse(response);
          this.navigationService.navigateToAuthorization(
            AuthMethod.PROXY_REGISTRATION_VERIFY,
            { assertion: response.body.idToken },
            this.destination,
            this.contentHeaderService.model
          );
        } else {
          this.navigationService.navigateToError(
            this.className,
            null,
            ERROR_PATHS.systemError,
            `Unexpected HTTP ${response.status} response returned from Proxy Registration Verify Customer.`
          );
        }
      },
      error => {
        if (this._isNotVerified(error)) {
          this._handleVerificationFailure();
          this._logVerifyOperation(VerifyCustomerResult.NOT_VERIFIED, identifierType);
        } else {
          this._logVerifyOperation(VerifyCustomerResult.ERROR);
          this.navigationService.navigateToError(
            this.className,
            error,
            ERROR_PATHS.systemError,
            'VerifyService verifyCustomer failed.'
          );
        }
      }
    );
  }

  _parseVerifyResponse(response: HttpResponse<PostVerifyResponse>) {
    this.decodedToken = JwtDecode(response.body.idToken);
    this.sessionService.setModifiedSessionObject(this.decodedToken?.userId, SessionKey.CUSTOMER, 'userName');
    this.sessionService.setModifiedSessionObject(this.decodedToken?.guid, SessionKey.CUSTOMER, 'guid');
  }

  _isNotVerified(error: any): boolean {
    return error instanceof HttpErrorResponse && error.status === 404;
  }

  backToPersonalInformation() {
    this.navigationService.navigate(`${this.baseRoute}/${PROXY_PATHS.personalInformation}`);
  }

  _logVerifyOperation(result: VerifyCustomerResult, identifierType?: IdentifierType) {
    const verifyResult: any = { result };
    if (identifierType) {
      verifyResult.identifier = identifierType;
    }

    this.logger.info(this.className, `Verify ${result ? 'completed' : 'started'}.`, VerifyCustomerResult);
  }

  _navigateToAdditionalIdentifier() {
    this.navigationService.navigate(`${this.baseRoute}/${PROXY_PATHS.additionalIdentifier}`);
  }

  _findDestination(): DestinationType {
    if (this.hasUsedOneTimeLink()) {
      const destinationFromIdClaims = this.oauthService.getIdentityClaims().meta?.destination;
      switch (destinationFromIdClaims) {
        case DestinationType.REGISTRATION:
          return DestinationType.OTL_REGISTRATION;
        case DestinationType.RECOVERY:
          return DestinationType.OTL_RECOVERY;
        default:
          this.navigationService.navigateToError(
            this.className,
            new Error('Destination in ID token was invalid.'),
            `${Flow.ONE_TIME_LINK}/${ONE_TIME_LINK_PATHS.invalidLink}`
          );
      }
    } else {
      return DestinationType.PROXY_REGISTRATION_EDIT;
    }
  }

  hasUsedOneTimeLink(): boolean {
    return (this.sessionService.get(SessionKey.ONE_TIME_LINK) as OneTimeLink)?.usedOneTimeLink === true;
  }

  get commonFlow(): string {
    return 'verify';
  }

  abstract _handleVerificationFailure(): void;
}
