import { NgModule } from '@angular/core';
import {
  BoltAlertConfirmationModule,
  BoltAlertErrorModule,
  BoltAlertInformationModule,
  BoltButtonPrimaryModule,
  BoltButtonStandardModule,
  BoltCheckBoxModule,
  BoltFieldErrorModule,
  BoltModalModule,
  BoltRadioButtonModule,
  BoltRadioGroupModule,
  BoltTextFieldModule
} from '@nw/bolt-angular';
import { SharedModule } from '@shared/shared.module';
import { AccountTypeComponent } from '../search/account-type/account-type.component';
import { AdditionalInformationComponent } from './additional-information/additional-information.component';
import { BusinessAccountNotFoundComponent } from './business-account-not-found/business-account-not-found.component';
import { BusinessIdentifierComponent } from './identifier/business/business-identifier.component';
import { PersonalIdentifierComponent } from './identifier/personal/personal-identifier.component';
import { ControllerIndividualInformationComponent } from './individual-information/controller/controller-individual-information.component';
import { PersonalIndividualInformationComponent } from './individual-information/personal/personal-individual-information.component';
import { AccountNotFoundErrorComponent } from './shared/account-not-found-error/account-not-found-error.component';
import { SearchService } from './shared/search.service';
import { StartOverComponent } from './start-over/start-over.component';

@NgModule({
  declarations: [
    AccountTypeComponent,
    PersonalIndividualInformationComponent,
    AdditionalInformationComponent,
    PersonalIdentifierComponent,
    BusinessIdentifierComponent,
    BusinessAccountNotFoundComponent,
    ControllerIndividualInformationComponent,
    StartOverComponent,
    AccountNotFoundErrorComponent
  ],
  imports: [
    SharedModule,
    BoltAlertConfirmationModule,
    BoltAlertErrorModule,
    BoltAlertInformationModule,
    BoltButtonPrimaryModule,
    BoltButtonStandardModule,
    BoltFieldErrorModule,
    BoltTextFieldModule,
    BoltRadioGroupModule,
    BoltRadioButtonModule,
    BoltCheckBoxModule,
    BoltModalModule
  ],
  exports: [AccountNotFoundErrorComponent, StartOverComponent],
  providers: [SearchService]
})
export class SearchModule {}
