<button
  *ngIf="!buttonContent; else textualHelpElement"
  ciamClickLogger
  type="button"
  class="btn btn-primary ml-2 help-icon"
  [attr.id]="id ? id + '-help-icon' : null"
  [attr.aria-controls]="id ? id + '-help-icon-content' : null"
  [attr.aria-expanded]="!isCollapsed"
  (click)="toggleContent()"
  aria-label="Help"
>
  ?
</button>
<ng-template #textualHelpElement>
  <a
    ciamClickLogger
    type="button"
    class="text-primary webkit-appearance-none"
    role="button"
    [attr.id]="id ? id + '-help-text' : null"
    [attr.aria-controls]="id ? id + '-help-text-content' : null"
    [attr.aria-expanded]="!isCollapsed"
    (click)="toggleContent()"
    href="javascript:void(0)"
  >
    {{ buttonContent }}
  </a>
</ng-template>

<div
  #helpTextContent
  [ngbCollapse]="isCollapsed"
  [attr.id]="id ? id + '-help-text-content' : null"
  class="h6 card card-body border-info mt-3 no-outline help-text-content"
  tabindex="-1"
>
  <ng-content></ng-content>
</div>
