import { ControlErrors, Controls } from '@shared/base-component/base.model';
import { CONTROL_ERROR_MESSAGES, CONTROL_PATTERNS } from '@shared/validation/control-constants';

export const CONTROLS: Controls = {
  availableContactTypes: {
    id: 'available-contact-types',
    ariaDescribedByErrors: 'available-contact-types-errors'
  },
  phoneNumber: {
    id: 'mobile-number',
    patterns: CONTROL_PATTERNS.phoneNumber,
    ariaDescribedByErrors: 'mobile-number-errors'
  }
};

export const CONTROL_ERRORS: ControlErrors = {
  availableContactTypes: {
    required: CONTROL_ERROR_MESSAGES.availableContactTypesRequired
  },
  phoneNumber: {
    required: CONTROL_ERROR_MESSAGES.phoneNumberRequired,
    pattern: CONTROL_ERROR_MESSAGES.phoneNumberPattern
  }
};
