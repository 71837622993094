<div class="d-flex flex-column">
  <div class="d-flex flex-column justify-content-between page-content">
    <section id="version-content" class="container">
      <div class="table-responsive">
        <table class="table table-bordered table-striped">
          <tbody>
            <tr>
              <th scope="row">Version</th>
              <td id="version">{{ version }}</td>
            </tr>
            <tr>
              <th scope="row">Version date</th>
              <td id="version-date">2024-09-17 16:00:38 EDT</td>
            </tr>
            <tr>
              <th scope="row">Environment name</th>
              <td id="environment-name">{{ environmentName }}</td>
            </tr>
            <tr>
              <th scope="row">Build number</th>
              <td id="build-number">3</td>
            </tr>
            <tr>
              <th scope="row">Git branch</th>
              <td id="git-branch">release_moonshiners-2024-09-19</td>
            </tr>
            <tr>
              <th scope="row">Git commit hash</th>
              <td id="git-commit">d6c05908923ca5aaa0d8225dc9becc4958e0a31e</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>
</div>
