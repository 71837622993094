import { Component } from '@angular/core';
import { SEARCH_PATHS } from '@common/search/search-paths';
import { oauthConfig } from '@config/oauth.config';
import { ENVIRONMENT } from '@environments/environment';
import { AdaBaseComponent } from '@shared/base-component/ada-base.component';
import { CommonFlow, Flow } from '@shared/navigation/flow.enum';
import { NavigationService } from '@shared/navigation/navigation.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'ciam-invalid-link',
  templateUrl: './invalid-link.component.html',
  styleUrls: ['./invalid-link.component.scss']
})
export class InvalidLinkComponent extends AdaBaseComponent {
  flowEnum = Flow;

  protected readonly className = 'InvalidLinkComponent';

  constructor(private _navigationService: NavigationService, private _oauthService: OAuthService) {
    super();
  }

  navigate(flow: Flow) {
    this._oauthService.configure(oauthConfig(ENVIRONMENT.apiConfig.clientId));
    this._oauthService.loadDiscoveryDocument();
    this._navigationService.navigate(`${flow}/${CommonFlow.SEARCH}/${SEARCH_PATHS.accountType}`);
  }
}
