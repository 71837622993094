import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { ControllerEligibilityComponent } from './controller/controller-eligibility.component';
import { EligibilityService } from './shared/eligibility.service';
import { StandardEligibilityComponent } from './standard/standard-eligibility.component';

@NgModule({
  declarations: [StandardEligibilityComponent, ControllerEligibilityComponent],
  imports: [SharedModule],
  providers: [EligibilityService]
})
export class EligibilityModule {}
