import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'ciam-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent {
  @ViewChild('helpTextContent') helpTextContent: ElementRef;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('_id') id: string;
  @Input() buttonContent: string;

  isCollapsed = true;

  toggleContent() {
    this.isCollapsed = !this.isCollapsed;

    if (!this.isCollapsed) {
      setTimeout(() => {
        this.helpTextContent.nativeElement.focus();
      }, 200);
    }
  }
}
