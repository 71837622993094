import { UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CONTROLS } from './additional-identifier.model';

export const validateAdditionalIdentifier: ValidatorFn = (formGroup: UntypedFormGroup): ValidationErrors | null => {
  const identifierType = formGroup.get('identifierType')?.value;

  if (identifierType) {
    const identifier = formGroup.get(identifierType);

    if (identifier) {
      if (!identifier.value) {
        identifier.setErrors({ required: true });
      } else if (!CONTROLS[identifierType].patterns[0].test(identifier.value)) {
        identifier.setErrors({ pattern: true });
      }
    }
  }

  return null;
};
