import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ENVIRONMENT } from '@environments/environment';
import { LoggingService } from '@shared/logging/logging.service';
import { NavigationService } from '@shared/navigation/navigation.service';
import { SpinnerService } from '@shared/spinner/spinner.service';

@Injectable()
export class AppRedirectGuard {
  protected readonly className = 'AppRedirectGuard';

  constructor(
    private _spinnerService: SpinnerService,
    private _navigationService: NavigationService,
    private _logger: LoggingService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this._spinnerService.show();
    this._logger.warn(this.className, `Attempted to navigate to unknown route: ${state.url.substring(1)}`);
    this._navigationService.navigateExternal(
      `${ENVIRONMENT.externalUrls.iam.basePath}${ENVIRONMENT.externalUrls.iam.login}`
    );
    return false;
  }
}
