<ciam-error-container
  errorId="snag-error"
  title="We're sorry, we hit a snag"
  [message]="message"
></ciam-error-container>

<ng-template #message>
  <div>
    Please call <span class="text-nowrap"><ciam-phone-number key="WEB_SUPPORT"></ciam-phone-number></span> for
    assistance.
  </div>
</ng-template>
