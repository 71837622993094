import { ControlErrors, Controls } from '@shared/base-component/base.model';
import { CONTROL_ERROR_MESSAGES, CONTROL_PATTERNS } from '@shared/validation/control-constants';

export enum AlertType {
  ERROR = 'error',
  INFO = 'info'
}

export interface AlertMessage {
  type: AlertType;
  message: string;
}

export const CONTROLS: Controls = {
  firstName: {
    id: 'your-info-first-name',
    patterns: CONTROL_PATTERNS.firstName,
    ariaDescribedByErrors: 'your-info-first-name-errors'
  },
  middleInitial: {
    id: 'your-info-middle-initial'
  },
  lastName: {
    id: 'your-info-last-name',
    patterns: CONTROL_PATTERNS.lastName,
    ariaDescribedByErrors: 'your-info-last-name-errors'
  },
  suffix: {
    id: 'your-info-suffix'
  },
  zipCode: {
    id: 'your-info-zip-code',
    patterns: CONTROL_PATTERNS.zipCode,
    ariaDescribedByErrors: 'your-info-zip-code-errors'
  }
};

export const CONTROL_ERRORS: ControlErrors = {
  firstName: {
    required: CONTROL_ERROR_MESSAGES.firstNameRequired,
    pattern: CONTROL_ERROR_MESSAGES.firstNamePattern
  },
  lastName: {
    required: CONTROL_ERROR_MESSAGES.lastNameRequired,
    pattern: CONTROL_ERROR_MESSAGES.lastNamePattern
  },
  zipCode: {
    required: CONTROL_ERROR_MESSAGES.zipCodeRequired,
    pattern: CONTROL_ERROR_MESSAGES.zipCodePattern
  }
};
