export interface DynamicChecklistItem {
  id: string;
  pattern: RegExp;
  isValid: boolean;
  text: string;
  ariaText?: string;
}

export const USERNAME_DYNAMIC_CHECKLIST: Array<DynamicChecklistItem> = [
  {
    id: 'username-length',
    pattern: /^.{6,60}$/,
    isValid: false,
    text: '6-60 characters',
    ariaText: '6 to 60 characters'
  },
  {
    id: 'username-no-spaces',
    pattern: /^[^\s]+$/,
    isValid: false,
    text: 'No spaces'
  },
  {
    id: 'username-letter',
    pattern: /^.*[A-Za-z].*$/,
    isValid: false,
    text: 'At least one letter'
  },
  {
    id: 'username-excluded-characters',
    pattern: /^[^#%\^&*()+\\;"'<>/,]+$/,
    isValid: false,
    text: 'None of these: # % ^ & * ( ) + / \\ ; " \' < > ,',
    ariaText:
      'None of these: number sign, percent, carat, ampersand, asterisk, parentheses, plus, forward slash, back slash, semicolon, double quote, apostrophe, less than, greater than, or comma'
  }
];

export const PASSWORD_DYNAMIC_CHECKLIST: Array<DynamicChecklistItem> = [
  {
    id: 'password-length',
    pattern: /^.{8,30}$/,
    isValid: false,
    text: '8-30 characters',
    ariaText: '8 to 30 characters'
  },
  {
    id: 'password-no-spaces',
    pattern: /^[^\s]+$/,
    isValid: false,
    text: 'No spaces'
  },
  {
    id: 'password-letter',
    pattern: /(?=.*[a-z])(?=.*[A-Z])/,
    isValid: false,
    text: 'At least one uppercase and lowercase letter'
  },
  {
    id: 'password-special',
    pattern: /^.*[!#$+,-.\/:=?@\[\]_{|}~].*$/,
    isValid: false,
    text: 'At least one special character: ! # $ + , - . / : = ? @ [ ] _ { } | ~',
    ariaText:
      'At least one  special character: exclamation mark, number sign, dollar sign, plus, comma, hyphen, period, forward slash, colon, equal, question mark, at sign, open bracket, close bracket, underscore, open brace, close brace, vertical bar, or tilde'
  },
  {
    id: 'password-number',
    pattern: /^.*[0-9].*$/,
    isValid: false,
    text: 'At least one number'
  },
  {
    id: 'password-excluded-characters',
    pattern: /^[^\^&*();<>"'%`]+$/,
    isValid: false,
    text: 'None of these: \' ^ & * ( ) ; < > " % `',
    ariaText:
      'None of these: apostrophe, carat, ampersand, asterisk, open parenthesis, close parenthesis, semicolon, less than, greater than, double quote, percent or grave accent'
  }
];
