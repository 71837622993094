import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { ERROR_PATHS } from '@app/error/error-paths';
import { LoggingService } from '@shared/logging/logging.service';
import { NavigationService } from '@shared/navigation/navigation.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  protected readonly className = 'GlobalErrorHandler';

  constructor(private _injector: Injector, private _ngZone: NgZone) {}

  handleError(error: any) {
    this._ngZone.run(() => {
      this._injector.get(LoggingService).error(this.className, 'An unexpected error occurred.', error);
      this._injector.get(NavigationService).navigateToError(this.className, error, ERROR_PATHS.systemError);
    });
  }
}
