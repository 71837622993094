import { NgModule } from '@angular/core';
import { SearchModule } from '@common/search/search.module';
import {
  BoltAlertConfirmationModule,
  BoltAlertErrorModule,
  BoltButtonPrimaryModule,
  BoltButtonStandardModule,
  BoltCheckBoxModule,
  BoltFieldErrorModule,
  BoltRadioButtonModule,
  BoltRadioGroupModule,
  BoltTextFieldModule
} from '@nw/bolt-angular';
import { SharedModule } from '@shared/shared.module';
import { ControllerAttestationComponent } from './controller-attestation/controller-attestation.component';
import { CreateLoginComponent } from './create-login/create-login.component';
import { InstantSuccessComponent } from './instant-success/instant-success.component';
import { NonInstantSuccessComponent } from './non-instant-success/non-instant-success.component';
import { RegisterSuccessComponent } from './register-success/register-success.component';
import { RegistrationRoutingModule } from './registration-routing.module';
import { RegistrationTypeComponent } from './registration-type/registration-type.component';
import { RegistrationService } from './shared/registration.service';
import { UpdateLoginComponent } from './update-login/update-login.component';

@NgModule({
  declarations: [
    RegistrationTypeComponent,
    CreateLoginComponent,
    NonInstantSuccessComponent,
    InstantSuccessComponent,
    ControllerAttestationComponent,
    UpdateLoginComponent,
    RegisterSuccessComponent
  ],
  imports: [
    SharedModule,
    RegistrationRoutingModule,
    BoltAlertConfirmationModule,
    BoltAlertErrorModule,
    BoltButtonPrimaryModule,
    BoltButtonStandardModule,
    BoltFieldErrorModule,
    BoltTextFieldModule,
    BoltRadioGroupModule,
    BoltRadioButtonModule,
    BoltCheckBoxModule,
    SearchModule
  ],
  providers: [RegistrationService]
})
export class RegistrationModule {}
