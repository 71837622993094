<div class="bolt-space-inner-wide-xl" id="preferenceCenter">
  <div class="bolt-row bolt-space-bottom-lg">
    <ng-container
      *ngIf="{
        appConfig: appConfig$ | async
      } as data"
    >
      <div class="clearfix">
        <ciam-bundle-loader [scriptsToLoad]="pcWidgetScripts" [cssToLoad]="[pcWidgetCSSscriptPath]">
          <ng-container slot="bundle">
            <preference-center [config]="data.appConfig | json"></preference-center>
          </ng-container>
        </ciam-bundle-loader>
      </div>
    </ng-container>
  </div>
</div>

<div id="prefSpinnerContainer" class="pref__spinner__overlay__container">
  <div id="spinnerOverlay" class="pref__spinner__overlay" aria-live="assertive" role="alert">
    <div class="pref__spinner__container">
      <div id="spinner" class="progress-spinner"></div>
      <br />
      <span id="spinnerMessage" class="pref__spinner__message">Please Wait ....</span>
    </div>
  </div>
</div>
