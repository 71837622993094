<div id="esa-text-box" class="overflow-auto esa-text-box p-2 border border-dark" tabindex="0">
  <p class="sr-only">Electronic Services &amp; Document Delivery Agreement</p>
  <p>
    At Nationwide Mutual Insurance Company, its subsidiaries and affiliates, including, without limitation, Allied
    Group, Inc. and Harleysville Group, Inc. (collectively, “Nationwide,” “we” or “us”), we give you the ability to
    transact business with us electronically. This includes, but is not limited to, transacting business online at
    <a ciamClickLogger id="nationwide-link-1" class="underline" href="https://nationwide.com/" target="_blank"
      >www.Nationwide.com</a
    >, on the Web sites of our affiliates, and through our mobile applications (collectively “Nationwide Website(s)” or
    “Site”). Your use of Nationwide Websites is governed by this Agreement, the
    <a
      ciamClickLogger
      id="nationwide-terms-conditions-link-1"
      class="underline"
      href="https://nationwide.com/personal/about-us/terms-conditions"
      target="_blank"
      >Nationwide.com Terms and Conditions</a
    >, and any other terms and conditions referenced on the applicable website or mobile application (incorporated
    herein by reference). This Electronic Services and Document Delivery Agreement (the “Agreement”) is a legally
    binding agreement between you and Nationwide. You may print or electronically save a copy of this Agreement to
    retain for your records. If you wish to transact business with Nationwide electronically, please carefully review
    and consent to the terms listed below.
  </p>
  <p>
    <strong>Establishing an online account</strong>
  </p>
  <p>For Personal Users</p>
  <p>
    You understand that by accepting this Agreement, you authorize Nationwide to establish an online account for you
    with the access capability and privileges to manage your Nationwide accounts, policies, or other products sold or
    provided by Nationwide and perform financial transactions as available. You represent that you are the person
    registered as the owner of this online account and you acknowledge that you are the only person permitted to use
    your unique username and password to access your online account. You represent that you have authority to give
    instructions and authorize transactions with respect to your Nationwide products, including general authority over
    changes to your personal information, movement of funds, contract changes, purchase of products or services, changes
    to your current or past product or service, or any other online transaction facilitated or permitted by the use of a
    Nationwide Website. You are also confirming that you are authorized to, and do, consent on behalf of all the other
    account owners, authorized signers, authorized representatives, delegates, product owners and/or users identified
    with your Nationwide products to perform the above listed transactions. You understand that by consenting to this
    Agreement, Nationwide has full authorization from you to rely and act upon any instructions received from you
    without further inquiry.
  </p>
  <p>
    Nationwide is not responsible for errors or failures caused by any malfunction of your computer, browser, or
    software. Nationwide is also not responsible for computer viruses or related problems associated with the use of an
    online system, or any delay or failure in connection with your receipt of email notices.
  </p>
  <p>
    Although there is no charge for the services covered by this Agreement, there may be costs associated with
    electronic access to the documents, such as usage charges from internet service providers and telephone companies.
    These costs are your responsibility.
  </p>
  <p>
    <strong>
      Nationwide reserves the right to modify or discontinue the services covered by this Agreement or modify the terms
      of this Agreement at any time and for any reason. You understand and agree that your continued use or access of
      Nationwide’s electronic services after any modifications are made to this Agreement signifies your acceptance of
      the updated or modified Agreement. We will notify you of material changes to this Agreement by either sending a
      notice to the email address you provide to us or by posting a notice on a Nationwide Website. Be sure to return to
      the Nationwide Websites periodically to review the most current version of this Agreement.
    </strong>
  </p>
  <p>For Commercial Users</p>
  <p>
    You understand by accepting this Agreement, you affirm that you are authorized on behalf of the First Named Insured
    to authorize Nationwide to establish an online account for the organization with the access capability and
    privileges to manage the organization’s accounts, policies, or other products sold or provided by Nationwide and
    perform financial transactions as available. You further represent that you have the First Named Insured’s authority
    to give instructions and authorize transactions with respect to its insurance and financial products included in the
    account, including general authority over changes to its movement of funds, contract changes, purchase of products
    or services, changes to its current or past product or service, or any other online transaction facilitated or
    permitted by the use of a Nationwide Website. You understand that by consenting to this Agreement, you affirm that
    you are authorized on behalf of the First Named Insured to give Nationwide full authorization to rely and act upon
    any instructions received from you without further inquiry.
  </p>
  <p>
    Nationwide is not responsible for errors or failures caused by any malfunction of your computer, browser, or
    software. Nationwide is also not responsible for computer viruses or related problems associated with the use of an
    online system, or any delay or failure in connection with your receipt of email notices.
  </p>
  <p>
    Although there is no charge for the services covered by this Agreement, there may be costs associated with
    electronic access to the documents, such as usage charges from internet service providers and telephone companies.
    These costs are your responsibility.
  </p>
  <p>
    <strong>
      Nationwide reserves the right to modify or discontinue the services covered by this Agreement or modify the terms
      of this Agreement at any time and for any reason. You understand and agree that your continued use or access of
      Nationwide’s electronic services after any modifications are made to this Agreement signifies your acceptance of
      the updated or modified Agreement. We will notify you of material changes to this Agreement by either sending a
      notice to the email address you provide to us or by posting a notice on a Nationwide Website. Be sure to return to
      the Nationwide Websites periodically to review the most current version of this Agreement.
    </strong>
  </p>
  <p><strong>Your Security</strong></p>
  <p>
    To prevent misuse of your online account, you agree to protect the confidentiality of and be solely responsible for
    the use of your username, password, and other authentication or security measures as required by Nationwide. If you
    suspect that your information has been compromised, you agree to immediately contact Nationwide. You should never
    provide your Nationwide.com username, password or other authentication or security measure to anyone else. If you
    choose to do so, you understand that you are authorizing that person or entity to conduct transactions using your
    online account. You are responsible for any resulting transactions. You may not obtain or attempt to obtain
    unauthorized access to the Nationwide Websites, or to any other protected materials or information, through any
    means not intentionally made available by Nationwide for your specific use.
  </p>
  <p>
    <strong>Your Consent to Use Electronic Signatures and Receive Electronic Documents</strong>
  </p>
  <p>
    One way that we transact business online is by allowing you to opt-in for the electronic delivery of specific
    documents. As part of transacting business with us, some information is required to be delivered to you “in
    writing.” This generally means that you are entitled to receive it on paper. However, with your consent, we may
    provide this information to you electronically by delivering it to your account on a Nationwide Website or by
    delivering it to your email address (“Electronic Delivery”). We also need your prior consent in order to use your
    electronic signature in lieu of using your “wet” traditional written
  </p>
  <p>Your Consent to Use Electronic Signatures</p>
  <p>
    By choosing to transact business with Nationwide electronically, you agree to use electronic signatures which shall
    have the same force and effect as your written signature. Electronic signatures may take various forms on Nationwide
    Websites, including checking a box.
  </p>
  <p>Your Consent to Receive Covered Documents Electronically</p>
  <p>
    You consent to receive the following documents that may arise during your relationship with Nationwide
    (collectively, the “Covered Documents”) electronically through your account on a Nationwide Website or your email
    address, instead of in paper format through U.S. Mail:
  </p>
  <ul class="p-2 m-2">
    <li>
      <strong>Account Related Documents</strong> including, but not limited to, insurance applications and application
      materials, policy documents, disclosures, policy-related notices (e.g., notice of premium increase, notice of
      renewal, notice of conditional renewal, notice of nonrenewal, notice of cancellation and notice of change in terms
      or conditions), prospectuses, statements, and other documents as permitted by law;
    </li>
    <li>
      <strong>Billing Documents</strong> including, but not limited to, bills, billing statements, payment receipts,
      disclosures, notices and other documents as permitted by law;
    </li>
    <li>
      <strong>Information applicable to your use of Nationwide Websites</strong> such as quotes, claims documents,
      updates, notifications, transaction receipts, documents requiring your signature, information that you request or
      any other documents related to your use of Nationwide Websites
    </li>
    <li>
      Any other documents permitted or required by law.
    </li>
  </ul>

  <p>
    Your consent does not mean that Nationwide must provide the Covered Documents electronically. Nationwide may, at its
    discretion, provide the Covered Documents through non-electronic means.
  </p>
  <p>
    Your consent for Nationwide to use your electronic signature and to receive the Covered Documents electronically
    applies to all Nationwide products you currently own and all products you may purchase from Nationwide in the future
    and continues after a policy modification or renewal. Your consent to receive documents electronically does not mean
    that all Covered Documents will be delivered to you electronically.
  </p>
  <p>
    <strong
      >Accepting this agreement will not automatically change the manner in which you currently receive communications
      from Nationwide. In order to activate and manage Electronic Delivery for specific documents, you may have to take
      additional steps on the Nationwide Websites, such as selecting which documents you would like electronically
      delivered to you on the Preferences Page.</strong
    >
  </p>
  <p>
    Covered Documents will not be available online indefinitely. Please save the documents to your computer or print
    them off to retain copies for your records.
  </p>
  <p>Your Right to Revoke Your Consent at Any Time</p>
  <p>
    If you would like to change the delivery method for certain types of documents or accounts, this may be done by
    changing your delivery preferences on the applicable Nationwide Website. Changing your delivery preferences does NOT
    revoke your consent to receive all documents electronically, nor does it revoke your consent to any other part of
    this Agreement.
  </p>
  <p>
    However, if you would like to revoke your consent to receive ALL Covered Documents electronically, you may only do
    so by deleting your online accounts. This may be done by calling the Nationwide Support Center at
    <ciam-phone-number key="WEB_SUPPORT"></ciam-phone-number> and requesting your online accounts be deleted. Revoking
    your consent to the Electronic Delivery of the Covered Documents will terminate all of your online accounts on
    Nationwide Websites and may cause you to lose any relevant discounts related to the Electronic Delivery of
    documents, but will not terminate your relationship with Nationwide unless additional notice is given. The
    revocation of your consent will take effect within a reasonable time of the Nationwide Service Center receiving your
    email or as otherwise required by law.
  </p>
  <p>Your Right to Obtain Paper Copies</p>
  <p>
    You may obtain paper copies of the Covered Documents at no additional cost. To request paper copies please contact
    the Nationwide Service Center at
    <ciam-phone-number key="NW_SERVICE_CENTER" _id="esa-service-center-phone-1"></ciam-phone-number>. Please be assured
    that requesting a paper copy in no way withdraws your consent to this Agreement or changes your election to continue
    to receive the Covered Documents electronically.
  </p>
  <p>Your Responsibility to Provide Us with a Valid and Active Email Address</p>
  <p>
    It is your responsibility to provide us with a valid and active email address. You may update your email address and
    other contact information by logging in to
    <a ciamClickLogger id="nationwide-link-2" class="underline" href="https://nationwide.com/" target="_blank">
      www.Nationwide.com
    </a>
    or by contacting the Nationwide Service Center at
    <ciam-phone-number key="NW_SERVICE_CENTER" _id="esa-service-center-phone-2"></ciam-phone-number>.
  </p>
  <p>
    E-mails returned as undeliverable may result in a suspension of Electronic Delivery of the Covered Documents. In the
    event of such a suspension, paper copies of the Covered Documents will be sent to you through U.S. mail at the last
    address you have provided. It is your responsibility to provide us with an updated mailing address.
  </p>
  <p>System Requirements</p>
  <p>To receive, access, and reply to the applicable Covered Documents you will need:</p>
  <ul class="p-2 m-2">
    <li>A personal computer or other access device which is capable of accessing the Internet;</li>
    <li>An Internet web browser which is capable of supporting 128-bit SSL encrypted communications;</li>
    <li>Software which permits you to receive and access Portable Document Format or (“PDF”) files</li>
    <li>The ability to download or print agreements and disclosures.</li>
  </ul>
  <p>
    Some states may require insurance documents to be displayed in a particular font size. To ensure that communications
    are displayed at the correct font size for your state, please make sure that the view size setting for the
    communications is set to 100% and your browser resolution is 800 x 600.
  </p>
  <p>In order to retain the Covered Documents your system must have the ability to:</p>
  <ul class="p-2 m-2">
    <li>Download and save files to your hard disk drive</li>
    <li>Print PDF files, or print Web pages and embedded HTML files.</li>
  </ul>
  <p>
    <strong
      >Your consent confirms that you have the hardware and software described above, that you are able to receive and
      review electronic records, and that you have an active email account.</strong
    >
  </p>
  <p>
    <strong>Enrollment with Our Equipment</strong>
  </p>
  <p>
    If you elect to receive the Covered Documents through one of our customer service representatives or by using our
    equipment, your enrollment may not be complete until you perform an additional action. At the time of your
    enrollment, we will advise you if you must perform any additional action in order to consent to this Agreement. If
    you perform the required additional action, this action will constitute your affirmative consent to this Agreement.
  </p>
  <p>
    <strong>State Specific Provisions</strong>
  </p>
  <p>
    <strong>Residents of Georgia Only</strong><br />
    You consent to the following: I AGREE TO RECEIVE ALL MAILINGS AND COMMUNICATIONS ELECTRONICALLY. SUCH ELECTRONIC
    MAILING OR COMMUNICATIONS MAY EVEN INCLUDE CANCELLATION OR NONRENEWAL NOTICES.
  </p>
  <p>
    <strong>Residents of Kentucky and Tennessee Only</strong><br />
    By consenting to this Agreement, you, the policyholder, elect to allow for policies, notices and communications to
    be sent to the electronic mail address provided by you and you should be aware that this election operates as
    consent by you for all notices to be sent electronically, including notice of nonrenewal and cancellation.
    Therefore, you, the policyholder, should be diligent in updating the electronic mail address provided to Nationwide
    in the event that the address should change.
  </p>
  <p><strong>For help when you need it</strong></p>
  <p>
    We appreciate your business and look forward to continuing to serve you. If you have any questions about this
    Agreement, please contact us at
    <ciam-phone-number key="NW_SERVICE_CENTER" _id="esa-service-center-phone-3"></ciam-phone-number>.
  </p>
  <p><strong>Limitation of Liability</strong></p>
  <p>
    <strong>Nationwide’s liabilities</strong>
  </p>
  <p>
    In no event will Nationwide, its respective service providers, employees, agents, officers and directors be liable
    to you for any punitive, indirect, direct, special, incidental or consequential damages. This includes any lost
    profits, costs of obtaining substitute service or lost opportunity, even if you’ve notified Nationwide about the
    possibility of such damages. This also includes any claims by any third parties arising out of (or in any way
    related to) the access, use or information and other materials present on the Site (“Content”) or a linked website.
    This applies whether such claims are brought under any theory of law or equity. This limitation on liability
    includes (but is not limited to) transmission of viruses that infects a user's equipment, mechanic or electronic
    equipment failure, failure of communication lines, telephone or other interconnects, unauthorized access, theft,
    operational errors, strikes or other labor problems, or any force majeure.
  </p>
  <p>
    <strong>Your liabilities</strong>
  </p>
  <p>
    Nationwide has the right to deny you access and use of the Site and its Content if you violate (as Nationwide may
    determine in its sole and absolute discretion) any provision of the
    <a
      ciamClickLogger
      id="nationwide-terms-conditions-link-2"
      class="underline"
      href="https://nationwide.com/personal/about-us/terms-conditions"
      target="_blank"
      >Nationwide.com Terms and Conditions</a
    >. Nationwide reserves the right to seek all other remedies available at law and in equity. You agree, at your own
    expense, to defend, indemnify and hold Nationwide harmless from any claim or demand (including reasonable attorneys'
    fees) made by a third party in connection with (or arising out of your access to, or use of) the Site or any of its
    Content in a manner other than as expressly authorized by the
    <a
      ciamClickLogger
      id="nationwide-terms-conditions-link-3"
      class="underline"
      href="https://nationwide.com/personal/about-us/terms-conditions"
      target="_blank"
      >Nationwide.com Terms and Conditions</a
    >. This includes your breach of the
    <a
      ciamClickLogger
      id="nationwide-terms-conditions-link-4"
      class="underline"
      href="https://nationwide.com/personal/about-us/terms-conditions"
      target="_blank"
      >Nationwide.com Terms and Conditions</a
    >, or your violation of applicable laws or any rights of any third party.
  </p>
  <p><strong>Disclaimer of Warranties</strong></p>
  <p>
    ALL INFORMATION AND OTHER MATERIALS PRESENT ON NATIONWIDE WEBSITES, INCLUDING, WITHOUT LIMITATION, PRODUCTS AND
    SERVICES ON NATIONWIDE WEB SITES ARE PROVIDED "AS IS," WITHOUT ANY WARRANTIES ABOUT THE CONTENT'S NATURE OR ACCURACY
    (EITHER WHEN POSTED OR AS A RESULT OF THE PASSAGE OF TIME) AND WITHOUT ANY REPRESENTATIONS OR GUARANTEES. NATIONWIDE
    MAKES NO REPRESENTATIONS, WARRANTIES OR GUARANTEES THAT THE NATIONWIDE WEBSITES WILL BE ACCESSIBLE CONTINUOUSLY AND
    WITHOUT INTERRUPTION, OR ERROR FREE.
  </p>
  <p><strong>Legal Effect</strong></p>
  <p>
    By signing this Agreement, you agree to the terms and conditions herein, and acknowledge and agree that your consent
    is provided and/or obtained in connection with a transaction affecting interstate commerce subject to the Electronic
    Signatures in Global and National Commerce Act and the Uniform Electronic Transactions Act, or a similar electronic
    transactions law, as adopted by state law. You further agree that:
  </p>
  <p>
    The Covered Documents made available to you on a Nationwide Website or delivered to your email address shall have
    the same meaning and effect as if you were provided a paper document, whether or not you choose to view the
    document(s), unless you previously withdrew your consent to receive documents by electronic means in accordance with
    this Agreement.
  </p>
  <p>
    The Covered Documents are considered received by you when Nationwide sends the electronic notification to the email
    address you provide unless Nationwide receives notice that the email notification was not delivered to you at the
    email address you provided.
  </p>
  <p>
    With respect to electronic Billing Documents, delays experienced due to the use of this service will not change any
    payment due date or the potential imposition of late fees. If you do not receive an anticipated e-mail notice, log
    on to
    <a ciamClickLogger id="nationwide-link-3" class="underline" href="https://nationwide.com/" target="_blank">
      www.Nationwide.com
    </a>
    for up-to-date information about your account(s).
  </p>
  <p>
    Except to the extent otherwise set forth in the Agreement or any other Nationwide agreement applicable to other
    portions of Nationwide Websites, this Agreement sets forth the entire understanding between Nationwide and you with
    respect to your access to, and use of, Nationwide Websites and supersedes all prior or contemporaneous
    understandings regarding access and use
  </p>
  <p>
    Any provision of this Agreement that shall be proven unenforceable under any law or regulation shall not affect the
    validity or enforceability of any other provision of this Agreement.
  </p>
  <p>
    This Agreement and any dispute arising from this Agreement, or the subject matter hereof, shall be governed by the
    laws of the United States and the State of Ohio.
  </p>
  <p>G 1008 02 19​</p>
</div>
