import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@environments/environment';
import {
  CustomerIdentificationService,
  GetInternetRegistrationByEcnResponse,
  PostSearchPotentialMatchResponse,
  PostSearchSingleMatchResponse,
  RegistrationManagementService
} from '@nationwide/api-client-internet-registration-v3';
import { CustomCookieService } from '@shared/storage/custom-cookie.service';
import { CustomSessionService, CUSTOM_SESSION } from '@shared/storage/custom-session.service';
import { Partner, SessionKey } from '@shared/storage/storage.model';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { SearchCriteria, SearchModel } from './search.model';

@Injectable()
export class SearchService {
  private _model: SearchModel;

  constructor(
    @Inject(CUSTOM_SESSION) private _sessionService: CustomSessionService,
    private _cookieService: CustomCookieService,
    private _oauthService: OAuthService,
    private _customerIdentificationService: CustomerIdentificationService,
    private _registrationManagementService: RegistrationManagementService
  ) {
    this.reset();
  }

  reset(preserveAccountType = false, preserveLifecycleStatus = false) {
    const newModel: SearchModel = {};
    if (preserveAccountType && this.model?.accountType) {
      newModel.accountType = this.model.accountType;
    }
    if (preserveLifecycleStatus && this.model?.lifecycleStatus) {
      newModel.lifecycleStatus = this.model.lifecycleStatus;
    }

    this.model = newModel;
    this._customerIdentificationService.configuration.accessToken = this._oauthService.getAccessToken();
    this._registrationManagementService.configuration.accessToken = this._oauthService.getAccessToken();
  }

  searchForCustomer(
    searchCriteria: SearchCriteria
  ): Observable<HttpResponse<PostSearchSingleMatchResponse | PostSearchPotentialMatchResponse>> {
    this._model.searchCriteria = searchCriteria;
    if (searchCriteria.filters?.partyType) {
      this._model.partyType = searchCriteria.filters.partyType;
    }
    return this._customerIdentificationService.customersSearchPost(
      {
        firstName: searchCriteria.input.firstName,
        lastName: searchCriteria.input.lastName,
        zipCode: searchCriteria.input.zipCode,
        dateOfBirth: searchCriteria.input.dateOfBirth,
        email: searchCriteria.input.email,
        phoneNumber: searchCriteria.input.phoneNumber,
        addressNumber: searchCriteria.input.addressNumber,
        agreementNumber: searchCriteria.input.agreementNumber,
        taxIdentifier: searchCriteria.input.taxIdentifier
      },
      ENVIRONMENT.apiConfig.clientId,
      this._cookieService.flowId,
      (this._sessionService.get(SessionKey.PARTNER) as Partner)?.id,
      false,
      this.model.partyType,
      this.model.lifecycleStatus,
      'response'
    );
  }

  getExistingRegistrations(ecn: string): Observable<HttpResponse<GetInternetRegistrationByEcnResponse>> {
    return this._registrationManagementService.internetRegistrationsGet(
      ENVIRONMENT.apiConfig.clientId,
      this._cookieService.flowId,
      ecn,
      (this._sessionService.get(SessionKey.PARTNER) as Partner)?.id,
      false,
      'response',
      false
    );
  }

  get model(): SearchModel {
    return this._model;
  }

  set model(model: SearchModel) {
    this._model = model;
  }
}
