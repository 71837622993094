import { Routes, mapToCanActivate } from '@angular/router';
import { CommonFlow } from '@shared/navigation/flow.enum';
import { TimeoutGuard } from '@shared/timeout/timeout.guard';
import { ControllerEligibilityComponent } from './controller/controller-eligibility.component';
import { ELIGIBILITY_PATHS } from './eligibility-route-paths';
import { StandardEligibilityComponent } from './standard/standard-eligibility.component';

export const ELIGIBILITY_ROUTES: Routes = [
  {
    path: CommonFlow.ELIGIBILITY,
    canActivate: mapToCanActivate([TimeoutGuard]),
    children: [
      { path: ELIGIBILITY_PATHS.standard, component: StandardEligibilityComponent },
      { path: ELIGIBILITY_PATHS.controller, component: ControllerEligibilityComponent }
    ]
  }
];
