import { Component, OnInit } from '@angular/core';
import { ENVIRONMENT } from '@environments/environment.dev';
import { ContentHeaderModel } from '@shared/content-header/content-header.model';
import { ContentHeaderService } from '@shared/content-header/content-header.service';
import { NavigationService } from '@shared/navigation/navigation.service';
import { RegistrationBaseComponent } from '../shared/registration-base.component';

@Component({
  selector: 'ciam-non-instant-success',
  templateUrl: './non-instant-success.component.html',
  styleUrls: ['./non-instant-success.component.scss']
})
export class NonInstantSuccessComponent extends RegistrationBaseComponent implements OnInit {
  constructor(private _contentHeaderService: ContentHeaderService, private _navigationService: NavigationService) {
    super();
  }

  ngOnInit() {
    this._contentHeaderService.updateProgress(this.contentHeaderModel);
  }

  backToNationwide() {
    this._navigationService.navigateExternal(ENVIRONMENT.externalUrls.nationwide.basePath);
  }

  get contentHeaderModel(): ContentHeaderModel {
    return this.fromForgotCredentials
      ? {}
      : {
          progressTitle: 'Watch for your activation key',
          progressPercent: 100
        };
  }
}
