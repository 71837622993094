import { CookieKey } from '@shared/storage/storage.model';

export const isMockEnabled = () => /true/i.test(getCookie(CookieKey.CIAM_MOCK_ENABLED));

// This doesn't use the CookieService, since it is not a component with an injectable constructor.
const getCookie = (cookieName: string): string => {
  const cookies = decodeURIComponent(document.cookie).split(';');

  for (let cookie of cookies) {
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }

    const name = `${cookieName}=`;
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }

  return null;
};
