import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppInjector } from '@app/app-injector';
import { DestinationType } from '@app/authorization/shared/authorization.model';
import { ENVIRONMENT } from '@environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ControlErrors, Controls } from '@shared/base-component/base.model';
import { ContentHeaderModel } from '@shared/content-header/content-header.model';
import { SessionKey } from '@shared/storage/storage.model';
import { filter } from 'rxjs/operators';
import { PROXY_PATHS } from '../proxy-paths';
import { VerifyCustomerBaseComponent } from '../shared/verify-customer-base.component';
import { CONTROLS, CONTROL_ERRORS } from './personal-information.model';

@UntilDestroy()
@Component({
  selector: 'ciam-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss']
})
export class PersonalInformationComponent extends VerifyCustomerBaseComponent implements OnInit {
  @ViewChild('personalInformationFormParent', { static: true })
  personalInformationFormParent: NgForm;
  personalInformationForm: UntypedFormGroup;
  personalInformationControls: Controls = CONTROLS;
  personalInformationControlErrors: ControlErrors = CONTROL_ERRORS;
  verifyFailedAttempts = 0;
  firstName = '';

  protected formBuilder: UntypedFormBuilder;

  protected readonly className = 'personalInformationComponent';

  constructor() {
    super();
    this.formBuilder = AppInjector.injector.get(UntypedFormBuilder);
  }

  get contentHeaderModel(): ContentHeaderModel {
    switch (this.destination) {
      case DestinationType.OTL_RECOVERY:
        return {
          title: 'Forgot username/password',
          progressTitle: 'Find account',
          progressPercent: 10
        };
      default:
        return {
          title: 'Sign up for online access',
          progressTitle: 'Find account',
          progressPercent: 10
        };
    }
  }

  ngOnInit(): void {
    this.logger.info(this.className, 'Entering personal information.', {
      previousVerify: this.verifyCustomerService.model?.previousVerify
    });
    super.ngOnInit();
    this.contentHeaderService.updateProgress(this.contentHeaderModel);
    this.firstName = this.sessionService.get(SessionKey.CUSTOMER)?.firstName || '';
    this._initializeForm();
  }

  back() {
    this.navigationService.navigateExternal(
      `${ENVIRONMENT.externalUrls.iam.basePath}${ENVIRONMENT.externalUrls.iam.login}`
    );
  }

  _verifyAccount() {
    this._performVerify({
      input: {
        dateOfBirth: this.personalInformationForm.get('dateOfBirth').value,
        zipCode: this.personalInformationForm.get('zipCode').value
      }
    });
  }

  _handleVerificationFailure() {
    this.verifyFailedAttempts++;

    if (this.verifyFailedAttempts < ENVIRONMENT.features.verify.maxAttempts.personalInformation) {
      this.spinnerService.hide();
    } else {
      this.navigationService.navigate(`${this.baseRoute}/${PROXY_PATHS.additionalIdentifier}`);
    }
  }

  private _initializeForm() {
    this.personalInformationForm = this.formBuilder.group(
      {
        dateOfBirth: [
          '',
          [Validators.required, ...this._buildPatternValidators(this.personalInformationControls.dateOfBirth.patterns)]
        ],
        zipCode: [
          '',
          [Validators.required, ...this._buildPatternValidators(this.personalInformationControls.zipCode.patterns)]
        ]
      },
      {
        updateOn: 'submit'
      }
    );
    this.personalInformationForm.statusChanges
      .pipe(
        untilDestroyed(this),
        filter(() => this.personalInformationForm.valid)
      )
      .subscribe(() => {
        this._verifyAccount();
      });
  }

  get errorMessage(): string {
    if (this.personalInformationFormParent.submitted && this.personalInformationFormParent.invalid) {
      return 'Please correct the following to continue.';
    } else if (this.verifyFailedAttempts > 0) {
      return 'We cannot locate an account associated with this information. Please check your information and try again.';
    }
    return null;
  }

  get personalInformationTitle(): string {
    return `${this.firstName ? 'Hi, ' + this.formattedFirstName + '. ' : ''}Please enter your personal information.`;
  }

  get formattedFirstName(): string {
    return this.firstName
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  protected get formGroup(): UntypedFormGroup {
    return this.personalInformationForm;
  }

  protected get controls(): Controls {
    return this.personalInformationControls;
  }

  protected get controlErrors(): ControlErrors {
    return this.personalInformationControlErrors;
  }
}
