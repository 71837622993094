import { NgModule } from '@angular/core';
import { SearchModule } from '@common/search/search.module';
import {
  BoltAlertConfirmationModule,
  BoltAlertErrorModule,
  BoltButtonPrimaryModule,
  BoltButtonStandardModule,
  BoltCheckBoxModule,
  BoltFieldErrorModule,
  BoltRadioButtonModule,
  BoltRadioGroupModule,
  BoltTextFieldModule
} from '@nw/bolt-angular';
import { SharedModule } from '@shared/shared.module';
import { AdditionalIdentifierComponent } from './additional-identifier/additional-identifier.component';
import { PersonalInformationComponent } from './personal-information/personal-information.component';
import { ProxyRoutingModule } from './proxy-routing.module';
import { VerifyCustomerService } from './shared/verify-customer.service';
import { UpdateUsernamePasswordComponent } from './update-username-password/update-username-password.component';
import { UpdateUsernamePasswordService } from './update-username-password/update-username-password.service';

@NgModule({
  declarations: [UpdateUsernamePasswordComponent, PersonalInformationComponent, AdditionalIdentifierComponent],
  imports: [
    SharedModule,
    ProxyRoutingModule,
    SearchModule,
    BoltAlertConfirmationModule,
    BoltAlertErrorModule,
    BoltButtonPrimaryModule,
    BoltButtonStandardModule,
    BoltFieldErrorModule,
    BoltTextFieldModule,
    BoltRadioGroupModule,
    BoltRadioButtonModule,
    BoltCheckBoxModule
  ],
  providers: [VerifyCustomerService, UpdateUsernamePasswordService]
})
export class ProxyModule {}
