<div class="d-flex flex-column">
  <form
    id="registration-type-form"
    class="d-flex flex-column justify-content-between page-content"
    [formGroup]="registrationTypeForm"
    #registrationTypeFormParent="ngForm"
    (ngSubmit)="resetPageErrorAndFocus()"
  >
    <section id="registration-type-content" class="container">
      <div id="registration-type-header" class="content-section">
        <bolt-alert-error
          #pageLevelError
          _id="page-level-error-invalid"
          [hidden]="!(registrationTypeFormParent.submitted && registrationTypeForm.invalid)"
        >
          Please correct the following to continue.
        </bolt-alert-error>
        <h2 #adaAutoFocus id="registration-type-title" class="h5">
          <strong>
            You have both personal and business accounts.
          </strong>
        </h2>
        <p id="registration-type-message">
          We see that you have a personal account with us and we also matched you with the following
        </p>
        <ciam-business-names></ciam-business-names>
      </div>
      <div id="registration-type-input" class="content-section">
        <fieldset id="registration-type-fieldset">
          <legend class="pre-form-content">
            <h2 id="registration-type-form-title" class="h5">
              What would you like to register for online access?
            </h2>
          </legend>
          <div [id]="registrationTypeControls.multipleTypesSelection.id" class="field-error">
            <div class="form-check mobile-friendly-radio">
              <bolt-radio-button
                [_id]="registrationTypeControls.multipleTypesSelection.id + '-' + 'personal-and-business'"
                _name="multipleTypesSelection"
                [checked]="null"
                label="Personal and business accounts"
                [value]="registrationFlows.BUSINESS"
                [formGroup]="registrationTypeForm"
              >
              </bolt-radio-button>
            </div>
            <div class="form-check mobile-friendly-radio">
              <bolt-radio-button
                [_id]="registrationTypeControls.multipleTypesSelection.id + '-' + 'personal-only'"
                _name="multipleTypesSelection"
                [checked]="null"
                label="Personal account"
                [value]="registrationFlows.PERSONAL"
                [formGroup]="registrationTypeForm"
              >
              </bolt-radio-button>
            </div>
          </div>
          <bolt-field-error
            id="multiple-types-selection-errors"
            _id="multiple-types-selection-errors"
            [errors]="registrationTypeForm.get('multipleTypesSelection').errors"
            [errorMessages]="registrationTypeControlErrors.multipleTypesSelection"
            *ngIf="registrationTypeFormParent.submitted"
          ></bolt-field-error>
        </fieldset>
      </div>
    </section>

    <section id="registration-type-buttons" class="button-container">
      <div class="container button-stack">
        <bolt-button-primary ciamClickLogger _id="registration-type-submit" _type="submit">
          Submit
        </bolt-button-primary>
        <input
          ciamClickLogger
          type="button"
          id="registration-type-cancel"
          class="btn btn-default"
          value="Cancel"
          data-cancel-trigger
        />
      </div>
    </section>
  </form>
</div>
