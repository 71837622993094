import { Inject, Injectable } from '@angular/core';
import { LoggingService } from '@shared/logging/logging.service';
import { CustomCookieService } from './custom-cookie.service';
import { CUSTOM_SESSION, CustomSessionService } from './custom-session.service';
import { CookieKey } from './storage.model';

@Injectable({
  providedIn: 'root'
})
export class StorageGuard {
  private readonly _className = 'StorageGuard';

  constructor(
    @Inject(CUSTOM_SESSION) private _sessionService: CustomSessionService,
    private _cookieService: CustomCookieService,
    private _logger: LoggingService
  ) {}

  canActivate(): boolean {
    this._logger.debug(this._className, `${this._className} activated.`);
    this._cookieService.delete(CookieKey.CIAM_FLOW_ID);
    this._sessionService.clear();
    return true;
  }
}
