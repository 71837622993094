import { Component, Input, OnInit } from '@angular/core';
import { PhoneNumber } from './phone-number.enum';

@Component({
  selector: 'ciam-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss']
})
export class PhoneNumberComponent implements OnInit {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('_aria-labelledby') inputAriaLabelledby: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('_id') inputId: string;
  @Input() key: string;
  phoneNumber: string;

  ngOnInit() {
    this.phoneNumber = PhoneNumber[this.key as keyof typeof PhoneNumber];
  }

  get id(): string {
    return this.inputId || `${this.key.replace(/_/g, '-').toLowerCase()}-phone`;
  }

  get ariaLabel(): string {
    // format the number to look like x. x x x. x x x. x x x x so the screen reader reads numbers separately
    return this.phoneNumber
      ? this.phoneNumber
          .split('')
          .join(' ')
          .replace(/\s\-/g, '.')
      : '';
  }

  get ariaLabelledby(): string {
    return this.inputAriaLabelledby || null;
  }
}
