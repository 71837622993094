import { NgModule } from '@angular/core';
import { RouterModule, Routes, mapToCanActivate, mapToCanActivateChild } from '@angular/router';
import { SEARCH_PATHS } from '@common/search/search-paths';
import { CommonFlow, Flow } from '@shared/navigation/flow.enum';
import { NavigationGuard } from '@shared/navigation/navigation.guard';
import { AppRedirectGuard } from './app-redirect.guard';
import { AppComponent } from './app.component';

export const APP_ROUTES: Routes = [
  {
    path: '',
    canActivateChild: mapToCanActivateChild([NavigationGuard]),
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: `/${Flow.REGISTRATION}/${CommonFlow.SEARCH}/${SEARCH_PATHS.accountType}`
      }
    ]
  },
  {
    path: '**',
    canActivate: mapToCanActivate([AppRedirectGuard]),
    component: AppComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTES, {
      useHash: true,
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule],
  providers: [AppRedirectGuard]
})
export class AppRoutingModule {}
