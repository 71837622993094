import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ENVIRONMENT } from '@environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ModalEvent } from '@nw/bolt-angular';
import { LoggingService } from '@shared/logging/logging.service';
import { NavigationService } from '@shared/navigation/navigation.service';
import { filter } from 'rxjs/operators';
import { CancelBodyMessages, CancelHeaderMessages } from './cancel.model';
import { CancelService } from './cancel.service';

@UntilDestroy()
@Component({
  selector: 'ciam-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.scss']
})
export class CancelComponent implements OnInit {
  protected readonly className = 'CancelComponent';

  private _triggers: Array<Element> = [];

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _navigationService: NavigationService,
    private _logger: LoggingService,
    private _router: Router,
    public cancelService: CancelService
  ) {}

  ngOnInit() {
    this._router.events
      .pipe(
        untilDestroyed(this),
        filter(event => event instanceof NavigationStart)
      )
      .subscribe(() => {
        this.cancelService.reset();
      });
    this._router.events
      .pipe(
        untilDestroyed(this),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        // NavigationEnd seems to work for static DOM elements; if we ever need to bind to an element that the Angular lifecycle
        // is continuing to resolve after the route/navigation has completed, this may need to change.
        setTimeout(() => {
          this._triggers = [];
          this._document.querySelectorAll('*[data-cancel-trigger]').forEach(element => {
            this._triggers.push(element);
          });
        });
      });
  }

  handleCancelModalSelection(modalEvent: ModalEvent = null) {
    this._logger.logElementClick(this.className, modalEvent.buttonEvent);
    if (modalEvent.choice === 'Leave this page') {
      this._navigationService.navigateExternal(
        (modalEvent.triggerTarget as Element).getAttribute('data-cancel-url') ||
          ENVIRONMENT.externalUrls.nationwide.basePath
      );
    }
  }

  get modalHeader(): string {
    return this.cancelService.model.modalHeader || CancelHeaderMessages.DEFAULT;
  }

  get modalBody(): string {
    return this.cancelService.model.modalBody || CancelBodyMessages.DEFAULT;
  }

  get triggers(): Array<Element> {
    return this._triggers;
  }
}
