import { AfterContentInit, Component } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { PRIMARY_OUTLET, Router, UrlSegment } from '@angular/router';
import { AppInjector } from '@app/app-injector';
import { Flow } from '@shared/navigation/flow.enum';
import { SpinnerService } from '@shared/spinner/spinner.service';

@Component({ template: '' })
export abstract class BaseComponent implements AfterContentInit {
  hideSpinnerOnLoad = true;

  protected className = 'BaseComponent';

  protected router: Router;
  protected spinnerService: SpinnerService;
  private _routeSegments: Array<UrlSegment>;

  private _flow: Flow;

  constructor() {
    this.router = AppInjector.injector.get(Router);
    this.spinnerService = AppInjector.injector.get(SpinnerService);

    this._routeSegments = this.router.parseUrl(this.router.url).root?.children[PRIMARY_OUTLET]?.segments;
  }

  ngAfterContentInit() {
    if (this.hideSpinnerOnLoad) {
      this.spinnerService.hide();
    }
  }

  _buildPatternValidators(patterns: RegExp[]): ValidatorFn[] {
    const patternValidators: ValidatorFn[] = [];
    patterns.forEach(pattern => patternValidators.push(Validators.pattern(pattern)));

    return patternValidators;
  }

  get flow(): Flow {
    if (!this._flow && this.routeSegments && this.routeSegments.length > 0) {
      this._flow = this.routeSegments[0]?.path as Flow;
    }

    return this._flow;
  }

  get baseRoute(): string {
    return this.flow;
  }

  get routeSegments(): Array<UrlSegment> {
    return this._routeSegments;
  }
}
