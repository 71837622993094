<div class="row no-gutters" *ngFor="let dynamicChecklistItem of dynamicChecklist">
  <div class="col-auto icon-column">
    <svg
      *ngIf="dynamicChecklistItem.isValid; else invalid"
      id="{{ dynamicChecklistItem.id + '-check-icon' }}"
      class="list-icon pr-2"
      attr.focusable="true"
      viewBox="0 0 32 32"
      attr.aria-labelledby="{{ dynamicChecklistItem.id + '-valid-image' }}"
      role="img"
    >
      <path
        fill="#3A913F"
        d="M31.458 8.124l-4.302-4.302-15.422 15.431-6.951-6.951-4.302 4.293 11.244 11.262 0.009-0.009 0.009 0.009z"
      ></path>
    </svg>
    <title id="{{ dynamicChecklistItem.id + '-valid-image' }}">Green checkmark</title>
    <ng-template #invalid>
      <svg
        id="{{ dynamicChecklistItem.id + '-x-icon' }}"
        class="list-icon pr-2"
        attr.focusable="true"
        viewBox="0 0 32 32"
        role="img"
        attr.aria-labelledby="{{ dynamicChecklistItem.id + '-invalid-image' }}"
      >
        <path
          fill="#C70000"
          d="M27.982 8.124l-4.293-4.302-7.716 7.716-7.716-7.716-4.302 4.302 7.716 7.716-7.716 7.716 4.302 4.302 7.716-7.716 7.716 7.716 4.293-4.302-7.716-7.716z"
        ></path>
      </svg>
      <title id="{{ dynamicChecklistItem.id + '-invalid-image' }}">Red X</title>
    </ng-template>
  </div>
  <div class="col">
    <span
      id="{{ dynamicChecklistItem.id + '-label' }}"
      attr.aria-hidden="{{ dynamicChecklistItem.ariaText !== null }}"
      class="align-middle"
    >
      {{ dynamicChecklistItem.text }}
    </span>
    <span id="{{ dynamicChecklistItem.id + '-aria-label' }}" class="sr-only" *ngIf="dynamicChecklistItem.ariaText">
      {{ dynamicChecklistItem.ariaText }}
    </span>
  </div>
</div>
