import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ENVIRONMENT } from '@environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ControlErrors, Controls } from '@shared/base-component/base.model';
import { Flow } from '@shared/navigation/flow.enum';
import { PartyType } from '@shared/storage/storage.model';
import { filter } from 'rxjs/operators';
import { PROXY_PATHS } from '../proxy-paths';
import { VerifyCustomerBaseComponent } from '../shared/verify-customer-base.component';
import { IdentifierType, VerifyCustomerCriteria } from '../shared/verify-customer.model';
import { CONTROLS, CONTROL_ERRORS } from './additional-identifier.model';
import { validateAdditionalIdentifier } from './validate-additional-identifier';

@UntilDestroy()
@Component({
  selector: 'ciam-additional-identifier',
  templateUrl: './additional-identifier.component.html',
  styleUrls: ['./additional-identifier.component.scss']
})
export class AdditionalIdentifierComponent extends VerifyCustomerBaseComponent implements OnInit {
  @ViewChild('additionalIdentifierFormParent', { static: true }) additionalIdentifierFormParent: NgForm;
  additionalIdentifierForm: UntypedFormGroup;
  additionalIdentifierControls: Controls = CONTROLS;
  additionalIdentifierControlErrors: ControlErrors = CONTROL_ERRORS;
  isVerified = false;
  submitted = false;
  verifyAttempts = {
    [IdentifierType.SOCIAL]: 0,
    [IdentifierType.ACCOUNT]: 0
  };

  identifierTypes = IdentifierType;

  protected readonly className = 'AdditionalIdentifierComponent';

  constructor(private _formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.logger.info(this.className, 'Entering additional identifier information.', {
      previousVerify: this.verifyCustomerService.model?.previousVerify
    });
    super.ngOnInit();
    this._initializeForm();
  }

  backToPersonalInformation() {
    this.navigationService.navigate(`${this.baseRoute}/${PROXY_PATHS.personalInformation}`);
  }

  _handleTypeSelection(identifierType: IdentifierType) {
    this.additionalIdentifierFormParent.resetForm();
    this.additionalIdentifierForm.get('identifierType').setValue(identifierType);
  }

  _verifyAccount() {
    let verifyCustomerCriteria: VerifyCustomerCriteria;
    switch (this.identifierType) {
      case IdentifierType.ACCOUNT:
        verifyCustomerCriteria = {
          input: { agreementNumber: this.additionalIdentifierForm.get(this.identifierType).value }
        };
        break;
      case IdentifierType.SOCIAL:
        verifyCustomerCriteria = {
          input: { taxIdentifier: this.additionalIdentifierForm.get(this.identifierType).value },
          filters: { partyType: PartyType.PERSON }
        };
        break;
      default:
        throw new Error(`Cannot perform verify.  ${this.identifierType} is not a valid identifier type.`);
    }
    this._performVerify(verifyCustomerCriteria, this.identifierType);
  }

  _handleVerificationFailure() {
    this.verifyAttempts[this.identifierType]++;

    if (this.hasMaxTotalVerifyAttempts) {
      this.logger.warn(this.className, 'Identifier verify failed max number of attempts.');
      this.navigationService.navigate(`${Flow.PROXY_REGISTRATION}/${PROXY_PATHS.startOver}`);
    } else {
      if (this.hasMaxSocialVerifyAttempts && this.identifierType !== IdentifierType.ACCOUNT) {
        this.logger.warn(
          this.className,
          'Identifier social verify failed max number of attempts; forcing account option.'
        );
        this._handleTypeSelection(IdentifierType.ACCOUNT);
      } else if (this.hasMaxAccountVerifyAttempts && this.identifierType !== IdentifierType.SOCIAL) {
        this.logger.warn(
          this.className,
          'Identifier account verify failed max number of attempts; forcing social option.'
        );
        this._handleTypeSelection(IdentifierType.SOCIAL);
      }

      this.isVerified = true;
    }

    this.spinnerService.hide();
  }

  private _initializeForm() {
    this.additionalIdentifierForm = this._formBuilder.group(
      {
        identifierType: IdentifierType.SOCIAL,
        [IdentifierType.SOCIAL]: [''],
        [IdentifierType.ACCOUNT]: ['']
      },
      {
        validators: validateAdditionalIdentifier,
        updateOn: 'submit'
      }
    );

    this.additionalIdentifierForm.statusChanges
      .pipe(
        untilDestroyed(this),
        filter(() => this.additionalIdentifierForm.valid)
      )
      .subscribe(() => {
        if (
          this.additionalIdentifierForm.get(IdentifierType.SOCIAL).value ||
          this.additionalIdentifierForm.get(IdentifierType.ACCOUNT).value
        ) {
          this._verifyAccount();
        }
      });
  }

  get identifierType(): IdentifierType {
    return this.additionalIdentifierForm.get('identifierType').value;
  }

  get hasMaxSocialVerifyAttempts(): boolean {
    return (
      this.verifyAttempts[IdentifierType.SOCIAL] >=
      ENVIRONMENT.features.verify.maxAttempts.additionalIdentifier[IdentifierType.SOCIAL]
    );
  }

  get hasMaxAccountVerifyAttempts(): boolean {
    return (
      this.verifyAttempts[IdentifierType.ACCOUNT] >=
      ENVIRONMENT.features.verify.maxAttempts.additionalIdentifier[IdentifierType.ACCOUNT]
    );
  }

  get hasMaxTotalVerifyAttempts(): boolean {
    return this.hasMaxSocialVerifyAttempts && this.hasMaxAccountVerifyAttempts;
  }

  protected get formGroup(): UntypedFormGroup {
    return this.additionalIdentifierForm;
  }

  protected get controls(): Controls {
    return this.additionalIdentifierControls;
  }

  protected get controlErrors(): ControlErrors {
    return this.additionalIdentifierControlErrors;
  }
}
