import { Component } from '@angular/core';
import { AdaBaseComponent } from '@shared/base-component/ada-base.component';
import { ForgotCredentials, SessionKey } from '@shared/storage/storage.model';

@Component({ template: '' })
export class ForgotCredentialsBaseComponent extends AdaBaseComponent {
  protected className = 'ForgotCredentialsBaseComponent';

  get fromRegistration(): boolean {
    return !!(this.sessionService.get(SessionKey.FORGOT_CREDENTIALS) as ForgotCredentials)?.fromRegistration;
  }
}
