import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { userAgent } from '@shared/user-agent/user-agent.util';
import { DynamicChecklistItem } from './dynamic-checklist-item.model';

@Component({
  selector: 'ciam-dynamic-checklist',
  templateUrl: './dynamic-checklist.component.html',
  styleUrls: ['./dynamic-checklist.component.scss']
})
export class DynamicChecklistComponent implements OnChanges {
  @Input() dynamicChecklist: Array<DynamicChecklistItem>;
  @Input() currentInput: string;

  userAgent = userAgent;

  ngOnChanges(changes: SimpleChanges) {
    if (Object.keys(changes).includes('currentInput')) {
      this.validateInput();
    }
  }

  validateInput() {
    this.dynamicChecklist.forEach(item => (item.isValid = item.pattern.test(this.currentInput)));
  }
}
