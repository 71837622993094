import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { AppInjector } from '@app/app-injector';
import { AuthMethod, DestinationType } from '@app/authorization/shared/authorization.model';
import { ERROR_PATHS } from '@app/error/error-paths';
import { CommonBaseComponent } from '@common/shared/common-base.component';
import {
  PostSearchPotentialMatchResponse,
  PostSearchSingleMatchResponse
} from '@nationwide/api-client-internet-registration-v3';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ContentHeaderService } from '@shared/content-header/content-header.service';
import { CommonFlow } from '@shared/navigation/flow.enum';
import { NavigationService } from '@shared/navigation/navigation.service';
import { SessionKey } from '@shared/storage/storage.model';
import { SEARCH_ADDL_INFO_PATHS, SEARCH_PATHS } from '../search-paths';
import {
  AdditionalInformationType,
  IdentifierType,
  SearchCriteria,
  SearchFlowType,
  SearchResult,
  SearchType
} from './search.model';
import { SearchService } from './search.service';

@UntilDestroy()
@Component({ template: '' })
export abstract class SearchBaseComponent extends CommonBaseComponent {
  protected contentHeaderService: ContentHeaderService;
  protected navigationService: NavigationService;
  protected searchService: SearchService;

  protected className = 'SearchBaseComponent';

  private readonly _spinnerMessage = 'Finding your Nationwide account...';
  private _searchFlowType: SearchFlowType;
  constructor() {
    super();

    this.contentHeaderService = AppInjector.injector.get(ContentHeaderService);
    this.navigationService = AppInjector.injector.get(NavigationService);
    this.searchService = AppInjector.injector.get(SearchService);
  }

  backToAccountType() {
    this.navigationService.navigate(`${this.flow}/${CommonFlow.SEARCH}/${SEARCH_PATHS.accountType}`, {
      queryParams: {
        systemId: this.sessionService.get(SessionKey.PARTNER)?.id,
        lifecycleStatus: this.searchService.model.lifecycleStatus
      }
    });
  }

  backToIndividualInformation() {
    this.navigationService.navigate(`${this.baseRoute}/${SEARCH_PATHS.individualInformation}`);
  }

  _performSearch(
    searchCriteria: SearchCriteria,
    additionalInfoType?: AdditionalInformationType,
    identifierType?: IdentifierType
  ) {
    this.spinnerService.show(this._spinnerMessage);
    this._setupSearchSession(searchCriteria);

    this._logSearchOperation(null, additionalInfoType, identifierType);

    this.searchService
      .searchForCustomer(searchCriteria)
      .pipe(untilDestroyed(this))
      .subscribe(
        response => {
          if (response.status === 200) {
            const singleMatchResponse = response as HttpResponse<PostSearchSingleMatchResponse>;
            this.sessionService.setModifiedSessionObject(
              singleMatchResponse.body.partyType,
              SessionKey.CUSTOMER,
              'partyType'
            );
            this._logSearchOperation(SearchResult.SINGLE_MATCH, additionalInfoType);
            this._handleSingleMatch(singleMatchResponse);
          } else if (response.status === 207) {
            this._handlePotentialMatch(response as HttpResponse<PostSearchPotentialMatchResponse>);
          } else {
            this.navigationService.navigateToError(
              this.className,
              null,
              ERROR_PATHS.systemError,
              `Unexpected HTTP ${response.status} response returned from search.`
            );
          }
        },
        error => {
          if (this._isNoMatch(error)) {
            this._handleNoMatch();
            this._logSearchOperation(SearchResult.NO_MATCH, this.searchService.model.additionalInformationType);
          } else {
            this._logSearchOperation(SearchResult.ERROR);
            this.navigationService.navigateToError(
              this.className,
              error,
              ERROR_PATHS.systemError,
              'SearchService searchForCustomer failed.'
            );
          }
        }
      );
  }

  _isNoMatch(error: any): boolean {
    return (
      error instanceof HttpErrorResponse &&
      (error.status === 404 || (error.status === 422 && error.error.code === 4216))
    );
  }

  _potentialMatchResultType(responseCode: number): SearchResult {
    let searchResult;
    switch (responseCode) {
      case 2071:
        searchResult = SearchResult.MULTI_MATCH;
        break;
      case 2073:
        searchResult = SearchResult.POTENTIAL_MATCH;
        break;
      default:
        searchResult = SearchResult.UNKNOWN_MATCH_TYPE;
        break;
    }
    return searchResult;
  }

  _logSearchOperation(
    result: SearchResult,
    additionalInfoType?: AdditionalInformationType,
    identifierType?: IdentifierType
  ) {
    const searchResult: any = {
      result
    };

    if (additionalInfoType) {
      searchResult.additionalInfo = additionalInfoType;
    }
    if (identifierType) {
      searchResult.identifier = identifierType;
    }

    this.logger.info(this.className, `Search ${result ? 'completed' : 'started'}.`, searchResult);
  }

  _handleSingleMatch(response: HttpResponse<PostSearchSingleMatchResponse>) {
    let destination: DestinationType;
    if (this.isRegistrationFlow) {
      if (this.searchFlowType === SearchFlowType.CONTROLLER) {
        destination = DestinationType.REG_CONTROLLER_ELIGIBILITY;
      } else {
        destination = DestinationType.REG_STANDARD_ELIGIBILITY;
      }
    } else {
      destination = DestinationType.FUP_STANDARD_ELIGIBILITY;
    }

    this.navigationService.navigateToAuthorization(
      AuthMethod.REGISTRATION_CUSTOMER_SEARCH,
      {
        assertion: response.body.idToken
      },
      destination,
      this.contentHeaderService.model
    );
  }

  _navigateToAdditionalInfo(additionalInformationType: AdditionalInformationType) {
    this.navigationService.navigate(
      `${this.parentFlowBaseRoute}/${CommonFlow.SEARCH}/${this.searchFlowType}/${SEARCH_PATHS.additionalInformation}/${
        SEARCH_ADDL_INFO_PATHS[additionalInformationType as keyof typeof SEARCH_ADDL_INFO_PATHS]
      }`
    );
  }

  private _setupSearchSession(searchCriteria: SearchCriteria) {
    if (searchCriteria.input.firstName) {
      this.sessionService.setModifiedSessionObject(searchCriteria.input.firstName, SessionKey.SEARCH, 'firstName');
    }
    if (searchCriteria.input.lastName) {
      this.sessionService.setModifiedSessionObject(searchCriteria.input.lastName, SessionKey.SEARCH, 'lastName');
    }
    if (searchCriteria.input.agreementNumber) {
      this.sessionService.setModifiedSessionObject(
        searchCriteria.input.agreementNumber,
        SessionKey.SEARCH,
        'agreementNumber'
      );
    }
  }

  abstract _handlePotentialMatch(response: HttpResponse<PostSearchPotentialMatchResponse>): void;

  abstract _handleNoMatch(): void;

  abstract get searchType(): SearchType;

  get searchFlowType(): SearchFlowType {
    if (!this._searchFlowType && this.routeSegments) {
      const commonFlowIndex = this.routeSegments.findIndex(segment => segment.path === this.commonFlow);
      if (commonFlowIndex >= 0) {
        const searchFlowTypeIndex = commonFlowIndex + 1;
        if (searchFlowTypeIndex < this.routeSegments.length) {
          this._searchFlowType = this.routeSegments[searchFlowTypeIndex]?.path as SearchFlowType;
        }
      }
    }

    return this._searchFlowType;
  }

  get baseRoute(): string {
    let route = `${this.parentFlowBaseRoute}/${this.commonFlow}`;
    if (this.searchFlowType) {
      route += `/${this.searchFlowType}`;
    }

    return route;
  }

  get commonFlow(): CommonFlow {
    return CommonFlow.SEARCH;
  }
}
