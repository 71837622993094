import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@environments/environment';
import {
  PatchInternetRegistrationPartialSuccessResponse,
  PatchInternetRegistrationRequest,
  PatchInternetRegistrationResponse,
  RegistrationManagementService
} from '@nationwide/api-client-internet-registration-v3';
import {} from '@shared/navigation/flow.enum';
import { CustomCookieService } from '@shared/storage/custom-cookie.service';
import { CustomSessionService, CUSTOM_SESSION } from '@shared/storage/custom-session.service';
import { Customer, Partner, SessionKey } from '@shared/storage/storage.model';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { UpdateUsernamePasswordModel } from './update-username-password.model';

@Injectable()
export class UpdateUsernamePasswordService {
  constructor(
    @Inject(CUSTOM_SESSION) private _sessionService: CustomSessionService,
    private _customCookieService: CustomCookieService,
    private _oauthService: OAuthService,
    private _registrationManagementService: RegistrationManagementService
  ) {
    this.reset();
  }

  reset() {
    this._registrationManagementService.configuration.accessToken = this._oauthService.getAccessToken();
  }

  updateUsernamePassword(
    updateUsernamePasswordModel: UpdateUsernamePasswordModel
  ): Observable<HttpResponse<PatchInternetRegistrationResponse | PatchInternetRegistrationPartialSuccessResponse>> {
    return this._registrationManagementService.internetRegistrationsGuidPatch(
      this.buildUpdateRequest(updateUsernamePasswordModel),
      ENVIRONMENT.apiConfig.clientId,
      this._customCookieService.flowId,
      (this._sessionService.get(SessionKey.CUSTOMER) as Customer)?.guid,
      (this._sessionService.get(SessionKey.PARTNER) as Partner)?.id,
      false,
      'response'
    );
  }

  private buildUpdateRequest(
    updateUsernamePasswordModel: UpdateUsernamePasswordModel
  ): PatchInternetRegistrationRequest {
    const request: PatchInternetRegistrationRequest = {
      acceptedEsa: updateUsernamePasswordModel.acceptedEsa,
      registration: updateUsernamePasswordModel.registration
    };

    if (updateUsernamePasswordModel.userName) {
      request.userName = updateUsernamePasswordModel.userName;
    }

    if (updateUsernamePasswordModel.password) {
      request.password = updateUsernamePasswordModel.password;
    }

    if (updateUsernamePasswordModel.emailAddress) {
      request.email = updateUsernamePasswordModel.emailAddress;
    }

    if (updateUsernamePasswordModel.phoneNumber) {
      request.phoneNumber = updateUsernamePasswordModel.phoneNumber;
    }

    return request;
  }
}
