import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { REGISTRATION_PATHS } from '@app/registration/registration-paths';
import { AdaBaseComponent } from '@shared/base-component/ada-base.component';
import { ContentHeaderService } from '@shared/content-header/content-header.service';
import { CommonFlow, Flow } from '@shared/navigation/flow.enum';
import { NavigationService } from '@shared/navigation/navigation.service';
import { PersonalBillingService } from '@shared/personal-billing/personal-billing.service';
@Component({
  selector: 'ciam-set-preferences',
  templateUrl: './set-preferences.component.html',
  styleUrls: ['./set-preferences.component.scss']
})
export class SetPreferencesComponent extends AdaBaseComponent implements OnInit, AfterViewInit {
  protected readonly className = 'SetPreferencesComponent';
  billingAccountNum: string;
  isEligibleForRefundEnrollment = false;

  constructor(
    private _contentHeaderService: ContentHeaderService,
    private _navigationService: NavigationService,
    private _billingService: PersonalBillingService,
    private _elementRef: ElementRef
  ) {
    super();
    window.addEventListener('message', (event: MessageEvent) => this.handleMessageEvent(event), false);
  }

  ngOnInit(): void {
    this._contentHeaderService.updateProgress({
      title: 'Sign up for online access',
      progressTitle: 'Set preferences',
      progressPercent: 90
    });
    // commenting the line below to pause our experiment for further analysis
    // this.isEligibleForRefundEnrollmentPage();
  }

  ngAfterViewInit() {
    this._elementRef.nativeElement
      .querySelector('preference-center')
      ?.addEventListener('outputEvent', this.updateProgressBar);
  }

  handleMessageEvent(event: MessageEvent) {
    if (event?.data?.name === 'paperlessPreferenceOptionSubmit') {
      if (event.data?.success === true) {
        this.logger.info(this.className, 'User has submitted paperless preference election', {
          existingPaperlessPreference: event.data?.existingPaperlessPreference,
          newPaperlessPreference: event.data?.newPaperlessPreference
        });
        this.navigateToNextPage();
      } else {
        this.logger.error(this.className, "An error has occurred setting the user's preferences");
        this.spinnerService.hide();
        document.getElementById('preferenceCenter').setAttribute('style', 'display : none');
        document.getElementById('set-preferences-error').setAttribute('style', 'display : block');
      }
    } else if (event?.data === 'radioOptionsContinueSuccess') {
      this.navigateToNextPage();
    }
  }

  updateProgressBar(evt: any) {
    const eventName = evt['detail'].eventName;
    if (eventName === 'preferenceCenterConfirmation') {
      const progressBarWidth = document
        .querySelector('bolt-progress-bar')
        ?.shadowRoot?.querySelector('.bolt-progress-bar-wc--fill') as HTMLElement;
      progressBarWidth.style.width = '90%';
    }
  }

  continue() {
    this.spinnerService.show();
    if (document.getElementById('set-preferences-error').style.display === 'block') {
      this.logger.info(this.className, 'Before the register Success redirection');
      this.navigateToNextPage();
    } else {
      window.postMessage('radioOptionsContinueClick', window.location.origin);
    }
  }

  get commonFlow(): CommonFlow {
    return CommonFlow.PREFERENCES;
  }

  isEligibleForRefundEnrollmentPage(): void {
    if (this.hasSingleBillingAccount() && this.hasPersonalProductsOnly()) {
      this.isActiveNonEasyPayBillingAccount();
    }
  }

  hasPersonalProductsOnly(): boolean {
    const token = JSON.parse(sessionStorage.getItem('id_token_claims_obj'));
    const agreements = token.agreements;
    const allowedPersonalProducts = ['Auto', 'Billing', 'Homeowners', 'Personal Umbrella'];
    return (
      agreements?.length > 1 &&
      agreements.every((agreement: any) => allowedPersonalProducts.includes(agreement.product_type))
    );
  }

  hasSingleBillingAccount(): boolean {
    const token = JSON.parse(sessionStorage.getItem('id_token_claims_obj'));
    let billingAccountTotal = 0;
    if (token?.agreements) {
      for (const agreement of token.agreements) {
        if (agreement?.product_type === 'Billing') {
          billingAccountTotal++;
          this.billingAccountNum = agreement.agreement_number;
        }
      }
    }
    if (billingAccountTotal === 1) {
      return true;
    } else {
      return false;
    }
  }

  isActiveNonEasyPayBillingAccount() {
    const url = this._billingService.setBillingAccountInfoUrl(this.billingAccountNum);
    const token = sessionStorage.getItem('access_token');
    this._billingService.getBillingMethod(url, token).subscribe(
      response => {
        this.logger.info(this.className, 'Get billing account info API call success');
        if (response.indicators.hasRecurring === false && response.status === 'Active') {
          this.isEligibleForRefundEnrollment = true;
        }
      },
      error => {
        this.logger.error('ERROR LOG IN COMPONENT', error, error.status);
        this.isEligibleForRefundEnrollment = false;
      }
    );
  }

  navigateToNextPage(): void {
    if (this.isEligibleForRefundEnrollment) {
      this._navigationService.navigate(CommonFlow.DIGITAL_REFUNDS);
    } else {
      this._navigationService.navigate(`${Flow.REGISTRATION}/${REGISTRATION_PATHS.registerSuccess}`);
    }
  }
}
