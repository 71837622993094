import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { bankAccountVerificationConfig } from '@config/bank-account-verification.config';
import { customerOtpConfig } from '@config/customer-otp.config';
import { eimAngularLoggingServiceConfig } from '@config/eim-angular-logging.config';
import { internetRegistrationConfig } from '@config/internet-registration.config';
import { mfaUserauthExternalLoginConfig } from '@config/mfa-userauth-external-login.config';
import { ApiModule as BankAccountVerificationModule } from '@nationwide/api-client-bank-account-verification-v1';
import { ApiModule as CustomerOtpModule } from '@nationwide/api-client-customer-otp-v2';
import { ApiModule as InternetRegistrationModule } from '@nationwide/api-client-internet-registration-v3';
import { ApiModule as MfaUserauthExternalLoginModule } from '@nationwide/api-client-mfa-userauth-external-login-v1';
import { EimAngularLoggingModule } from '@nw/eim-angular-logging';
import { SharedModule } from '@shared/shared.module';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AppInjector } from './app-injector';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthorizationModule } from './authorization/authorization.module';
import { EligibilityModule } from './common/eligibility/eligibility.module';
import { MfaModule } from './common/mfa/mfa.module';
import { SearchModule } from './common/search/search.module';
import { DigitalRefundsModule } from './digital-refunds/digital-refunds.module';
import { ErrorModule } from './error/error.module';
import { ForgotCredentialsModule } from './forgot-credentials/forgot-credentials.module';
import { NonInstantActivationModule } from './non-instant-activation/non-instant-activation.module';
import { OneTimeLinkModule } from './one-time-link/one-time-link.module';
import { PreferencesModule } from './preferences/preferences.module';
import { ProxyModule } from './proxy/proxy.module';
import { RegistrationModule } from './registration/registration.module';
import { VersionModule } from './version/version.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    OAuthModule.forRoot(),
    MfaUserauthExternalLoginModule.forRoot(mfaUserauthExternalLoginConfig),
    CustomerOtpModule.forRoot(customerOtpConfig),
    EimAngularLoggingModule.forRoot(eimAngularLoggingServiceConfig),
    InternetRegistrationModule.forRoot(internetRegistrationConfig),
    BankAccountVerificationModule.forRoot(bankAccountVerificationConfig),
    SharedModule,
    AuthorizationModule,
    SearchModule,
    EligibilityModule,
    MfaModule,
    RegistrationModule,
    ProxyModule,
    ForgotCredentialsModule,
    NonInstantActivationModule,
    PreferencesModule,
    DigitalRefundsModule,
    ErrorModule,
    VersionModule,
    OneTimeLinkModule,
    AppRoutingModule
  ],
  bootstrap: [AppComponent],
  providers: [{ provide: LocationStrategy, useClass: PathLocationStrategy }]
})
export class AppModule {
  constructor(private _injector: Injector) {
    AppInjector.injector = _injector;
  }
}
