import { Component } from '@angular/core';
import { AdaBaseComponent } from '@shared/base-component/ada-base.component';
import { Registration, SessionKey } from '@shared/storage/storage.model';
import { RegistrationFlowType } from './registration.model';

@Component({ template: '' })
export class RegistrationBaseComponent extends AdaBaseComponent {
  protected className = 'RegistrationBaseComponent';

  private _registrationFlowType: RegistrationFlowType;

  get fromForgotCredentials(): boolean {
    return !!(this.sessionService.get(SessionKey.REGISTRATION) as Registration)?.fromForgotCredentials;
  }

  get registrationFlowType(): RegistrationFlowType {
    if (!this._registrationFlowType && this.routeSegments && this.routeSegments.length > 1) {
      this._registrationFlowType = this.routeSegments[1]?.path as RegistrationFlowType;
    }

    return this._registrationFlowType;
  }

  get baseRoute(): string {
    let route: string = this.flow;
    if (this.registrationFlowType) {
      route = `${route}/${this.registrationFlowType}`;
    }

    return route;
  }
}
