import { Component } from '@angular/core';
import { AppInjector } from '@app/app-injector';
import { REGISTRATION_PATHS } from '@app/registration/registration-paths';
import { RegistrationFlowType } from '@app/registration/shared/registration.model';
import { CommonBaseComponent } from '@common/shared/common-base.component';
import { CommonFlow } from '@shared/navigation/flow.enum';
import { NavigationService } from '@shared/navigation/navigation.service';
import { MFA_PATHS } from '../mfa-route-paths';

@Component({ template: '' })
export abstract class MfaBaseComponent extends CommonBaseComponent {
  protected navigationService: NavigationService;

  protected className = 'MfaBaseComponent';

  constructor() {
    super();

    this.navigationService = AppInjector.injector.get(NavigationService);
  }

  sendNewCode() {
    this.navigationService.navigate(`${this.baseRoute}/${MFA_PATHS.sendCode}`);
  }

  continueToRegistration() {
    if (this.parentFlowType === RegistrationFlowType.PERSONAL) {
      this.navigationService.navigate(`${this.parentFlowBaseRoute}/${REGISTRATION_PATHS.createLogin}`);
    } else {
      this.navigationService.navigate(`${this.parentFlowBaseRoute}/${REGISTRATION_PATHS.controllerAttestation}`);
    }
  }

  get commonFlow(): CommonFlow {
    return CommonFlow.MFA;
  }
}
