import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ENVIRONMENT } from '@environments/environment';
import { loadFullStory } from '@shared/full-story/full-story-util';
import { loadNewRelic } from '@shared/new-relic/new-relic-util';
import { loadNora } from '@shared/nora/nora-util';
import { loadRsa } from '@shared/rsa/rsa-util';
import { AppModule } from './app/app.module';

if (ENVIRONMENT.production) {
  enableProdMode();
}

loadNewRelic(ENVIRONMENT.newRelic);
loadFullStory(ENVIRONMENT.fullStory);
loadRsa(ENVIRONMENT.rsa);
loadNora(ENVIRONMENT.nora);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
