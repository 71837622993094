import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { BoltAlertErrorModule, BoltModalModule, BoltProgressBarModule } from '@nw/bolt-angular';
import { DynamicChecklistComponent } from '@shared/dynamic-checklist/dynamic-checklist.component';
import { CookieService } from 'ngx-cookie-service';
import { DisablePasteDirective } from './attr-directive/disable-paste.directive';
import { BusinessNamesComponent } from './business-names/business-names.component';
import { CancelComponent } from './cancel/cancel.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ContentHeaderComponent } from './content-header/content-header.component';
import { DelayedFocusoutDirective } from './delayed-focusout/delayed-focusout.directive';
import { ErrorContainerComponent } from './error-container/error-container.component';
import { EsaTextComponent } from './esa-text/esa-text.component';
import { ExpressLinksComponent } from './express-links/express-links.component';
import { FooterComponent } from './footer/footer.component';
import { GlobalErrorHandler } from './global-error-handler/global-error-handler';
import { HeaderComponent } from './header/header.component';
import { HelpComponent } from './help/help.component';
import { ClickLoggerDirective } from './logging/click-logger.directive';
import { LoggingInterceptor } from './logging/logging.interceptor';
import { PhoneNumberComponent } from './phone-number/phone-number.component';
import { SpinnerComponent } from './spinner/spinner.component';

@NgModule({
  declarations: [
    ClickLoggerDirective,
    DelayedFocusoutDirective,
    DisablePasteDirective,
    ContentHeaderComponent,
    SpinnerComponent,
    CancelComponent,
    HeaderComponent,
    FooterComponent,
    ContactUsComponent,
    ExpressLinksComponent,
    HelpComponent,
    PhoneNumberComponent,
    DynamicChecklistComponent,
    BusinessNamesComponent,
    ErrorContainerComponent,
    EsaTextComponent
  ],
  imports: [
    NgbCollapseModule,
    CommonModule,
    BrowserModule,
    BoltModalModule,
    BoltProgressBarModule,
    BoltAlertErrorModule
  ],
  exports: [
    NgbCollapseModule,
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    BoltModalModule,
    ClickLoggerDirective,
    DelayedFocusoutDirective,
    DisablePasteDirective,
    ContentHeaderComponent,
    SpinnerComponent,
    CancelComponent,
    HeaderComponent,
    FooterComponent,
    ContactUsComponent,
    ExpressLinksComponent,
    HelpComponent,
    PhoneNumberComponent,
    DynamicChecklistComponent,
    BusinessNamesComponent,
    ErrorContainerComponent,
    EsaTextComponent
  ],
  providers: [
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ]
})
export class SharedModule {}
