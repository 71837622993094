import { ControlErrors, Controls } from '@shared/base-component/base.model';
import { CONTROL_ERROR_MESSAGES, CONTROL_PATTERNS } from '@shared/validation/control-constants';

export const CONTROLS: Controls = {
  username: {
    id: 'update-username-password-username',
    patterns: CONTROL_PATTERNS.updateUsername,
    ariaDescribedByErrors: 'update-username-password-username-errors',
    ariaDescribedBy:
      'username-help-message username-validations-header username-length-aria-label username-no-spaces-label username-letter-label username-excluded-characters-aria-label'
  },
  password: {
    id: 'update-username-password-password',
    patterns: CONTROL_PATTERNS.updatePassword,
    ariaDescribedByErrors: 'update-username-password-password-errors',
    ariaDescribedBy:
      'password-validations-header password-length-aria-label password-no-spaces-label password-letter-label password-number-or-special-aria-label password-excluded-characters-aria-label'
  },
  passwordConfirmation: {
    id: 'update-username-password-password-confirmation',
    ariaDescribedByErrors: 'update-username-password-password-confirmation-errors'
  },
  phoneNumber: {
    id: 'update-username-password-phone-number',
    patterns: CONTROL_PATTERNS.phoneNumber,
    ariaDescribedByErrors: 'update-username-password-phone-number-errors',
    ariaDescribedBy: 'mobile-number-help-message'
  },
  emailAddress: {
    id: 'update-username-password-email-address',
    patterns: CONTROL_PATTERNS.emailAddress,
    ariaDescribedByErrors: 'update-username-password-email-address-errors'
  },
  acceptedEsa: {
    id: 'update-username-password-esa-checkbox',
    ariaDescribedByErrors: 'update-username-password-esa-checkbox-errors'
  }
};

export const CONTROL_ERRORS: ControlErrors = {
  username: {
    required: CONTROL_ERROR_MESSAGES.usernameRequired,
    pattern: CONTROL_ERROR_MESSAGES.usernamePattern,
    usernameUnavailable: CONTROL_ERROR_MESSAGES.usernameUnavailable
  },
  password: {
    required: CONTROL_ERROR_MESSAGES.passwordRequired,
    pattern: CONTROL_ERROR_MESSAGES.passwordPattern,
    usernamePasswordMatch: CONTROL_ERROR_MESSAGES.usernamePasswordMatch
  },
  passwordConfirmation: {
    required: CONTROL_ERROR_MESSAGES.passwordConfirmationRequired,
    passwordMismatch: CONTROL_ERROR_MESSAGES.passwordConfirmationPasswordMismatch
  },
  phoneNumber: {
    pattern: CONTROL_ERROR_MESSAGES.phoneNumberPattern
  },
  emailAddress: {
    required: CONTROL_ERROR_MESSAGES.emailAddressRequired,
    pattern: CONTROL_ERROR_MESSAGES.emailAddressPattern
  },
  acceptedEsa: {
    required: CONTROL_ERROR_MESSAGES.acceptedEsaRequired
  }
};

export interface UpdateUsernamePasswordModel {
  userName?: string;
  password?: string;
  emailAddress?: string;
  phoneNumber?: string;
  acceptedEsa?: boolean;
  registration?: boolean;
}
