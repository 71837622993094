import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { AppInjector } from '@app/app-injector';
import { ENVIRONMENT } from '@environments/environment';
import { PostSearchPotentialMatchResponse } from '@nationwide/api-client-internet-registration-v3';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ControlErrors, Controls } from '@shared/base-component/base.model';
import { ContentHeaderModel } from '@shared/content-header/content-header.model';
import { ContentHeaderService } from '@shared/content-header/content-header.service';
import { Registration, SessionKey } from '@shared/storage/storage.model';
import { filter } from 'rxjs/operators';
import { SearchBaseComponent } from '../shared/search-base.component';
import { AdditionalInformationType } from '../shared/search.model';
import { AlertMessage, AlertType, CONTROLS, CONTROL_ERRORS } from './individual-information.model';

@UntilDestroy()
@Component({ template: '' })
export abstract class IndividualInformationBaseComponent extends SearchBaseComponent implements OnInit {
  @ViewChild('individualInfoFormParent', { static: true }) individualInfoFormParent: NgForm;
  individualInformationForm: UntypedFormGroup;
  individualInformationControls: Controls = CONTROLS;
  individualInformationControlErrors: ControlErrors = CONTROL_ERRORS;

  notFoundSearchAttempts = 0;

  alertType = AlertType;

  protected contentHeaderService: ContentHeaderService;
  protected formBuilder: UntypedFormBuilder;
  protected className = 'IndividualInformationBaseComponent';

  constructor() {
    super();

    this.formBuilder = AppInjector.injector.get(UntypedFormBuilder);
  }

  ngOnInit() {
    this.logger.info(this.className, 'Entering individual information.');

    this.contentHeaderService.updateProgress(this.contentHeaderModel);
    this.searchService.reset(true, true);

    this._initializeForm();
  }

  get alertMessage(): AlertMessage {
    if (this.individualInfoFormParent.submitted && this.individualInfoFormParent.invalid) {
      return this.invalidAlert;
    } else if (this.notFoundSearchAttempts > 0) {
      return this.notFoundAlert;
    }

    return null;
  }

  protected get invalidAlert(): AlertMessage {
    return { type: AlertType.ERROR, message: 'Please correct the following to continue.' };
  }

  protected get notFoundAlert(): AlertMessage {
    return {
      type: AlertType.ERROR,
      message:
        'We cannot locate an account associated with this information. Please check your information and try again.'
    };
  }

  _findAccount() {
    this._performSearch({
      input: {
        firstName: this.individualInformationForm.get('firstName').value,
        lastName: this.individualInformationForm.get('lastName').value,
        zipCode: this.individualInformationForm.get('zipCode').value
      }
    });
  }

  _handleNoMatch() {
    this.notFoundSearchAttempts++;

    if (this.notFoundSearchAttempts < ENVIRONMENT.features.search.maxAttempts.individualInfo) {
      this.spinnerService.hide();
    } else {
      this.searchService.model.previousSearch = this.searchType;
      this.navigationService.navigate(this.customerNotMatchedRoute);
    }
  }

  _handlePotentialMatch(response: HttpResponse<PostSearchPotentialMatchResponse>) {
    let additionalInformationType: AdditionalInformationType = null;
    if (response.body.uniqueSearches) {
      const uniqueSearchAttribute = response.body.uniqueSearches[0].find(
        uniqueSearchAttr =>
          ![
            PostSearchPotentialMatchResponse.UniqueSearchesEnum.FirstName,
            PostSearchPotentialMatchResponse.UniqueSearchesEnum.LastName,
            PostSearchPotentialMatchResponse.UniqueSearchesEnum.ZipCode,
            PostSearchPotentialMatchResponse.UniqueSearchesEnum.AgreementNumber,
            PostSearchPotentialMatchResponse.UniqueSearchesEnum.TaxIdentifier
          ].includes(uniqueSearchAttr)
      );
      if (uniqueSearchAttribute) {
        for (const type in AdditionalInformationType) {
          if (
            AdditionalInformationType[type as keyof typeof AdditionalInformationType] ===
            uniqueSearchAttribute.valueOf()
          ) {
            additionalInformationType = AdditionalInformationType[type as keyof typeof AdditionalInformationType];
          }
        }
      }
    }

    this.searchService.model.previousSearch = this.searchType;
    this._logSearchOperation(this._potentialMatchResultType(response.body.message.code), additionalInformationType);

    if (additionalInformationType) {
      this.searchService.model.additionalInformationType = additionalInformationType;
      this._navigateToAdditionalInfo(additionalInformationType);
    } else {
      this.navigationService.navigate(this.customerNotMatchedRoute);
    }
  }

  protected _initializeForm() {
    this.individualInformationForm = this.formBuilder.group(
      {
        firstName: [
          '',
          [Validators.required, ...this._buildPatternValidators(this.individualInformationControls.firstName.patterns)]
        ],
        middleInitial: [''],
        lastName: [
          '',
          [Validators.required, ...this._buildPatternValidators(this.individualInformationControls.lastName.patterns)]
        ],
        suffix: [''],
        zipCode: [
          '',
          [Validators.required, ...this._buildPatternValidators(this.individualInformationControls.zipCode.patterns)]
        ]
      },
      {
        updateOn: 'submit'
      }
    );

    this.individualInformationForm.statusChanges
      .pipe(
        untilDestroyed(this),
        filter(() => this.individualInformationForm.valid)
      )
      .subscribe(() => {
        this._findAccount();
      });
  }

  abstract back(): void;

  abstract get contentHeaderModel(): ContentHeaderModel;

  abstract get individualInfoMessageText(): string;

  abstract get yourInfoTitleText(): string;

  abstract get yourInfoMessageText(): string;

  abstract get findAccountButtonText(): string;

  abstract get isControllerSearch(): boolean;

  abstract get customerNotMatchedRoute(): string;

  get businessNames(): string[] {
    return (this.sessionService.get(SessionKey.REGISTRATION) as Registration)?.businessNames;
  }

  protected get formGroup(): UntypedFormGroup {
    return this.individualInformationForm;
  }

  protected get controls(): Controls {
    return this.individualInformationControls;
  }

  protected get controlErrors(): ControlErrors {
    return this.individualInformationControlErrors;
  }
}
