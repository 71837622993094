/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@environments/environment';
import { CUSTOM_SESSION, CustomSessionService } from '@shared/storage/custom-session.service';
import { Customer, SessionKey } from '@shared/storage/storage.model';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientCredentialsTokenService {
  clientCredentialsTokenUri: string;

  constructor(
    private _httpClient: HttpClient,
    private _oauthService: OAuthService,
    @Inject(CUSTOM_SESSION) private _sessionService: CustomSessionService
  ) {
    this.clientCredentialsTokenUri = `${ENVIRONMENT?.apiConfig?.services?.clientCredentialsModisToken?.basePath}${ENVIRONMENT?.apiConfig?.services?.clientCredentialsModisToken?.path}`;
  }

  getRefId(): Observable<any> {
    const customer = this._sessionService.get(SessionKey.CUSTOMER) as Customer;
    this.clientCredentialsTokenUri = this.clientCredentialsTokenUri.replace(':ecn', customer?.ecn);
    return this._httpClient.get<any>(this.clientCredentialsTokenUri, {
      headers: this.getHttpHeaders(this._oauthService.getAccessToken())
    });
  }

  getHttpHeaders(token: string): HttpHeaders {
    let headers = new HttpHeaders({
      client_id: ENVIRONMENT?.apiConfig?.clientId,
      'X-NW-Transaction-ID': '' + Date.now(),
      'X-NWD-ConsumerID': ENVIRONMENT?.apiConfig?.consumerId
    });

    if (token) {
      headers = headers.append('Authorization', 'Bearer ' + token);
      headers = headers.append('X-NW-Access-Token', token);
      headers = headers.append('X-NW-Message-ID', '' + Date.now());
    }
    headers = headers.append('directTransFunction', 'PART_LANDING_PAGE_R');
    return headers;
  }
}
