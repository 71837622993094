import { NgModule } from '@angular/core';
import {
  BoltAlertConfirmationModule,
  BoltAlertErrorModule,
  BoltButtonPrimaryModule,
  BoltButtonStandardModule,
  BoltCheckBoxModule,
  BoltFieldErrorModule,
  BoltRadioButtonModule,
  BoltRadioGroupModule,
  BoltTextFieldModule
} from '@nw/bolt-angular';
import { SharedModule } from '@shared/shared.module';
import { BundleLoaderComponent } from './preference-center/bundle-loader/bundle-loader.component';
import { PreferenceCenterWidgetComponent } from './preference-center/preference-center-widget/preference-center-widget.component';
import { PreferencesRoutingModule } from './preferences-routing.module';
import { SetPreferencesComponent } from './set-preferences/set-preferences.component';

@NgModule({
  declarations: [SetPreferencesComponent, BundleLoaderComponent, PreferenceCenterWidgetComponent],
  imports: [
    SharedModule,
    PreferencesRoutingModule,
    BoltAlertConfirmationModule,
    BoltAlertErrorModule,
    BoltButtonPrimaryModule,
    BoltButtonStandardModule,
    BoltCheckBoxModule,
    BoltFieldErrorModule,
    BoltRadioButtonModule,
    BoltRadioGroupModule,
    BoltTextFieldModule
  ]
})
export class PreferencesModule {}
