import { Component } from '@angular/core';
import { AdaBaseComponent } from '@shared/base-component/ada-base.component';

@Component({
  selector: 'ciam-online-access-error',
  templateUrl: './online-access-error.component.html',
  styleUrls: ['./online-access-error.component.scss']
})
export class OnlineAccessErrorComponent extends AdaBaseComponent {
  protected readonly className = 'OnlineAccessErrorComponent';
}
