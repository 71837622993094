<div class="d-flex flex-column">
  <form
    id="individual-info-form"
    class="d-flex flex-column justify-content-between page-content"
    [formGroup]="individualInformationForm"
    #individualInfoFormParent="ngForm"
    (ngSubmit)="resetPageErrorAndFocus()"
  >
    <section id="individual-info-content" class="container">
      <div id="individual-info-header" class="content-section">
        <bolt-alert-error
          #pageLevelError
          _id="page-level-error"
          *ngIf="alertMessage && alertMessage.type === alertType.ERROR"
        >
          {{ alertMessage.message }}
        </bolt-alert-error>
        <bolt-alert-information
          #pageLevelError
          _id="page-level-error"
          *ngIf="alertMessage && alertMessage.type === alertType.INFO"
        >
          {{ alertMessage.message }}
        </bolt-alert-information>
        <h2 #adaAutoFocus id="individual-info-title" class="h5">
          <strong>
            Enter your personal information.
          </strong>
        </h2>
        <p id="individual-info-message">
          {{ individualInfoMessageText }}
        </p>
        <ciam-business-names [businessNames]="businessNames" *ngIf="isControllerSearch"></ciam-business-names>
      </div>
      <div id="individual-info-inputs">
        <fieldset class="content-section">
          <legend>
            <h2 id="your-info-title" class="h5 pre-form-content">
              {{ yourInfoTitleText }}
            </h2>
            <p id="your-info-message" class="h6 font-weight-normal pre-form-content" *ngIf="yourInfoMessageText">
              {{ yourInfoMessageText }}
            </p>
          </legend>
          <div class="form-group">
            <bolt-textfield
              [_id]="individualInformationControls.firstName.id"
              _name="firstName"
              [_aria-describedby]="individualInformationControls.firstName.ariaDescribedBy"
              maxlength="80"
              [formGroup]="individualInformationForm"
              [parentForm]="individualInfoFormParent"
            >
              First name
            </bolt-textfield>
            <bolt-field-error
              id="your-info-first-name-errors"
              _id="your-info-first-name-errors"
              _class="field-error"
              [errors]="individualInformationForm.get('firstName').errors"
              [errorMessages]="individualInformationControlErrors.firstName"
              *ngIf="individualInfoFormParent.submitted"
            ></bolt-field-error>
          </div>
          <div class="form-group w-50 text-nowrap">
            <bolt-textfield
              [_id]="individualInformationControls.middleInitial.id"
              _name="middleInitial"
              maxlength="1"
              [formGroup]="individualInformationForm"
              [parentForm]="individualInfoFormParent"
            >
              Middle initial <em class="font-weight-lighter">(optional)</em>
            </bolt-textfield>
          </div>
          <div class="form-group">
            <bolt-textfield
              [_id]="individualInformationControls.lastName.id"
              _name="lastName"
              maxlength="80"
              [formGroup]="individualInformationForm"
              [parentForm]="individualInfoFormParent"
            >
              Last name
            </bolt-textfield>
            <bolt-field-error
              id="your-info-last-name-errors"
              _id="your-info-last-name-errors"
              _class="field-error"
              [errors]="individualInformationForm.get('lastName').errors"
              [errorMessages]="individualInformationControlErrors.lastName"
              *ngIf="individualInfoFormParent.submitted"
            ></bolt-field-error>
          </div>
          <div class="form-group w-50 text-nowrap">
            <bolt-textfield
              [_id]="individualInformationControls.suffix.id"
              _name="suffix"
              maxlength="10"
              [formGroup]="individualInformationForm"
              [parentForm]="individualInfoFormParent"
            >
              Suffix <em class="font-weight-lighter">(optional)</em>
            </bolt-textfield>
          </div>
          <div class="form-group w-50 text-nowrap">
            <bolt-textfield
              [_id]="individualInformationControls.zipCode.id"
              _name="zipCode"
              _class="nwpii"
              maxlength="5"
              mask="00000"
              inputmode="numeric"
              [formGroup]="individualInformationForm"
              [parentForm]="individualInfoFormParent"
            >
              ZIP code
            </bolt-textfield>
            <bolt-field-error
              id="your-info-zip-code-errors"
              _id="your-info-zip-code-errors"
              _class="field-error"
              [errors]="individualInformationForm.get('zipCode').errors"
              [errorMessages]="individualInformationControlErrors.zipCode"
              *ngIf="individualInfoFormParent.submitted"
            ></bolt-field-error>
          </div>
        </fieldset>
      </div>
    </section>
    <section id="individual-info-buttons" class="button-container">
      <div class="container button-stack">
        <bolt-button-primary ciamClickLogger _id="individual-info-find-account" _type="submit">
          {{ findAccountButtonText }}
        </bolt-button-primary>
        <input
          *ngIf="!isControllerSearch"
          ciamClickLogger
          type="button"
          id="individual-info-back"
          class="btn nw-btn-inline"
          value="Back"
          (click)="back()"
        />
        <input
          ciamClickLogger
          type="button"
          id="individual-info-cancel"
          class="btn btn-default"
          value="Cancel"
          data-cancel-trigger
        />
      </div>
    </section>
  </form>
</div>
