<div class="d-flex flex-column">
  <div class="d-flex flex-column justify-content-between page-content">
    <section id="instant-success-content" class="container">
      <span #adaAutoFocus tabindex="-1" class="m-0 p-0 no-outline">
        <!-- This element is used purely for on-load ADA focusing. -->
      </span>

      <div
        [attr.id]="fromForgotCredentials ? 'main-content' : 'instant-success-header'"
        class="content-section no-outline"
        tabindex="-1"
      >
        <!-- TODO: The following aria-describedby should probably be an aria-labelledby. -->
        <bolt-alert-confirmation _id="alert-confirmation" _aria-describedby="progress-bar-label">
          <strong>Success!</strong> Thank you for setting up online access.
          <p class="mb-0">
            Your username is: <strong>{{ userName }}</strong>
          </p>
        </bolt-alert-confirmation>
      </div>

      <div class="button-standalone content-section">
        <bolt-button-primary ciamClickLogger _type="button" _id="instant-success-continue" (click)="continue()">
          Login to view your account
        </bolt-button-primary>
      </div>
    </section>
  </div>
</div>
