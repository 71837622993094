/* eslint-disable @typescript-eslint/naming-convention */
import { Code as AccountVerificationCode } from '@nationwide/api-client-bank-account-verification-v1';
import { ControlErrors, Controls } from '@shared/base-component/base.model';
import { CONTROL_ERROR_MESSAGES, CONTROL_PATTERNS } from '@shared/validation/control-constants';

export const CONTROLS: Controls = {
  routingNumber: {
    id: 'routing-number',
    patterns: CONTROL_PATTERNS.routingNumber,
    ariaDescribedByErrors: 'routing-number-errors',
    ariaDescribedBy: 'routing-num-helptext-textfield-col, routing-length-aria-label'
  },
  accountNumber: {
    id: 'account-number',
    patterns: CONTROL_PATTERNS.accountNumber,
    ariaDescribedByErrors: 'account-number-errors',
    ariaDescribedBy: 'account-num-helptext-textfield-col, account-length-aria-label'
  },
  confirmAccountNumber: {
    id: 'confirm-account-number',
    patterns: CONTROL_PATTERNS.confirmAccountNumber,
    ariaDescribedByErrors: 'confirm-account-number-errors',
    ariaDescribedBy: 'confirm-account-length-aria-label'
  },
  firstName: {
    id: 'first-name',
    patterns: CONTROL_PATTERNS.firstName,
    ariaDescribedByErrors: 'name-errors'
  },
  middleName: {
    id: 'middle-name',
    patterns: CONTROL_PATTERNS.firstName,
    ariaDescribedByErrors: 'name-errors'
  },
  lastName: {
    id: 'last-name',
    patterns: CONTROL_PATTERNS.firstName,
    ariaDescribedByErrors: 'name-errors'
  },
  streetAddress: {
    id: 'street-address',
    patterns: CONTROL_PATTERNS.streetAddress,
    ariaDescribedByErrors: 'street-address-errors'
  },
  apartmentNumber: {
    id: 'apartment-number',
    ariaDescribedByErrors: 'apartment-errors'
  },
  cityName: {
    id: 'city-name',
    patterns: CONTROL_PATTERNS.city,
    ariaDescribedByErrors: 'city-errors'
  },
  stateSelection: {
    id: 'state',
    ariaDescribedByErrors: 'state-errors'
  },
  zipCode: {
    id: 'zip-code',
    patterns: CONTROL_PATTERNS.zipCode,
    ariaDescribedByErrors: 'zip-code-errors'
  }
};

export enum ShadowDomIndexToFormInput {
  'routingNumber' = 0,
  'accountNumber' = 1,
  'confirmAccountNumber' = 2,
  'firstName' = 3,
  'middleName' = 4,
  'lastName' = 5,
  'streetAddress' = 6,
  'apartmentNumber' = 7,
  'cityName' = 8,
  'zipCode' = 9
}

export const CONTROL_ERRORS: ControlErrors = {
  accountType: {
    required: CONTROL_ERROR_MESSAGES.accountTypeRequired
  },
  routingNumber: {
    required: CONTROL_ERROR_MESSAGES.routingNumberRequired,
    pattern: CONTROL_ERROR_MESSAGES.routingNumberPattern
  },
  accountNumber: {
    required: CONTROL_ERROR_MESSAGES.bankAccountNumberRequired,
    pattern: CONTROL_ERROR_MESSAGES.bankAccountNumberPattern
  },
  confirmAccountNumber: {
    required: CONTROL_ERROR_MESSAGES.confirmAccountNumberRequired,
    pattern: CONTROL_ERROR_MESSAGES.confirmAccountNumberPattern,
    mismatch: CONTROL_ERROR_MESSAGES.accountNumberMatch
  },
  firstName: {
    required: CONTROL_ERROR_MESSAGES.firstNameRequired,
    pattern: CONTROL_ERROR_MESSAGES.firstNamePattern
  },
  zipCode: {
    required: CONTROL_ERROR_MESSAGES.zipCodeRequired,
    pattern: CONTROL_ERROR_MESSAGES.zipCodePattern
  },
  cityName: {
    required: CONTROL_ERROR_MESSAGES.cityNameRequired,
    pattern: CONTROL_ERROR_MESSAGES.cityNamePattern
  },
  stateSelection: {
    required: CONTROL_ERROR_MESSAGES.stateNameRequired
  },
  streetAddress: {
    required: CONTROL_ERROR_MESSAGES.streetAddressRequired,
    pattern: CONTROL_ERROR_MESSAGES.streetAddressPattern
  }
};

export const STATES = [
  { abbr: 'AK', name: 'Alaska' },
  { abbr: 'AL', name: 'Alabama' },
  { abbr: 'AR', name: 'Arkansas' },
  { abbr: 'AZ', name: 'Arizona' },
  { abbr: 'CA', name: 'California' },
  { abbr: 'CO', name: 'Colorado' },
  { abbr: 'CT', name: 'Connecticut' },
  { abbr: 'DC', name: 'District of Columbia' },
  { abbr: 'DE', name: 'Delaware' },
  { abbr: 'FL', name: 'Florida' },
  { abbr: 'GA', name: 'Georgia' },
  { abbr: 'HI', name: 'Hawaii' },
  { abbr: 'IA', name: 'Iowa' },
  { abbr: 'ID', name: 'Idaho' },
  { abbr: 'IL', name: 'Illinois' },
  { abbr: 'IN', name: 'Indiana' },
  { abbr: 'KS', name: 'Kansas' },
  { abbr: 'KY', name: 'Kentucky' },
  { abbr: 'LA', name: 'Louisiana' },
  { abbr: 'MA', name: 'Massachusetts' },
  { abbr: 'MD', name: 'Maryland' },
  { abbr: 'ME', name: 'Maine' },
  { abbr: 'MI', name: 'Michigan' },
  { abbr: 'MN', name: 'Minnesota' },
  { abbr: 'MO', name: 'Missouri' },
  { abbr: 'MS', name: 'Mississippi' },
  { abbr: 'MT', name: 'Montana' },
  { abbr: 'NC', name: 'North Carolina' },
  { abbr: 'ND', name: 'North Dakota' },
  { abbr: 'NE', name: 'Nebraska' },
  { abbr: 'NH', name: 'New Hampshire' },
  { abbr: 'NJ', name: 'New Jersey' },
  { abbr: 'NM', name: 'New Mexico' },
  { abbr: 'NV', name: 'Nevada' },
  { abbr: 'NY', name: 'New York' },
  { abbr: 'OH', name: 'Ohio' },
  { abbr: 'OK', name: 'Oklahoma' },
  { abbr: 'OR', name: 'Oregon' },
  { abbr: 'PA', name: 'Pennsylvania' },
  { abbr: 'RI', name: 'Rhode Island' },
  { abbr: 'SC', name: 'South Carolina' },
  { abbr: 'SD', name: 'South Dakota' },
  { abbr: 'TN', name: 'Tennessee' },
  { abbr: 'TX', name: 'Texas' },
  { abbr: 'UT', name: 'Utah' },
  { abbr: 'VA', name: 'Virginia' },
  { abbr: 'VT', name: 'Vermont' },
  { abbr: 'WA', name: 'Washington' },
  { abbr: 'WI', name: 'Wisconsin' },
  { abbr: 'WV', name: 'West Virginia' },
  { abbr: 'WY', name: 'Wyoming' }
];

export const ACCEPTABLE_RESPONSE_CODES: AccountVerificationCode[] = ['1111', '3333', '5555', 'GN01', 'RT03', 'ND00'];

export const WRONG_ACCOUNT_TYPE_CODES: AccountVerificationCode[] = ['NW01', 'NW02', '7777', '8888', '9999'];

export const FAIL_RESPONSE_CODES: AccountVerificationCode[] = [
  'GN05',
  'GP01',
  'GS01',
  'GS02',
  'ND01',
  'RT00',
  'RT02',
  'RT01',
  'RT04'
];

export enum Account_Recommendation {
  Accept = 'Accept',
  Wrong_Account_Type = 'Wrong Account Type',
  Decline = 'Decline'
}
