import { NgModule } from '@angular/core';
import { BoltButtonLinkModule, BoltButtonPrimaryModule } from '@nw/bolt-angular';
import { SharedModule } from '@shared/shared.module';
import { InvalidLinkComponent } from './invalid-link/invalid-link.component';
import { OneTimeLinkRoutingModule } from './one-time-link-routing.module';
import { OneTimeLinkComponent } from './one-time-link/one-time-link.component';

@NgModule({
  declarations: [OneTimeLinkComponent, InvalidLinkComponent],
  imports: [SharedModule, OneTimeLinkRoutingModule, BoltButtonPrimaryModule, BoltButtonLinkModule]
})
export class OneTimeLinkModule {}
