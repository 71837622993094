<div class="d-flex flex-column">
  <form
    id="confirmation-form"
    class="d-flex flex-column justify-content-between page-content"
    [formGroup]="confirmationForm"
  >
    <section id="confirmation-content" class="container">
      <div id="confirmation-header" class="content-section">
        <bolt-alert-confirmation _id="alert-confirmation">
          <strong>Success!</strong> Thank you for verifying your identity.
        </bolt-alert-confirmation>
        <h2 #adaAutoFocus class="h5" id="remember-title">
          <strong>Remember this computer or device</strong>
        </h2>
        <p id="remember-message">
          By having us remember a secure computer or device, you will minimize the number of security challenges you
          encounter at login. We are able to remember multiple computers and devices.
        </p>
      </div>
      <div id="confirmation-inputs" class="content-section">
        <fieldset class="form-group">
          <legend class="pre-form-content">
            <h2 id="remember-checkbox-title" class="h5">
              Would you like us to remember this device?
            </h2>
          </legend>
          <bolt-check-box _id="remember-checkbox" _name="rememberCheckbox" [formGroup]="confirmationForm">
            <strong>Yes.</strong> This is a private or secure device for accessing my Nationwide account.
          </bolt-check-box>
        </fieldset>
      </div>
    </section>
    <section id="confirmation-buttons" class="button-container">
      <div class="container button-stack">
        <bolt-button-primary ciamClickLogger _id="confirmation-continue" (click)="continue()">
          Continue
        </bolt-button-primary>
      </div>
    </section>
  </form>
</div>
