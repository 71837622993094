export const SEARCH_PATHS: {
  accountType: string;
  individualInformation: string;
  additionalInformation: string;
  identifier: string;
  startOver: string;
  accountNotFound: string;
} = {
  accountType: 'account-type',
  individualInformation: 'individual-information',
  additionalInformation: 'additional-information',
  identifier: 'identifier',
  startOver: 'start-over',
  accountNotFound: 'account-not-found'
};

export const SEARCH_ADDL_INFO_PATHS: {
  dateOfBirth: string;
  phoneNumber: string;
  email: string;
  addressNumber: string;
} = {
  dateOfBirth: 'dob',
  phoneNumber: 'phone',
  email: 'email',
  addressNumber: 'address'
};
