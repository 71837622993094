import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@environments/environment';
import {
  PatchInternetRegistrationPartialSuccessResponse,
  PatchInternetRegistrationRequest,
  PatchInternetRegistrationResponse,
  RegistrationManagementService
} from '@nationwide/api-client-internet-registration-v3';
import { CustomCookieService } from '@shared/storage/custom-cookie.service';
import { CustomSessionService, CUSTOM_SESSION } from '@shared/storage/custom-session.service';
import { Customer, Partner, SessionKey } from '@shared/storage/storage.model';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// rsa.js
// eslint-disable-next-line @typescript-eslint/naming-convention
declare function encode_deviceprint(): string;

@Injectable()
export class ForgotCredentialsService {
  constructor(
    @Inject(CUSTOM_SESSION) private _sessionService: CustomSessionService,
    private _oauthService: OAuthService,
    private _registrationManagementService: RegistrationManagementService,
    private _cookieService: CustomCookieService
  ) {
    this.reset();
  }

  reset() {
    this._registrationManagementService.configuration.accessToken = this._oauthService.getAccessToken();
  }

  updateCredentials(
    password: string,
    username?: string
  ): Observable<HttpResponse<PatchInternetRegistrationResponse | PatchInternetRegistrationPartialSuccessResponse>> {
    const request: PatchInternetRegistrationRequest = {
      password
    };

    if (username) {
      request.userName = username;
    }

    if (this._sessionService.get(SessionKey.MFA)?.rememberDevice) {
      request.device = {
        devicePrint: encode_deviceprint(), //NOSONAR
        channel: 'WEB',
        deviceToken: this._cookieService.deviceToken
      };
    }

    return this._registrationManagementService.internetRegistrationsGuidPatch(
      request,
      ENVIRONMENT.apiConfig.clientId,
      this._cookieService.flowId,
      (this._sessionService.get(SessionKey.CUSTOMER) as Customer)?.guid,
      (this._sessionService.get(SessionKey.PARTNER) as Partner)?.id,
      false,
      'response'
    );
  }

  getCurrentUsername(guid: string): Observable<string> {
    return this._registrationManagementService
      .internetRegistrationsGuidGet(
        ENVIRONMENT.apiConfig.clientId,
        this._cookieService.flowId,
        guid,
        (this._sessionService.get(SessionKey.PARTNER) as Partner)?.id
      )
      .pipe(map(response => response.userName));
  }
}
