<div class="d-flex flex-column">
  <div class="d-flex flex-column justify-content-between page-content">
    <section id="set-preferences-content" class="container">
      <div id="set-preferences-header" class="content-section">
        <div id="set-preferences-error" style="display: none;">
          <bolt-alert-error #pageLevelError _id="page-level-error">
            We're temporarily unable to update preferences. Try updating them later. You may continue to your account.
          </bolt-alert-error>
        </div>
        <h2 #adaAutoFocus id="set-preferences-title" class="h5">
          <strong>
            Set your communication preferences
          </strong>
        </h2>
      </div>
      <div id="set-preferences-paperless" class="content-section">
        <h2 class="h5">
          Consider going paperless!
        </h2>
        <ul class="list-group">
          <li>Offers immediate access 24/7</li>
          <li>Reduces waste</li>
          <li>Increases security and convenience</li>
          <li>Streamlines your recordkeeping</li>
        </ul>
        <p>
          We'll send you an email or text message with a link to view your bills, documents and other important notices
          as soon as they're available online in your policy's Document Center.
        </p>
        <p>
          <strong>Note:</strong> Even if you do not elect to receive mail, regulations require that certain documents be
          mailed to you.
        </p>
      </div>
      <ciam-preference-center-widget id="preference_center_widget"></ciam-preference-center-widget>
    </section>
    <section id="set-preferences-buttons" class="button-container">
      <div class="container button-stack">
        <bolt-button-primary ciamClickLogger _type="button" _id="set-preferences-continue" (click)="continue()">
          Continue
        </bolt-button-primary>
      </div>
    </section>
  </div>
</div>
