import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@environments/environment';
import { isMockEnabled } from '@environments/environment-util';
import { EimAngularLoggingRequest, EimAngularLoggingService } from '@nw/eim-angular-logging';
import { CustomCookieService } from '@shared/storage/custom-cookie.service';
import { CustomSessionService, CUSTOM_SESSION } from '@shared/storage/custom-session.service';
import { Observable, of, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  private readonly _excludedSessionKeys = ['axeViolations', 'length', 'session_state'];
  private readonly _maskedSessionKeys = ['accessToken', 'access_token', 'id_token_claims_obj', 'activationKey'];

  constructor(
    @Inject(CUSTOM_SESSION) private _sessionService: CustomSessionService,
    private _cookieService: CustomCookieService,
    private _logger: EimAngularLoggingService
  ) {}

  debug(className: string, message: string, messageDetail?: any, observe = false): Observable<any> | Subscription {
    const logEvent: Observable<any> = this._logger.debug(this._buildLogRequest(message, className, messageDetail));
    return observe ? logEvent : logEvent.subscribe();
  }

  info(className: string, message: string, messageDetail?: any, observe = false): Observable<any> | Subscription {
    const logEvent: Observable<any> = this._logger.info(this._buildLogRequest(message, className, messageDetail));
    return observe ? logEvent : logEvent.subscribe();
  }

  warn(className: string, message: string, messageDetail?: any, observe = false): Observable<any> | Subscription {
    const logEvent: Observable<any> = this._logger.warn(this._buildLogRequest(message, className, messageDetail));
    return observe ? logEvent : logEvent.subscribe();
  }

  error(className: string, message: string, messageDetail?: any, observe = false): Observable<any> | Subscription {
    const logEvent: Observable<any> = this._logger.error(this._buildLogRequest(message, className, messageDetail));
    return observe ? logEvent : logEvent.subscribe();
  }

  logElementClick(className: string, event: Event): Subscription {
    const target = (event.currentTarget || event.target) as HTMLElement;
    if (target) {
      return this.info(className, 'Element clicked.', {
        id: target.id || target.attributes?.getNamedItem('_id')?.value,
        text: target.attributes?.getNamedItem('value')?.value?.trim() || target.innerText?.trim(),
        href:
          target.attributes?.getNamedItem('data-cancel-url')?.value || target.attributes?.getNamedItem('href')?.value,
        cancelTrigger: !!target.attributes?.getNamedItem('data-cancel-trigger')
      }) as Subscription;
    }

    return of({}).subscribe();
  }

  private _buildLogRequest(message: string, className: string, messageDetail?: any): EimAngularLoggingRequest {
    const request: EimAngularLoggingRequest = {
      transactionId: this._cookieService.flowId,
      message,
      className
    };
    const storage = (this._sessionService as any).storage;
    if (storage) {
      request.session = this._buildSessionData(storage);
      (request.session as any).ciamFlowId = this._cookieService.flowId;
      (request.session as any).ciamSessionId = this._cookieService.sessionId;

      // TODO: This log property (session_id) is deprecated and should be removed in a future release.
      // It is being maintained, for now, for compatibility with existing Splunk queries/dashboards.
      (request.session as any).session_id = this._cookieService.flowId;

      if (!ENVIRONMENT.production) {
        (request.session as any).mockEnabled = isMockEnabled();
      }
    }

    if (messageDetail) {
      request.messageDetail = messageDetail;
    }

    return request;
  }

  private _buildSessionData(storage: any) {
    const sessionData: any = {};
    Object.keys(storage).forEach(key => {
      if (!this._excludedSessionKeys.includes(key)) {
        if (this._maskedSessionKeys.includes(key)) {
          if (key === 'id_token_claims_obj') {
            sessionData[key] = this.maskActivationKey(storage[key]);
          } else {
            sessionData[key] = '*****';
          }
        } else {
          try {
            sessionData[key] = JSON.parse(storage[key]);
          } catch (error) {
            sessionData[key] = storage[key];
          }
        }
      }
    });
    return sessionData;
  }

  private maskActivationKey(storage: any) {
    const sessionData = JSON.parse(storage);
    if (sessionData.verify?.activationKey) {
      sessionData.verify.activationKey = '*****';
    }
    return sessionData;
  }
}
