import { AfterContentInit, Component, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AppInjector } from '@app/app-injector';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BoltAlertErrorComponent } from '@nw/bolt-angular';
import { LoggingService } from '@shared/logging/logging.service';
import { CUSTOM_SESSION, CustomSessionService } from '@shared/storage/custom-session.service';
import { userAgent } from '@shared/user-agent/user-agent.util';
import { find } from 'rxjs/operators';
import { BaseComponent } from './base.component';
import { Control, ControlErrors, Controls } from './base.model';

@UntilDestroy()
@Component({ template: '' })
export abstract class AdaBaseComponent extends BaseComponent implements AfterContentInit {
  @ViewChild('adaAutoFocus') adaAutoFocus: ElementRef;
  @ViewChild('pageLevelError') pageLevelError: BoltAlertErrorComponent;

  protected sessionService: CustomSessionService;
  protected logger: LoggingService;

  protected className = 'AdaBaseComponent';

  private readonly _maskString = '*****';

  constructor() {
    super();

    this.sessionService = AppInjector.injector.get(CUSTOM_SESSION) as CustomSessionService;
    this.logger = AppInjector.injector.get(LoggingService);
  }

  ngAfterContentInit() {
    this.spinnerService.model?.notifications
      ?.pipe(
        untilDestroyed(this),
        find(enabled => enabled === false)
      )
      .subscribe({
        next: () => {
          this._setElementAttributes();
        }
      });

    super.ngAfterContentInit();
  }

  resetPageErrorAndFocus() {
    // Remove (and re-add later) the page-level error message role attribute to help browsers recognize that an existing alert is active.
    if (this.pageLevelError) {
      this.pageLevelError.message.nativeElement.parentNode.setAttribute('role', null);
    }

    // Scroll back to top and move focus to top of form.
    window.scrollTo(0, 0);
    const formSubmitFocus = document.getElementById('form-submit-focus');
    if (formSubmitFocus) {
      formSubmitFocus.focus();
    }

    if (this.pageLevelError) {
      this.pageLevelError.message.nativeElement.parentNode.setAttribute('role', 'alert');
    }

    if (this.formGroup) {
      this._resetAndUpdateAriaDescribedBy();
    }

    if (this.controlErrors && this.formGroup && this.formGroup.invalid) {
      this.logger.warn(
        this.className,
        'Validation error occurred.',
        this._getFormErrors(this.formGroup, this.controlErrors)
      );
    }
  }

  _setElementAttributes() {
    setTimeout(() => {
      const element = this.adaAutoFocus?.nativeElement || document.getElementById('main-content');
      if (element) {
        element.classList.add('no-outline');
        element.setAttribute('tabindex', '-1');
        element.setAttribute('aria-labelledby', `progress-bar-label ${element.getAttribute('id')}`);
        if (userAgent.isFirefox()) {
          element.setAttribute('aria-live', 'assertive');
        }
        element.focus();
      }
    }, 200);
  }

  _getFormErrors(formGroup: UntypedFormGroup, controlErrors: ControlErrors) {
    const results: any = {};

    for (const formField in controlErrors) {
      if (Object.prototype.hasOwnProperty.call(controlErrors, formField)) {
        // since we don't have the specific type for the control errors object,
        // we need to check for unique properties (formControls) for this instance

        for (const errorKey in controlErrors[formField]) {
          if (Object.prototype.hasOwnProperty.call(controlErrors[formField], errorKey)) {
            // similar to above, we need to check each formControl field
            // for the unique properties (errors) for this instance

            if (this._hasError(formGroup, formField, errorKey)) {
              results[formField] = {
                error: controlErrors[formField][errorKey]
              };

              this._updateFormFieldValue(formGroup, formField, results);
            }
          }
        }
      }
    }

    return results;
  }

  _resetAndUpdateAriaDescribedBy() {
    for (const controlKey of Object.keys(this.formGroup.controls)) {
      const control = this.controls[controlKey];
      if (control && (control.ariaDescribedBy || control.ariaDescribedByErrors)) {
        let ariaDescribedBy = control.ariaDescribedBy;

        if (this.formGroup.get(controlKey)?.errors && control.ariaDescribedByErrors) {
          ariaDescribedBy = ariaDescribedBy
            ? `${control.ariaDescribedByErrors} ${ariaDescribedBy}`
            : control.ariaDescribedByErrors;
        }

        this._updateAriaDescribedBy(control, ariaDescribedBy);
      }
    }
  }

  private _hasError(formGroup: UntypedFormGroup, formField: string, errorKey: string) {
    return ((formGroup.get(formField) || ({} as any)).errors || {})[errorKey];
  }

  private _updateFormFieldValue(formGroup: UntypedFormGroup, formField: string, results: any) {
    if (formGroup.get(formField).value) {
      if (['password', 'passwordConfirmation'].includes(formField)) {
        results[formField]['value'] = this._maskString;
      } else {
        results[formField]['value'] = formGroup.get(formField).value;
      }
    }
  }

  private _updateAriaDescribedBy(control: Control, ariaDescribedBy: string) {
    if (ariaDescribedBy) {
      document.getElementById(control.id)?.setAttribute('aria-describedby', ariaDescribedBy);
    } else {
      document.getElementById(control.id)?.removeAttribute('aria-describedby');
    }
  }

  protected get formGroup(): UntypedFormGroup {
    return null;
  }

  protected get controls(): Controls {
    return null;
  }

  protected get controlErrors(): ControlErrors {
    return null;
  }
}
