<a class="skip-main" href="#main-content" (click)="handleSkipToMainContent($event)">Skip to main content</a>
<ciam-header></ciam-header>

<main role="main">
  <ciam-content-header></ciam-content-header>
  <ciam-spinner></ciam-spinner>
  <div id="route-content">
    <router-outlet></router-outlet>
  </div>
</main>

<ciam-footer></ciam-footer>
<ciam-cancel></ciam-cancel>
