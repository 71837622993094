import { Injectable, Renderer2 } from '@angular/core';
import { ScriptParams } from '../ScriptParams.interface';

@Injectable({
  providedIn: 'root'
})
export class ScriptsLoaderService {
  loadScripts(renderer: Renderer2, wrapper: any, bundle: ScriptParams[]): void {
    bundle.forEach((src: ScriptParams): void => this.loadScript(renderer, wrapper, src));
  }

  loadScript(renderer: Renderer2, wrapper: any, src: ScriptParams): void {
    const links = wrapper.getElementsByTagName('script');

    const script = renderer.createElement('script');
    script.src = src.path;
    script.type = 'text/javascript';
    script.async = src.async || false;
    script.noModule = src.noModule || false;
    script.defer = src.defer || false;

    if (this.checkIfScriptExists(links, script)) {
      return;
    }
    renderer.appendChild(wrapper, script);
  }

  loadStyles(renderer: Renderer2, wrapper: any, cssPath: string[]): void {
    cssPath.forEach((src: string): void => this.loadStyle(renderer, wrapper, src));
  }

  loadStyle(renderer: Renderer2, wrapper: any, src: string): void {
    const links = wrapper.getElementsByTagName('link');

    const link = renderer.createElement('link');
    link.href = src;
    link.rel = 'stylesheet';
    link.type = 'text/css';

    if (this.checkIfScriptExists(links, link)) {
      return;
    }
    renderer.appendChild(wrapper, link);
  }

  checkIfScriptExists(links: any, link: HTMLLinkElement): boolean {
    for (const node of links) {
      if (node.outerHTML === link.outerHTML) {
        return true;
      }
    }
    return false;
  }
}
