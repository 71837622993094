import { PartyType } from '@shared/storage/storage.model';

export enum AccountType {
  PERSONAL = 'personal',
  BUSINESS = 'business',
  TRUST = 'trust'
}

export enum LifecycleStatus {
  ALL = 'all'
}

export enum SearchFilter {
  NONE = 'none'
}

export enum SearchFlowType {
  PERSONAL = 'personal',
  BUSINESS = 'business',
  CONTROLLER = 'controller'
}

export enum SearchType {
  PERSONAL_INDIVIDUAL_INFORMATION = 'personalIndividualInformation',
  PERSONAL_ADDITIONAL_INFORMATION = 'personalAdditionalInformation',
  PERSONAL_IDENTIFIER = 'personalIdentifier',
  BUSINESS_IDENTIFIER = 'businessIdentifier',
  CONTROLLER_INDIVIDUAL_INFORMATION = 'controllerIndividualInformation'
}

export enum AdditionalInformationType {
  DATE_OF_BIRTH = 'dateOfBirth',
  PHONE_NUMBER = 'phoneNumber',
  EMAIL = 'email',
  ADDRESS_NUMBER = 'addressNumber',
  ZIP_CODE = 'zipCode'
}

export enum IdentifierType {
  SOCIAL = 'social',
  ACCOUNT = 'account'
}

export enum SearchResult {
  SINGLE_MATCH = 'singleMatch',
  MULTI_MATCH = 'multiMatch',
  POTENTIAL_MATCH = 'potentialMatch',
  NO_MATCH = 'noMatch',
  ERROR = 'error',
  UNKNOWN_MATCH_TYPE = 'unknownMatchType'
}
export interface SearchCriteria {
  input: {
    firstName?: string;
    lastName?: string;
    zipCode?: string;
    taxIdentifier?: string;
    agreementNumber?: string;
    dateOfBirth?: string;
    phoneNumber?: string;
    email?: string;
    addressNumber?: string;
  };
  filters?: {
    partyType?: PartyType;
  };
}

export interface SearchModel {
  lifecycleStatus?: LifecycleStatus;
  accountType?: AccountType;
  additionalInformationType?: AdditionalInformationType;
  searchCriteria?: SearchCriteria;
  previousSearch?: SearchType;
  partyType?: PartyType;
}
