<ciam-error-container
  errorId="account-issue"
  title="There is an issue with your account"
  [message]="message"
></ciam-error-container>

<ng-template #message>
  <div>
    Please contact us at
    <span class="text-nowrap"><ciam-phone-number key="WEB_SUPPORT"></ciam-phone-number></span> for assistance.
  </div>
</ng-template>
