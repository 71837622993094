import { Component, OnInit } from '@angular/core';
import { ERROR_PATHS } from '@app/error/error-paths';
import { ClientCredentialsTokenService } from '@app/registration/client-credentials-token/client-credentials-token.service';
import { ENVIRONMENT } from '@environments/environment';
import { ContentHeaderService } from '@shared/content-header/content-header.service';
import { NavigationService } from '@shared/navigation/navigation.service';
import { SessionKey } from '@shared/storage/storage.model';
import { RegistrationBaseComponent } from '../shared/registration-base.component';

@Component({
  selector: 'ciam-register-success',
  templateUrl: './register-success.component.html',
  styleUrls: ['./register-success.component.scss']
})
export class RegisterSuccessComponent extends RegistrationBaseComponent implements OnInit {
  userName: string;
  isMobile: boolean;
  protected readonly className = 'RegisterSuccessComponent';

  constructor(
    private _navigationService: NavigationService,
    private _contentHeaderService: ContentHeaderService,
    private _clientCredentialsTokenService: ClientCredentialsTokenService
  ) {
    super();
  }

  ngOnInit() {
    this.userName = this.sessionService.get(SessionKey.CUSTOMER)?.userName;
    this.isMobile = this.isMobileCheck();
    this._contentHeaderService.reset();
  }

  isMobileCheck(): boolean {
    return /android|ipad|ipod|iphone/i.test(window.navigator.userAgent);
  }

  redirectToAppStore(): void {
    const mobileAppUrl =
      'https://downloadapp.nationwide.com/PKX4/5d8b4774?c=DigExp&af_c_id=NW.com%20Reg&af_adset=Button&af_adset_id=Simple&af_ad_id=NWM-0018';
    this._navigationService.navigateExternal(mobileAppUrl);
  }

  continue() {
    this.spinnerService.show();
    const pingToken = this.sessionService.get(SessionKey.PING_TOKEN);
    const type = this.sessionService.get(SessionKey.TYPE);
    const partnerSpId = this.sessionService.get(SessionKey.PARTNER_SP_ID);
    if (partnerSpId === `${ENVIRONMENT?.trca.sscPartnerSpId}`) {
      this._clientCredentialsTokenService.getRefId().subscribe(
        response => {
          if (response?.tokenId) {
            const sscEditInfoLink = ENVIRONMENT.trca.sscHomePageRedirect?.concat(response.tokenId);
            this.logger.info(this.className, sscEditInfoLink);
            this._navigationService.navigateExternal(sscEditInfoLink);
          } else {
            this._navigationService.navigateToError(
              this.className,
              new Error('tokenId not retrived'),
              ERROR_PATHS.systemError,
              'ClientCredentialsTokenService getRefId() failed.'
            );
          }
        },
        error => {
          this._navigationService.navigateToError(
            this.className,
            error,
            ERROR_PATHS.systemError,
            'ClientCredentialsTokenService getRefId() failed.'
          );
        }
      );
    } else {
      this._navigationService.navigateExternal(
        `${ENVIRONMENT.externalUrls.cam.basePath}${ENVIRONMENT.externalUrls.cam.index}?token=${pingToken}&type=${type}`
      );
    }
  }
}
