import { ControlErrors, Controls } from '@shared/base-component/base.model';
import { CONTROL_ERROR_MESSAGES } from '@shared/validation/control-constants';

export const CONTROLS: Controls = {
  activation: {
    id: 'your-information-activation',
    label: 'Activation Key',
    helpText: 'Open the mail to find the activation key.',
    ariaDescribedByErrors: 'non-instant-activation-errors',
    ariaDescribedBy: 'non-instant-activation-help-message'
  }
};

export const CONTROL_ERRORS: ControlErrors = {
  activation: {
    required: CONTROL_ERROR_MESSAGES.activationKeyRequired,
    pattern: CONTROL_ERROR_MESSAGES.activationKeyNotFound
  }
};
