import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { ERROR_PATHS } from '@app/error/error-paths';
import { FORGOT_CREDENTIALS_PATHS } from '@app/forgot-credentials/forgot-credentials-paths';
import { REGISTRATION_PATHS } from '@app/registration/registration-paths';
import { RegistrationFlowType } from '@app/registration/shared/registration.model';
import { MFA_PATHS } from '@common/mfa/mfa-route-paths';
import { SEARCH_PATHS } from '@common/search/search-paths';
import { SearchFlowType } from '@common/search/shared/search.model';
import { ENVIRONMENT } from '@environments/environment';
import { Eligible, PostEligibilityResponse } from '@nationwide/api-client-internet-registration-v3';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CommonFlow, Flow } from '@shared/navigation/flow.enum';
import { Customer, PartyType, Search, SessionKey } from '@shared/storage/storage.model';
import { OAuthService } from 'angular-oauth2-oidc';
import { EligibilityBaseComponent } from '../shared/eligibility-base.component';

@UntilDestroy()
@Component({
  selector: 'ciam-personal-eligibility',
  templateUrl: '../shared/eligibility-base.component.html',
  styleUrls: ['../shared/eligibility-base.component.scss']
})
export class StandardEligibilityComponent extends EligibilityBaseComponent {
  protected readonly className = 'StandardEligibilityComponent';

  constructor(private _oauthService: OAuthService) {
    super();
  }

  _checkEligibility() {
    const customer = this.sessionService.get(SessionKey.CUSTOMER) as Customer;
    this.eligibilityService
      .checkCustomerEligibility(customer?.ecn, (this.sessionService.get(SessionKey.SEARCH) as Search)?.agreementNumber)
      .pipe(untilDestroyed(this))
      .subscribe(
        eligibilityResponse => {
          const eligibilityResponseBody = eligibilityResponse.body;
          this.sessionService.setModifiedSessionObject(
            eligibilityResponseBody.partyType,
            SessionKey.CUSTOMER,
            'partyType'
          );
          this._logEligibilityResults(eligibilityResponseBody);
          this.eligibilityService
            .getExistingRegistrations(customer.ecn)
            .pipe(untilDestroyed(this))
            .subscribe(
              getInternetRegistrationsResponse => {
                this._processInternetRegistrations(getInternetRegistrationsResponse.body.internetRegistrations);
                this._handleRouting(eligibilityResponseBody);
              },
              error => {
                if (error instanceof HttpErrorResponse && error.status === 404 && error.error.code === 4040) {
                  this.sessionService.setModifiedSessionObject(null, SessionKey.CUSTOMER, 'guid');
                  this.sessionService.setModifiedSessionObject(null, SessionKey.CUSTOMER, 'accountAccess');
                  this._handleRouting(eligibilityResponseBody);
                } else {
                  this.logger.error(
                    this.className,
                    `Unexpected HTTP ${error.status} response returned from getInternetRegistrations.`
                  );
                  this.navigationService.navigateToError(
                    this.className,
                    error,
                    ERROR_PATHS.systemError,
                    'EligibilityService getInternetRegistrations failed.'
                  );
                }
              }
            );
        },
        error => {
          this.logger.error(this.className, `Unexpected HTTP ${error.status} response returned from eligibility.`);
          this.navigationService.navigateToError(
            this.className,
            error,
            ERROR_PATHS.systemError,
            'EligibilityService checkCustomerEligibility failed.'
          );
        }
      );
  }

  _handleRouting(eligibilityResponse: PostEligibilityResponse) {
    if (this._isRegisteredCustomer()) {
      this.logger.info(this.className, 'Customer is registered with an online account.', {
        partyType: eligibilityResponse.partyType
      });

      if (this.isRegistrationFlow) {
        this.sessionService.setModifiedSessionObject(true, SessionKey.FORGOT_CREDENTIALS, 'fromRegistration');
      }

      if (eligibilityResponse.partyType === PartyType.PERSON) {
        this._handleRegisteredCustomer();
      } else {
        this._navigateToSnagError(
          `Unsupported registered party type for forgot credentials: ${eligibilityResponse.partyType}`
        );
      }
    } else {
      this.logger.info(this.className, 'Customer is not registered with an online account.', {
        partyType: eligibilityResponse.partyType
      });

      if (this.isFupFlow) {
        this.sessionService.setModifiedSessionObject(true, SessionKey.REGISTRATION, 'fromForgotCredentials');
      }

      if (eligibilityResponse.partyType === PartyType.PERSON) {
        this._handlePersonEligibility(eligibilityResponse);
      } else if (eligibilityResponse.partyType === PartyType.ORGANIZATION) {
        this._handleOrganizationEligibility(eligibilityResponse);
      } else {
        this._navigateToSnagError(
          `Unsupported unregistered party type for registration: ${eligibilityResponse.partyType}`
        );
      }
    }
  }

  _handleRegisteredCustomer() {
    if (this._hasEligibileAccountAccess((this.sessionService.get(SessionKey.CUSTOMER) as Customer)?.accountAccess)) {
      if (this._trustLevel === 1) {
        this.navigationService.navigate(`${Flow.FORGOT_CREDENTIALS}/${CommonFlow.MFA}/${MFA_PATHS.sendCode}`);
      } else if (this._trustLevel >= 2) {
        this.navigationService.navigate(`${Flow.FORGOT_CREDENTIALS}/${FORGOT_CREDENTIALS_PATHS.resetUsernamePassword}`);
      } else {
        throw new Error(
          'Invalid trust level found during eligibility check while attempting to navigate registered customer to the FUP flow'
        );
      }
    } else {
      this._navigateToSnagError('Customer does not have appropriate account access.');
    }
  }

  _handlePersonEligibility(eligibilityResponse: PostEligibilityResponse) {
    const isEligibleForIndividual = this._isEligibleForIndividual(eligibilityResponse);
    const isEligibleForCommercialController = this._isEligibleForCommercialController(eligibilityResponse);

    if (isEligibleForIndividual && isEligibleForCommercialController) {
      this._handlePersonDualEligibility(eligibilityResponse);
    } else if (isEligibleForIndividual) {
      this._navigateToIndividualRegistration();
    } else {
      this._navigateToSnagError(`Unsupported eligibility result for party type: ${PartyType.PERSON}`);
    }
  }

  _handlePersonDualEligibility(eligibilityResponse: PostEligibilityResponse) {
    if (ENVIRONMENT.features.registration.individualEnabled && ENVIRONMENT.features.registration.businessEnabled) {
      this._navigateToBusinessRegistration(eligibilityResponse);
    } else if (ENVIRONMENT.features.registration.individualEnabled) {
      this.logger.warn(
        this.className,
        'Business registration is disabled; defaulting Person to individual registration.'
      );
      this._navigateToIndividualRegistration();
    } else if (ENVIRONMENT.features.registration.businessEnabled) {
      this._navigateToSnagError(
        'Individual registration is disabled, but Person is eligible for both individual and commercial controller.'
      );
    } else {
      this._navigateToSnagError('Individual and business registrations are disabled.');
    }
  }

  _handleOrganizationEligibility(eligibilityResponse: PostEligibilityResponse) {
    if (this._isEligibleForCommercialController(eligibilityResponse)) {
      this._navigateToBusinessRegistration(eligibilityResponse);
    } else {
      this._navigateToSnagError(`Unsupported eligibility result for party type: ${PartyType.ORGANIZATION}`);
    }
  }

  _navigateToIndividualRegistration() {
    if (ENVIRONMENT.features.registration.individualEnabled) {
      if (this._trustLevel === 1) {
        this.navigationService.navigate(
          `${Flow.REGISTRATION}/${RegistrationFlowType.PERSONAL}/${CommonFlow.MFA}/${MFA_PATHS.sendCode}`
        );
      } else if (this._trustLevel >= 2) {
        this.navigationService.navigate(
          `${Flow.REGISTRATION}/${RegistrationFlowType.PERSONAL}/${REGISTRATION_PATHS.createLogin}`
        );
      } else {
        throw new Error(
          'Invalid trust level found during eligibility check while attempting to navigate unregistered customer to the registration flow'
        );
      }
    } else {
      this._navigateToSnagError('Individual registration is disabled.');
    }
  }

  _navigateToBusinessRegistration(eligibilityResponse: PostEligibilityResponse) {
    if (ENVIRONMENT.features.registration.businessEnabled) {
      this._setupBusinessRegistrationSession(eligibilityResponse);

      if (eligibilityResponse.partyType === PartyType.ORGANIZATION) {
        this.sessionService.remove(SessionKey.SEARCH);
        this.navigationService.navigate(
          `${Flow.REGISTRATION}/${CommonFlow.SEARCH}/${SearchFlowType.CONTROLLER}/${SEARCH_PATHS.individualInformation}`
        );
      } else {
        this.navigationService.navigate(`${Flow.REGISTRATION}/${REGISTRATION_PATHS.registrationType}`);
      }
    } else {
      this._navigateToSnagError('Business registration is disabled.');
    }
  }

  _isRegisteredCustomer(): boolean {
    const customer: Customer = this.sessionService.get(SessionKey.CUSTOMER);
    if (customer) {
      return customer.guid != null && customer.accountAccess != null;
    } else {
      throw new Error('Customer session data does not exist.');
    }
  }

  _setupBusinessRegistrationSession(eligibilityResponse: PostEligibilityResponse) {
    this.sessionService.setModifiedSessionObject(
      (this.sessionService.get(SessionKey.SEARCH) as Search)?.agreementNumber,
      SessionKey.REGISTRATION,
      'agreementNumber'
    );
    this.sessionService.setModifiedSessionObject(
      eligibilityResponse.registrationEligibility.commercialController?.commercialOrganizations.map(
        commercialOrganization => commercialOrganization.name
      ),
      SessionKey.REGISTRATION,
      'businessNames'
    );
    this.sessionService.setModifiedSessionObject(
      (this.sessionService.get(SessionKey.CUSTOMER) as Customer)?.ecn,
      SessionKey.REGISTRATION,
      'agreementOwnerEcn'
    );
  }

  private _navigateToSnagError(error: any) {
    this.navigationService.navigateToError(this.className, new Error(error), ERROR_PATHS.snagError);
  }

  private _isEligibleForIndividual(eligibilityResponse: PostEligibilityResponse): boolean {
    return String(eligibilityResponse.registrationEligibility.individual.eligible) === Eligible.True;
  }

  private _isEligibleForCommercialController(eligibilityResponse: PostEligibilityResponse): boolean {
    return String(eligibilityResponse.registrationEligibility.commercialController?.eligible) === Eligible.True;
  }

  private get _trustLevel(): number {
    return parseInt((this._oauthService.getIdentityClaims() as any)['trust_level'], 10);
  }

  private _logEligibilityResults(eligibilityResponse: PostEligibilityResponse) {
    this.logger.info(this.className, 'Customer registration eligibility.', {
      partyType: eligibilityResponse.partyType,
      individual: eligibilityResponse.registrationEligibility.individual.eligible,
      commercialController: eligibilityResponse.registrationEligibility.commercialController?.eligible
    });
  }
}
