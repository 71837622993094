import { Component, Inject, Input, OnInit, TemplateRef } from '@angular/core';
import { ContentHeaderService } from '@shared/content-header/content-header.service';
import { CustomSessionService, CUSTOM_SESSION } from '@shared/storage/custom-session.service';
import { userAgent } from '@shared/user-agent/user-agent.util';

@Component({
  selector: 'ciam-error-container',
  templateUrl: './error-container.component.html',
  styleUrls: ['./error-container.component.scss']
})
export class ErrorContainerComponent implements OnInit {
  @Input() errorId: string;
  @Input() title?: string;
  @Input() message: TemplateRef<any>;
  @Input() content?: TemplateRef<any>;

  userAgent = userAgent;

  constructor(
    @Inject(CUSTOM_SESSION) private _sessionService: CustomSessionService,
    private _contentHeaderService: ContentHeaderService
  ) {}

  ngOnInit() {
    // Fully clear the session, as any flow the user was in has been hard-stopped.
    this._sessionService.clear();
    this._contentHeaderService.reset({ title: this.title });
  }
}
