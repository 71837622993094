import { Component } from '@angular/core';
import { AdaBaseComponent } from '@shared/base-component/ada-base.component';

@Component({
  selector: 'ciam-system-error',
  templateUrl: './system-error.component.html',
  styleUrls: ['./system-error.component.scss']
})
export class SystemErrorComponent extends AdaBaseComponent {
  protected readonly className = 'SystemErrorComponent';
}
