import { AfterViewInit, Component, Input, Renderer2, ViewChild } from '@angular/core';
import { ScriptParams } from '../ScriptParams.interface';
import { ScriptsLoaderService } from '../scripts-loader/scripts-loader.service';

@Component({
  selector: 'ciam-bundle-loader',
  templateUrl: './bundle-loader.component.html',
  styleUrls: ['./bundle-loader.component.scss']
})
export class BundleLoaderComponent implements AfterViewInit {
  @ViewChild('bundle') bundle: any;
  @Input()
  scriptsToLoad: ScriptParams[];
  @Input()
  cssToLoad: string[];
  loaded = false;

  constructor(private _renderer: Renderer2, private _scriptsLoaderService: ScriptsLoaderService) {}

  ngAfterViewInit(): void {
    if (!this.loaded) {
      if (this.scriptsToLoad) {
        this._scriptsLoaderService.loadScripts(this._renderer, this.bundle.nativeElement, this.scriptsToLoad);
      }
      if (this.cssToLoad) {
        this._scriptsLoaderService.loadStyles(this._renderer, this.bundle.nativeElement, this.cssToLoad);
      }
      if (!this.scriptsToLoad && !this.cssToLoad) {
        // eslint-disable-next-line no-console
      }
      this.loaded = true;
    }
  }
}
