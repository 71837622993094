import { ControlErrors, Controls } from '@shared/base-component/base.model';
import { CONTROL_ERROR_MESSAGES } from '@shared/validation/control-constants';

export const CONTROLS: Controls = {
  acceptedServiceAgreement: {
    id: 'controller-attestation-sa-checkbox',
    ariaDescribedByErrors: 'controller-attestation-sa-checkbox-errors'
  }
};

export const CONTROL_ERRORS: ControlErrors = {
  acceptedServiceAgreement: {
    required: CONTROL_ERROR_MESSAGES.acceptedServiceAgreementRequired
  }
};
