import { MfaStatus } from '@nationwide/api-client-internet-registration-v3';
import { AccountAccess } from '@shared/account-access/account-access.model';
import { ContentHeaderModel } from '@shared/content-header/content-header.model';

export enum CookieKey {
  CIAM_MOCK_ENABLED = 'CIAM_MOCK_ENABLED',
  CIAM_FLOW_ID = 'CIAM_FLOW_ID',
  CIAM_SESSION_ID = 'CIAM_SESSION_ID',
  CIAM_DEVICE_TOKEN = 'CIAM_DEVICE_TOKEN',
  DEVICE_TOKEN = 'DEVICE_TOKEN'
}

export enum SessionKey {
  PARTNER = 'partner',
  CUSTOMER = 'customer',
  AUTHORIZATION = 'authorization',
  SEARCH = 'search',
  MFA = 'mfa',
  REGISTRATION = 'registration',
  FORGOT_CREDENTIALS = 'forgotCredentials',
  ONE_TIME_LINK = 'oneTimeLink',
  PING_TOKEN = 'pingToken',
  TYPE = 'type',
  LOGIN_TYPE = 'loginType',
  PARTNER_SP_ID = 'partnerSpId'
}

export enum PartyType {
  PERSON = 'Person',
  ORGANIZATION = 'Organization'
}

export interface Partner {
  id?: string;
}

export interface Customer {
  firstName?: string;
  partyType?: PartyType;
  ecn?: string;
  guid?: string;
  userName?: string;
  accountAccess?: AccountAccess;
  mfaStatus?: MfaStatus;
}

export interface Authorization {
  contentHeader?: ContentHeaderModel;
}

export interface Search {
  firstName?: string;
  lastName?: string;
  agreementNumber?: string;
}

export interface Mfa {
  newPhoneFailures?: number;
  newMobileNumber?: string;
  selectedPostalAddress?: boolean;
  rememberDevice?: boolean;
}

export interface Registration {
  fromForgotCredentials?: boolean;
  agreementNumber?: string;
  agreementOwnerEcn?: string;
  businessNames?: string[];
  firstName?: string;
  lastName?: string;
}

export interface ForgotCredentials {
  fromRegistration?: boolean;
}

export interface OneTimeLink {
  usedOneTimeLink?: boolean;
  consumerId?: string;
  contextId?: string;
  trustLevel?: number;
}
