<ciam-error-container errorId="invalid-link" [message]="message" [content]="content"></ciam-error-container>

<ng-template #message>
  <div>We're sorry, the link is no longer valid.</div>
</ng-template>

<ng-template #content>
  <div class="content-section">
    <h1 id="main-content" class="h5 mb-3 no-outline">
      Let's try this again or give us a call
    </h1>
    <p>
      <bolt-button-primary ciamClickLogger _id="invalid-link-create" (click)="navigate(flowEnum.REGISTRATION)">
        Create an account
      </bolt-button-primary>
    </p>
    <p>
      <bolt-button-link
        ciamClickLogger
        _id="invalid-link-forgot"
        _class="p-0 m-0 d-inline align-baseline"
        (click)="navigate(flowEnum.FORGOT_CREDENTIALS)"
      >
        Forgot username/password
      </bolt-button-link>
    </p>
  </div>
</ng-template>
