import { Routes, mapToCanActivate } from '@angular/router';
import { CommonFlow } from '@shared/navigation/flow.enum';
import { TimeoutGuard } from '@shared/timeout/timeout.guard';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { ExpiredCodeComponent } from './expired-code/expired-code.component';
import { InvalidPhoneComponent } from './invalid-phone/invalid-phone.component';
import { MFA_PATHS } from './mfa-route-paths';
import { SendCodeComponent } from './send-code/send-code.component';
import { UndeliverableCodeComponent } from './undeliverable-code/undeliverable-code.component';
import { VerifyCodeComponent } from './verify-code/verify-code.component';

export const MFA_ROUTES: Routes = [
  {
    path: CommonFlow.MFA,
    canActivate: mapToCanActivate([TimeoutGuard]),
    children: [
      { path: MFA_PATHS.undeliverableCode, component: UndeliverableCodeComponent },
      { path: MFA_PATHS.sendCode, component: SendCodeComponent },
      { path: MFA_PATHS.invalidPhone, component: InvalidPhoneComponent },
      { path: MFA_PATHS.verifyCode, component: VerifyCodeComponent },
      { path: MFA_PATHS.expiredCode, component: ExpiredCodeComponent },
      { path: MFA_PATHS.confirmation, component: ConfirmationComponent }
    ]
  }
];
