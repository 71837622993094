import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { AppInjector } from '@app/app-injector';
import { AuthMethod, DestinationType } from '@app/authorization/shared/authorization.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AdaBaseComponent } from '@shared/base-component/ada-base.component';
import { ControlErrors, Controls } from '@shared/base-component/base.model';
import { ContentHeaderModel } from '@shared/content-header/content-header.model';
import { ContentHeaderService } from '@shared/content-header/content-header.service';
import { NavigationService } from '@shared/navigation/navigation.service';
import { CustomSessionService, CUSTOM_SESSION } from '@shared/storage/custom-session.service';
import { filter } from 'rxjs/operators';
import { CONTROLS, CONTROL_ERRORS } from './activation-key.model';

@UntilDestroy()
@Component({
  selector: 'activation-key',
  templateUrl: './activation-key.component.html',
  styleUrls: ['./activation-key.component.scss']
})
export class ActivationKeyComponent extends AdaBaseComponent implements OnInit {
  @ViewChild('activationFormParent', { static: true })
  activationFormParent: NgForm;
  activationForm: UntypedFormGroup;
  activationControls: Controls = CONTROLS;
  activationControlErrors: ControlErrors = CONTROL_ERRORS;
  activationKey = '';
  protected readonly className = 'ActivationKeyComponent';
  protected contentHeaderService: ContentHeaderService;
  protected sessionService: CustomSessionService;

  constructor(
    private _contentHeaderService: ContentHeaderService,
    private _navigationService: NavigationService,
    private _formBuilder: UntypedFormBuilder
  ) {
    super();
    this.sessionService = AppInjector.injector.get(CUSTOM_SESSION) as CustomSessionService;
  }

  get contentHeaderModel(): ContentHeaderModel {
    return {
      title: 'Sign up for online access'
    };
  }

  ngOnInit() {
    this._contentHeaderService.updateProgress(this.contentHeaderModel);

    if (this.sessionService.has('activationKey')) {
      this.activationKey = this.sessionService.get('activationKey');
      this.sessionService.remove('activationKey');
    }
    this.initializeForm();
  }

  initializeForm() {
    this.activationForm = this._formBuilder.group(
      {
        activation: [this.activationKey ? this.activationKey : '', [Validators.required]]
      },
      {
        updateOn: 'submit'
      }
    );
    this.activationForm.statusChanges
      .pipe(
        untilDestroyed(this),
        filter(() => this.activationForm.valid)
      )
      .subscribe(() => {
        this._submit();
      });
  }

  activationInputValidation(event: KeyboardEvent) {
    const inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      return false;
    }
  }

  _submit() {
    this.sessionService.set('activationKey', this.activationForm.get('activation').value);
    this._navigationService.navigateToAuthorization(
      AuthMethod.CONTEXT_CACHE,
      {
        contextId: this.sessionService.get('contextIdL2'),
        otp: this.sessionService.get('otpL2'),
        activationKey: this.activationForm.get('activation').value
      },
      DestinationType.NON_INSTANT_ACTIVATION_SUCCESS
    );
  }

  get errorMessage(): string {
    if (this.activationFormParent.submitted && this.activationFormParent.invalid) {
      return 'Please correct the following to continue.';
    } else if (this.sessionService.has('maximumAttempts')) {
      return "Activation Key doesn't match our records.";
    } else return null;
  }

  protected get formGroup(): UntypedFormGroup {
    return this.activationForm;
  }

  protected get controls(): Controls {
    return this.activationControls;
  }

  protected get controlErrors(): ControlErrors {
    return this.activationControlErrors;
  }
}
