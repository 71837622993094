export const MFA_PATHS: {
  undeliverableCode: string;
  sendCode: string;
  invalidPhone: string;
  verifyCode: string;
  expiredCode: string;
  confirmation: string;
} = {
  undeliverableCode: 'undeliverable-code',
  sendCode: 'send-code',
  invalidPhone: 'invalid-phone',
  verifyCode: 'verify-code',
  expiredCode: 'expired-code',
  confirmation: 'confirmation'
};
