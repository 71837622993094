export const ERROR_PATHS: {
  accessLocked: string;
  onlineAccessError: string;
  snagError: string;
  systemError: string;
  accountIssue: string;
} = {
  accessLocked: 'access-locked',
  onlineAccessError: 'online-access-error',
  snagError: 'snag-error',
  systemError: 'system-error',
  accountIssue: 'account-issue'
};
