import { NgModule } from '@angular/core';
import { RouterModule, Routes, mapToCanActivateChild } from '@angular/router';
import { Flow } from '@shared/navigation/flow.enum';
import { NavigationGuard } from '@shared/navigation/navigation.guard';
import { ActivationKeyComponent } from './activation-key/activation-key.component';
import { NON_INSTANT_ACTIVATION_PATHS } from './non-instant-activation-paths';
import { UpdateAccountComponent } from './update-account/update-account.component';

// TODO: This would be better implemented as a RegistrationFlowType, so that it is considered a "proper" registration
// flow and inheritance of some common registration functionality and components makes sense.
//
// e.g. /registration/non-instant-activation/<proxy-paths>

export const NON_INSTANT_ACTIVATION_ROUTES: Routes = [
  {
    path: Flow.NON_INSTANT_ACTIVATION,
    canActivateChild: mapToCanActivateChild([NavigationGuard]),
    children: [
      {
        path: 'verify',
        children: [{ path: NON_INSTANT_ACTIVATION_PATHS.activationKey, component: ActivationKeyComponent }]
      },
      { path: NON_INSTANT_ACTIVATION_PATHS.updateAccount, component: UpdateAccountComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(NON_INSTANT_ACTIVATION_ROUTES)],
  exports: [RouterModule]
})
export class NonInstantActivationRoutingModule {}
