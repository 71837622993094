import { Component, Inject, Input } from '@angular/core';
import { CustomSessionService, CUSTOM_SESSION } from '@shared/storage/custom-session.service';
import { Registration, SessionKey } from '@shared/storage/storage.model';

@Component({
  selector: 'ciam-business-names',
  templateUrl: './business-names.component.html',
  styleUrls: ['./business-names.component.scss']
})
export class BusinessNamesComponent {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('_id') id: string;

  constructor(@Inject(CUSTOM_SESSION) private _sessionService: CustomSessionService) {}

  get businessNames(): string[] {
    return (this._sessionService.get(SessionKey.REGISTRATION) as Registration)?.businessNames;
  }
}
