import { Location } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { AppInjector } from '@app/app-injector';
import { ERROR_PATHS } from '@app/error/error-paths';
import { ENVIRONMENT } from '@environments/environment';
import { NavigationService } from '@shared/navigation/navigation.service';
import { CustomSessionService, CUSTOM_SESSION } from '@shared/storage/custom-session.service';
import { SessionKey } from '@shared/storage/storage.model';
import { Observable, of } from 'rxjs';
import { ScriptParams } from '../ScriptParams.interface';

@Component({
  selector: 'ciam-preference-center-widget',
  templateUrl: './preference-center-widget.component.html',
  styleUrls: ['./preference-center-widget.component.scss']
})
export class PreferenceCenterWidgetComponent implements OnInit, AfterViewInit {
  pcWidgetScripts: ScriptParams[];
  pcWidgetUrl: string = ENVIRONMENT.preferenceCenter.basePath;
  pcWidgetMainScriptPath = `${this.pcWidgetUrl}main.js`;
  pcWidgetRuntimeScriptPath = `${this.pcWidgetUrl}runtime.js`;
  pcWidgetPolyfillSD = `${this.pcWidgetUrl}polyfills/webcomponentsjs/bundles/webcomponents-sd.js`;
  pcWidgetCSSscriptPath = `${this.pcWidgetUrl}styles.css`;
  ecn: string;
  appConfig$: Observable<any>;
  protected sessionService: CustomSessionService;
  protected readonly className = 'PreferenceCenterWidgetComponent';

  constructor(
    private _navigationService: NavigationService,
    private _elementRef: ElementRef,
    private _location: Location
  ) {
    this.pcWidgetScripts = [
      { path: this.pcWidgetMainScriptPath },
      { path: this.pcWidgetRuntimeScriptPath },
      { path: this.pcWidgetPolyfillSD }
    ];
    this.sessionService = AppInjector.injector.get(CUSTOM_SESSION) as CustomSessionService;
  }

  ngOnInit(): void {
    this.ecn = this.sessionService.get(SessionKey.CUSTOMER)?.ecn;
    let canShowRadioOptions = true;
    if (this.sessionService.get(SessionKey.LOGIN_TYPE) === 'commercial') {
      canShowRadioOptions = false;
    }
    if (this.ecn) {
      this.setAppConfig(this.ecn, canShowRadioOptions);
    } else {
      this._navigationService.navigateToError(
        this.className,
        new Error(),
        ERROR_PATHS.systemError,
        'ECN not available for Preference Center loading'
      );
    }
  }

  ngAfterViewInit() {
    const url = this._location.path();
    let paramValue;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get('pcversion');
    }

    if (paramValue === 'y') {
      this._elementRef.nativeElement
        .querySelector('preference-center')
        ?.addEventListener('outputEvent', this.addEventListenerPrefCenterVersion);
    } else {
      this._elementRef.nativeElement
        .querySelector('preference-center')
        ?.addEventListener('outputEvent', this.addEventListenerPrefCenter);
    }
  }

  addEventListenerPrefCenter(evt: any) {
    const eventName = evt['detail'].eventName;
    const success = evt['detail'].eventObj.success;
    const action = evt['detail'].eventObj.action;
    if (eventName === 'preferenceCenterConfirmation') {
      document.getElementById('prefSpinnerContainer').setAttribute('style', 'display : none');
    }

    if (!success) {
      if (action !== 'save') {
        document.getElementById('preferenceCenter').setAttribute('style', 'display : none');
        document.getElementById('set-preferences-error').setAttribute('style', 'display : block');
      }
    }
  }

  addEventListenerPrefCenterVersion(evt: any) {
    const eventName = evt['detail'].eventName;
    if (eventName === 'preferenceCenterConfirmation') {
      document.getElementById('prefSpinnerContainer').setAttribute('style', 'display : none');
    }
  }

  private setAppConfig(customerEcn: string, canShowRadioOptions: boolean) {
    this.appConfig$ = of({
      rootElementId: 'preference-container',
      consumer: {
        consumerId: 'CIAM',
        authRealm: 'member'
      },
      customer: {
        ecn: customerEcn
      },
      features: {
        showPleaseWaitSpinner: false,
        showConfirmationMessages: true,
        showCallToActionMessages: true,
        showCSRFooter: false,
        showEDDAFooter: true,
        enableEmailSelection: true,
        enableEDDASelection: true,
        enableGoPaperless: true,
        enableMarketingEmailUnsubscribe: false,
        enableRetrieveErrorRedirect: false,
        showRadioOptions: canShowRadioOptions
      },
      components: {
        niPreferences: {
          enabled: true,
          accordion: {
            expanded: false,
            collapsible: true
          },
          features: {
            showSaveButton: true,
            showCancelButton: true,
            showIneligibleBillingAccounts: false,
            showEligiblePolicies: false,
            showUsageBasedInsTextPref: false
          }
        },
        nfPreferences: {
          enabled: true,
          accordion: {
            expanded: false,
            collapsible: true
          },
          features: {
            showSaveButton: true,
            showCancelButton: true
          }
        },
        commercialPreferences: {
          enabled: true,
          accordion: {
            expanded: false,
            collapsible: true
          },
          features: {
            showSaveButton: true,
            showCancelButton: true,
            showEligiblePolicies: false,
            showIneligiblePolicies: true
          }
        },
        marketingPreferences: {
          enabled: true,
          accordion: {
            expanded: false,
            collapsible: true
          },
          features: {
            showSaveButton: true,
            showCancelButton: true,
            showUnsubscribeAll: false,
            enableUnsubAllRedirect: false
          }
        }
      }
    });
  }
}
