export const PROXY_PATHS: {
  personalInformation: string;
  additionalIdentifier: string;
  updateUsernamePassword: string;
  startOver: string;
} = {
  updateUsernamePassword: 'update-username-password',
  personalInformation: 'personal-information',
  additionalIdentifier: 'additional-identifier',
  startOver: 'start-over'
};
