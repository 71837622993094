<ciam-error-container
  errorId="online-access-error"
  title="Online access failed"
  [message]="message"
></ciam-error-container>

<ng-template #message>
  <div>
    An error occurred while processing your request to create an online account. Please try again later or call
    <span class="text-nowrap"><ciam-phone-number key="WEB_SUPPORT"></ciam-phone-number>.</span>
  </div>
</ng-template>
