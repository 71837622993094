import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, NgForm, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { REGISTRATION_PATHS } from '@app/registration/registration-paths';
import {
  Code as AccountVerificationCode,
  AccountVerifyRequest,
  AccountVerifyResponse
} from '@nationwide/api-client-bank-account-verification-v1';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AdaBaseComponent } from '@shared/base-component/ada-base.component';
import { ControlErrors, Controls } from '@shared/base-component/base.model';
import { ContentHeaderService } from '@shared/content-header/content-header.service';
import { CommonFlow, Flow } from '@shared/navigation/flow.enum';
import { NavigationService } from '@shared/navigation/navigation.service';
import { PersonalBillingService } from '@shared/personal-billing/personal-billing.service';
import { validateAccountNumberMatch } from '@shared/validation/validate-account-number-match';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DigitalRefundsService } from '../digital-refunds-service/digital-refunds.service';
import {
  ACCEPTABLE_RESPONSE_CODES,
  Account_Recommendation,
  CONTROLS,
  CONTROL_ERRORS,
  STATES,
  ShadowDomIndexToFormInput,
  WRONG_ACCOUNT_TYPE_CODES
} from './digital-refunds.model';

declare global {
  interface Window {
    nwcsasn: '';
    nwcsaprodsn: '';
  }
}

@UntilDestroy()
@Component({
  selector: 'ciam-digital-refunds',
  templateUrl: './digital-refunds.component.html',
  styleUrls: ['./digital-refunds.component.scss']
})
export class DigitalRefundsComponent extends AdaBaseComponent implements OnInit, AfterViewInit {
  @ViewChild('digitalRefundsFormParent', { static: true })
  digitalRefundsFormParent: NgForm;
  digitalRefundsForm: UntypedFormGroup;
  digitalRefundsControls: Controls = CONTROLS;
  digitalRefundsControlErrors: ControlErrors = CONTROL_ERRORS;
  digitalRefundsStates = STATES;
  protected readonly className = 'DigitalRefundsComponent';
  private _errorMessage: string;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _contentHeaderService: ContentHeaderService,
    private _digitalRefundsService: DigitalRefundsService,
    private _billingService: PersonalBillingService,
    private _navigationService: NavigationService,
    private _oauthService: OAuthService
  ) {
    super();
  }

  ngOnInit(): void {
    this.logger.info(this.className, 'Registering for digital refunds');
    this._contentHeaderService.updateProgress({
      title: 'Sign up for online access',
      progressTitle: 'Set preferences',
      progressPercent: 95
    });
    this._initializeForm();
  }

  ngAfterViewInit(): void {
    this.addEventHandlersToForm();
    this.updateProgressBar();
  }

  private _initializeForm() {
    this.digitalRefundsForm = this._formBuilder.group(
      {
        accountType: ['', [Validators.required]],
        routingNumber: [
          '',
          [Validators.required, ...this._buildPatternValidators(this.digitalRefundsControls?.routingNumber?.patterns)]
        ],
        accountNumber: [
          '',
          [Validators.required, ...this._buildPatternValidators(this.digitalRefundsControls?.accountNumber?.patterns)]
        ],
        confirmAccountNumber: [
          '',
          [
            Validators.required,
            ...this._buildPatternValidators(this.digitalRefundsControls?.confirmAccountNumber?.patterns)
          ]
        ],
        firstName: [
          '',
          [Validators.required, ...this._buildPatternValidators(this.digitalRefundsControls?.firstName?.patterns)]
        ],
        middleName: ['', [...this._buildPatternValidators(this.digitalRefundsControls?.middleName?.patterns)]],
        lastName: ['', [...this._buildPatternValidators(this.digitalRefundsControls?.lastName?.patterns)]],
        streetAddress: [
          '',
          [Validators.required, ...this._buildPatternValidators(this.digitalRefundsControls?.streetAddress?.patterns)]
        ],
        apartmentNumber: ['', []],
        cityName: [
          '',
          [Validators.required, ...this._buildPatternValidators(this.digitalRefundsControls?.cityName?.patterns)]
        ],
        stateSelection: ['', [Validators.required]],
        zipCode: [
          '',
          [Validators.required, ...this._buildPatternValidators(this.digitalRefundsControls?.zipCode?.patterns)]
        ]
      },
      {
        updateOn: 'change',
        validators: [validateAccountNumberMatch]
      }
    );

    this.digitalRefundsForm.statusChanges.pipe(
      untilDestroyed(this),
      filter(() => !this._isFormError())
    );
  }

  addEventHandlersToForm(): void {
    const formTextfields = document.getElementsByTagName('bolt-textfield');
    for (let id = 0; id < formTextfields.length; id++) {
      const shadowDomBoltElement = formTextfields[id]?.shadowRoot?.querySelector(
        '.bolt-textfield-wc--input'
      ) as HTMLInputElement;
      const oldBoltElementName = ShadowDomIndexToFormInput[id];
      shadowDomBoltElement?.addEventListener('change', (event: any) => {
        this.digitalRefundsForm.patchValue({ [`${oldBoltElementName}`]: event.target.value });
      });
    }
  }

  updateProgressBar() {
    const progressBar = document
      .querySelector('bolt-progress-bar')
      ?.shadowRoot?.querySelector('.bolt-progress-bar-wc--fill') as HTMLElement;
    if (progressBar) {
      progressBar.style.width = '95%';
    }
  }

  _skipFormClick() {
    this._navigationService.navigate(`${Flow.REGISTRATION}/${REGISTRATION_PATHS.registerSuccess}`);
  }

  _submit() {
    if (this.isFormErrorOnSubmit()) {
      window.scrollTo(0, 0);
      setTimeout(this._removeDuplicateFormErrors, 300);
      return;
    }
    this._verifyBankAccount().subscribe(
      (response: HttpResponse<AccountVerifyResponse>) => {
        this.logger.info(this.className, 'Bank Account Verification API call success');
        const recommendationFromCode = this._getRecommendationFromCode(response.body.Verification.Code);
        this._handleBankAccountRecommendation(recommendationFromCode, response?.body?.Details?.BankName);
      },
      (error: HttpErrorResponse) => {
        this.logger.error(
          this.className,
          'Celebrus Session Number: ' + window.nwcsaprodsn,
          'VerifyBankAccount API call error:' + JSON.stringify(error)
        );
        this.errorMessage =
          'Bank account cannot be validated. Please check your information and try again, or enter information for a different bank account.';
        window.scrollTo(0, 0);
      }
    );
    setTimeout(this._removeDuplicateFormErrors, 300);
  }

  _verifyBankAccount() {
    const accountToVerify: AccountVerifyRequest = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      RoutingNumber: this.digitalRefundsForm.get('routingNumber').value,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      AccountNumber: this.digitalRefundsForm.get('accountNumber').value,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      AccountType: this.digitalRefundsForm.get('accountType').value
    };
    return this._digitalRefundsService.verifyBankAccount(accountToVerify);
  }

  _getRecommendationFromCode(code: AccountVerificationCode): Account_Recommendation {
    this.logger.info(this.className, 'VerifyBankAccount Code: ' + code);
    if (ACCEPTABLE_RESPONSE_CODES.includes(code)) {
      return Account_Recommendation.Accept;
    } else if (WRONG_ACCOUNT_TYPE_CODES.includes(code)) {
      return Account_Recommendation.Wrong_Account_Type;
    } else {
      return Account_Recommendation.Decline;
    }
  }

  _handleBankAccountRecommendation(recommendation: Account_Recommendation, bankName: string): void {
    if (recommendation === Account_Recommendation.Accept) {
      this.errorMessage = '';
      this.addRefundMethod(bankName).subscribe(
        () => {
          this.logger.info(this.className, 'add refund method API call success');
        },
        (error?: HttpErrorResponse) => {
          this.logger.error(this.className, 'add refund method API call error:' + JSON.stringify(error));
        }
      );
      this._navigationService.navigate(`${Flow.REGISTRATION}/${REGISTRATION_PATHS.registerSuccess}`);
    } else if (recommendation === Account_Recommendation.Wrong_Account_Type) {
      this.errorMessage = 'Please check your account type';
      window.scrollTo(0, 0);
    } else {
      this.errorMessage =
        'Bank account cannot be validated. Please check your information and try again, or enter information for a different bank account.';
      window.scrollTo(0, 0);
    }
  }

  _removeDuplicateFormErrors(): void {
    const formErrors = document.getElementsByTagName('bolt-field-error');
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < formErrors.length; i++) {
      const duplicateFormErrorElement = formErrors[i]?.shadowRoot?.querySelector('#icon-wrapper') as HTMLInputElement;
      duplicateFormErrorElement.style.display = 'none';
    }
  }

  onStateChange($evt: any): void {
    const newVal = $evt?.target?.value;
    this.state.patchValue(newVal);
    setTimeout(() => {
      this.state.updateValueAndValidity();
    });
  }

  getBillingNumber(): any {
    const token = JSON.parse(sessionStorage.getItem('id_token_claims_obj'));
    // this does need to loop - there can be multiple agreements, but there should only be one billing agreement
    for (const agreement of token.agreements) {
      if (agreement.product_type === 'Billing') {
        return agreement.agreement_number;
      }
    }
  }

  addRefundMethod(bankName: string): Observable<any> {
    const billingNumber = this.getBillingNumber();
    const url = this._billingService.setBillingUrl(billingNumber, 'refundMethod');
    const requestBody = {
      requestType: 'RefundMethodChange.PayPlanUpdate',
      action: 'add',
      refundMethod: {
        paymentMethodType: 'ElectronicFundsTransfer.PaymentMethod',
        electronicFundsTransfer: {
          bankName,
          bankAccountType: this.digitalRefundsForm.get('accountType').value,
          bankRoutingNumber: this.digitalRefundsForm.get('routingNumber').value,
          bankAccountNumber: this.digitalRefundsForm.get('accountNumber').value
        },
        payorInfo: {
          firstName: this.digitalRefundsForm.get('firstName').value,
          middleName: this.digitalRefundsForm.get('middleName').value,
          lastName: this.digitalRefundsForm.get('lastName').value,
          addressLine1: this.digitalRefundsForm.get('streetAddress').value,
          addressLine2: this.digitalRefundsForm.get('apartmentNumber').value,
          city: this.digitalRefundsForm.get('cityName').value,
          state: this.digitalRefundsForm.get('stateSelection').value,
          postalCode: this.digitalRefundsForm.get('zipCode').value
        }
      }
    };
    const token = this._oauthService.getAccessToken();
    return this._billingService.patchRefundMethod(url, requestBody, token);
  }

  _isFormError(): boolean {
    if (
      this.digitalRefundsFormParent &&
      this.digitalRefundsFormParent.invalid &&
      this.digitalRefundsFormParent.submitted
    ) {
      this._errorMessage = 'Please correct the following to continue.';
      return true;
    }
    return false;
  }

  isFormErrorOnSubmit(): boolean {
    if (this.digitalRefundsFormParent?.invalid) {
      this._errorMessage = 'Please correct the following to continue.';
      return true;
    }
    return false;
  }

  protected get formGroup(): UntypedFormGroup {
    return this.digitalRefundsForm;
  }

  protected get controls(): Controls {
    return this.digitalRefundsControls;
  }

  protected get controlErrors(): ControlErrors {
    return this.digitalRefundsControlErrors;
  }

  get state(): AbstractControl {
    return this.formGroup.get('stateSelection');
  }

  get errorMessage(): string {
    return this._errorMessage;
  }

  set errorMessage(message: string) {
    this._errorMessage = message;
  }

  get commonFlow(): CommonFlow {
    return CommonFlow.PREFERENCES;
  }
}
