import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm } from '@angular/forms';
import { SEARCH_PATHS } from '@common/search/search-paths';
import { ENVIRONMENT } from '@environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ControlErrors, Controls } from '@shared/base-component/base.model';
import { PartyType } from '@shared/storage/storage.model';
import { filter } from 'rxjs/operators';
import { IdentifierType, SearchType } from '../../shared/search.model';
import { IdentifierBaseComponent } from '../identifier-base.component';
import { CONTROLS, CONTROL_ERRORS } from './personal-identifier.model';
import { validatePersonalIdentifier } from './validate-personal-identifier';

@UntilDestroy()
@Component({
  selector: 'ciam-personal-identifier',
  templateUrl: './personal-identifier.component.html',
  styleUrls: ['./personal-identifier.component.scss']
})
export class PersonalIdentifierComponent extends IdentifierBaseComponent implements OnInit {
  @ViewChild('identifierFormParent', { static: true }) identifierFormParent: NgForm;
  identifierForm: UntypedFormGroup;
  identifierControls: Controls = CONTROLS;
  identifierControlErrors: ControlErrors = CONTROL_ERRORS;

  submitted = false;
  nonSingleMatch = false;
  searchAttempts = {
    [IdentifierType.SOCIAL]: 0,
    [IdentifierType.ACCOUNT]: 0
  };

  identifierTypes = IdentifierType;

  protected readonly className = 'PersonalIdentifierComponent';

  constructor(private _formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit() {
    this.logger.info(this.className, 'Entering identifier information.', {
      previousSearch: this.searchService.model?.previousSearch
    });

    this._initializeForm();
  }

  _handleTypeSelection(identifierType: IdentifierType) {
    this.identifierFormParent.resetForm();
    this.identifierForm.get('identifierType').setValue(identifierType);
  }

  _findAccount() {
    let searchCriteria;
    switch (this.identifierType) {
      case IdentifierType.ACCOUNT:
        searchCriteria = { input: { agreementNumber: this.identifierForm.get(this.identifierType).value } };
        break;
      case IdentifierType.SOCIAL:
        searchCriteria = {
          input: { taxIdentifier: this.identifierForm.get(this.identifierType).value },
          filters: { partyType: PartyType.PERSON }
        };
        break;
      default:
        throw new Error(`Cannot perform search.  ${this.identifierType} is not a valid identifier type.`);
    }
    this._performSearch(searchCriteria, null, this.identifierType);
  }

  _handleUnresolvablePotentialMatch() {
    this.searchAttempts[this.identifierType]++;

    if (this.hasMaxTotalSearchAttempts) {
      this.logger.warn(this.className, 'Identifier search failed max number of attempts.');
      this.navigationService.navigate(`${this.baseRoute}/${SEARCH_PATHS.startOver}`);
    } else {
      if (this.hasMaxSocialSearchAttempts && this.identifierType !== IdentifierType.ACCOUNT) {
        this.logger.warn(
          this.className,
          'Identifier social search failed max number of attempts; forcing account option.'
        );
        this._handleTypeSelection(IdentifierType.ACCOUNT);
      } else if (this.hasMaxAccountSearchAttempts && this.identifierType !== IdentifierType.SOCIAL) {
        this.logger.warn(
          this.className,
          'Identifier account search failed max number of attempts; forcing social option.'
        );
        this._handleTypeSelection(IdentifierType.SOCIAL);
      }

      this.nonSingleMatch = true;
    }

    this.spinnerService.hide();
  }

  _handleNoMatch() {
    this._handleUnresolvablePotentialMatch();
  }

  private _initializeForm() {
    this.identifierForm = this._formBuilder.group(
      {
        identifierType: IdentifierType.SOCIAL,
        [IdentifierType.SOCIAL]: [''],
        [IdentifierType.ACCOUNT]: ['']
      },
      {
        validators: validatePersonalIdentifier,
        updateOn: 'submit'
      }
    );

    this.identifierForm.statusChanges
      .pipe(
        untilDestroyed(this),
        filter(() => this.identifierForm.valid)
      )
      .subscribe(() => {
        if (
          this.identifierForm.get(IdentifierType.SOCIAL).value ||
          this.identifierForm.get(IdentifierType.ACCOUNT).value
        ) {
          this._findAccount();
        }
      });
  }

  get identifierType(): IdentifierType {
    return this.identifierForm.get('identifierType').value;
  }

  get hasMaxSocialSearchAttempts(): boolean {
    return (
      this.searchAttempts[IdentifierType.SOCIAL] >=
      ENVIRONMENT.features.search.maxAttempts.identifier[IdentifierType.SOCIAL]
    );
  }

  get hasMaxAccountSearchAttempts(): boolean {
    return (
      this.searchAttempts[IdentifierType.ACCOUNT] >=
      ENVIRONMENT.features.search.maxAttempts.identifier[IdentifierType.ACCOUNT]
    );
  }

  get hasMaxTotalSearchAttempts(): boolean {
    return this.hasMaxSocialSearchAttempts && this.hasMaxAccountSearchAttempts;
  }

  get searchType(): SearchType {
    return SearchType.PERSONAL_IDENTIFIER;
  }

  protected get formGroup(): UntypedFormGroup {
    return this.identifierForm;
  }

  protected get controls(): Controls {
    return this.identifierControls;
  }

  protected get controlErrors(): ControlErrors {
    return this.identifierControlErrors;
  }
}
