import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ERROR_PATHS } from '@app/error/error-paths';
import { ENVIRONMENT } from '@environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ControlErrors, Controls } from '@shared/base-component/base.model';
import { ContentHeaderModel } from '@shared/content-header/content-header.model';
import { ContentHeaderService } from '@shared/content-header/content-header.service';
import {
  DynamicChecklistItem,
  PASSWORD_DYNAMIC_CHECKLIST,
  USERNAME_DYNAMIC_CHECKLIST
} from '@shared/dynamic-checklist/dynamic-checklist-item.model';
import { NavigationService } from '@shared/navigation/navigation.service';
import { CustomCookieService } from '@shared/storage/custom-cookie.service';
import { Customer, PartyType, Registration, SessionKey } from '@shared/storage/storage.model';
import { validatePasswordsMatch } from '@shared/validation/validate-passwords-match';
import { validateUsernamePasswordDoNotMatch } from '@shared/validation/validate-username-password-not-match';
import { filter } from 'rxjs/operators';
import { REGISTRATION_PATHS } from '../registration-paths';
import { RegistrationBaseComponent } from '../shared/registration-base.component';
import { CreateBusinessAccountModel, RegistrationFlowType } from '../shared/registration.model';
import { RegistrationService } from '../shared/registration.service';
import { CONTROLS, CONTROL_ERRORS } from './create-login.model';

@UntilDestroy()
@Component({
  selector: 'ciam-create-login',
  templateUrl: './create-login.component.html',
  styleUrls: ['./create-login.component.scss']
})
export class CreateLoginComponent extends RegistrationBaseComponent implements OnInit {
  createLoginForm: UntypedFormGroup;
  createLoginControls: Controls = CONTROLS;
  createLoginControlErrors: ControlErrors = CONTROL_ERRORS;

  currentUsernameInput = '';
  usernameDynamicChecklist: Array<DynamicChecklistItem> = USERNAME_DYNAMIC_CHECKLIST;

  currentPasswordInput = '';
  passwordDynamicChecklist: Array<DynamicChecklistItem> = PASSWORD_DYNAMIC_CHECKLIST;

  protected readonly className = 'CreateLoginComponent';

  private readonly _spinnerMessage = 'Registering your online account...';

  constructor(
    private _contentHeaderService: ContentHeaderService,
    private _registrationService: RegistrationService,
    private _navigationService: NavigationService,
    private _customCookieService: CustomCookieService,
    private _formBuilder: UntypedFormBuilder
  ) {
    super();
  }

  ngOnInit() {
    this.logger.info(this.className, 'Entering account information.');

    this._contentHeaderService.reset(this.contentHeaderModel);
    this._registrationService.reset();

    this._initializeForm();
  }

  handleShowHideClick(event: Event) {
    this.logger.logElementClick(this.className, event);
  }

  trimWhiteSpace(event: any) {
    this.createLoginForm.get('emailAddress').setValue(event.target.value.trim());
  }

  _submit() {
    this.spinnerService.show(this._spinnerMessage);

    if (this.isPersonalRegistration) {
      this.createPersonalAccount();
    } else {
      this.createBusinessAccount();
    }
  }

  private createPersonalAccount() {
    this.logger.info(this.className, 'Attempting to create account.');

    this._registrationService
      .createAccount({
        userName: this.createLoginForm.get('username').value,
        password: this.createLoginForm.get('password').value,
        phoneNumber: this.createLoginForm.get('phoneNumber').value,
        email: this.createLoginForm.get('emailAddress').value,
        acceptedEsa: this.createLoginForm.get('acceptedEsa').value
      })
      .pipe(untilDestroyed(this))
      .subscribe(
        response => {
          if (response.accountStatus === 'NON-INSTANT') {
            this.logger.info(this.className, 'Non-instant account created.');
            this._navigationService.navigate(`${this.baseRoute}/${REGISTRATION_PATHS.nonInstantSuccess}`);
          } else {
            this.logger.info(this.className, 'Instant account created.');
            this._routeToIamSuccessPage(response, RegistrationFlowType.PERSONAL);
          }
        },
        error => {
          if (error instanceof HttpErrorResponse && error.error?.code === 4215) {
            this.logger.warn(this.className, 'Username unavailable.');
            this.createLoginForm.get('username').setErrors({ usernameUnavailable: true });
            this.spinnerService.hide();
          } else {
            this.logger.error(this.className, 'Error occurred creating account.');
            this._navigationService.navigateToError(
              this.className,
              error,
              ERROR_PATHS.onlineAccessError,
              'RegistrationService createAccount failed.'
            );
          }
        }
      );
  }

  private createBusinessAccount() {
    this.logger.info(this.className, 'Attempting to create account controllership.');

    const registration = this.sessionService.get(SessionKey.REGISTRATION) as Registration;
    const requestModel: CreateBusinessAccountModel = {
      agreementNumber: registration?.agreementNumber,
      agreementOwnerEcn: registration?.agreementOwnerEcn,
      userName: this.createLoginForm.get('username').value,
      password: this.createLoginForm.get('password').value,
      phoneNumber: this.createLoginForm.get('phoneNumber').value,
      email: this.createLoginForm.get('emailAddress').value,
      acceptedEsa: this.createLoginForm.get('acceptedEsa').value,
      acceptedAttestation: true
    };

    const customer = this.sessionService.get(SessionKey.CUSTOMER) as Customer;
    if (customer.partyType === PartyType.ORGANIZATION) {
      const registrationSession = this.sessionService.get(SessionKey.REGISTRATION) as Registration;
      requestModel.controllerFirstName = registrationSession?.firstName;
      requestModel.controllerLastName = registrationSession?.lastName;
    } else {
      requestModel.controllerEcn = customer?.ecn;
    }

    this._registrationService
      .createBusinessAccount(requestModel)
      .pipe(untilDestroyed(this))
      .subscribe(
        response => {
          if (response.accountStatus === 'NON-INSTANT') {
            this.logger.info(this.className, 'Non-instant account controllership created.');
            this._navigationService.navigate(`${this.baseRoute}/${REGISTRATION_PATHS.nonInstantSuccess}`);
          } else {
            this.logger.info(this.className, 'Instant account controllership created.');
            this._routeToIamSuccessPage(response, RegistrationFlowType.BUSINESS);
          }
        },
        error => {
          if (error instanceof HttpErrorResponse && error.error?.code === 4215) {
            this.logger.warn(this.className, 'Username unavailable.');
            this.createLoginForm.get('username').setErrors({ usernameUnavailable: true });
            this.spinnerService.hide();
          } else {
            this.logger.error(this.className, 'Error occurred creating account controller.');
            this._navigationService.navigateToError(
              this.className,
              error,
              ERROR_PATHS.onlineAccessError,
              'RegistrationService createBusinessAccount failed.'
            );
          }
        }
      );
  }

  private _initializeForm() {
    this.createLoginForm = this._formBuilder.group(
      {
        username: [
          '',
          [Validators.required, ...this._buildPatternValidators(this.createLoginControls?.username?.patterns)]
        ],
        password: [
          '',
          [Validators.required, ...this._buildPatternValidators(this.createLoginControls?.password?.patterns)]
        ],
        passwordConfirmation: ['', [Validators.required]],
        phoneNumber: [
          this.sessionService.get(SessionKey.MFA)?.newMobileNumber || '',
          this._buildPatternValidators(this.createLoginControls?.phoneNumber?.patterns)
        ],
        emailAddress: [
          '',
          [
            this.isNonInstantRegistration ? Validators.nullValidator : Validators.required,
            ...this._buildPatternValidators(this.createLoginControls?.emailAddress?.patterns)
          ]
        ],
        acceptedEsa: ['', Validators.requiredTrue]
      },
      {
        updateOn: 'submit',
        validators: [validatePasswordsMatch, validateUsernamePasswordDoNotMatch]
      }
    );

    this.createLoginForm.statusChanges
      .pipe(
        untilDestroyed(this),
        filter(() => this.createLoginForm.valid)
      )
      .subscribe(() => {
        this._submit();
      });
  }

  private _routeToIamSuccessPage(response: any, registrationType: RegistrationFlowType) {
    if (response.device?.deviceToken) {
      this._customCookieService.deviceToken = response.device.deviceToken;
    }
    if (response.pingToken) {
      let url = `${ENVIRONMENT.externalUrls.iam.basePath}${ENVIRONMENT.externalUrls.iam.unprotectedLogin}?redirectURL=${ENVIRONMENT.externalUrls.iam.basePath}${ENVIRONMENT.externalUrls.iam.ciamRedirect}&pingToken=${response.pingToken}`;
      if (registrationType === RegistrationFlowType.BUSINESS) {
        url += '&loginType=commercial';
      }
      this._navigationService.navigateExternal(url);
    } else {
      this.sessionService.setModifiedSessionObject(
        this.createLoginForm.get('username').value,
        SessionKey.CUSTOMER,
        'userName'
      );
      this._navigationService.navigate(`${this.baseRoute}/${REGISTRATION_PATHS.instantSuccess}`);
    }
  }

  get contentHeaderModel(): ContentHeaderModel {
    return this.fromForgotCredentials
      ? {}
      : {
          title: 'Sign up for online access',
          progressTitle: 'Create login',
          progressPercent: 66
        };
  }

  get isPersonalRegistration(): boolean {
    return this.registrationFlowType === RegistrationFlowType.PERSONAL;
  }

  get isNonInstantRegistration(): boolean {
    return this.sessionService.get(SessionKey.MFA)?.selectedPostalAddress;
  }

  protected get formGroup(): UntypedFormGroup {
    return this.createLoginForm;
  }

  protected get controls(): Controls {
    return this.createLoginControls;
  }

  protected get controlErrors(): ControlErrors {
    return this.createLoginControlErrors;
  }
}
