import { HttpResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { PostSearchPotentialMatchResponse } from '@nationwide/api-client-internet-registration-v3';
import { SearchBaseComponent } from '../shared/search-base.component';
import { AdditionalInformationType, IdentifierType } from '../shared/search.model';

@Component({ template: '' })
export abstract class IdentifierBaseComponent extends SearchBaseComponent {
  readonly additionalInfoAttrs = [
    PostSearchPotentialMatchResponse.UniqueSearchesEnum.AddressNumber,
    PostSearchPotentialMatchResponse.UniqueSearchesEnum.DateOfBirth,
    PostSearchPotentialMatchResponse.UniqueSearchesEnum.Email,
    PostSearchPotentialMatchResponse.UniqueSearchesEnum.PhoneNumber
  ];

  readonly nameSearchAttrs = [
    PostSearchPotentialMatchResponse.UniqueSearchesEnum.FirstName,
    PostSearchPotentialMatchResponse.UniqueSearchesEnum.LastName,
    PostSearchPotentialMatchResponse.UniqueSearchesEnum.ZipCode
  ];

  _findAdditionalInfoType(response: HttpResponse<PostSearchPotentialMatchResponse>, identifierType: IdentifierType) {
    const requiredAttr =
      identifierType === IdentifierType.ACCOUNT
        ? PostSearchPotentialMatchResponse.UniqueSearchesEnum.AgreementNumber
        : PostSearchPotentialMatchResponse.UniqueSearchesEnum.TaxIdentifier;

    const uniqueSearchWithPreviousSearchAttr = response.body.uniqueSearches?.find(
      uniqueSearch =>
        uniqueSearch.includes(requiredAttr) &&
        uniqueSearch.every(uniqueSearchAttr => !this.nameSearchAttrs.includes(uniqueSearchAttr)) &&
        uniqueSearch.find(uniqueSearchAttr => this.additionalInfoAttrs.includes(uniqueSearchAttr))
    );

    let uniqueSearchAttribute = null;
    if (uniqueSearchWithPreviousSearchAttr) {
      uniqueSearchAttribute = uniqueSearchWithPreviousSearchAttr.find(attr => this.additionalInfoAttrs.includes(attr));
    }

    let additionalInformationType = null;
    if (uniqueSearchAttribute) {
      for (const type in AdditionalInformationType) {
        if (
          AdditionalInformationType[type as keyof typeof AdditionalInformationType] === uniqueSearchAttribute.valueOf()
        ) {
          additionalInformationType = AdditionalInformationType[type as keyof typeof AdditionalInformationType];
        }
      }
    }

    return additionalInformationType;
  }

  _handlePotentialMatch(response: HttpResponse<PostSearchPotentialMatchResponse>) {
    this.searchService.model.previousSearch = this.searchType;
    const additionalInformationType = this._findAdditionalInfoType(response, this.identifierType);
    this._logSearchOperation(this._potentialMatchResultType(response.body.message.code), additionalInformationType);
    if (additionalInformationType) {
      this.searchService.model.additionalInformationType = additionalInformationType;
      this._navigateToAdditionalInfo(additionalInformationType);
    } else {
      this._handleUnresolvablePotentialMatch();
    }
  }

  abstract _handleUnresolvablePotentialMatch(): void;

  abstract get identifierType(): IdentifierType;
}
