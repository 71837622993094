<bolt-modal
  _id="cancel-modal"
  [triggerElements]="triggers"
  [buttons]="[
    { label: 'Leave this page', primary: true },
    { label: 'Cancel', primary: false }
  ]"
  (buttonClicked)="handleCancelModalSelection($event)"
>
  <div id="cancel-modal-content">
    <p id="cancel-modal-header">
      <strong>{{ modalHeader }}</strong>
    </p>
    <p id="cancel-modal-body">{{ modalBody }}</p>
  </div>
</bolt-modal>
