export enum ContactPointType {
  EMAIL = 'email',
  NEW_PHONE = 'newPhone',
  TEXT = 'text',
  POSTAL_ADDRESS = 'postalAddress'
}

export interface ContactPoint {
  type: ContactPointType;
  id: string;
  value?: string;
}
