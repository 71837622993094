import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FORGOT_CREDENTIALS_PATHS } from '@app/forgot-credentials/forgot-credentials-paths';
import { CancelBodyMessages } from '@shared/cancel/cancel.model';
import { CancelService } from '@shared/cancel/cancel.service';
import { Flow } from '@shared/navigation/flow.enum';
import { SessionKey } from '@shared/storage/storage.model';
import { MfaBaseComponent } from '../shared/mfa-base.component';

@Component({
  selector: 'ciam-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent extends MfaBaseComponent implements OnInit {
  confirmationForm: UntypedFormGroup;

  protected readonly className = 'ConfirmationComponent';

  constructor(private _cancelService: CancelService, private _formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit() {
    this.logger.info(this.className, 'Confirming MFA enrollment.');

    this._cancelService.model = { modalBody: CancelBodyMessages.MFA };
    this.confirmationForm = this._formBuilder.group({ rememberCheckbox: [''] });
  }

  continue() {
    this.spinnerService.show();

    const rememberDevice = this.confirmationForm.get('rememberCheckbox').value || false;
    this.sessionService.setModifiedSessionObject(rememberDevice, SessionKey.MFA, 'rememberDevice');
    this.logger.info(this.className, 'Continued from confirmation.', {
      rememberDevice
    });

    switch (this.flow) {
      case Flow.REGISTRATION:
        this.continueToRegistration();
        break;
      case Flow.FORGOT_CREDENTIALS:
        this.navigationService.navigate(
          `${this.parentFlowBaseRoute}/${FORGOT_CREDENTIALS_PATHS.resetUsernamePassword}`
        );
        break;
      default:
        throw new Error(`Unexpected flow: ${this.flow}`);
    }
  }
}
