import { Component, OnInit } from '@angular/core';
import { ERROR_PATHS } from '@app/error/error-paths';
import { NON_INSTANT_ACTIVATION_PATHS } from '@app/non-instant-activation/non-instant-activation-paths';
import { ONE_TIME_LINK_PATHS } from '@app/one-time-link/one-time-link-paths';
import { ContentHeaderService } from '@shared/content-header/content-header.service';
import { Flow } from '@shared/navigation/flow.enum';
import { NavigationService } from '@shared/navigation/navigation.service';
import { OneTimeLink, SessionKey } from '@shared/storage/storage.model';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthorizationBaseComponent } from '../shared/authorization-base.component';
import {
  AuthMethod,
  Authorization,
  Destination,
  DestinationType,
  findAuthorization,
  findDestination
} from '../shared/authorization.model';

@Component({
  selector: 'ciam-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent extends AuthorizationBaseComponent implements OnInit {
  protected readonly className = 'CallbackComponent';

  constructor(
    private _contentHeaderService: ContentHeaderService,
    private _oauthService: OAuthService,
    private _navigationService: NavigationService
  ) {
    super();
  }

  ngOnInit(): Promise<void> {
    this.spinnerService.show();

    return this._oauthService
      .tryLogin({
        preventClearHashAfterLogin: true,
        onTokenReceived: receivedToken => {
          this._processState(receivedToken.state);
          this._contentHeaderService.reset(this.contentHeaderModel);
        }
      })
      .then(this._processOAuthResult.bind(this))
      .then(() => {
        this._processNonInstantActivationSuccess();
        this._navigationService.navigate(this.destination.route);
      })
      .catch(error => {
        this._processState(this._oauthService.state);
        if (this.destination?.type === DestinationType.NON_INSTANT_ACTIVATION_SUCCESS) {
          this._checkActivationKeyMaximumAttempts(error);
        } else {
          this._navigateToError(error);
        }
      });
  }

  _processState(stateStr: string) {
    let state;
    try {
      state = atob(decodeURIComponent(stateStr));
    } catch (error) {
      throw new Error('Could not parse state from OAuth callback.');
    }
    this.destination = findDestination(state);
  }

  _processOAuthResult(oauthSuccess: boolean) {
    if (oauthSuccess) {
      const idClaims: any = this._oauthService.getIdentityClaims();
      if (!idClaims) {
        throw new Error('No identity claims exist.');
      }

      const authorization = findAuthorization(idClaims['auth_method']);
      if (!authorization) {
        throw new Error('No auth method data found in identity claims.');
      }

      if (authorization.method === AuthMethod.CONTEXT_CACHE) {
        const trustLevel = this._findTrustLevelFromIdClaims(idClaims);

        if ((this.sessionService.get(SessionKey.ONE_TIME_LINK) as OneTimeLink)?.usedOneTimeLink) {
          this.sessionService.setModifiedSessionObject(
            idClaims.meta?.consumerId,
            SessionKey.ONE_TIME_LINK,
            'consumerId'
          );
          this.sessionService.setModifiedSessionObject(trustLevel, SessionKey.ONE_TIME_LINK, 'trustLevel');
          this.sessionService.setModifiedSessionObject(idClaims.meta?.firstName, SessionKey.CUSTOMER, 'firstName');
        }

        if (!this.destination || (this.destination.type === DestinationType.OTL_VERIFY && trustLevel === 2)) {
          this.destination = this._findDestinationFromIdClaims(idClaims);
        }
      }

      if (!this.destination) {
        throw new Error('Valid destination could not be derived from state or ID token.');
      }
      if (!this.destination.allowedAuthMethods.includes(authorization.method)) {
        throw new Error('Authorization is not valid for destination.');
      }

      this._processNonInstantActivation(idClaims);

      const ecn = this._findEcn(idClaims, authorization);
      if (!ecn) {
        throw new Error('No ECN data found in identity claims.');
      }

      this.sessionService.setModifiedSessionObject(ecn, SessionKey.CUSTOMER, 'ecn');
      this._storeUsername(idClaims, authorization);
    } else {
      this._navigateToError(new Error('OAuth attempt was unsuccessful.'));
    }
  }

  _findEcn(idClaims: any, authorization: Authorization): string {
    let ecn;

    const ecnData = authorization.data?.ecn;
    if (ecnData) {
      ecn = idClaims[ecnData.claim];
      if (ecn && ecnData.pattern) {
        const matches: string[] = ecn.match(ecnData.pattern);
        if (matches?.length > 1) {
          ecn = matches[1];
        } else {
          throw new Error('ECN data value does not match expected pattern.');
        }
      }
    }

    return ecn;
  }

  _findDestinationFromIdClaims(idClaims: any): Destination {
    return idClaims.meta?.destination ? findDestination(idClaims.meta.destination) : null;
  }

  _findTrustLevelFromIdClaims(idClaims: any): number {
    return idClaims.trust_level ? parseInt(idClaims.trust_level, 10) : null;
  }

  _processNonInstantActivation(idClaims: any) {
    if (this.destination?.type === DestinationType.NON_INSTANT_ACTIVATION_SUCCESS) {
      this.sessionService.setModifiedSessionObject(idClaims.meta.guid, SessionKey.CUSTOMER, 'guid');
      this.sessionService.set('activationStartDate', idClaims.meta.activationStartDate);
    }
  }

  _navigateToError(error: any) {
    const errorPath = (this.sessionService.get(SessionKey.ONE_TIME_LINK) as OneTimeLink)?.usedOneTimeLink
      ? `${Flow.ONE_TIME_LINK}/${ONE_TIME_LINK_PATHS.invalidLink}`
      : ERROR_PATHS.systemError;
    this._navigationService.navigateToError(this.className, error, errorPath);
  }

  _processNonInstantActivationSuccess() {
    if (
      this.destination?.type === DestinationType.NON_INSTANT_ACTIVATION_SUCCESS &&
      this.sessionService.has('maximumAttempts')
    ) {
      this.sessionService.remove('maximumAttempts');
    }

    if (this.sessionService.has('activationKey')) {
      this.sessionService.remove('activationKey');
    }
  }

  _checkActivationKeyMaximumAttempts(error: any) {
    let maximumAttempts = this.sessionService.has('maximumAttempts') ? this.sessionService.get('maximumAttempts') : 0;
    if (maximumAttempts < 4) {
      this.sessionService.set('maximumAttempts', ++maximumAttempts);
      this._navigationService.navigate(
        `${Flow.NON_INSTANT_ACTIVATION}/verify/${NON_INSTANT_ACTIVATION_PATHS.activationKey}`
      );
    } else {
      this.sessionService.remove('maximumAttempts');
      this._navigateToError(error);
    }
  }

  _storeUsername(idClaims: any, authorization: Authorization) {
    if (authorization.method === AuthMethod.PING) {
      const userName = idClaims.userId;
      if (userName) {
        this.sessionService.setModifiedSessionObject(userName, SessionKey.CUSTOMER, 'userName');
      }
    }
  }
}
