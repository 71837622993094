import { Component } from '@angular/core';
import { AdaBaseComponent } from '@shared/base-component/ada-base.component';

@Component({
  selector: 'ciam-snag-error',
  templateUrl: './snag-error.component.html',
  styleUrls: ['./snag-error.component.scss']
})
export class SnagErrorComponent extends AdaBaseComponent {
  protected readonly className = 'SnagErrorComponent';
}
