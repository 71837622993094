import { Directive, HostListener } from '@angular/core';
import { LoggingService } from './logging.service';

@Directive({ selector: '[ciamClickLogger]' })
export class ClickLoggerDirective {
  protected readonly className = 'ClickLoggerDirective';

  constructor(private _logger: LoggingService) {}

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    this._logger.logElementClick(this.className, event);
  }
}
