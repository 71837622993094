<div class="d-flex flex-column">
  <div class="d-flex flex-column justify-content-between page-content">
    <section id="undeliverable-code-content" class="container">
      <div class="row justify-content-center">
        <div class="col-lg">
          <div id="undeliverable-code-header" class="content-section">
            <h2 id="undeliverable-code-title" class="h5">
              <strong>We need to send you a code to confirm your identity.</strong>
            </h2>
            <bolt-alert-warning _id="alert-warning">
              For assistance, call <ciam-phone-number key="WEB_SUPPORT"></ciam-phone-number>. To make this process
              easier in the future, provide your preferred email address or cell phone number in your profile once your
              online access has been restored.
            </bolt-alert-warning>
          </div>
        </div>
        <div class="col-auto content-section" hidden>
          <ciam-express-links></ciam-express-links>
        </div>
      </div>
    </section>
    <section id="undeliverable-code-buttons" class="button-container">
      <div class="container button-stack">
        <input
          ciamClickLogger
          type="button"
          id="undeliverable-code-cancel"
          class="btn btn-default"
          value="Cancel"
          data-cancel-trigger
        />
      </div>
    </section>
  </div>
</div>
