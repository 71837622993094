import { Component } from '@angular/core';
import { ContentHeaderService } from './content-header.service';

@Component({
  selector: 'ciam-content-header',
  templateUrl: './content-header.component.html',
  styleUrls: ['./content-header.component.scss']
})
export class ContentHeaderComponent {
  constructor(public contentHeaderService: ContentHeaderService) {}

  get shouldShowContentHeader(): boolean {
    return this.shouldShowProgressBar || this.shouldShowTitle;
  }

  get shouldShowTitle(): boolean {
    return !!this.contentHeaderService.model.title;
  }

  get shouldShowProgressBar(): boolean {
    return !!this.contentHeaderService.model.progressTitle;
  }
}
