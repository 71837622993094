import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { VersionRoutingModule } from './version-routing-module';
import { VersionComponent } from './version/version.component';

@NgModule({
  declarations: [VersionComponent],
  imports: [SharedModule, VersionRoutingModule]
})
export class VersionModule {}
