import { NgModule } from '@angular/core';
import { RouterModule, Routes, mapToCanActivateChild } from '@angular/router';
import { StartOverComponent } from '@common/search/start-over/start-over.component';
import { Flow } from '@shared/navigation/flow.enum';
import { NavigationGuard } from '@shared/navigation/navigation.guard';
import { AdditionalIdentifierComponent } from './additional-identifier/additional-identifier.component';
import { PersonalInformationComponent } from './personal-information/personal-information.component';
import { PROXY_PATHS } from './proxy-paths';
import { UpdateUsernamePasswordComponent } from './update-username-password/update-username-password.component';

// TODO: This would be better implemented as a RegistrationFlowType, so that it is considered a "proper" registration
// flow and inheritance of some common registration functionality and components makes sense.
//
// e.g. /registration/proxy/<proxy-paths>

export const PROXY_ROUTES: Routes = [
  {
    path: Flow.PROXY_REGISTRATION,
    canActivateChild: mapToCanActivateChild([NavigationGuard]),
    children: [
      {
        path: 'verify',
        children: [
          { path: PROXY_PATHS.personalInformation, component: PersonalInformationComponent },
          { path: PROXY_PATHS.additionalIdentifier, component: AdditionalIdentifierComponent }
        ]
      },
      { path: PROXY_PATHS.startOver, component: StartOverComponent },
      { path: PROXY_PATHS.updateUsernamePassword, component: UpdateUsernamePasswordComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(PROXY_ROUTES)],
  exports: [RouterModule]
})
export class ProxyRoutingModule {}
