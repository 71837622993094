<div class="d-flex flex-column">
  <form
    id="updateUsernamePassword-form"
    class="d-flex flex-column justify-content-between page-content"
    [formGroup]="updateUsernamePasswordForm"
    #updateUsernamePasswordFormParent="ngForm"
    (ngSubmit)="resetPageErrorAndFocus()"
  >
    <section id="updateUsernamePassword-content" class="container">
      <div id="updateUsernamePassword-header" class="content-section">
        <div id="updateUsernamePassword-error">
          <bolt-alert-error
            #pageLevelError
            _id="page-level-error"
            [hidden]="!(updateUsernamePasswordFormParent.submitted && updateUsernamePasswordForm.invalid)"
          >
            Please correct the following to continue.
          </bolt-alert-error>
        </div>
        <ng-template>
          <h2 #adaAutoFocus id="updateUsernamePassword-title" class="h5">
            <strong>Update username and password</strong>
          </h2>
        </ng-template>
        <div>Follow the guidelines to safeguard your account</div>
      </div>
      <div id="updateUsernamePassword-inputs" class="content-section">
        <!--
          Note: The layout of this page differs slightly from the others - there is no fieldset.
          This is due to an issue with Voiceover in Safari that causes the progress bar to be read when every field is tabbed into.
        -->
        <div class="form-group">
          <bolt-textfield
            ciamDelayedFocusout
            [_id]="updateUsernamePasswordControls.username.id"
            _name="username"
            [_aria-describedby]="updateUsernamePasswordControls.username.ariaDescribedBy"
            _class="nwpii"
            maxlength="60"
            [formGroup]="updateUsernamePasswordForm"
            [parentForm]="updateUsernamePasswordFormParent"
            placeholder="Username or email address"
            (input)="currentUsernameInput = $event.target.value"
            (focusin)="usernameChecklist.hidden = false"
            (delayedFocusout)="usernameChecklist.hidden = true"
          >
            Update Username
          </bolt-textfield>
          <bolt-field-error
            id="update-username-password-username-errors"
            _id="update-username-password-username-errors"
            _class="field-error"
            [errors]="updateUsernamePasswordForm.get('username').errors"
            [errorMessages]="updateUsernamePasswordControlErrors.username"
            *ngIf="updateUsernamePasswordFormParent.submitted"
          ></bolt-field-error>
          <span id="username-help-message" class="help-text">
            <em>
              To make your username easy to remember, use your email address.
            </em>
          </span>
          <div #usernameChecklist id="username-validations" class="mt-2" hidden>
            <span id="username-validations-header">Your username is not case sensitive and must have:</span>
            <ciam-dynamic-checklist [dynamicChecklist]="usernameDynamicChecklist" [currentInput]="currentUsernameInput">
            </ciam-dynamic-checklist>
          </div>
        </div>
        <div class="form-group">
          <bolt-textfield
            ciamDelayedFocusout
            [_id]="updateUsernamePasswordControls.password.id"
            _name="password"
            [_aria-describedby]="updateUsernamePasswordControls.password.ariaDescribedBy"
            _class="nwpii"
            maxlength="30"
            [maskedToggle]="true"
            maskDescription="password"
            [formGroup]="updateUsernamePasswordForm"
            [parentForm]="updateUsernamePasswordFormParent"
            (input)="currentPasswordInput = $event.target.value"
            (focusin)="passwordChecklist.hidden = false"
            (delayedFocusout)="passwordChecklist.hidden = true"
            (buttonClicked)="handleShowHideClick($event)"
          >
            Create Password
          </bolt-textfield>
          <bolt-field-error
            id="update-username-password-password-errors"
            _id="update-username-password-password-errors"
            _class="field-error"
            [errors]="updateUsernamePasswordForm.get('password').errors"
            [errorMessages]="updateUsernamePasswordControlErrors.password"
            *ngIf="updateUsernamePasswordFormParent.submitted"
          ></bolt-field-error>
          <div #passwordChecklist id="password-validations" class="mt-3" hidden>
            <span id="password-validations-header">Passwords are case sensitive and must have:</span>
            <ciam-dynamic-checklist [dynamicChecklist]="passwordDynamicChecklist" [currentInput]="currentPasswordInput">
            </ciam-dynamic-checklist>
          </div>
        </div>
        <div class="form-group">
          <bolt-textfield
            [_id]="updateUsernamePasswordControls.passwordConfirmation.id"
            _name="passwordConfirmation"
            _class="nwpii"
            maxlength="30"
            [maskedToggle]="true"
            maskDescription="password"
            [formGroup]="updateUsernamePasswordForm"
            [parentForm]="updateUsernamePasswordFormParent"
            (buttonClicked)="handleShowHideClick($event)"
          >
            Confirm password
          </bolt-textfield>
          <bolt-field-error
            id="update-username-password-password-confirmation-errors"
            _id="update-username-password-password-confirmation-errors"
            _class="field-error"
            [errors]="updateUsernamePasswordForm.get('passwordConfirmation').errors"
            [errorMessages]="updateUsernamePasswordControlErrors.passwordConfirmation"
            *ngIf="updateUsernamePasswordFormParent.submitted"
          >
          </bolt-field-error>
        </div>

        <div class="form-group">
          <bolt-textfield
            [_id]="updateUsernamePasswordControls.emailAddress.id"
            _name="emailAddress"
            maxlength="80"
            [formGroup]="updateUsernamePasswordForm"
            [parentForm]="updateUsernamePasswordFormParent"
            placeholder="name@domain.com"
            (change)="trimWhiteSpace($event)"
          >
            Email Address
          </bolt-textfield>
          <bolt-field-error
            id="update-username-password-email-address-errors"
            _id="update-username-password-email-address-errors"
            _class="field-error"
            [errors]="updateUsernamePasswordForm.get('emailAddress').errors"
            [errorMessages]="updateUsernamePasswordControlErrors.emailAddress"
            *ngIf="updateUsernamePasswordFormParent.submitted"
          >
          </bolt-field-error>
        </div>
        <div class="form-group">
          <bolt-textfield
            [_id]="updateUsernamePasswordControls.phoneNumber.id"
            _name="phoneNumber"
            [_aria-describedby]="updateUsernamePasswordControls.phoneNumber.ariaDescribedBy"
            mask="000-000-0000"
            [formGroup]="updateUsernamePasswordForm"
            [parentForm]="updateUsernamePasswordFormParent"
            [dropSpecialCharacters]="false"
            placeholder="555-555-5555"
          >
            Mobile Phone Number <em class="font-weight-lighter"> (optional)</em>
          </bolt-textfield>
          <bolt-field-error
            id="update-username-password-phone-number-errors"
            _id="update-username-password-phone-number-errors"
            _class="field-error"
            [errors]="updateUsernamePasswordForm.get('phoneNumber').errors"
            [errorMessages]="updateUsernamePasswordControlErrors.phoneNumber"
            *ngIf="updateUsernamePasswordFormParent.submitted"
          ></bolt-field-error>
          <span id="mobile-number-help-message" class="help-text">
            <em>
              This mobile phone number will be used to text you a security code when we need to confirm your identity
              when you're logging in to your account. Message and data rates may apply.
            </em>
          </span>
        </div>
        <div class="form-group">
          <label id="updateUsernamePassword-esa-document-label" aria-hidden="true">
            <strong>Electronic Services &amp; Document Delivery Agreement</strong>
          </label>
          <div id="esa-link-format">
            <a
              ciamclicklogger
              id="updateUsernamePassword-esa-document-link"
              aria-describedby="updateUsernamePassword-esa-document-link-aria-label"
              class="underline"
              href="https://www.nationwide.com/electronic-delivery-agreement.jsp"
              target="_blank"
              >View full agreement</a
            >
            <span id="updateUsernamePassword-esa-document-link-aria-label" class="sr-only" aria-hidden="true">
              View full Electronic Services and Document Delivery agreement
            </span>
          </div>
          <div class="pre-form-content">
            <ciam-esa-text></ciam-esa-text>
          </div>
          <bolt-check-box
            [_id]="updateUsernamePasswordControls.acceptedEsa.id"
            _name="acceptedEsa"
            [formGroup]="updateUsernamePasswordForm"
          >
            <strong>
              By checking this box, I confirm that I have read and accept the terms and conditions in the Electronic
              Services and Document Delivery Agreement.
            </strong>
          </bolt-check-box>
          <bolt-field-error
            id="update-username-password-esa-checkbox-errors"
            _id="update-username-password-esa-checkbox-errors"
            _class="field-error"
            [errors]="updateUsernamePasswordForm.get('acceptedEsa').errors"
            [errorMessages]="updateUsernamePasswordControlErrors.acceptedEsa"
            *ngIf="updateUsernamePasswordFormParent.submitted"
          >
          </bolt-field-error>
        </div>
      </div>
    </section>
    <section id="updateUsernamePassword-buttons" class="button-container">
      <div class="container button-stack">
        <bolt-button-primary ciamClickLogger _id="updateUsernamePassword-submit" _type="submit">
          Submit
        </bolt-button-primary>
        <input
          ciamClickLogger
          type="button"
          id="updateUsernamePassword-cancel"
          class="btn btn-default"
          value="Cancel"
          data-cancel-trigger
        />
      </div>
    </section>
  </form>
</div>
