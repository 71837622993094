<ciam-error-container
  errorId="business-account-not-found"
  [message]="message"
  [content]="content"
></ciam-error-container>
<ng-template #message>
  <div>We cannot locate an account with this information.</div>
</ng-template>

<ng-template #content>
  <div class="content-section">
    <h1 id="main-content" class="h5 mb-3 no-outline">
      Let's try and find you another way
    </h1>
    <p id="business-account-not-found-help-message">
      Use your personal information to register for online access.
    </p>
    <p>
      <bolt-button-primary
        ciamClickLogger
        _id="business-account-not-found-personal-information"
        (click)="navigateToPersonalIndividualInformation()"
      >
        Personal account registration
      </bolt-button-primary>
    </p>
  </div>
</ng-template>
