import { Component, OnInit } from '@angular/core';
import { ERROR_PATHS } from '@app/error/error-paths';
import { ENVIRONMENT } from '@environments/environment';
import { untilDestroyed } from '@ngneat/until-destroy';
import { ContentHeaderService } from '@shared/content-header/content-header.service';
import { NavigationService } from '@shared/navigation/navigation.service';
import { Customer, Registration, SessionKey } from '@shared/storage/storage.model';
import { RegistrationBaseComponent } from '../shared/registration-base.component';
import { CreateBusinessAccountModel } from '../shared/registration.model';
import { RegistrationService } from '../shared/registration.service';

@Component({
  selector: 'app-update-login',
  templateUrl: './update-login.component.html',
  styleUrls: ['./update-login.component.scss']
})
export class UpdateLoginComponent extends RegistrationBaseComponent implements OnInit {
  hideSpinnerOnLoad = false;

  businessNames: string;

  protected readonly className = 'UpdateLoginComponent';

  constructor(
    private _registrationService: RegistrationService,
    private _navigationService: NavigationService,
    private _contentHeaderService: ContentHeaderService
  ) {
    super();
  }

  ngOnInit() {
    this.spinnerService.show('Registering your business account...');
    this.businessNames = this.formattedBusinessNames;
    this._createBusinessAccount();
  }

  continue() {
    this._navigationService.navigateExternal(
      `${ENVIRONMENT.externalUrls.iam.basePath}${ENVIRONMENT.externalUrls.iam.login}`
    );
  }

  private _createBusinessAccount() {
    this.logger.info(this.className, 'Attempting to create account controllership for a registered controller.');

    const requestModel: CreateBusinessAccountModel = {
      agreementNumber: (this.sessionService.get(SessionKey.REGISTRATION) as Registration)?.agreementNumber,
      agreementOwnerEcn: (this.sessionService.get(SessionKey.REGISTRATION) as Registration)?.agreementOwnerEcn,
      controllerEcn: (this.sessionService.get(SessionKey.CUSTOMER) as Customer)?.ecn,
      acceptedAttestation: true
    };

    this._registrationService
      .createBusinessAccount(requestModel)
      .pipe(untilDestroyed(this))
      .subscribe(
        response => {
          this.logger.info(
            this.className,
            `Account controllership added to existing ${response.accountStatus} account`
          );
          this._contentHeaderService.updateProgress({
            progressTitle: 'Confirmation',
            progressPercent: 100
          });
          this.spinnerService.hide();
        },
        error => {
          this.logger.error(this.className, 'Error occurred creating account controller.');
          this._navigationService.navigateToError(
            this.className,
            error,
            ERROR_PATHS.onlineAccessError,
            'RegistrationService createBusinessAccount failed.'
          );
        }
      );
  }

  get formattedBusinessNames(): string {
    const unformattedBusinessNames = (this.sessionService.get(SessionKey.REGISTRATION) as Registration)?.businessNames;
    if (unformattedBusinessNames?.length === 1) {
      return unformattedBusinessNames[0];
    } else {
      const last = unformattedBusinessNames?.pop();
      return `${unformattedBusinessNames?.join(', ')} and ${last}`;
    }
  }
}
