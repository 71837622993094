import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { AuthorizationRoutingModule } from './authorization-routing.module';
import { AuthorizeComponent } from './authorize/authorize.component';
import { CallbackComponent } from './callback/callback.component';

@NgModule({
  declarations: [AuthorizeComponent, CallbackComponent],
  imports: [AuthorizationRoutingModule, SharedModule]
})
export class AuthorizationModule {}
