import { NgModule } from '@angular/core';
import {
  BoltAlertConfirmationModule,
  BoltAlertErrorModule,
  BoltButtonPrimaryModule,
  BoltButtonStandardModule,
  BoltCheckBoxModule,
  BoltFieldErrorModule,
  BoltRadioButtonModule,
  BoltRadioGroupModule,
  BoltTextFieldModule
} from '@nw/bolt-angular';
import { SharedModule } from '@shared/shared.module';
import { DigitalRefundsRoutingModule } from './digital-refunds-routing.module';
import { DigitalRefundsComponent } from './digital-refunds/digital-refunds.component';

@NgModule({
  declarations: [DigitalRefundsComponent],
  imports: [
    SharedModule,
    DigitalRefundsRoutingModule,
    BoltAlertConfirmationModule,
    BoltAlertErrorModule,
    BoltButtonPrimaryModule,
    BoltButtonStandardModule,
    BoltCheckBoxModule,
    BoltFieldErrorModule,
    BoltRadioButtonModule,
    BoltRadioGroupModule,
    BoltTextFieldModule
  ]
})
export class DigitalRefundsModule {}
