import { Inject, Injectable } from '@angular/core';

import { ERROR_PATHS } from '@app/error/error-paths';
import { ENVIRONMENT } from '@environments/environment';
import { NavigationService } from '@shared/navigation/navigation.service';
import { CUSTOM_SESSION, CustomSessionService } from '@shared/storage/custom-session.service';
import { Customer, SessionKey } from '@shared/storage/storage.model';
import { MfaStatus, isMfaStatusValid } from './mfa-status.model';

@Injectable({
  providedIn: 'root'
})
export class MfaStatusGuard {
  private readonly _className = 'MfaStatusGuard';

  constructor(
    @Inject(CUSTOM_SESSION) private _sessionService: CustomSessionService,
    private _navigationService: NavigationService
  ) {}

  canActivate(): boolean {
    const mfaStatus = (this._sessionService.get(SessionKey.CUSTOMER) as Customer)?.mfaStatus;
    if (!ENVIRONMENT.features.general.mfaStatusGuard || !mfaStatus || isMfaStatusValid(mfaStatus)) {
      return true;
    } else if (mfaStatus === MfaStatus.LOCKOUT) {
      this._navigationService.navigateToError(
        this._className,
        new Error(`mfaStatus is: ${MfaStatus.LOCKOUT}`),
        ERROR_PATHS.accessLocked
      );
      return false;
    } else {
      this._navigationService.navigateToError(
        this._className,
        new Error(`mfaStatus is: ${mfaStatus}`),
        ERROR_PATHS.snagError
      );
      return false;
    }
  }
}
