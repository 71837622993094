import { Injectable } from '@angular/core';
import { CancelModel } from './cancel.model';

@Injectable({
  providedIn: 'root'
})
export class CancelService {
  private _model: CancelModel;

  constructor() {
    this.reset();
  }

  reset() {
    this.model = {};
  }

  get model(): CancelModel {
    return this._model;
  }

  set model(model: CancelModel) {
    this._model = model;
  }
}
