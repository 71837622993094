<div class="d-flex flex-column">
  <div class="d-flex flex-column justify-content-between page-content">
    <section id="expired-code-content" class="container">
      <div class="row justify-content-center">
        <div id="expired-code-header" class="col-lg content-section">
          <h2 id="expired-code-title" class="h5">
            <strong>
              We've sent you a verification code. It can take up to fifteen minutes for this code to arrive.
            </strong>
          </h2>
          <bolt-alert-error _id="page-level-error">
            Your code has expired. Please request a new code.
          </bolt-alert-error>
        </div>
        <div class="col-auto content-section" hidden>
          <ciam-express-links></ciam-express-links>
        </div>
      </div>
    </section>
    <section id="expired-code-buttons" class="button-container">
      <div class="container button-stack">
        <bolt-button-primary ciamClickLogger _id="expired-code-send" (click)="sendNewCode()">
          Send a new code
        </bolt-button-primary>
        <input
          ciamClickLogger
          type="button"
          id="expired-code-cancel"
          class="btn btn-default"
          value="Cancel"
          data-cancel-trigger
        />
      </div>
    </section>
  </div>
</div>
