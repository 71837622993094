import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@environments/environment';
import {
  CustomerVerificationService,
  PostVerifyRequest,
  PostVerifyResponse
} from '@nationwide/api-client-internet-registration-v3';
import { CustomCookieService } from '@shared/storage/custom-cookie.service';
import { CustomSessionService, CUSTOM_SESSION } from '@shared/storage/custom-session.service';
import { Customer, Partner, SessionKey } from '@shared/storage/storage.model';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { VerifyCustomerCriteria, VerifyCustomerModel } from './verify-customer.model';

@Injectable()
export class VerifyCustomerService {
  private _model: VerifyCustomerModel;

  constructor(
    @Inject(CUSTOM_SESSION) private _sessionService: CustomSessionService,
    private _cookieService: CustomCookieService,
    private _oauthService: OAuthService,
    private _customerVerificationService: CustomerVerificationService
  ) {
    this.reset();
  }

  verifyCustomer(verifyCustomerCriteria: VerifyCustomerCriteria): Observable<HttpResponse<PostVerifyResponse>> {
    const customer = this._sessionService.get(SessionKey.CUSTOMER) as Customer;

    return this._customerVerificationService.customerEcnVerifyPost(
      this.buildVerifyRequest(verifyCustomerCriteria),
      ENVIRONMENT.apiConfig.clientId,
      this._cookieService.flowId,
      customer?.ecn,
      (this._sessionService.get(SessionKey.PARTNER) as Partner)?.id,
      false,
      'response'
    );
  }

  reset() {
    this._customerVerificationService.configuration.accessToken = this._oauthService.getAccessToken();
  }

  private buildVerifyRequest(verifyCustomerCriteria: VerifyCustomerCriteria): PostVerifyRequest {
    const request: PostVerifyRequest = { party: {} };
    if (!!verifyCustomerCriteria.input?.dateOfBirth && verifyCustomerCriteria.input?.zipCode) {
      request.party = {
        dob: verifyCustomerCriteria.input.dateOfBirth,
        zipCode: verifyCustomerCriteria.input.zipCode
      };
    }
    if (verifyCustomerCriteria.input?.agreementNumber) {
      request.party.agreementNumber = verifyCustomerCriteria.input.agreementNumber;
    } else if (verifyCustomerCriteria.input?.taxIdentifier) {
      request.party.ssn = this.formatSsn(verifyCustomerCriteria.input.taxIdentifier);
    }

    return request;
  }

  private formatSsn(ssn: string): string {
    if (ssn) {
      ssn = ssn.replace('-', '');
      ssn = `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5)}`;
    }
    return ssn;
  }

  get model(): VerifyCustomerModel {
    return this._model;
  }

  set model(model: VerifyCustomerModel) {
    this._model = model;
  }
}
