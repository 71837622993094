<div class="d-flex flex-column">
  <form
    id="individual-additional-info-form"
    class="d-flex flex-column justify-content-between page-content"
    [formGroup]="individualAdditionalInfoForm"
    #individualAdditionalInfoFormParent="ngForm"
    (ngSubmit)="resetPageErrorAndFocus()"
  >
    <section id="individual-additional-info-content" class="container">
      <div id="individual-additional-info-header" class="content-section">
        <bolt-alert-error #pageLevelError _id="page-level-error" [hidden]="!errorMessage">
          {{ errorMessage }}
          <a
            *ngIf="showIdentifierLink"
            ciamclicklogger
            id="individual-additional-info-identifier-link"
            class="underline"
            href="javascript:void(0)"
            (click)="navigateToIdentifier()"
            >search with something else.</a
          >
        </bolt-alert-error>
        <h2 #adaAutoFocus id="individual-additional-info-title" class="h5">
          <strong>
            We'll need a little more information to find you.
          </strong>
        </h2>
      </div>

      <div id="individual-additional-info-inputs">
        <fieldset class="content-section">
          <legend class="pre-form-content">
            <h2 id="individual-additional-info-your-information" class="h5">
              Your information
            </h2>
          </legend>
          <div class="form-group">
            <bolt-textfield
              [_id]="individualAdditionalInfoControl.id"
              [_name]="additionalInformationType"
              [_aria-describedby]="individualAdditionalInfoControl.ariaDescribedBy"
              [_class]="nwpiiClass"
              [placeholder]="individualAdditionalInfoControl.placeholder"
              [maxlength]="individualAdditionalInfoControl.maxLength?.toString()"
              [mask]="individualAdditionalInfoControl.mask"
              [dropSpecialCharacters]="false"
              [formGroup]="individualAdditionalInfoForm"
              [parentForm]="individualAdditionalInfoFormParent"
            >
              {{ individualAdditionalInfoControl.label }}
            </bolt-textfield>
            <bolt-field-error
              id="individual-additional-info-{{ additionalInformationType }}-errors"
              _id="individual-additional-info-{{ additionalInformationType }}-errors"
              _class="field-error"
              [errors]="individualAdditionalInfoForm.get(additionalInformationType).errors"
              [errorMessages]="individualAdditionalInfoControlErrors[additionalInformationType]"
              *ngIf="individualAdditionalInfoFormParent.submitted"
            >
            </bolt-field-error>
            <span
              *ngIf="individualAdditionalInfoControl.helpText"
              id="individual-additional-info-{{ additionalInformationType }}-help-message"
              class="help-text"
            >
              <em>{{ individualAdditionalInfoControl.helpText }}</em>
            </span>
          </div>
        </fieldset>
      </div>
    </section>

    <section id="individual-additional-info-buttons" class="button-container">
      <div class="container button-stack">
        <bolt-button-primary ciamClickLogger _id="individual-additional-info-find-account" _type="submit">
          Find account
        </bolt-button-primary>
        <input
          ciamClickLogger
          type="button"
          id="individual-additional-info-cancel"
          class="btn btn-default"
          value="Cancel"
          data-cancel-trigger
        />
      </div>
    </section>
  </form>
</div>
