import { UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export const validateUsernamePasswordDoNotMatch: ValidatorFn = (
  formGroup: UntypedFormGroup
): ValidationErrors | null => {
  const passwordField = formGroup.get('password');

  if (passwordField.value && passwordField.value === formGroup.get('username').value) {
    passwordField.setErrors({ usernamePasswordMatch: true });
  }

  return null;
};
