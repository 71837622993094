/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ENVIRONMENT } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PersonalBillingService {
  personalBillingUri: string;

  constructor(private _httpClient: HttpClient) {
    this.personalBillingUri = `${ENVIRONMENT?.apiConfig?.services?.personalLinesBilling?.basePath}`;
  }

  patchRefundMethod(url: string, body: object, token: string): Observable<any> {
    return this._httpClient.patch(url, body, {
      headers: {
        Authorization: 'Bearer ' + token,
        client_id: ENVIRONMENT?.apiConfig?.clientId,
        'X-NW-Message-ID': '' + Date.now()
      }
    });
  }

  getBillingMethod(url: string, token: string): Observable<any> {
    let headerOptions = new HttpHeaders({
      Authorization: 'Bearer ' + token,
      client_id: ENVIRONMENT?.apiConfig?.clientId,
      'X-NW-Message-ID': '' + Date.now()
    });

    if (!ENVIRONMENT.production) {
      headerOptions = headerOptions.append('connectionType', 'CIB1');
    }

    return this._httpClient.get(url, { headers: headerOptions });
  }

  setBillingUrl(account: string, method: string) {
    return `${this.personalBillingUri}/billing-accounts/${account}/pay-plans/current?request_type=${method}`;
  }

  setBillingAccountInfoUrl(account: string) {
    return `${this.personalBillingUri}/billing-accounts/${account}`;
  }
}
