import { Component, Input } from '@angular/core';

@Component({
  selector: 'ciam-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('_aria-describedby') ariaDescribedby: string;
}
