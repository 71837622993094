<!-- Main Content -->
<header id="app-header" class="section-separator pt-3 pb-3" role="banner">
  <div class="container d-flex flex-row">
    <a
      ciamClickLogger
      id="header-logo-link"
      href="javascript:void(0)"
      title="Nationwide Homepage"
      data-cancel-trigger
      data-cancel-url="https://www.nationwide.com"
    >
      <img id="header-logo" class="header-logo" alt="Nationwide logo" src="assets/header-logo.svg" />
    </a>
    <input
      #contactUsButton
      ciamClickLogger
      type="button"
      id="header-contact-us"
      class="btn nw-btn-inline ml-auto align-self-center"
      value="Contact Us"
    />
  </div>
</header>

<!-- Contact Us Modal -->
<bolt-modal
  _id="contact-us-modal"
  [triggerElements]="[contactUsButton]"
  [buttons]="[{ label: 'Close', primary: true }]"
  (buttonClicked)="handleContactUsSelection($event)"
>
  <div id="contact-us-modal-content">
    <p id="contact-us-modal-header"><span class="h4">Contact us</span></p>
    <ciam-contact-us _aria-describedby="contact-us-modal-header"></ciam-contact-us>
  </div>
</bolt-modal>
