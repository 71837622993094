import { ControlErrors, Controls } from '@shared/base-component/base.model';
import { CONTROL_ERROR_MESSAGES, CONTROL_PATTERNS } from '@shared/validation/control-constants';

export const CONTROLS: Controls = {
  dateOfBirth: {
    id: 'individual-additional-info-dateOfBirth',
    label: 'Date of birth',
    helpText: 'mm/dd/yyyy',
    ariaDescribedByErrors: 'individual-additional-info-dateOfBirth-errors',
    ariaDescribedBy: 'individual-additional-info-dateOfBirth-help-message',
    patterns: CONTROL_PATTERNS.dateOfBirth,
    mask: '00/00/0000'
  },
  phoneNumber: {
    id: 'individual-additional-info-phoneNumber',
    label: 'Phone number',
    ariaDescribedByErrors: 'individual-additional-info-phoneNumber-errors',
    placeholder: '555-555-5555',
    patterns: CONTROL_PATTERNS.phoneNumber,
    mask: '000-000-0000'
  },
  email: {
    id: 'individual-additional-info-email',
    label: 'Email address',
    ariaDescribedByErrors: 'individual-additional-info-email-errors',
    placeholder: 'name@domain.com',
    patterns: CONTROL_PATTERNS.emailAddress,
    maxLength: 80
  },
  addressNumber: {
    id: 'individual-additional-info-addressNumber',
    label: 'House number / PO Box',
    ariaDescribedByErrors: 'individual-additional-info-addressNumber-errors',
    patterns: CONTROL_PATTERNS.addressNumber,
    maxLength: 12,
    mask: '0{12}'
  }
};

export const CONTROL_ERRORS: ControlErrors = {
  dateOfBirth: {
    required: CONTROL_ERROR_MESSAGES.dateOfBirthRequired,
    pattern: CONTROL_ERROR_MESSAGES.dateOfBirthPattern
  },
  phoneNumber: {
    required: CONTROL_ERROR_MESSAGES.phoneNumberRequired,
    pattern: CONTROL_ERROR_MESSAGES.phoneNumberPattern
  },
  email: {
    required: CONTROL_ERROR_MESSAGES.emailAddressRequired,
    pattern: CONTROL_ERROR_MESSAGES.emailAddressPattern
  },
  addressNumber: {
    required: CONTROL_ERROR_MESSAGES.addressNumberRequired,
    pattern: CONTROL_ERROR_MESSAGES.addressNumberPattern
  }
};
