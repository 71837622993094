export enum MfaStatus {
  DELETE = 'DELETE',
  DELETED = 'DELETED',
  LOCKOUT = 'LOCKOUT',
  NOTENROLLED = 'NOTENROLLED',
  UNLOCKED = 'UNLOCKED',
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED'
}

export const VALID_MFA_STATUSES: MfaStatus[] = [
  MfaStatus.DELETE,
  MfaStatus.DELETED,
  MfaStatus.NOTENROLLED,
  MfaStatus.UNLOCKED,
  MfaStatus.UNVERIFIED,
  MfaStatus.VERIFIED
];

export const isMfaStatusValid = (mfaStatus: string): boolean =>
  VALID_MFA_STATUSES.find(status => status === mfaStatus) !== undefined;
