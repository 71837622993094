import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { SEARCH_PATHS } from '@common/search/search-paths';
import { ENVIRONMENT } from '@environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LogLevel } from '@nw/eim-angular-logging';
import { ControlErrors, Controls } from '@shared/base-component/base.model';
import { ContentHeaderService } from '@shared/content-header/content-header.service';
import { NavigationService } from '@shared/navigation/navigation.service';
import { filter } from 'rxjs/operators';
import { IdentifierType, SearchType } from '../../shared/search.model';
import { IdentifierBaseComponent } from '../identifier-base.component';
import { CONTROLS, CONTROL_ERRORS } from './business-identifier.model';

@UntilDestroy()
@Component({
  selector: 'ciam-business-identifier',
  templateUrl: './business-identifier.component.html',
  styleUrls: ['./business-identifier.component.scss']
})
export class BusinessIdentifierComponent extends IdentifierBaseComponent implements OnInit {
  @ViewChild('businessInformationFormParent', { static: true }) businessInformationFormParent: NgForm;
  businessInformationForm: UntypedFormGroup;
  businessInformationControls: Controls = CONTROLS;
  businessInformationControlErrors: ControlErrors = CONTROL_ERRORS;
  notFoundSearchAttempts = 0;

  protected readonly className = 'BusinessIdentifierComponent';

  constructor(
    private _contentHeaderService: ContentHeaderService,
    private _navigationService: NavigationService,
    private _formBuilder: UntypedFormBuilder
  ) {
    super();
  }

  ngOnInit(): void {
    this._contentHeaderService.updateProgress({
      progressTitle: 'Find business account',
      progressPercent: 10
    });

    this.logger.info(this.className, 'Entering business information.');

    this._initializeForm();
  }

  _findAccount() {
    this._performSearch(
      { input: { agreementNumber: this.businessInformationForm.get('accountNumber').value } },
      null,
      IdentifierType.ACCOUNT
    );
  }

  _handleUnresolvablePotentialMatch() {
    this._navigationService.navigateToError(
      this.className,
      new Error('Business account not found.'),
      `${this.baseRoute}/${SEARCH_PATHS.accountNotFound}`,
      null,
      LogLevel.WARN
    );
  }

  _handleNoMatch() {
    this.notFoundSearchAttempts++;
    if (this.notFoundSearchAttempts < ENVIRONMENT.features.search.maxAttempts.businessInfo) {
      this.spinnerService.hide();
    } else {
      this.searchService.model.previousSearch = SearchType.BUSINESS_IDENTIFIER;
      this.navigationService.navigate(`${this.baseRoute}/${SEARCH_PATHS.startOver}`);
    }
  }

  private _initializeForm() {
    this.businessInformationForm = this._formBuilder.group(
      {
        accountNumber: [
          '',
          [
            Validators.required,
            ...this._buildPatternValidators(this.businessInformationControls.accountNumber.patterns)
          ]
        ]
      },
      {
        updateOn: 'submit'
      }
    );

    this.businessInformationForm.statusChanges
      .pipe(
        untilDestroyed(this),
        filter(() => this.businessInformationForm.valid)
      )
      .subscribe(() => {
        this._findAccount();
      });
  }

  get identifierType(): IdentifierType {
    return IdentifierType.ACCOUNT;
  }

  get searchType(): SearchType {
    return SearchType.BUSINESS_IDENTIFIER;
  }

  protected get formGroup(): UntypedFormGroup {
    return this.businessInformationForm;
  }

  protected get controls(): Controls {
    return this.businessInformationControls;
  }

  protected get controlErrors(): ControlErrors {
    return this.businessInformationControlErrors;
  }
}
