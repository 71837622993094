export const CONTROL_PATTERNS = {
  createUsername: [/^(?=.*[a-zA-z])[^\s#%\^&*()+\\;"'<>/,]{6,60}$/, /^[\x00-\x7F]+$/],
  createPassword: [/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!#$+,-.\/:=?@\[\]_{|}~])[^\s\^&*();<>"`'%]{8,30}$/],
  updateUsername: [/^(?=.*[a-zA-z])[^\s#%\^&*()+\\;"'<>/,]{6,60}$/, /^[\x00-\x7F]+$/],
  updatePassword: [/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!#$+,-.\/:=?@\[\]_{|}~])[^\s\^&*();<>"`'%]{8,30}$/],
  firstName: [/^[a-zA-Z\-'\s]{1,80}$/],
  phoneNumber: [/^\d{3}-\d{3}-\d{4}$/],
  lastName: [/^[a-zA-Z\-'\s]{1,80}$/],
  emailAddress: [
    /^[a-zA-Z0-9-!#$%&_+`{}|']+(?:\.[a-zA-Z0-9-!#$%&_+`{}|']+)*@(?:[a-zA-Z0-9-]+)(?:\.[a-zA-Z0-9]{2,})+$/,
    /^(?:.){1,64}@.+$/,
    /^(?:.){1,80}$/
  ],
  dateOfBirth: [/^\d{2}\/\d{2}\/\d{4}$/],
  zipCode: [/^\d{5}$/],
  code: [/^\d+$/],
  addressNumber: [/^\d+$/],
  agreementNumber: [/^[a-zA-Z0-9\-\s]{1,30}$/],
  streetAddress: [/^[a-zA-Z0-9\s/]{1,50}$/],
  city: [/^[a-zA-Z\-\s]{1,30}$/],
  ssn: [/^\d{9}$/],
  routingNumber: [/^\d{9}$/],
  accountNumber: [/^\d{3,17}$/],
  confirmAccountNumber: [/^\d{3,17}$/]
};

export const CONTROL_ERROR_MESSAGES = {
  zipCodeRequired: 'ZIP code is required.',
  zipCodePattern: 'ZIP code must be numbers in the format of 12345.',
  ssnRequired: 'Social Security number is required.',
  ssnPattern: 'Social Security number format must be 123-45-6789.',
  activationKeyRequired: 'Activation key is required.',
  activationKeyNotFound: "The activation key doesn't match our records.",
  accountRequired: 'Account/Policy/Contract number is required.',
  accountPattern: 'Account/Policy/Contract number can only contain numbers, letters, spaces or a dash ( - ).',
  firstNameRequired: 'First name is required.',
  firstNamePattern: "First name can only contain letters, a dash ( - ) or an apostrophe ( ' ).",
  lastNameRequired: 'Last name is required.',
  lastNamePattern: "Last name can only contain letters, a dash ( - ) or an apostrophe ( ' ).",
  usernameRequired: 'Username is required.',
  usernamePattern: 'Check the username format rules.',
  usernameUnavailable: 'That username is unavailable. Please create another username.',
  passwordRequired: 'Password is required.',
  passwordPattern: 'Check the password format rules.',
  usernamePasswordMatch: 'Username and password cannot be the same.',
  passwordMismatch: 'Password and confirm password must match.',
  passwordConfirmationRequired: 'Confirm password is required.',
  previousPasswordUsed: "Try another password. You've already used that password.",
  accountTypeRequired: 'A selection is required.',
  acceptedServiceAgreementRequired:
    'Check that you have read and accept the terms and conditions in the Service Agreement.',
  passwordConfirmationPasswordMismatch: 'Password and confirm password must match.',
  phoneNumberPattern: 'Mobile number must be 10 digits.',
  phoneNumberRequired: 'Mobile number is required.',
  dateOfBirthRequired: 'Date of birth is required.',
  dateOfBirthPattern: 'Date of birth format must be mm/dd/yyyy.',
  emailAddressRequired: 'Email address is required.',
  emailAddressPattern: 'Check email address format.',
  multipleTypesSelectionRequired: 'A selection is required.',
  accountNumberRequired: 'Account number is required.',
  accountNumberPattern: 'Account number can only contain numbers, letters, spaces or a dash ( - ).',
  codeRequired: 'Please enter your code to continue.',
  addressNumberRequired: 'House number / PO Box is required.',
  addressNumberPattern: 'House number / PO Box can only contain up to 12 numbers.',
  codeInvalid: 'You have entered an invalid code. Please try again or request a new code.',
  availableContactTypesRequired: 'A selection is required.',
  acceptedEsaRequired:
    'Check that you have read and accept the terms and conditions in the Electronic Services & Document Delivery Agreement.',
  bankAccountNumberRequired: 'Account number is required.',
  bankAccountNumberPattern: 'Account number is invalid.',
  confirmAccountNumberRequired: 'Confirm account number is required.',
  confirmAccountNumberPattern: 'Confirm account number is invalid.',
  accountNumberMatch: 'Account number and confirm account number must match.',
  routingNumberRequired: 'Bank routing number is required.',
  routingNumberPattern: 'Bank routing number is invalid.',
  cityNameRequired: 'City name is required.',
  cityNamePattern: 'City name is invalid.',
  stateNameRequired: 'State selection is required.',
  streetAddressRequired: 'Street address is required.',
  streetAddressPattern: 'Street address is invalid.'
};
