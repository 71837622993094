<div class="d-flex flex-column">
  <div id="start-over" class="d-flex flex-column justify-content-between page-content">
    <section id="start-over-content" class="container">
      <div id="start-over-header" class="content-section">
        <bolt-alert-error #pageLevelError _id="start-over-error">
          <ciam-account-not-found-error hasStartOverMessage="true"></ciam-account-not-found-error>
        </bolt-alert-error>
      </div>

      <div class="button-standalone content-section">
        <bolt-button-primary ciamClickLogger _type="button" _id="start-over-start-over" (click)="startOver()">
          Start over
        </bolt-button-primary>
      </div>
    </section>

    <section id="start-over-buttons" class="button-container">
      <div class="container button-stack">
        <input
          ciamClickLogger
          type="button"
          id="start-over-cancel"
          class="btn btn-default"
          value="Cancel"
          data-cancel-trigger
        />
      </div>
    </section>
  </div>
</div>
