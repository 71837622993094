<div class="d-flex flex-column">
  <form
    id="personal-information-form"
    class="d-flex flex-column justify-content-between page-content personal-information-content"
    [formGroup]="personalInformationForm"
    #personalInformationFormParent="ngForm"
    (ngSubmit)="resetPageErrorAndFocus()"
  >
    <section id="personal-information-content" class="container">
      <div id="personal-information-header" class="content-section">
        <bolt-alert-error #pageLevelError _id="page-level-error" [hidden]="!errorMessage">
          {{ errorMessage }}
        </bolt-alert-error>
        <h2 #adaAutoFocus id="personal-information-title" class="h5">
          <strong>
            {{ personalInformationTitle }}
          </strong>
        </h2>
      </div>
      <div id="personal-information-inputs">
        <fieldset class="content-section">
          <legend>
            <h2 id="your-information-title" class="h5 pre-form-content">
              Your information
            </h2>
          </legend>
          <div class="form-group">
            <bolt-textfield
              [_id]="personalInformationControls.dateOfBirth.id"
              _name="dateOfBirth"
              [_aria-describedby]="personalInformationControls.dateOfBirth.ariaDescribedBy"
              [dropSpecialCharacters]="false"
              [formGroup]="personalInformationForm"
              [parentForm]="personalInformationFormParent"
              mask="00/00/0000"
              >Date of birth
            </bolt-textfield>
            <bolt-field-error
              id="personal-information-dateOfBirth-errors"
              _id="personal-information-dateOfBirth-errors"
              _class="field-error"
              [errors]="personalInformationForm.get('dateOfBirth').errors"
              [errorMessages]="personalInformationControlErrors.dateOfBirth"
              *ngIf="personalInformationFormParent.submitted"
            ></bolt-field-error>
            <span id="personal-information-dateOfBirth-help-message" class="help-text">
              <em>{{ personalInformationControls.dateOfBirth.helpText }}</em>
            </span>
          </div>
          <div class="input-styles form-group">
            <bolt-textfield
              [_id]="personalInformationControls.zipCode.id"
              _name="zipCode"
              _class="nwpii"
              maxlength="5"
              mask="00000"
              inputmode="numeric"
              [formGroup]="personalInformationForm"
              [parentForm]="personalInformationFormParent"
            >
              ZIP code
            </bolt-textfield>
            <bolt-field-error
              id="your-information-zip-code-errors"
              _id="your-information-zip-code-errors"
              _class="field-error"
              [errors]="personalInformationForm.get('zipCode').errors"
              [errorMessages]="personalInformationControlErrors.zipCode"
              *ngIf="personalInformationFormParent.submitted"
            ></bolt-field-error>
          </div>
        </fieldset>
      </div>
    </section>

    <section id="personal-information-buttons" class="button-container">
      <div class="container button-stack">
        <bolt-button-primary ciamClickLogger _id="personal-information-find-account" _type="submit">
          Find account
        </bolt-button-primary>
        <input
          *ngIf="!hasUsedOneTimeLink()"
          ciamClickLogger
          type="button"
          id="personal-information-back"
          class="btn nw-btn-inline"
          value="Back"
          (click)="back()"
        />
        <input
          ciamClickLogger
          type="button"
          id="personal-information-cancel"
          class="btn btn-default"
          value="Cancel"
          data-cancel-trigger
        />
      </div>
    </section>
  </form>
</div>
