<div class="d-flex flex-column">
  <form
    id="identifier-form"
    class="d-flex flex-column justify-content-between page-content"
    [formGroup]="identifierForm"
    #identifierFormParent="ngForm"
    (ngSubmit)="resetPageErrorAndFocus(); nonSingleMatch = false; submitted = true"
  >
    <section id="identifier-content" class="container">
      <div id="identifier-header" class="content-section">
        <bolt-alert-error #pageLevelError _id="page-level-error" [hidden]="!submitted || nonSingleMatch">
          Please correct the following to continue.
        </bolt-alert-error>
        <bolt-alert-error #pageLevelError _id="identifier-error" [hidden]="!submitted || !nonSingleMatch">
          <ciam-account-not-found-error></ciam-account-not-found-error>
        </bolt-alert-error>
        <h2 #adaAutoFocus id="identifier-title" class="h5">
          <strong>
            We'll need a little more information to find you.
          </strong>
        </h2>
      </div>

      <div id="identifier-inputs">
        <fieldset class="content-section">
          <legend>
            <h2 id="identifier-your-information" class="h5 pre-form-content">
              Your information
            </h2>
            <p
              *ngIf="!(hasMaxSocialSearchAttempts || hasMaxAccountSearchAttempts)"
              id="identifier-method"
              class="h6 font-weight-normal pre-form-content"
            >
              Which method should we use?
            </p>
          </legend>
          <div *ngIf="!(hasMaxSocialSearchAttempts || hasMaxAccountSearchAttempts)">
            <div id="identifier-options" class="form-group">
              <div class="form-check mobile-friendly-radio">
                <bolt-radio-button
                  _id="identifier-option-social"
                  _name="identifierType"
                  label="Social Security number"
                  [value]="identifierTypes.SOCIAL"
                  [formGroup]="identifierForm"
                  (change)="_handleTypeSelection(identifierTypes.SOCIAL)"
                >
                </bolt-radio-button>
              </div>
              <div class="form-check mobile-friendly-radio">
                <bolt-radio-button
                  _id="identifier-option-account"
                  _name="identifierType"
                  label="Account/Policy/Contract number"
                  [value]="identifierTypes.ACCOUNT"
                  [formGroup]="identifierForm"
                  (change)="_handleTypeSelection(identifierTypes.ACCOUNT)"
                >
                </bolt-radio-button>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div *ngIf="identifierType === identifierTypes.SOCIAL && !hasMaxSocialSearchAttempts">
              <bolt-textfield
                [_id]="identifierControls.social.id"
                _name="social"
                [_aria-describedby]="identifierControls.social.ariaDescribedBy"
                _class="nwpii"
                mask="000-00-0000"
                [dropSpecialCharacters]="true"
                [maskedToggle]="true"
                maskDescription="information"
                [formGroup]="identifierForm"
                [parentForm]="identifierFormParent"
              >
                Social Security number
              </bolt-textfield>
              <bolt-field-error
                id="identifier-social-errors"
                _id="identifier-social-errors"
                _class="field-error"
                [errors]="identifierForm.get('social').errors"
                [errorMessages]="identifierControlErrors.social"
                *ngIf="identifierFormParent.submitted"
              >
              </bolt-field-error>
              <span id="identifier-social-help-message" class="help-text">
                <em>With dashes (ex. 123-45-6789)</em>
              </span>
            </div>
            <div *ngIf="identifierType === identifierTypes.ACCOUNT && !hasMaxAccountSearchAttempts">
              <bolt-textfield
                [_id]="identifierControls.account.id"
                _name="account"
                [_aria-describedby]="identifierControls.account.ariaDescribedBy"
                _class="nwpii"
                maxlength="30"
                [maskedToggle]="true"
                maskDescription="information"
                [formGroup]="identifierForm"
                [parentForm]="identifierFormParent"
              >
                Account/Policy/Contract number
              </bolt-textfield>
              <bolt-field-error
                id="identifier-account-errors"
                _id="identifier-account-errors"
                _class="field-error"
                [errors]="identifierForm.get('account').errors"
                [errorMessages]="identifierControlErrors.account"
                *ngIf="identifierFormParent.submitted"
              >
              </bolt-field-error>
              <span id="identifier-account-help-message" class="help-text">
                <em>Note: Retirement plan case numbers are not accepted.</em>
              </span>
            </div>
          </div>
        </fieldset>
      </div>
    </section>

    <section id="identifier-buttons" class="button-container">
      <div class="container button-stack">
        <bolt-button-primary ciamClickLogger _id="identifier-find-account" _type="submit">
          Find account
        </bolt-button-primary>
        <input
          ciamClickLogger
          type="button"
          id="identifier-start-over"
          class="btn nw-btn-inline"
          value="Start over"
          (click)="backToIndividualInformation()"
        />
        <input
          ciamClickLogger
          type="button"
          id="identifier-cancel"
          class="btn btn-default"
          value="Cancel"
          data-cancel-trigger
        />
      </div>
    </section>
  </form>
</div>
