<div class="d-flex flex-column">
  <form
    id="activation-form"
    class="d-flex flex-column justify-content-between page-content page-height"
    [formGroup]="activationForm"
    #activationFormParent="ngForm"
  >
    <section id="activation-content" class="container">
      <div class="section-separator"></div>
      <div id="activation-header" class="content-section">
        <bolt-alert-error #pageLevelError _id="page-level-error" [hidden]="!errorMessage">
          {{ errorMessage }}
        </bolt-alert-error>
        <h2 #adaAutoFocus id="activation-title" class="h5">
          <strong class="activation-title-styles">
            We recently mailed you an activation key
          </strong>
        </h2>
      </div>
      <div id="activation-inputs">
        <fieldset class="content-section">
          <legend class="information-title-styles">
            <h2 id="your-information-title" class="h6 pre-form-content">
              Enter this activation key to complete online registration and access your account.<br />
              Can't find your key? Call us at <strong>1-877-304-1065.</strong>
            </h2>
          </legend>
          <div class="form-group">
            <bolt-textfield
              [_id]="activationControls.activation.id"
              _name="activation"
              [_aria-describedby]="activationControls.activation.ariaDescribedBy"
              [dropSpecialCharacters]="false"
              maxlength="30"
              (keypress)="activationInputValidation($event)"
              [formGroup]="activationForm"
              [parentForm]="activationFormParent"
              >Activation Key
            </bolt-textfield>
            <bolt-field-error
              id="non-instant-activation-errors"
              _id="non-instant-activation-errors"
              _class="field-error"
              [errors]="activationForm.get('activation').errors"
              [errorMessages]="activationControlErrors.activation"
              *ngIf="activationFormParent.submitted"
            ></bolt-field-error>
            <span id="non-instant-activation-help-message" class="help-text">
              <em>{{ activationControls.activation.helpText }}</em>
            </span>
          </div>
        </fieldset>
      </div>
    </section>

    <section id="activation-buttons" class="button-container">
      <div class="container button-stack">
        <bolt-button-primary ciamClickLogger _id="activation-find-account" _type="submit">
          Confirm code
        </bolt-button-primary>
        <input
          ciamClickLogger
          type="button"
          id="activation-cancel"
          class="btn btn-default"
          value="Cancel"
          data-cancel-trigger
        />
      </div>
    </section>
  </form>
</div>
