import { Injector } from '@angular/core';

// https://devblogs.microsoft.com/premier-developer/angular-how-to-simplify-components-with-typescript-inheritance/#use-a-class-to-store-the-injector

export class AppInjector {
  private static _injector: Injector;

  static get injector(): Injector {
    return AppInjector._injector;
  }

  static set injector(injector: Injector) {
    AppInjector._injector = injector;
  }
}
