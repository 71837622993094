import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessLockedComponent } from './access-locked/access-locked.component';
import { AccountIssueComponent } from './account-issue/account-issue.component';
import { ERROR_PATHS } from './error-paths';
import { OnlineAccessErrorComponent } from './online-access-error/online-access-error.component';
import { SnagErrorComponent } from './snag-error/snag-error.component';
import { SystemErrorComponent } from './system-error/system-error.component';

export const ERROR_ROUTES: Routes = [
  { path: ERROR_PATHS.accessLocked, component: AccessLockedComponent },
  { path: ERROR_PATHS.onlineAccessError, component: OnlineAccessErrorComponent },
  { path: ERROR_PATHS.snagError, component: SnagErrorComponent },
  { path: ERROR_PATHS.systemError, component: SystemErrorComponent },
  { path: ERROR_PATHS.accountIssue, component: AccountIssueComponent }
];

@NgModule({
  imports: [RouterModule.forChild(ERROR_ROUTES)],
  exports: [RouterModule]
})
export class ErrorRoutingModule {}
