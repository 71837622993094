<div class="d-flex flex-column">
  <div class="d-flex flex-column justify-content-between page-content">
    <section attr.id="{{ errorId + '-content' }}" class="container">
      <div attr.id="{{ errorId + '-header' }}" class="content-section">
        <bolt-alert-error #pageLevelError _id="page-level-error">
          <ng-container *ngTemplateOutlet="message"></ng-container>
        </bolt-alert-error>
      </div>
      <ng-container *ngTemplateOutlet="content ? content : defaultContent"></ng-container>
      <div class="content-section">
        <h2 id="contact-us-title" class="h5 pre-form-content" *ngIf="!userAgent.isFirefox()">
          Contact us
        </h2>
        <h2
          #adaAutoFocus
          id="contact-us-title"
          class="h5 pre-form-content"
          aria-describedby="page-level-error"
          *ngIf="userAgent.isFirefox()"
        >
          Contact us
        </h2>
        <div id="contact-us-content">
          <ciam-contact-us _aria-describedby="contact-us-title"></ciam-contact-us>
        </div>
      </div>
    </section>
  </div>
</div>

<ng-template #defaultContent></ng-template>
