<div id="contact-us" class="table-responsive">
  <table id="contact-table" class="table borderless" [attr.aria-describedby]="ariaDescribedby">
    <tr id="general-table" class="border-bottom">
      <th scope="row" id="general-header">General questions</th>
      <td id="general-number" headers="general-header">
        <span>1-877 On Your Side </span>
        <span class="text-nowrap"
          >(<ciam-phone-number key="NW_SERVICE_CENTER" _id="contact-us-service-center-phone"> </ciam-phone-number
          >)</span
        >
      </td>
      <td id="general-hours" headers="general-header">
        <span class="text-nowrap">Weekdays: </span>
        <span class="text-nowrap">8 a.m. to 9 p.m. EST</span>
        <br />
        <span class="text-nowrap">Weekends: </span>
        <span class="text-nowrap">9 a.m. to 7 p.m. EST</span>
      </td>
    </tr>
    <tr id="web-table" class="border-bottom">
      <th scope="row" id="web-header">Web support</th>
      <td id="web-number" class="text-nowrap" headers="web-header">
        <ciam-phone-number key="WEB_SUPPORT" _id="contact-us-web-support-phone"></ciam-phone-number>
      </td>
      <td id="web-hours" headers="web-header">
        <span class="text-nowrap">Monday - Friday </span>
        <span class="text-nowrap">9 a.m. - 9 p.m. ET</span>
        <br />
        Saturday <span class="text-nowrap">8:30 a.m. - 5 p.m. ET</span>
      </td>
    </tr>
    <tr id="pet-table" class="border-bottom">
      <th scope="row" id="pet-header">Pet inquiries & pet web support</th>
      <td id="pet-number" class="text-nowrap" headers="pet-header">
        <ciam-phone-number key="PET_SUPPORT" _id="contact-us-pet-support-phone"></ciam-phone-number>
      </td>
      <td id="pet-hours" headers="pet-header">
        <span class="text-nowrap">Monday - Friday </span>
        <span class="text-nowrap">8 a.m. - 10 p.m. ET</span>
        <br />
        Saturday <span class="text-nowrap">10:00 a.m. - 6:30 p.m. ET</span>
      </td>
    </tr>
    <tr id="billing-table" class="border-bottom">
      <th scope="row" id="billing-header">Billing support</th>
      <td id="billing-number" class="text-nowrap" headers="billing-header">
        <ciam-phone-number key="BILLING_SUPPORT" _id="contact-us-billing-phone"></ciam-phone-number>
      </td>
      <td id="billing-hours" headers="billing-header">Anytime</td>
    </tr>
    <tr id="claims-table" class="border-bottom">
      <th scope="row" id="claims-header">Claims support</th>
      <td id="claims-number" class="text-nowrap" headers="claims-header">
        <ciam-phone-number key="CLAIMS_SUPPORT" _id="contact-us-claims-phone"></ciam-phone-number>
      </td>
      <td id="claims-hours" headers="claims-header">Anytime</td>
    </tr>
    <tr id="life-table" class="border-bottom">
      <th scope="row" id="life-header">Life insurance & investing</th>
      <td id="life-number" class="text-nowrap" headers="life-header">
        <ciam-phone-number key="LIFE_INVESTING_SUPPORT" _id="contact-us-life-investing-phone"></ciam-phone-number>
      </td>
      <td id="life-hours" headers="life-header">
        <span class="text-nowrap">Monday - Friday </span>
        <span class="text-nowrap">8 a.m. - 8 p.m. ET</span>
      </td>
    </tr>
    <tr id="annuity-table" class="border-bottom">
      <th scope="row" id="annuity-header">Annuity & life tech support</th>
      <td id="annuity-number" class="text-nowrap" headers="annuity-header">
        <ciam-phone-number key="ANNUITY_LIFE_SUPPORT" _id="contact-us-annuity-life-phone"></ciam-phone-number>
      </td>
      <td id="annuity-hours" headers="annuity-header">
        <span class="text-nowrap">Monday - Friday </span>
        <span class="text-nowrap">8 a.m. - 8 p.m. ET</span>
      </td>
    </tr>
    <tr id="retirement-table" class="border-bottom">
      <th scope="row" id="retirement-header">Retirement plans tech support</th>
      <td id="retirement-number" class="text-nowrap" headers="retirement-header">
        <ciam-phone-number key="RETIREMENT_PLAN_SUPPORT" _id="contact-us-rp-phone"></ciam-phone-number>
      </td>
      <td id="retirement-hours" headers="retirement-header">
        <span class="text-nowrap">Monday - Friday </span>
        <span class="text-nowrap">8 a.m. - 8 p.m. ET</span>
      </td>
    </tr>
    <tr id="commercial-table" class="border-bottom">
      <th scope="row" id="commercial-header">Commercial service center</th>
      <td id="commercial-number" class="text-nowrap" headers="commercial-header">
        <ciam-phone-number key="COMMERCIAL_SERVICE_CENTER" _id="contact-us-commercial-phone"></ciam-phone-number>
      </td>
      <td id="commercial-hours" headers="commercial-header">
        <span class="text-nowrap">Monday - Friday </span>
        <span class="text-nowrap">8 a.m. - 8 p.m. ET</span>
      </td>
    </tr>
    <tr id="smartride-table" class="border-bottom">
      <th scope="row" id="smartride-header">SmartRide&reg;</th>
      <td id="smartride-number" class="text-nowrap" headers="smartride-header">
        <ciam-phone-number key="SMARTRIDE_SUPPORT" _id="contact-us-smartride-phone"></ciam-phone-number>
      </td>
      <td id="smartride-hours" headers="smartride-header">
        <span class="text-nowrap">Monday - Friday </span>
        <span class="text-nowrap">8 a.m. - 9 p.m. ET</span>
      </td>
    </tr>
  </table>

  <div>
    <a ciamClickLogger id="email-link" href="https://nationwide.com/personal/contact/online-form" target="_blank">
      <strong>Send us an email</strong>
    </a>
  </div>
</div>
