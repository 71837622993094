<div class="d-flex flex-column">
  <form
    id="reset-username-password-form"
    class="d-flex flex-column justify-content-between page-content"
    [formGroup]="resetUsernamePasswordForm"
    #resetUsernamePasswordFormParent="ngForm"
    (ngSubmit)="resetPageErrorAndFocus()"
  >
    <section id="reset-username-password-content" class="container">
      <div id="reset-username-password-header" class="content-section">
        <div id="reset-username-password-error">
          <bolt-alert-error
            #pageLevelError
            _id="page-level-error"
            [hidden]="!(resetUsernamePasswordFormParent.submitted && resetUsernamePasswordForm.invalid)"
          >
            Please correct the following to continue.
          </bolt-alert-error>
        </div>
        <ng-container *ngIf="fromRegistration; else sameFlow">
          <h1 id="main-content" class="h3">Hmm. You already have an online account.</h1>
          <h2 id="account-found-title" class="h5">We can take care of that here.</h2>
        </ng-container>
        <ng-template #sameFlow>
          <h2 #adaAutoFocus id="account-found-title" class="h5"><strong>We found your account.</strong></h2>
        </ng-template>
      </div>
      <div id="current-username-display" class="content-section">
        <p id="current-username-label">
          <strong>Your current username</strong><br /><span id="current-username">{{ initialUserName }}</span>
        </p>
      </div>
      <div id="reset-username-inputs" class="content-section">
        <p id="account-found-message">
          If you'd like, you may update your current username.
        </p>
        <div id="reset-username-input" class="content-section">
          <div class="form-group">
            <bolt-textfield
              ciamDelayedFocusout
              [_id]="resetUsernamePasswordControls.username.id"
              _name="username"
              [_aria-describedby]="resetUsernamePasswordControls.username.ariaDescribedBy"
              _class="nwpii"
              maxlength="60"
              [formGroup]="resetUsernamePasswordForm"
              [parentForm]="resetUsernamePasswordFormParent"
              (input)="currentUsernameInput = $event.target.value"
              (focusin)="usernameChecklist.hidden = false"
              (delayedFocusout)="usernameChecklist.hidden = true"
            >
              Update username <em class="font-weight-lighter">(optional)</em>
            </bolt-textfield>
            <bolt-field-error
              id="reset-username-errors"
              _id="reset-username-errors"
              _class="field-error"
              [errors]="resetUsernamePasswordForm.get('username').errors"
              [errorMessages]="resetUsernamePasswordControlErrors.username"
              *ngIf="resetUsernamePasswordFormParent.submitted"
            ></bolt-field-error>
            <div #usernameChecklist id="username-validations" class="mt-2" hidden>
              <span id="username-validations-header">Your username is not case sensitive and must have:</span>
              <ciam-dynamic-checklist
                [dynamicChecklist]="usernameDynamicChecklist"
                [currentInput]="currentUsernameInput"
              >
              </ciam-dynamic-checklist>
            </div>
          </div>
        </div>
      </div>
      <div id="reset-password-inputs" class="content-section">
        <h3 id="reset-password-header" class="h5">Reset your password</h3>
        <div class="form-group">
          <bolt-textfield
            ciamDelayedFocusout
            [_id]="resetUsernamePasswordControls.password.id"
            _name="password"
            [_aria-describedby]="resetUsernamePasswordControls.password.ariaDescribedBy"
            _class="nwpii"
            maxlength="30"
            maskDescription="password"
            [maskedToggle]="true"
            [formGroup]="resetUsernamePasswordForm"
            [parentForm]="resetUsernamePasswordFormParent"
            (input)="currentPasswordInput = $event.target.value"
            (focusin)="passwordChecklist.hidden = false"
            (delayedFocusout)="passwordChecklist.hidden = true"
            (buttonClicked)="handleShowHideClick($event)"
          >
            New password
          </bolt-textfield>
          <bolt-field-error
            id="reset-password-errors"
            _id="reset-password-errors"
            _class="field-error"
            [errors]="resetUsernamePasswordForm.get('password').errors"
            [errorMessages]="resetUsernamePasswordControlErrors.password"
            *ngIf="resetUsernamePasswordFormParent.submitted"
          ></bolt-field-error>
          <div #passwordChecklist id="password-validations" class="mt-3" hidden>
            <span id="password-validations-header">Passwords are case sensitive and must have:</span>
            <ciam-dynamic-checklist [dynamicChecklist]="passwordDynamicChecklist" [currentInput]="currentPasswordInput">
            </ciam-dynamic-checklist>
          </div>
        </div>
        <div class="form-group">
          <bolt-textfield
            [_id]="resetUsernamePasswordControls.passwordConfirmation.id"
            _name="passwordConfirmation"
            _class="nwpii"
            maxlength="30"
            maskDescription="password"
            [maskedToggle]="true"
            [formGroup]="resetUsernamePasswordForm"
            [parentForm]="resetUsernamePasswordFormParent"
            (buttonClicked)="handleShowHideClick($event)"
          >
            Confirm new password
          </bolt-textfield>
          <bolt-field-error
            id="reset-password-password-confirmation-errors"
            _id="reset-password-password-confirmation-errors"
            _class="field-error"
            [errors]="resetUsernamePasswordForm.get('passwordConfirmation').errors"
            [errorMessages]="resetUsernamePasswordControlErrors.passwordConfirmation"
            *ngIf="resetUsernamePasswordFormParent.submitted"
          ></bolt-field-error>
        </div>
      </div>
    </section>
    <section id="reset-username-password-buttons" class="button-container">
      <div class="container button-stack">
        <bolt-button-primary ciamClickLogger _id="reset-username-password-continue" _type="submit">
          Continue
        </bolt-button-primary>
        <input
          ciamClickLogger
          type="button"
          id="reset-username-password-cancel"
          class="btn btn-default"
          value="Cancel"
          data-cancel-trigger
        />
      </div>
    </section>
  </form>
</div>
