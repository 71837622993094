export enum AccountStatus {
  ACTIVATED = 'Activated',
  ACTIVE = 'Active',
  CSR_RESET = 'CSRReset',
  DISABLED = 'Disabled',
  ENABLED = 'Enabled',
  INSTANT = 'Instant',
  NO_ACCESS = 'NoAccess',
  NON_INSTANT = 'Non-instant',
  PARTIAL = 'Partial',
  PROXY = 'Proxy',
  PROXY_RESET_PASSWORD = 'PROXY_RESET_PASSWORD',
  RESET_PASSWORD = 'RESET_PASSWORD'
}

export enum AccessLevel {
  ONE = '1',
  TWO = '2',
  ONE_HUNDRED = '100',
  ONE_HUNDRED_TEN = '110',
  ONE_HUNDRED_FIFTY = '150',
  FOUR_HUNDRED = '400'
}

export enum AccountAccessType {
  ACTIVATED_1,
  ACTIVATED_2,
  ACTIVE_150,
  CSR_RESET_1,
  CSR_RESET_110,
  DISABLED_1,
  DISABLED_100,
  DISABLED_150,
  DISABLED_2,
  ENABLED,
  INSTANT_2,
  NO_ACCESS_400,
  NON_INSTANT_1,
  PARTIAL_1,
  PROXY_1,
  PROXY_RESET_PASSWORD,
  RESET_PASSWORD_1,
  RESET_PASSWORD_110
}

export interface AccountAccess {
  type: AccountAccessType;
  access: {
    accountStatus: AccountStatus;
    accessLevel: AccessLevel;
  };
}

export const VALID_ACCOUNT_ACCESSES: AccountAccess[] = [
  {
    type: AccountAccessType.ACTIVATED_1,
    access: {
      accountStatus: AccountStatus.ACTIVATED,
      accessLevel: AccessLevel.ONE
    }
  },
  {
    type: AccountAccessType.ACTIVATED_2,
    access: {
      accountStatus: AccountStatus.ACTIVATED,
      accessLevel: AccessLevel.TWO
    }
  },
  {
    type: AccountAccessType.ACTIVE_150,
    access: {
      accountStatus: AccountStatus.ACTIVE,
      accessLevel: AccessLevel.ONE_HUNDRED_FIFTY
    }
  },
  {
    type: AccountAccessType.CSR_RESET_1,
    access: {
      accountStatus: AccountStatus.CSR_RESET,
      accessLevel: AccessLevel.ONE
    }
  },
  {
    type: AccountAccessType.CSR_RESET_110,
    access: {
      accountStatus: AccountStatus.CSR_RESET,
      accessLevel: AccessLevel.ONE_HUNDRED_TEN
    }
  },
  {
    type: AccountAccessType.DISABLED_1,
    access: {
      accountStatus: AccountStatus.DISABLED,
      accessLevel: AccessLevel.ONE
    }
  },
  {
    type: AccountAccessType.DISABLED_100,
    access: {
      accountStatus: AccountStatus.DISABLED,
      accessLevel: AccessLevel.ONE_HUNDRED
    }
  },
  {
    type: AccountAccessType.DISABLED_150,
    access: {
      accountStatus: AccountStatus.DISABLED,
      accessLevel: AccessLevel.ONE_HUNDRED_FIFTY
    }
  },
  {
    type: AccountAccessType.DISABLED_2,
    access: {
      accountStatus: AccountStatus.DISABLED,
      accessLevel: AccessLevel.TWO
    }
  },
  {
    type: AccountAccessType.ENABLED,
    access: {
      accountStatus: AccountStatus.ENABLED,
      accessLevel: null
    }
  },
  {
    type: AccountAccessType.INSTANT_2,
    access: {
      accountStatus: AccountStatus.INSTANT,
      accessLevel: AccessLevel.TWO
    }
  },
  {
    type: AccountAccessType.NO_ACCESS_400,
    access: {
      accountStatus: AccountStatus.NO_ACCESS,
      accessLevel: AccessLevel.FOUR_HUNDRED
    }
  },
  {
    type: AccountAccessType.NON_INSTANT_1,
    access: {
      accountStatus: AccountStatus.NON_INSTANT,
      accessLevel: AccessLevel.ONE
    }
  },
  {
    type: AccountAccessType.PARTIAL_1,
    access: {
      accountStatus: AccountStatus.PARTIAL,
      accessLevel: AccessLevel.ONE
    }
  },
  {
    type: AccountAccessType.PROXY_1,
    access: {
      accountStatus: AccountStatus.PROXY,
      accessLevel: AccessLevel.ONE
    }
  },
  {
    type: AccountAccessType.PROXY_RESET_PASSWORD,
    access: {
      accountStatus: AccountStatus.PROXY_RESET_PASSWORD,
      accessLevel: null
    }
  },
  {
    type: AccountAccessType.RESET_PASSWORD_1,
    access: {
      accountStatus: AccountStatus.RESET_PASSWORD,
      accessLevel: AccessLevel.ONE
    }
  },
  {
    type: AccountAccessType.RESET_PASSWORD_110,
    access: {
      accountStatus: AccountStatus.RESET_PASSWORD,
      accessLevel: AccessLevel.ONE_HUNDRED_TEN
    }
  }
];

export const findAccountAccess = (accountStatus: string, accessLevel: string): AccountAccess =>
  VALID_ACCOUNT_ACCESSES.find(
    accountAccess =>
      accountStatus?.toLowerCase() === accountAccess.access.accountStatus.toLowerCase() &&
      (accountAccess.access.accessLevel === null ||
        accessLevel?.toLowerCase() === accountAccess.access.accessLevel.toLowerCase())
  );
