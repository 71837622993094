import { Routes, mapToCanActivate } from '@angular/router';
import { CommonFlow } from '@shared/navigation/flow.enum';
import { StorageGuard } from '@shared/storage/storage.guard';
import { TimeoutGuard } from '@shared/timeout/timeout.guard';
import { AccountTypeComponent } from './account-type/account-type.component';
import { AdditionalInformationComponent } from './additional-information/additional-information.component';
import { BusinessAccountNotFoundComponent } from './business-account-not-found/business-account-not-found.component';
import { BusinessIdentifierComponent } from './identifier/business/business-identifier.component';
import { PersonalIdentifierComponent } from './identifier/personal/personal-identifier.component';
import { ControllerIndividualInformationComponent } from './individual-information/controller/controller-individual-information.component';
import { PersonalIndividualInformationComponent } from './individual-information/personal/personal-individual-information.component';
import { SEARCH_ADDL_INFO_PATHS, SEARCH_PATHS } from './search-paths';
import { SearchFlowType } from './shared/search.model';
import { StartOverComponent } from './start-over/start-over.component';

export const SEARCH_ROUTES: Routes = [
  {
    path: CommonFlow.SEARCH,
    canActivate: mapToCanActivate([TimeoutGuard]),
    children: [
      {
        path: SEARCH_PATHS.accountType,
        component: AccountTypeComponent,
        canActivate: mapToCanActivate([StorageGuard])
      },
      {
        path: SearchFlowType.PERSONAL,
        children: [
          { path: SEARCH_PATHS.individualInformation, component: PersonalIndividualInformationComponent },
          {
            path: SEARCH_PATHS.additionalInformation,
            children: [
              {
                path: SEARCH_ADDL_INFO_PATHS.dateOfBirth,
                component: AdditionalInformationComponent
              },
              {
                path: SEARCH_ADDL_INFO_PATHS.phoneNumber,
                component: AdditionalInformationComponent
              },
              {
                path: SEARCH_ADDL_INFO_PATHS.email,
                component: AdditionalInformationComponent
              },
              {
                path: SEARCH_ADDL_INFO_PATHS.addressNumber,
                component: AdditionalInformationComponent
              }
            ]
          },
          { path: SEARCH_PATHS.identifier, component: PersonalIdentifierComponent },
          { path: SEARCH_PATHS.startOver, component: StartOverComponent }
        ]
      },
      {
        path: SearchFlowType.BUSINESS,
        children: [
          { path: SEARCH_PATHS.identifier, component: BusinessIdentifierComponent },
          {
            path: SEARCH_PATHS.additionalInformation,
            children: [
              {
                path: SEARCH_ADDL_INFO_PATHS.dateOfBirth,
                component: AdditionalInformationComponent
              },
              {
                path: SEARCH_ADDL_INFO_PATHS.phoneNumber,
                component: AdditionalInformationComponent
              },
              {
                path: SEARCH_ADDL_INFO_PATHS.email,
                component: AdditionalInformationComponent
              },
              {
                path: SEARCH_ADDL_INFO_PATHS.addressNumber,
                component: AdditionalInformationComponent
              }
            ]
          },
          { path: SEARCH_PATHS.accountNotFound, component: BusinessAccountNotFoundComponent },
          { path: SEARCH_PATHS.startOver, component: StartOverComponent }
        ]
      },
      {
        path: SearchFlowType.CONTROLLER,
        children: [
          { path: SEARCH_PATHS.individualInformation, component: ControllerIndividualInformationComponent },
          {
            path: SEARCH_PATHS.additionalInformation,
            children: [
              {
                path: SEARCH_ADDL_INFO_PATHS.dateOfBirth,
                component: AdditionalInformationComponent
              },
              {
                path: SEARCH_ADDL_INFO_PATHS.phoneNumber,
                component: AdditionalInformationComponent
              },
              {
                path: SEARCH_ADDL_INFO_PATHS.email,
                component: AdditionalInformationComponent
              },
              {
                path: SEARCH_ADDL_INFO_PATHS.addressNumber,
                component: AdditionalInformationComponent
              }
            ]
          },
          { path: SEARCH_PATHS.startOver, component: StartOverComponent }
        ]
      }
    ]
  }
];
