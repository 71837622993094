import { Component } from '@angular/core';
import { AdaBaseComponent } from '@shared/base-component/ada-base.component';

@Component({
  selector: 'ciam-invalid-phone',
  templateUrl: './invalid-phone.component.html',
  styleUrls: ['./invalid-phone.component.scss']
})
export class InvalidPhoneComponent extends AdaBaseComponent {
  protected readonly className = 'InvalidPhoneComponent';
}
