import { LogLevel } from '@nw/eim-angular-logging';
import { AdditionalInformationType, IdentifierType } from '../app/common/search/shared/search.model';
import { isMockEnabled } from './environment-util';

export const ENVIRONMENT = {
  name: 'dev',
  production: false,
  features: {
    general: {
      cookieDomain: '.nationwide.com',
      navigationGuard: true,
      mfaStatusGuard: true,
      flowTimeout: {
        enabled: true,
        time: 1139000
      }
    },
    search: {
      maxAttempts: {
        businessInfo: 2,
        individualInfo: 2,
        identifier: {
          [IdentifierType.SOCIAL]: 2,
          [IdentifierType.ACCOUNT]: 2
        },
        additionalInfo: {
          [AdditionalInformationType.DATE_OF_BIRTH]: 2,
          [AdditionalInformationType.PHONE_NUMBER]: 2,
          [AdditionalInformationType.EMAIL]: 2,
          [AdditionalInformationType.ADDRESS_NUMBER]: 2
        }
      }
    },
    verify: {
      maxAttempts: {
        personalInformation: 2,
        additionalIdentifier: {
          [IdentifierType.SOCIAL]: 2,
          [IdentifierType.ACCOUNT]: 2
        }
      }
    },
    mfa: {
      maxAttempts: {
        newPhone: 2
      }
    },
    registration: {
      individualEnabled: true,
      businessEnabled: true,
      trustEnabled: false
    },
    oneTimeLink: {
      enabled: true
    }
  },
  apiConfig: {
    clientId: 'rX2XC6LfPLxVdxJ7yJHNUifLMS3gefdS',
    proxyClientId: 'GyhmDqHeDNbaQLkEwKp1GFl77syTVJcy',
    consumerId: 'CIAM',
    services: {
      oauth: {
        basePath: isMockEnabled()
          ? 'https://ciam-wiremock-dev.apps.nwie.net/security-processing/enterprise-user-auth/v2'
          : 'https://api-test.nwie.net/security-processing/enterprise-user-auth/v2'
      },
      customerOtp: {
        basePath: isMockEnabled()
          ? 'https://ciam-wiremock-dev.apps.nwie.net/customer-relationship-management/customer-otp/v2'
          : 'https://api-dev.nwie.net/customer-relationship-management/customer-otp/v2'
      },
      mfaUserauthExternalLogin: {
        basePath: isMockEnabled()
          ? 'https://ciam-wiremock-dev.apps.nwie.net/mfa-userauth-external-login/v1'
          : 'https://api-dev.nwie.net/mfa-userauth-external-login/v1'
      },
      internetRegistration: {
        basePath: isMockEnabled()
          ? 'https://ciam-wiremock-dev.apps.nwie.net/customer-relationship-management/internet-registration/v3'
          : 'https://api-dev.nwie.net/customer-relationship-management/internet-registration/v3'
      },
      clientCredentialsToken: {
        basePath: 'https://api-dev.nwie.net/client-credentials-token/v1',
        path: '/customers/:ecn/refid'
      },
      bankAccountVerification: {
        basePath: 'https://api-dev.nwie.net/billing-collections/bank-account-verification/v1'
      },
      personalLinesBilling: {
        basePath: 'https://api-dev.nwie.net/billing-collections/personal-lines-billing/v2'
      },
      clientCredentialsModisToken: {
        basePath: 'https://api-dev.nwie.net/customer-relationship-management/ssc-sso-token-api/v1',
        path: '/customers/:ecn/modisToken'
      }
    }
  },
  externalUrls: {
    nationwide: {
      basePath: 'https://www.nationwide.com',
      personalSupport: '/personal/contact/support'
    },
    iam: {
      basePath: 'https://iam-dev-cnp.apps.nwie.net/access',
      login: '/web/login.htm',
      individualRegistration: '/register/signon-standard.htm?userType=individual',
      commercialRegistration: '/register/signon-commercial.htm',
      trustRegistration: '/register/signon-standard.htm?userType=trust',
      existingOnlineAccount: '/register/signon.x?existingAccount=true',
      trustForgotCredentials: '/retrieve-username/verify-user.htm?userType=trust',
      unprotectedLogin: '/redirect/un-protected-page.x',
      ciamRedirect: '/redirect/ciam-redirect.x',
      sessionExpired: '/web/sessionExpired.htm'
    },
    cam: {
      basePath: 'https://devl-myaccount.nationwide.nwie.net/myaccount',
      index: '/index.jsp'
    }
  },
  rsa: {
    js: 'https://pt-static.nationwide.com/static/js/rsa-plus-hashtable.js'
  },
  preferenceCenter: {
    basePath: 'https://st-static-nationwide.apps.nwie.net/app/preferences_2/application/'
  },
  trca: {
    sscPartnerSpId: 'https://betaretirementsolutions.financialtrans.com/tf/myPLANCAT/Welcome',
    sscHomePageRedirect: 'https://betaretirement.financialtrans.com/nationwide/modis-page/?token='
  },
  fullStory: {
    enabled: true,
    js: '//tags.nationwide.com/test/Bootstrap.js'
  },
  newRelic: {
    // https://rpm.newrelic.com/accounts/1092591/browser/629726453
    enabled: true,
    accountID: '1092591',
    trustKey: '2602088',
    agentID: '629726453',
    licenseKey: 'b06a1da2fa',
    applicationID: '629726453',
    beacon: 'bam-cell.nr-data.net',
    errorBeacon: 'bam-cell.nr-data.net',
    sa: 1
  },
  nora: {
    enabled: false,
    baseUrl: 'https://noratest-static.nationwide.com',
    chatbotJs: '/nw-chatbot.js',
    webchatJs: '/nw-webchat.js'
  },
  logging: {
    logToServer: true,
    logToConsole: true,
    splunkToken: '1D104122-5869-4954-855A-FD3F257E76C2',
    env: 'dev',
    app: 'ciam',
    sourceType: 'ciam_app_json',
    clientLoggingBasePath: 'https://api-test.nwie.net/it-management/client-logging/v1',
    rootLogLevel: LogLevel.DEBUG
  }
};
