import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ENVIRONMENT } from '@environments/environment';
import {
  AccountVerificationService,
  AccountVerifyRequest,
  AccountVerifyResponse
} from '@nationwide/api-client-bank-account-verification-v1';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DigitalRefundsService {
  constructor(private _accountVerificationService: AccountVerificationService, private _oauthService: OAuthService) {}

  verifyBankAccount(accountToVerify: AccountVerifyRequest): Observable<HttpResponse<AccountVerifyResponse>> {
    this._accountVerificationService.configuration.accessToken = this._oauthService.getAccessToken();
    const xNWMessageID = Date.now().toString();
    const clientId = ENVIRONMENT?.apiConfig?.clientId;
    return this._accountVerificationService.bankAccountsVerifyPost(xNWMessageID, clientId, accountToVerify, 'response');
  }
}
