<div class="d-flex flex-column">
  <div class="d-flex flex-column justify-content-between page-content">
    <section id="non-instant-success-content" class="container">
      <div
        [attr.id]="fromForgotCredentials ? 'main-content' : 'non-instant-success-header'"
        class="content-section no-outline"
        tabindex="-1"
      >
        <h2 #adaAutoFocus id="non-instant-success-title" class="h5">
          <strong>
            Watch for your activation key
          </strong>
        </h2>
        <p id="your-info-title">
          Your registration is almost complete. To help keep your personal information safe, we're sending you a
          personal activation key by U.S. mail. You'll be able to finish the activation process once you get the letter.
        </p>
      </div>

      <div class="button-standalone content-section">
        <bolt-button-primary
          ciamClickLogger
          _type="button"
          _id="non-instant-success-back-to-nw"
          (click)="backToNationwide()"
        >
          Back to nationwide.com
        </bolt-button-primary>
      </div>
    </section>
  </div>
</div>
