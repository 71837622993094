import { Component } from '@angular/core';

@Component({
  selector: 'app-account-issue',
  templateUrl: './account-issue.component.html',
  styleUrls: ['./account-issue.component.scss']
})
export class AccountIssueComponent {
  protected readonly className = 'AccountIssueComponent';
}
