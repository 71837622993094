import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ControlErrors, Controls } from '@shared/base-component/base.model';
import { ContentHeaderService } from '@shared/content-header/content-header.service';
import { NavigationService } from '@shared/navigation/navigation.service';
import { Customer, PartyType, SessionKey } from '@shared/storage/storage.model';
import { filter } from 'rxjs/operators';
import { REGISTRATION_PATHS } from '../registration-paths';
import { RegistrationBaseComponent } from '../shared/registration-base.component';
import { CONTROLS, CONTROL_ERRORS } from './controller-attestation.model';

@UntilDestroy()
@Component({
  selector: 'ciam-controller-attestation',
  templateUrl: './controller-attestation.component.html',
  styleUrls: ['./controller-attestation.component.scss']
})
export class ControllerAttestationComponent extends RegistrationBaseComponent implements OnInit {
  controllerAttestationFormParent: NgForm;
  controllerAttestationForm: UntypedFormGroup;
  controllerAttestationControls: Controls = CONTROLS;
  controllerAttestationControlErrors: ControlErrors = CONTROL_ERRORS;

  protected readonly className = 'ControllerAttestationComponent';

  constructor(
    private _contentHeaderService: ContentHeaderService,
    private _navigationService: NavigationService,
    private _formBuilder: UntypedFormBuilder
  ) {
    super();
  }

  ngOnInit(): void {
    this._contentHeaderService.updateProgress({
      progressTitle: 'Confirm permissions',
      progressPercent: 66
    });

    this.logger.info(this.className, 'Completing controller attestation.');

    this._initializeForm();
  }

  submit() {
    this.spinnerService.show();

    this.logger.info(this.className, 'Accepted the attestation.');

    const customer = this.sessionService.get(SessionKey.CUSTOMER) as Customer;
    this._navigationService.navigate(
      `${this.baseRoute}/${
        customer?.guid && customer?.partyType === PartyType.PERSON
          ? REGISTRATION_PATHS.updateLogin
          : REGISTRATION_PATHS.createLogin
      }`
    );
  }

  private _initializeForm() {
    this.controllerAttestationForm = this._formBuilder.group(
      {
        acceptedServiceAgreement: ['', Validators.requiredTrue]
      },
      {
        updateOn: 'submit'
      }
    );
    this.controllerAttestationForm.statusChanges
      .pipe(
        untilDestroyed(this),
        filter(() => this.controllerAttestationForm.valid)
      )
      .subscribe(() => {
        this.submit();
      });
  }
}
