import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ERROR_PATHS } from '@app/error/error-paths';
import { REGISTRATION_PATHS } from '@app/registration/registration-paths';
import { RegistrationBaseComponent } from '@app/registration/shared/registration-base.component';
import { RegistrationFlowType } from '@app/registration/shared/registration.model';
import { ENVIRONMENT } from '@environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ControlErrors, Controls } from '@shared/base-component/base.model';
import { ContentHeaderService } from '@shared/content-header/content-header.service';
import {
  DynamicChecklistItem,
  PASSWORD_DYNAMIC_CHECKLIST,
  USERNAME_DYNAMIC_CHECKLIST
} from '@shared/dynamic-checklist/dynamic-checklist-item.model';
import { Flow } from '@shared/navigation/flow.enum';
import { NavigationService } from '@shared/navigation/navigation.service';
import { CustomCookieService } from '@shared/storage/custom-cookie.service';
import { Customer, SessionKey } from '@shared/storage/storage.model';
import { validatePasswordsMatch } from '@shared/validation/validate-passwords-match';
import { validateUsernamePasswordDoNotMatch } from '@shared/validation/validate-username-password-not-match';
import { filter } from 'rxjs/operators';
import { CONTROLS, CONTROL_ERRORS, UpdateUsernamePasswordModel } from './update-username-password.model';
import { UpdateUsernamePasswordService } from './update-username-password.service';

@UntilDestroy()
@Component({
  selector: 'ciam-update-username-password',
  templateUrl: './update-username-password.component.html',
  styleUrls: ['./update-username-password.component.scss']
})
export class UpdateUsernamePasswordComponent extends RegistrationBaseComponent implements OnInit {
  updateUsernamePasswordForm: UntypedFormGroup;
  updateUsernamePasswordControls: Controls = CONTROLS;
  updateUsernamePasswordControlErrors: ControlErrors = CONTROL_ERRORS;
  currentUsernameInput = '';
  usernameDynamicChecklist: Array<DynamicChecklistItem> = USERNAME_DYNAMIC_CHECKLIST;

  currentPasswordInput = '';
  userName = '';
  passwordDynamicChecklist: Array<DynamicChecklistItem> = PASSWORD_DYNAMIC_CHECKLIST;

  protected readonly className = 'UpdateUsernamePasswordComponent';

  private readonly _spinnerMessage = 'Updating username and password details...';

  constructor(
    private _contentHeaderService: ContentHeaderService,
    private _navigationService: NavigationService,
    private _customCookieService: CustomCookieService,
    private _updateUsernamePasswordService: UpdateUsernamePasswordService,
    private _formBuilder: UntypedFormBuilder
  ) {
    super();
  }

  ngOnInit() {
    this.logger.info(this.className, 'Entering account information.');
    this._contentHeaderService.reset({
      title: 'Sign up for online access',
      progressTitle: 'Update username and password',
      progressPercent: 66
    });
    this.userName = (this.sessionService.get(SessionKey.CUSTOMER) as Customer)?.userName;
    this._initializeForm();
  }

  handleShowHideClick(event: Event) {
    this.logger.logElementClick(this.className, event);
  }

  trimWhiteSpace(event: any) {
    this.updateUsernamePasswordForm.get('emailAddress').setValue(event.target.value.trim());
  }
  _submit() {
    this.spinnerService.show(this._spinnerMessage);

    const updateUsernamePasswordModel: UpdateUsernamePasswordModel = {
      userName: this.updateUsernamePasswordForm.get('username').value,
      password: this.updateUsernamePasswordForm.get('password').value,
      emailAddress: this.updateUsernamePasswordForm.get('emailAddress').value,
      phoneNumber: this.updateUsernamePasswordForm.get('phoneNumber').value,
      acceptedEsa: this.updateUsernamePasswordForm.get('acceptedEsa').value,
      registration: true
    };
    this._updateUsernamePasswordService
      .updateUsernamePassword(updateUsernamePasswordModel)
      .pipe(untilDestroyed(this))
      .subscribe(
        response => {
          if (response.status === 200) {
            this._routeToIamSuccessPage(response);
          }

          if (response.status === 207) {
            this.logger.error(this.className, 'Error occurred while updating account - Partial Success.');
            this._navigationService.navigateToError(
              this.className,
              new Error(),
              ERROR_PATHS.onlineAccessError,
              'RegistrationService updateAccount failed due to partial success.'
            );
          }
        },
        error => {
          if (error instanceof HttpErrorResponse && error.error?.code === 4215) {
            this.logger.warn(this.className, 'Username unavailable.');
            this.updateUsernamePasswordForm.get('username').setErrors({ usernameUnavailable: true });
            this.spinnerService.hide();
          } else {
            this.logger.error(this.className, 'Error occurred while updating account.');
            this._navigationService.navigateToError(
              this.className,
              error,
              ERROR_PATHS.onlineAccessError,
              'RegistrationService updateAccount failed.'
            );
          }
        }
      );
  }

  private _initializeForm() {
    this.updateUsernamePasswordForm = this._formBuilder.group(
      {
        username: [
          this.userName ? this.userName : '',
          [Validators.required, ...this._buildPatternValidators(this.updateUsernamePasswordControls.username.patterns)]
        ],
        password: [
          '',
          [Validators.required, ...this._buildPatternValidators(this.updateUsernamePasswordControls.password.patterns)]
        ],
        passwordConfirmation: ['', [Validators.required]],
        phoneNumber: [
          this.sessionService.get(SessionKey.MFA)?.newMobileNumber || '',
          this._buildPatternValidators(this.updateUsernamePasswordControls.phoneNumber.patterns)
        ],
        emailAddress: [
          '',
          [
            Validators.required,
            ...this._buildPatternValidators(this.updateUsernamePasswordControls.emailAddress.patterns)
          ]
        ],
        acceptedEsa: ['', Validators.requiredTrue]
      },
      {
        updateOn: 'submit',
        validators: [validatePasswordsMatch, validateUsernamePasswordDoNotMatch]
      }
    );

    this.updateUsernamePasswordForm.statusChanges
      .pipe(
        untilDestroyed(this),
        filter(() => this.updateUsernamePasswordForm.valid)
      )
      .subscribe(() => {
        this._submit();
      });
  }

  private _routeToIamSuccessPage(response: any) {
    if (response.device?.deviceToken) {
      this._customCookieService.deviceToken = response.device.deviceToken;
    }
    if (response.body?.pingToken) {
      this._navigationService.navigateExternal(
        `${ENVIRONMENT.externalUrls.iam.basePath}${ENVIRONMENT.externalUrls.iam.unprotectedLogin}?redirectURL=${ENVIRONMENT.externalUrls.iam.basePath}${ENVIRONMENT.externalUrls.iam.ciamRedirect}&pingToken=${response.body.pingToken}&type=proxy`
      );
    } else {
      this.sessionService.setModifiedSessionObject(
        this.updateUsernamePasswordForm.get('username').value,
        SessionKey.CUSTOMER,
        'userName'
      );
      this._navigationService.navigate(
        `${Flow.REGISTRATION}/${RegistrationFlowType.PERSONAL}/${REGISTRATION_PATHS.instantSuccess}`
      );
    }
  }

  protected get formGroup(): UntypedFormGroup {
    return this.updateUsernamePasswordForm;
  }

  protected get controls(): Controls {
    return this.updateUsernamePasswordControls;
  }

  protected get controlErrors(): ControlErrors {
    return this.updateUsernamePasswordControlErrors;
  }
}
