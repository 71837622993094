import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ERROR_PATHS } from '@app/error/error-paths';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ModalEvent } from '@nw/bolt-angular';
import { ControlErrors, Controls } from '@shared/base-component/base.model';
import { CancelBodyMessages } from '@shared/cancel/cancel.model';
import { CancelService } from '@shared/cancel/cancel.service';
import { ContentHeaderService } from '@shared/content-header/content-header.service';
import { Observable, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { MFA_PATHS } from '../mfa-route-paths';
import { ContactPoint, ContactPointType } from '../shared/contact-point.model';
import { MfaBaseComponent } from '../shared/mfa-base.component';
import { MfaService } from '../shared/mfa.service';
import { CONTROLS, CONTROL_ERRORS } from './verify-code.model';

@UntilDestroy()
@Component({
  selector: 'ciam-verify-code',
  templateUrl: './verify-code.component.html',
  styleUrls: ['./verify-code.component.scss']
})
export class VerifyCodeComponent extends MfaBaseComponent implements OnInit {
  selectedContactPoint: ContactPoint;
  showNumbersOnlyHelpText = false;

  verifyCodeForm: UntypedFormGroup;
  verifyCodeControls: Controls = CONTROLS;
  verifyCodeControlErrors: ControlErrors = CONTROL_ERRORS;
  codeMask = '';

  protected readonly className = 'VerifyCodeComponent';

  private readonly _emailInstruction = 'Check your email in a new tab or browser window';
  private readonly _textInstruction = 'Check that your phone has a signal';
  private readonly _spinnerMessage = 'Verifying your code...';

  constructor(
    private _contentHeaderService: ContentHeaderService,
    private _cancelService: CancelService,
    private _mfaService: MfaService,
    private _formBuilder: UntypedFormBuilder
  ) {
    super();
  }

  ngOnInit() {
    this.logger.info(this.className, 'Verifying a code.');

    this._mfaService.model.user.requestedNewCode = false;
    delete this._mfaService.model.user.previousContactPoint;

    this._cancelService.model = { modalBody: CancelBodyMessages.MFA };

    this._initializeForm();

    this.selectedContactPoint = this._mfaService.model.user.selectedContactPoint;
  }

  handleWheresMyCodeModalSelection(modalEvent: ModalEvent) {
    this.logger.logElementClick(this.className, modalEvent.buttonEvent);

    if (modalEvent.choice === 'Send a new code') {
      // Saving these properties for logging around the "Where's my code?" process.
      this._mfaService.model.user.requestedNewCode = true;
      this._mfaService.model.user.previousContactPoint = this._mfaService.model.user.selectedContactPoint;

      this.spinnerService.show();
      super.sendNewCode();
    }
  }

  applyCodeMask() {
    if (!this.codeMask) {
      this.codeMask = '000000';
    }
  }

  checkForDigitEntry(event: KeyboardEvent) {
    if (event?.key?.length === 1 && !event.ctrlKey && !event.altKey) {
      if (
        !this.verifyCodeControls.code.patterns[0].test(event.key) &&
        ((event.target as HTMLInputElement) == null || (event.target as HTMLInputElement).value.length < 6)
      ) {
        this._handleNonNumericOtpCharacters(event, event.key);
      }
    }
  }

  checkForDigitPaste(event: ClipboardEvent) {
    const input = this._getCopyPasteContent(event);

    if (!this.verifyCodeControls.code.patterns[0].test(input)) {
      this._handleNonNumericOtpCharacters(event, input);
    }
  }

  checkForDigitDrag(event: DragEvent) {
    const input = event.dataTransfer.getData('Text');

    if (!this.verifyCodeControls.code.patterns[0].test(input)) {
      this._handleNonNumericOtpCharacters(event, input);
    }
  }

  _processFormSubmission(formControl: AbstractControl) {
    if (formControl.valid) {
      this._contentHeaderService.updateProgress({ progressTitle: 'Confirmation' });
      this.navigationService.navigate(`${this.baseRoute}/${MFA_PATHS.confirmation}`);
    } else if (formControl.errors.expired) {
      this.navigationService.navigate(`${this.baseRoute}/${MFA_PATHS.expiredCode}`);
    } else if (formControl.errors.locked) {
      this.navigationService.navigateToError(
        this.className,
        new Error('MfaService validateOtp access locked.'),
        ERROR_PATHS.accessLocked
      );
    } else if (formControl.errors.failed) {
      this.navigationService.navigateToError(
        this.className,
        new Error('MfaService validateOtp failed.'),
        ERROR_PATHS.systemError
      );
    } else {
      this.codeMask = '';

      const validationErrors: ValidationErrors = formControl.errors;
      formControl.setValue('', { onlySelf: true, emitEvent: false });
      formControl.setErrors(validationErrors, { emitEvent: false });
    }
  }

  _validateOtp(codeFormControl: AbstractControl): Observable<ValidationErrors> | null {
    this.spinnerService.show(this._spinnerMessage);

    this.logger.info(this.className, 'Entered code.');

    return this._mfaService.validateOtp(codeFormControl.value).pipe(
      map(() => {
        this.logger.info(this.className, 'Successfully validated OTP.', { result: 'Success' });
        return null;
      }),
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 422 && error.error?.code) {
          if (error.error.code === 4223) {
            this.logger.warn(this.className, 'Failed to validate OTP.', { result: 'Expired' });
            return of({ expired: true });
          } else if (error.error.code === 4220) {
            this.logger.warn(this.className, 'Failed to validate OTP.', { result: 'Invalid' });
            this.spinnerService.hide();
            return of({ invalid: true });
          } else if (error.error.code === 4221) {
            this.logger.warn(this.className, 'Failed to validate OTP.', { result: 'Locked' });
            return of({ locked: true });
          }
        }

        this.logger.error(this.className, 'Failed to validate OTP.', { result: 'System Error' });
        return of({ failed: true });
      })
    );
  }

  _getCopyPasteContent(event: ClipboardEvent) {
    if (event?.clipboardData?.getData) {
      return event.clipboardData.getData('text/plain');
    }

    if (window && (window as any).clipboardData && (window as any).clipboardData.getData) {
      return (window as any).clipboardData.getData('Text');
    }

    return null;
  }

  get mfaModalInstructions(): string {
    return this.selectedContactPoint.type === ContactPointType.EMAIL ? this._emailInstruction : this._textInstruction;
  }

  private _initializeForm() {
    this.verifyCodeForm = this._formBuilder.group(
      {
        code: ['', [Validators.required], [this._validateOtp.bind(this)]]
      },
      {
        updateOn: 'submit'
      }
    );

    const codeFormControl = this.verifyCodeForm.get('code');
    codeFormControl.statusChanges
      .pipe(
        untilDestroyed(this),
        filter(() => codeFormControl.valid || codeFormControl.invalid)
      )
      .subscribe(() => {
        this._processFormSubmission(codeFormControl);
      });
  }

  private _handleNonNumericOtpCharacters(event: Event, input: string) {
    this.logger.warn(this.className, 'User entered non-numeric otp character(s).', { input });
    this.showNumbersOnlyHelpText = true;
    event.preventDefault();
  }

  protected get formGroup(): UntypedFormGroup {
    return this.verifyCodeForm;
  }

  protected get controls(): Controls {
    return this.verifyCodeControls;
  }

  protected get controlErrors(): ControlErrors {
    return this.verifyCodeControlErrors;
  }
}
