import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { MFA_PATHS } from '@common/mfa/mfa-route-paths';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ControlErrors, Controls } from '@shared/base-component/base.model';
import { ContentHeaderService } from '@shared/content-header/content-header.service';
import { CommonFlow, Flow } from '@shared/navigation/flow.enum';
import { NavigationService } from '@shared/navigation/navigation.service';
import { filter } from 'rxjs/operators';
import { RegistrationBaseComponent } from '../shared/registration-base.component';
import { RegistrationFlowType } from '../shared/registration.model';
import { CONTROLS, CONTROL_ERRORS } from './registration-type.model';

@UntilDestroy()
@Component({
  selector: 'ciam-registration-type',
  templateUrl: './registration-type.component.html',
  styleUrls: ['./registration-type.component.scss']
})
export class RegistrationTypeComponent extends RegistrationBaseComponent implements OnInit {
  @ViewChild('registrationTypeFormParent', { static: true })
  registrationTypeFormParent: NgForm;
  registrationTypeForm: UntypedFormGroup;
  registrationTypeControls: Controls = CONTROLS;
  registrationTypeControlErrors: ControlErrors = CONTROL_ERRORS;

  registrationFlows = RegistrationFlowType;

  protected readonly className = 'RegistrationTypeComponent';

  constructor(
    private _contentHeaderService: ContentHeaderService,
    private _navigationService: NavigationService,
    private _formBuilder: UntypedFormBuilder
  ) {
    super();
  }

  ngOnInit(): void {
    this._contentHeaderService.updateProgress({
      progressPercent: 20
    });

    this.logger.info(this.className, 'Registering multiple account types.');

    this._initializeForm();
  }

  submit() {
    this.spinnerService.show();

    this._navigationService.navigate(
      `${Flow.REGISTRATION}/${this.registrationTypeForm.get('multipleTypesSelection').value}/${CommonFlow.MFA}/${
        MFA_PATHS.sendCode
      }`
    );
  }

  private _initializeForm() {
    this.registrationTypeForm = this._formBuilder.group(
      {
        multipleTypesSelection: ['', [Validators.required]]
      },
      {
        updateOn: 'submit'
      }
    );

    this.registrationTypeForm.statusChanges
      .pipe(
        untilDestroyed(this),
        filter(() => this.registrationTypeForm.valid)
      )
      .subscribe(() => {
        this.logger.info(this.className, 'Multiple types selected.', {
          multipleTypesSelection: this.registrationTypeForm.get('multipleTypesSelection').value
        });
        this.submit();
      });
  }

  protected get formGroup(): UntypedFormGroup {
    return this.registrationTypeForm;
  }

  protected get controls(): Controls {
    return this.registrationTypeControls;
  }

  protected get controlErrors(): ControlErrors {
    return this.registrationTypeControlErrors;
  }
}
