<div class="d-flex flex-column">
  <form
    id="digital-refunds-form"
    #digitalRefundsFormParent="ngForm"
    class="d-flex flex-column justify-content-between page-content"
    [formGroup]="digitalRefundsForm"
    (ngSubmit)="resetPageErrorAndFocus()"
  >
    <section id="refunds-content" class="container pb-4">
      <div id="refunds-header" class="content-section">
        <div id="digital-refunds-error">
          <bolt-alert-error #pageLevelError _id="page-level-error" [hidden]="!errorMessage">
            {{ errorMessage }}
          </bolt-alert-error>
        </div>
        <h5 #adaAutoFocus id="refunds-title">
          <strong>
            Add refund method (optional)
          </strong>
        </h5>
        <p id="digital-refund-message">
          Refunds are faster and safer when using a digital method. Please take a moment to choose which account we can
          link to potential refunds. You can always change preferences like this to your billing account later in the
          billing preferences page.
        </p>
      </div>
      <div id="account-information-inputs" class="content-section account-info-section">
        <fieldset id="account-information-fieldset">
          <legend class="pre-form-content">
            <h5 id="account-information-form-title">
              Account information
            </h5>
          </legend>
          <label for="radio-buttons">Select an account type</label>
          <div [formGroup]="digitalRefundsForm" id="radio-buttons" class="container">
            <div class="form-check mobile-friendly-radio d-flex">
              <bolt-radio-button
                class="pr-5"
                _id="checking"
                _name="accountType"
                [checked]="null"
                label="Checking"
                value="Checking"
                [formGroup]="digitalRefundsForm"
              >
              </bolt-radio-button>
              <bolt-radio-button
                _id="savings"
                _name="accountType"
                [checked]="null"
                label="Savings"
                value="Savings"
                [formGroup]="digitalRefundsForm"
              >
              </bolt-radio-button>
            </div>
          </div>
          <bolt-field-error
            id="digital-refunds-account-type-errors"
            _id="digital-refunds-account-type-errors"
            _class="field-error"
            [errors]="digitalRefundsForm.get('accountType').errors"
            [errorMessages]="digitalRefundsControlErrors.accountType"
            *ngIf="digitalRefundsFormParent.submitted"
          >
          </bolt-field-error>
          <div class="form-group">
            <bolt-textfield
              ciamDelayedFocusout
              [_id]="digitalRefundsControls.routingNumber.id"
              _name="routingNumber"
              [_aria-describedby]="digitalRefundsControls.routingNumber.ariaDescribedBy"
              maxlength="9"
              label="Bank routing number"
              [formGroup]="digitalRefundsForm"
              [parentForm]="digitalRefundsFormParent"
              optionaltext="hide"
            >
            </bolt-textfield>
            <bolt-field-error
              id="digital-refunds-routing-number-errors"
              _id="digital-refunds-routing-number-errors"
              _class="field-error"
              [errors]="digitalRefundsForm.get('routingNumber').errors"
              [errorMessages]="digitalRefundsControlErrors.routingNumber"
              *ngIf="digitalRefundsFormParent.submitted"
            >
            </bolt-field-error>
            <div class="digital-refunds-help-text pl-0" id="routing-num-helptext-textfield-col">
              Your bank routing number is the first set of nine numbers on the bottom left of your check.
            </div>
            <bolt-textfield
              [_id]="digitalRefundsControls.accountNumber.id"
              _name="accountNumber"
              [_aria-describedby]="digitalRefundsControls.accountNumber.ariaDescribedBy"
              maxlength="17"
              label="Account number"
              [formGroup]="digitalRefundsForm"
              [parentForm]="digitalRefundsFormParent"
              optionaltext="hide"
            >
            </bolt-textfield>
            <bolt-field-error
              id="digital-refunds-account-number-errors"
              _id="digital-refunds-account-number-errors"
              _class="field-error"
              [errors]="digitalRefundsForm.get('accountNumber').errors"
              [errorMessages]="digitalRefundsControlErrors.accountNumber"
              *ngIf="digitalRefundsFormParent.submitted"
            >
            </bolt-field-error>
            <div class="digital-refunds-help-text pl-0" id="account-num-helptext-textfield-col">
              Your account number is the set of numbers that appears to the right of your bank routing number and may
              start with zero.
            </div>
            <bolt-textfield
              [_id]="digitalRefundsControls.confirmAccountNumber.id"
              _name="confirmAccountNumber"
              [_aria-describedby]="digitalRefundsControls.confirmAccountNumber.ariaDescribedBy"
              appDisablePaste
              maxlength="17"
              label="Confirm account number"
              [formGroup]="digitalRefundsForm"
              [parentForm]="digitalRefundsFormParent"
              optionaltext="hide"
            >
            </bolt-textfield>
            <bolt-field-error
              id="digital-refunds-confirm-account-number-errors"
              _id="digital-refunds-confirm-account-number-errors"
              _class="field-error"
              [errors]="digitalRefundsForm.get('confirmAccountNumber').errors"
              [errorMessages]="digitalRefundsControlErrors.confirmAccountNumber"
              *ngIf="digitalRefundsFormParent.submitted"
            >
            </bolt-field-error>
          </div>
        </fieldset>
      </div>
      <h5 id="account-holder-form-title">
        Account holder's name and address
      </h5>
      <div class="row form-group width-full">
        <div class="col-sm-4">
          <bolt-textfield
            _name="firstName"
            type="text"
            [id]="digitalRefundsControls.firstName.id"
            [_id]="digitalRefundsControls.firstName.id"
            label="First name"
            [formGroup]="digitalRefundsForm"
            [parentForm]="digitalRefundsFormParent"
            optionaltext="hide"
          >
          </bolt-textfield>
          <bolt-field-error
            id="digital-refunds-first-name-errors"
            _id="digital-refunds-first-name-errors"
            _class="field-error"
            [errors]="digitalRefundsForm.get('firstName').errors"
            [errorMessages]="digitalRefundsControlErrors.firstName"
            *ngIf="digitalRefundsFormParent.submitted"
          >
          </bolt-field-error>
        </div>

        <div class="col-sm-4">
          <bolt-textfield
            type="text"
            [id]="digitalRefundsControls.middleName.id"
            [_id]="digitalRefundsControls.middleName.id"
            _name="middleName"
            label="Middle name"
            [formGroup]="digitalRefundsForm"
            [parentForm]="digitalRefundsFormParent"
          >
          </bolt-textfield>
        </div>
        <div class="col-sm-4">
          <bolt-textfield
            type="text"
            [id]="digitalRefundsControls.lastName.id"
            [_id]="digitalRefundsControls.lastName.id"
            _name="lastName"
            label="Last name"
            [formGroup]="digitalRefundsForm"
            [parentForm]="digitalRefundsFormParent"
          >
          </bolt-textfield>
        </div>
      </div>

      <div class="row form-group width-full">
        <div class="col-sm-8">
          <bolt-textfield
            type="text"
            [id]="digitalRefundsControls.streetAddress.id"
            [_id]="digitalRefundsControls.streetAddress.id"
            _name="streetAddress"
            label="Street address"
            [formGroup]="digitalRefundsForm"
            [parentForm]="digitalRefundsFormParent"
            optionaltext="hide"
          >
          </bolt-textfield>
          <bolt-field-error
            id="digital-refunds-street-address-errors"
            _id="digital-refunds-street-address-errors"
            _class="field-error"
            [errors]="digitalRefundsForm.get('streetAddress').errors"
            [errorMessages]="digitalRefundsControlErrors.streetAddress"
            *ngIf="digitalRefundsFormParent.submitted"
          >
          </bolt-field-error>
        </div>
        <div class="col-sm-4">
          <bolt-textfield
            type="text"
            [id]="digitalRefundsControls.apartmentNumber.id"
            [_id]="digitalRefundsControls.apartmentNumber.id"
            _name="apartmentNumber"
            label="Apt. / Unit #"
            [formGroup]="digitalRefundsForm"
            [parentForm]="digitalRefundsFormParent"
          >
          </bolt-textfield>
        </div>
      </div>

      <div class="row form-group width-full">
        <div class="col-sm-4">
          <bolt-textfield
            type="text"
            [id]="digitalRefundsControls.cityName.id"
            [_id]="digitalRefundsControls.cityName.id"
            _name="cityName"
            label="City"
            [formGroup]="digitalRefundsForm"
            [parentForm]="digitalRefundsFormParent"
            optionaltext="hide"
          >
          </bolt-textfield>
          <bolt-field-error
            id="digital-refunds-city-name-errors"
            _id="digital-refunds-city-name-errors"
            _class="field-error"
            [errors]="digitalRefundsForm.get('cityName').errors"
            [errorMessages]="digitalRefundsControlErrors.cityName"
            *ngIf="digitalRefundsFormParent.submitted"
          >
          </bolt-field-error>
        </div>
        <div class="col-sm-4">
          <label for="state">State</label>
          <select
            class="form-control nw-input--medium"
            [formGroup]="digitalRefundsForm"
            _name="stateSelection"
            [id]="digitalRefundsControls.stateSelection.id"
            (change)="onStateChange($event)"
          >
            <option></option>
            <option *ngFor="let state of digitalRefundsStates" value="{{ state.abbr }}">
              {{ state.name }}
            </option>
          </select>
          <bolt-field-error
            id="digital-refunds-state-selection-errors"
            _id="digital-refunds-state-selection-errors"
            _class="field-error"
            [errors]="digitalRefundsForm.get('stateSelection').errors"
            [errorMessages]="digitalRefundsControlErrors.stateSelection"
            *ngIf="digitalRefundsFormParent.submitted"
          >
          </bolt-field-error>
        </div>
        <div class="col-sm-4">
          <bolt-textfield
            [id]="digitalRefundsControls.zipCode.id"
            [_id]="digitalRefundsControls.zipCode.id"
            _name="zipCode"
            maxlength="5"
            mask="00000"
            inputmode="numeric"
            label="ZIP"
            [formGroup]="digitalRefundsForm"
            [parentForm]="digitalRefundsFormParent"
            optionaltext="hide"
          >
          </bolt-textfield>
          <bolt-field-error
            id="digital-refunds-zip-code-errors"
            _id="digital-refunds-zip-code-errors"
            _class="field-error"
            [errors]="digitalRefundsForm.get('zipCode').errors"
            [errorMessages]="digitalRefundsControlErrors.zipCode"
            *ngIf="digitalRefundsFormParent.submitted"
          >
          </bolt-field-error>
        </div>
      </div>
    </section>

    <section id="digital-refund-buttons" class="button-container">
      <div class="container button-stack">
        <bolt-button-primary ciamClickLogger _id="digital-refund-continue" _type="submit" (click)="_submit()">
          Continue
        </bolt-button-primary>
        <bolt-button-standard ciamClickLogger _id="digital-refund-skip" _type="button" (click)="_skipFormClick()">
          Skip for now
        </bolt-button-standard>
      </div>
    </section>
  </form>
</div>
