export enum Flow {
  AUTHORIZATION = 'authorization',
  REGISTRATION = 'registration',
  FORGOT_CREDENTIALS = 'forgot-credentials',
  PROXY_REGISTRATION = 'proxy-registration',
  ONE_TIME_LINK = 'otl',
  VERSION = 'version',
  NON_INSTANT_ACTIVATION = 'non-instant-activation'
}

export enum CommonFlow {
  SEARCH = 'search',
  ELIGIBILITY = 'eligibility',
  MFA = 'mfa',
  PREFERENCES = 'preferences',
  DIGITAL_REFUNDS = 'digital-refunds'
}
