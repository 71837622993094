<div class="d-flex flex-column">
  <form
    #pageForm
    id="send-code-form"
    class="d-flex flex-column justify-content-between page-content"
    [formGroup]="sendCodeForm"
    #sendCodeFormParent="ngForm"
    (ngSubmit)="_submitForm()"
  >
    <section id="send-code-content" class="container">
      <div class="row justify-content-center">
        <div class="col-lg">
          <div id="send-code-header" class="content-section">
            <bolt-alert-error #pageLevelError _id="page-level-error" [hidden]="!errorMessage">
              {{ errorMessage }}
            </bolt-alert-error>
            <h2 #adaAutoFocus id="verify-identity-title" class="h5">
              <strong>{{
                newPhoneFailures > 0
                  ? 'We need to send your code using a different method.'
                  : 'We need to send you a code to verify your identity.'
              }}</strong>
            </h2>
            <p id="verify-identity-message">
              {{ fullHelpMessage }}
            </p>
            <p id="postal-mail-message" *ngIf="hasPostalAddress">
              You {{ hasOnlyPostal ? '' : 'also ' }}have the option of receiving an activation key by U.S. mail, which
              takes a few days.
            </p>
            <p id="contact-us-message" *ngIf="newPhoneOnly; else nonNewPhoneOnlyMessage">
              For assistance, please call
              <span class="text-nowrap"><ciam-phone-number key="WEB_SUPPORT"></ciam-phone-number></span>.
            </p>
            <ng-template #nonNewPhoneOnlyMessage>
              <p id="contact-us-message">
                If the information below is incorrect, please call
                <span class="text-nowrap"><ciam-phone-number key="WEB_SUPPORT"></ciam-phone-number></span>.
              </p>
            </ng-template>
          </div>
          <div id="send-code-inputs" class="content-section">
            <fieldset class="form-group">
              <legend class="pre-form-content">
                <h2 id="send-code-title" class="h5">
                  {{
                    hasOnlyOneValidContactPoint && !newPhoneOnly
                      ? 'If the information on file is correct, click Request code.'
                      : 'Where would you like the code sent?'
                  }}
                </h2>
              </legend>
              <div
                [id]="sendCodeControls.availableContactTypes.id"
                class="field-error"
                *ngIf="!hasOnlyOneValidContactPoint; else singleContactTemplate"
              >
                <div
                  class="form-check"
                  [ngClass]="{ 'mobile-friendly-radio': i < mfaService.model.user.contactPoints.length - 1 }"
                  *ngFor="let contactPoint of mfaService.model.user.contactPoints; let i = index"
                >
                  <bolt-radio-button
                    [_id]="contactPoint.type"
                    _name="availableContactTypes"
                    [checked]="null"
                    [label]="buildContactMethodText(contactPoint)"
                    [value]="contactPoint.type"
                    [formGroup]="sendCodeForm"
                    *ngIf="newPhoneFailures < allowedNewPhoneAttempts || contactPoint.type !== newPhoneType"
                    (change)="handleContactPointSelection(contactPoint.type)"
                  >
                    <div
                      class="form-group"
                      *ngIf="contactPoint.type === newPhoneType"
                      [hidden]="!((selectedContactPoint || {}).type === newPhoneType)"
                    >
                      <ng-container *ngTemplateOutlet="mobilePhoneTemplate"></ng-container>
                    </div>
                  </bolt-radio-button>
                </div>
              </div>
              <ng-template #singleContactTemplate>
                <div [id]="sendCodeControls.availableContactTypes.id" class="field-error">
                  <div class="form-check mb-2 new-phone-only-phone-text">
                    <ng-template
                      *ngTemplateOutlet="hasOnlyNewPhone ? mobilePhoneTemplate : singleValidContactTemplate"
                    ></ng-template>
                  </div>
                </div>
              </ng-template>
              <bolt-field-error
                id="available-contact-types-errors"
                _id="available-contact-types-errors"
                [errors]="sendCodeForm.get('availableContactTypes').errors"
                [errorMessages]="sendCodeControlErrors.availableContactTypes"
                *ngIf="sendCodeFormParent.submitted"
              ></bolt-field-error>
            </fieldset>
          </div>
        </div>
        <div class="col-auto content-section" hidden>
          <ciam-express-links></ciam-express-links>
        </div>
      </div>
    </section>
    <section id="send-code-buttons" class="button-container">
      <div class="container button-stack">
        <bolt-button-primary ciamClickLogger _id="send-code-confirm" _type="submit">
          Request code
        </bolt-button-primary>
        <input
          ciamClickLogger
          type="button"
          id="send-code-cancel"
          class="btn btn-default"
          value="Cancel"
          data-cancel-trigger
        />
      </div>
    </section>
  </form>
</div>

<ng-template #mobilePhoneTemplate>
  <bolt-textfield
    [_id]="sendCodeControls.phoneNumber.id"
    _name="phoneNumber"
    _class="mobile-number-field"
    [_aria-describedby]="sendCodeControls.phoneNumber.ariaDescribedBy"
    inputmode="numeric"
    maxlength="12"
    [dropSpecialCharacters]="false"
    mask="000-000-0000"
    placeholder="555-555-5555"
    [formGroup]="sendCodeForm"
    [parentForm]="sendCodeFormParent"
  >
    Mobile number
  </bolt-textfield>
  <bolt-field-error
    id="mobile-number-errors"
    _id="mobile-number-errors"
    _class="field-error"
    [errors]="sendCodeForm.get('phoneNumber').errors"
    [errorMessages]="sendCodeControlErrors.phoneNumber"
    *ngIf="sendCodeFormParent.submitted"
  ></bolt-field-error>
</ng-template>

<ng-template #singleValidContactTemplate>
  <div [innerHTML]="findSingleValidContactPointText()"></div>
</ng-template>
