export enum RegistrationFlowType {
  PERSONAL = 'personal',
  BUSINESS = 'business'
}

export interface CreatePersonalAccountModel {
  userName?: string;
  password?: string;
  phoneNumber?: string;
  email?: string;
  acceptedEsa?: boolean;
}

export interface CreateBusinessAccountModel {
  agreementNumber?: string;
  agreementOwnerEcn?: string;
  controllerEcn?: string;
  controllerFirstName?: string;
  controllerLastName?: string;
  userName?: string;
  password?: string;
  email?: string;
  phoneNumber?: string;
  acceptedEsa?: boolean;
  acceptedAttestation?: boolean;
}
