import { NgModule } from '@angular/core';
import { RouterModule, Routes, mapToCanActivate, mapToCanActivateChild } from '@angular/router';
import { ELIGIBILITY_ROUTES } from '@common/eligibility/eligibility-routes';
import { MFA_ROUTES } from '@common/mfa/mfa-routes';
import { SEARCH_PATHS } from '@common/search/search-paths';
import { SEARCH_ROUTES } from '@common/search/search-routes';
import { MfaStatusGuard } from '@shared/mfa-status/mfa-status.guard';
import { CommonFlow, Flow } from '@shared/navigation/flow.enum';
import { NavigationGuard } from '@shared/navigation/navigation.guard';
import { TimeoutGuard } from '@shared/timeout/timeout.guard';
import { FORGOT_CREDENTIALS_PATHS } from './forgot-credentials-paths';
import { ResetUsernamePasswordConfirmationComponent } from './reset-username-password-confirmation/reset-username-password-confirmation.component';
import { ResetUsernamePasswordComponent } from './reset-username-password/reset-username-password.component';

export const FORGOT_CREDENTIALS_ROUTES: Routes = [
  {
    path: Flow.FORGOT_CREDENTIALS,
    canActivateChild: mapToCanActivateChild([NavigationGuard]),
    children: [
      { path: '', pathMatch: 'full', redirectTo: `${CommonFlow.SEARCH}/${SEARCH_PATHS.accountType}` },
      ...SEARCH_ROUTES,
      ...ELIGIBILITY_ROUTES,
      ...MFA_ROUTES,
      {
        path: FORGOT_CREDENTIALS_PATHS.resetUsernamePassword,
        component: ResetUsernamePasswordComponent,
        canActivate: mapToCanActivate([TimeoutGuard, MfaStatusGuard])
      },
      {
        path: FORGOT_CREDENTIALS_PATHS.resetUsernamePasswordConfirmation,
        component: ResetUsernamePasswordConfirmationComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(FORGOT_CREDENTIALS_ROUTES)],
  exports: [RouterModule]
})
export class ForgotCredentialsRoutingModule {}
