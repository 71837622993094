import { Injectable } from '@angular/core';
import { ContentHeaderModel } from './content-header.model';

@Injectable({
  providedIn: 'root'
})
export class ContentHeaderService {
  private _model: ContentHeaderModel;

  constructor() {
    this.reset();
  }

  reset(model?: ContentHeaderModel) {
    const inputModel = model || {};
    this.model = {
      title: inputModel.title || '',
      progressTitle: inputModel.progressTitle || '',
      progressPercent: inputModel.progressPercent || 0
    };
  }

  updateProgress(model: ContentHeaderModel) {
    if (model.title) {
      this.model.title = model.title;
    }

    if (model.progressTitle) {
      this.model.progressTitle = model.progressTitle;
    }

    if (model.progressPercent) {
      this.model.progressPercent = model.progressPercent;
    }
  }

  get model(): ContentHeaderModel {
    return this._model;
  }

  set model(model: ContentHeaderModel) {
    this._model = model;
  }
}
