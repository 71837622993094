<footer id="app-footer" class="small pt-4 pb-4 footer-container" role="contentinfo">
  <div class="container">
    <section id="footer-heading" class="row mb-3">
      <div class="col-md-auto mr-auto small-screen">
        <a
          ciamClickLogger
          id="footer-logo-link"
          href="javascript:void(0)"
          title="Nationwide Homepage"
          data-cancel-trigger
          data-cancel-url="https://www.nationwide.com"
        >
          <img id="footer-logo" class="footer-logo" alt="Nationwide logo" src="assets/footer-logo.svg" />
        </a>
      </div>
      <div class="col-auto my-auto">
        <strong id="footer-on-your-side">1-877 On Your Side </strong>
        <span class="text-nowrap"
          >(<ciam-phone-number
            key="NW_SERVICE_CENTER"
            _id="footer-phone"
            _aria-labelledby="footer-on-your-side footer-phone"
          ></ciam-phone-number
          >)</span
        >
      </div>
    </section>
    <section id="footer-logo-section" class="row mb-3">
      <div class="col-auto ml-auto social-icons">
        <a
          ciamClickLogger
          class="mr-4"
          id="footer-facebook-logo-link"
          href="https://www.facebook.com/nationwide"
          rel="noopener noreferrer"
          title="Nationwide Facebook (Opens in a new window)"
          target="_blank"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            name="Facebook logo"
            role="img"
            focusable="false"
            aria-labelledby="social-facebook"
          >
            <desc id="social-facebook">Facebook</desc>
            <path
              d="M22.77 37.5V21.473h5.302l.825-6.246H22.77v-4.006c0-1.768.472-3.064 3.064-3.064h3.3V2.736c-.59-.118-2.475-.236-4.832-.236-4.713 0-8.013 2.946-8.013 8.25v4.595h-5.421v6.246h5.42V37.5h6.482z"
            ></path>
          </svg>
        </a>
        <a
          ciamClickLogger
          class="mr-4"
          id="footer-twitter-logo-link"
          href="https://twitter.com/nationwide"
          rel="noopener noreferrer"
          title="Nationwide Twitter (Opens in a new window)"
          target="_blank"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            name="Twitter logo"
            role="img"
            focusable="false"
            aria-labelledby="social-twitter"
          >
            <desc id="social-twitter">Twitter</desc>
            <path
              d="M37.5 9.23c-1.346.6-2.692.898-4.038 1.197 1.495-.897 2.542-2.243 3.14-3.889-1.346.748-2.841 1.347-4.487 1.795-1.495-1.645-3.29-2.542-5.384-2.542-3.89 0-7.18 3.14-7.18 7.18 0 .597 0 1.046.15 1.644-5.983-.299-11.218-3.14-14.808-7.478-.598 1.047-.897 2.243-.897 3.59 0 2.542 1.196 4.636 3.14 5.982-1.196 0-2.243-.299-3.29-.897v.15c0 3.44 2.543 6.431 5.684 7.03-.598.149-1.197.299-1.945.299-.448 0-.897 0-1.346-.15.898 2.842 3.59 4.936 6.731 4.936-2.393 1.944-5.534 2.991-8.825 2.991-.598 0-1.196 0-1.645-.15 3.29 2.095 7.03 3.291 11.068 3.291 13.163 0 20.342-10.918 20.342-20.341v-.898c1.346-1.047 2.543-2.393 3.59-3.74z"
            ></path>
          </svg>
        </a>
        <a
          ciamClickLogger
          class="mr-4"
          id="footer-instagram-logo-link"
          href="https://www.instagram.com/nationwide/"
          rel="noopener noreferrer"
          title="Nationwide Instagram (Opens in a new window)"
          target="_blank"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            name="Instagram logo"
            role="img"
            focusable="false"
            aria-labelledby="social-instagram"
          >
            <desc id="social-instagram">Instagram</desc>
            <path
              d="M20 5.653c4.673 0 5.226.018 7.071.102 1.707.078 2.633.363 3.25.603.817.317 1.4.696 2.012 1.309a5.422 5.422 0 0 1 1.31 2.012c.239.617.524 1.543.602 3.25.084 1.845.102 2.398.102 7.071s-.018 5.226-.102 7.071c-.078 1.707-.363 2.633-.603 3.25a5.422 5.422 0 0 1-1.309 2.012 5.422 5.422 0 0 1-2.012 1.31c-.617.239-1.543.524-3.25.602-1.845.084-2.398.102-7.071.102s-5.226-.018-7.071-.102c-1.707-.078-2.633-.363-3.25-.603a5.422 5.422 0 0 1-2.012-1.309 5.422 5.422 0 0 1-1.31-2.012c-.239-.617-.524-1.543-.602-3.25-.084-1.845-.102-2.398-.102-7.071s.018-5.226.102-7.071c.078-1.707.363-2.633.603-3.25.317-.817.696-1.4 1.309-2.012a5.422 5.422 0 0 1 2.012-1.31c.617-.239 1.543-.524 3.25-.602 1.845-.084 2.398-.102 7.071-.102M20 2.5c-4.753 0-5.349.02-7.215.105-1.863.085-3.135.381-4.248.814a8.578 8.578 0 0 0-3.1 2.018 8.578 8.578 0 0 0-2.018 3.1c-.433 1.113-.729 2.385-.814 4.248C2.52 14.65 2.5 15.247 2.5 20s.02 5.349.105 7.215c.085 1.863.381 3.135.814 4.248a8.578 8.578 0 0 0 2.018 3.1 8.578 8.578 0 0 0 3.1 2.018c1.113.433 2.385.729 4.248.814 1.866.085 2.462.105 7.215.105s5.349-.02 7.215-.105c1.863-.085 3.135-.381 4.248-.814a8.578 8.578 0 0 0 3.1-2.018 8.578 8.578 0 0 0 2.018-3.1c.433-1.113.729-2.385.814-4.248.085-1.866.105-2.462.105-7.215s-.02-5.349-.105-7.215c-.085-1.863-.381-3.135-.814-4.248a8.578 8.578 0 0 0-2.018-3.1 8.578 8.578 0 0 0-3.1-2.018c-1.113-.433-2.385-.729-4.248-.814C25.35 2.52 24.753 2.5 20 2.5z"
            ></path>
            <path
              d="M20 11.014a8.986 8.986 0 1 0 0 17.972 8.986 8.986 0 0 0 0-17.972zm0 14.82a5.833 5.833 0 1 1 0-11.667 5.833 5.833 0 0 1 0 11.666z"
            ></path>
            <circle cx="29.342" cy="10.658" r="2.1"></circle>
          </svg>
        </a>
        <a
          ciamClickLogger
          class="mr-4"
          id="footer-linkedIn-logo-link"
          href="https://www.linkedin.com/company/2340/"
          rel="noopener noreferrer"
          title="Nationwide LinkedIn (Opens in a new window)"
          target="_blank"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            name="LinkedIn logo"
            role="img"
            focusable="false"
            aria-labelledby="social-linkedIn"
          >
            <desc id="social-linkedIn">LinkedIn</desc>
            <g>
              <g>
                <path
                  d="M34.876 2.5H5.111c-1.346 0-2.543 1.047-2.543 2.543v29.914c0 1.346 1.197 2.543 2.543 2.543h29.765c1.346 0 2.543-1.047 2.543-2.543V5.043c.15-1.346-1.047-2.543-2.543-2.543zM13.04 32.265H7.803V15.662h5.236v16.603zm-2.693-18.996a3 3 0 0 1-2.991-2.991 3 3 0 0 1 2.991-2.992 3 3 0 0 1 2.992 2.992 3 3 0 0 1-2.992 2.991zm21.987 18.996h-5.235v-8.077c0-1.944 0-4.338-2.692-4.338s-3.14 2.094-3.14 4.338v8.227H16.03V15.662h4.936v2.244h.15c.747-1.346 2.393-2.692 4.935-2.692 5.235 0 6.133 3.44 6.133 7.927v9.124h.15z"
                ></path>
              </g>
            </g>
          </svg>
        </a>
        <a
          ciamClickLogger
          id="footer-youtube-logo-link"
          href="https://www.youtube.com/nationwide"
          rel="noopener noreferrer"
          title="Nationwide YouTube (Opens in a new window)"
          target="_blank"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            name="YouTube logo"
            role="img"
            focusable="false"
            aria-labelledby="social-youtube"
          >
            <desc id="social-youtube">YouTube</desc>
            <g>
              <path
                d="M37.217 12.986s-.284-2.41-1.417-3.543c-1.276-1.417-2.834-1.417-3.543-1.558-4.818-.425-12.186-.425-12.186-.425s-7.227 0-12.186.425c-.85.141-2.268.141-3.685 1.558-.991 1.134-1.417 3.543-1.417 3.543S2.5 15.82 2.5 18.654v2.692c0 2.834.425 5.668.425 5.668s.284 2.41 1.417 3.543c1.275 1.417 3.118 1.275 3.826 1.558 2.834.284 11.903.425 11.903.425s7.368 0 12.186-.425c.709-.141 2.126-.141 3.543-1.558.992-1.134 1.417-3.543 1.417-3.543s.283-2.834.283-5.668v-2.692c0-2.834-.283-5.668-.283-5.668zm-20.83 11.478v-9.778l9.494 4.818-9.494 4.96z"
              ></path>
            </g>
          </svg>
        </a>
      </div>
    </section>
    <section id="footer-info-section" class="row mb-3">
      <div class="col mb-3">
        <a
          ciamClickLogger
          href="javascript:void(0)"
          data-cancel-trigger
          data-cancel-url="https://www.nationwide.com/personal/about-us/"
          class="mr-2"
          id="footer-about-us"
          >About us</a
        >|<a
          ciamClickLogger
          href="javascript:void(0)"
          data-cancel-trigger
          data-cancel-url="https://www.nationwide.com/personal/about-us/careers/"
          class="mr-2 ml-2"
          id="footer-careers"
          >Careers</a
        >|<a
          ciamClickLogger
          href="javascript:void(0)"
          data-cancel-trigger
          data-cancel-url="https://www.nationwide.com/personal/contact/"
          class="ml-2"
          id="footer-contact-us"
          >Help Center</a
        >
      </div>
    </section>
    <section id="footer-business-section" class="row mb-3">
      <div class="col">
        <span id="footer-business-sites">Sites for business partners:</span>
        <p>
          <a
            ciamClickLogger
            class="mr-2"
            id="footer-financial-advisor"
            aria-labelledby="footer-business-sites footer-financial-advisor"
            href="javascript:void(0)"
            data-cancel-trigger
            data-cancel-url="https://nationwidefinancial.com/?_ga=2.47370023.1863556056.1589201636-1795695527.1582823998#!%2F"
            >Financial advisor &amp; investment firm</a
          >|<a
            ciamClickLogger
            class="mr-2 ml-2"
            id="footer-employer-plan-sponsor"
            aria-labelledby="footer-business-sites footer-employer-plan-sponsor"
            href="javascript:void(0)"
            data-cancel-trigger
            data-cancel-url="https://myplan.nwservicecenter.com/iApp/isc/cmd/Login?_ga=2.47370023.1863556056.1589201636-1795695527.1582823998"
            >Employer/plan sponsor</a
          >|<a
            ciamClickLogger
            class="ml-2"
            id="footer-pension-administrator"
            aria-labelledby="footer-business-sites footer-pension-administrator"
            href="javascript:void(0)"
            data-cancel-trigger
            data-cancel-url="https://pas.nwservicecenter.com/iApp/pas/welcome.action?_ga=2.186314665.1863556056.1589201636-1795695527.1582823998"
            >Pension administrator</a
          >
        </p>
      </div>
    </section>
    <section id="footer-company-section" class="row mb-3">
      <div class="col">
        <p id="footer-company-content" class="text-justify">
          Products underwritten by Nationwide Mutual Insurance Company and Affiliated Companies. Not all Nationwide
          affiliated companies are mutual companies, and not all Nationwide members are insured by a mutual company.
          Subject to underwriting guidelines, review and approval. Products and discounts not available to all persons
          in all states. Nationwide Investment Services Corporation, member
          <a ciamClickLogger id="footer-finra" href="https://www.finra.org/#/" rel="noopener noreferrer" target="_blank"
            >FINRA</a
          >. Home Office: One Nationwide Plaza, Columbus, OH. Nationwide, the Nationwide N and Eagle and other marks
          displayed on this page are service marks of Nationwide Mutual Insurance Company, unless otherwise disclosed.
          &copy;{{ currentYear }}. Nationwide Mutual Insurance Company.
        </p>
      </div>
    </section>
    <section id="footer-slogan-section" class="row mb-3">
      <div class="col">
        <p id="footer-slogan-content">
          <strong>Nationwide is on your side<sup>&reg;</sup></strong>
        </p>
      </div>
    </section>
    <section id="footer-privacy-section" class="row privacy-section">
      <div class="col-sm-12 col-md-6 col-lg-6 mr-auto">
        <a
          ciamClickLogger
          class="mr-2"
          id="footer-privacy"
          href="javascript:void(0)"
          data-cancel-trigger
          data-cancel-url="https://www.nationwide.com/personal/privacy-security/"
          >Privacy</a
        >|<a
          ciamClickLogger
          class="mr-2 ml-2"
          id="footer-california-consumer-privacy-act-rights"
          href="javascript:void(0)"
          data-cancel-trigger
          data-cancel-url="https://www.nationwide.com/personal/privacy-security/california-rights/"
          >California Consumer Privacy Act Rights</a
        >|<a
          ciamClickLogger
          class="mr-2 ml-2"
          id="footer-accessibility"
          href="javascript:void(0)"
          data-cancel-trigger
          data-cancel-url="https://www.nationwide.com/personal/about-us/website-accessibility"
          >Accessibility</a
        >|<a
          ciamClickLogger
          class="mr-2 ml-2"
          id="footer-terms-and-conditions"
          href="javascript:void(0)"
          data-cancel-trigger
          data-cancel-url="https://www.nationwide.com/personal/about-us/terms-conditions"
          >Terms and conditions</a
        >|
        <span id="teconsent"></span>
      </div>
      <div class="col-md-auto small-screen">
        <div class="small-screen housing-truste">
          <a
            ciamClickLogger
            id="footer-equal-housing-logo-link"
            href="https://portal.hud.gov/hudportal/HUD?src=/program_offices/fair_housing_equal_opp"
            rel="noopener noreferrer"
            title="Fair Housing Equal Opportunity information (Opens in a new window)"
            target="_blank"
          >
            <img
              id="footer-equal-housing-logo"
              alt="Equal Housing Opportunity Insurer logo"
              class="equal-housing-logo"
              src="assets/equal-housing-logo.svg"
            />
          </a>
          <a
            ciamClickLogger
            id="footer-truste-logo-link"
            href="https://privacy.truste.com/privacy-seal/validation?rid=33d56fbf-04d6-4530-8bb7-7a7f6bc358db"
            rel="noopener noreferrer"
            title="Nationwide TRUSTe Certification (Opens in a new window)"
            target="_blank"
          >
            <img
              id="footer-truste-logo"
              alt="TRUSTe Certified Privacy logo"
              class="ml-3"
              src="https://privacy-policy.truste.com/privacy-seal/seal?rid=33d56fbf-04d6-4530-8bb7-7a7f6bc358db"
            />
          </a>
        </div>
      </div>
    </section>
  </div>
</footer>
