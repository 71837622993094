import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { ERROR_PATHS } from '@app/error/error-paths';
import { RegistrationFlowType } from '@app/registration/shared/registration.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CommonFlow, Flow } from '@shared/navigation/flow.enum';
import { Customer, PartyType, Registration, Search, SessionKey } from '@shared/storage/storage.model';
import { MFA_PATHS } from '../../mfa/mfa-route-paths';
import { EligibilityBaseComponent } from '../shared/eligibility-base.component';

@UntilDestroy()
@Component({
  selector: 'ciam-controller-eligibility',
  templateUrl: '../shared/eligibility-base.component.html',
  styleUrls: ['../shared/eligibility-base.component.scss']
})
export class ControllerEligibilityComponent extends EligibilityBaseComponent {
  protected readonly className = 'ControllerEligibilityComponent';

  _checkEligibility() {
    const registrationSession: Registration = this.sessionService.get(SessionKey.REGISTRATION);
    if (
      !registrationSession?.agreementNumber ||
      !registrationSession?.businessNames ||
      !registrationSession?.businessNames?.length
    ) {
      throw new Error('Organization session data not available.');
    }

    const customer = this.sessionService.get(SessionKey.CUSTOMER) as Customer;
    if (customer?.partyType === PartyType.ORGANIZATION) {
      const searchSession: Search = this.sessionService.get(SessionKey.SEARCH);
      if (!searchSession?.firstName || !searchSession?.lastName) {
        throw new Error('New account controller party session data not available.');
      }
      this._setupBusinessRegistrationSession(searchSession);
      this.navigationService.navigate(
        `${Flow.REGISTRATION}/${RegistrationFlowType.BUSINESS}/${CommonFlow.MFA}/${MFA_PATHS.sendCode}`
      );
    } else if (customer?.partyType === PartyType.PERSON) {
      this.eligibilityService
        .getExistingRegistrations(customer.ecn)
        .pipe(untilDestroyed(this))
        .subscribe(
          getInternetRegistrationsResponse => {
            this._processInternetRegistrations(getInternetRegistrationsResponse.body.internetRegistrations);
            this._handleRouting();
          },
          error => {
            if (error instanceof HttpErrorResponse && error.status === 404 && error.error.code === 4040) {
              this.sessionService.setModifiedSessionObject(null, SessionKey.CUSTOMER, 'guid');
              this.sessionService.setModifiedSessionObject(null, SessionKey.CUSTOMER, 'accountAccess');
              this._handleRouting();
            } else {
              this.logger.error(
                this.className,
                `Unexpected HTTP ${error.status} response returned from getInternetRegistrations.`
              );
              this.navigationService.navigateToError(
                this.className,
                error,
                ERROR_PATHS.systemError,
                'EligibilityService getInternetRegistrations failed.'
              );
            }
          }
        );
    } else {
      throw new Error(`Unrecognized party type: ${customer?.partyType}`);
    }
  }

  _handleRouting() {
    const customer: Customer = this.sessionService.get(SessionKey.CUSTOMER);
    if (!customer.guid || (customer.guid && this._hasEligibileAccountAccess(customer.accountAccess))) {
      this.navigationService.navigate(
        `${Flow.REGISTRATION}/${RegistrationFlowType.BUSINESS}/${CommonFlow.MFA}/${MFA_PATHS.sendCode}`
      );
    } else {
      this.navigationService.navigateToError(
        this.className,
        new Error('Customer does not have appropriate account access.'),
        ERROR_PATHS.snagError
      );
    }
  }

  _setupBusinessRegistrationSession(searchSession: Search) {
    this.sessionService.setModifiedSessionObject(searchSession.firstName, SessionKey.REGISTRATION, 'firstName');
    this.sessionService.setModifiedSessionObject(searchSession.lastName, SessionKey.REGISTRATION, 'lastName');
  }
}
