import { NgModule } from '@angular/core';
import { SearchModule } from '@common/search/search.module';
import {
  BoltAlertConfirmationModule,
  BoltAlertErrorModule,
  BoltButtonPrimaryModule,
  BoltButtonStandardModule,
  BoltCheckBoxModule,
  BoltFieldErrorModule,
  BoltRadioButtonModule,
  BoltRadioGroupModule,
  BoltTextFieldModule
} from '@nw/bolt-angular';
import { SharedModule } from '@shared/shared.module';
import { ActivationKeyComponent } from './activation-key/activation-key.component';
import { NonInstantActivationRoutingModule } from './non-instant-activation-routing.module';
import { UpdateAccountComponent } from './update-account/update-account.component';
import { UpdateAccountService } from './update-account/update-account.service';

@NgModule({
  declarations: [ActivationKeyComponent, UpdateAccountComponent],
  imports: [
    SharedModule,
    SearchModule,
    BoltAlertConfirmationModule,
    BoltAlertErrorModule,
    BoltButtonPrimaryModule,
    BoltButtonStandardModule,
    BoltFieldErrorModule,
    BoltTextFieldModule,
    BoltRadioGroupModule,
    BoltRadioButtonModule,
    BoltCheckBoxModule,
    NonInstantActivationRoutingModule
  ],
  providers: [UpdateAccountService]
})
export class NonInstantActivationModule {}
