import { Component } from '@angular/core';
import { AdaBaseComponent } from '@shared/base-component/ada-base.component';

@Component({
  selector: 'ciam-access-locked',
  templateUrl: './access-locked.component.html',
  styleUrls: ['./access-locked.component.scss']
})
export class AccessLockedComponent extends AdaBaseComponent {
  protected readonly className = 'AccessLockedComponent';
}
