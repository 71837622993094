import { Directive, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

// https://stackoverflow.com/a/60999920

@UntilDestroy()
@Directive({
  selector: '[ciamDelayedFocusout]'
})
export class DelayedFocusoutDirective implements OnInit {
  @Output() delayedFocusout = new EventEmitter<boolean>();

  mouseDownSubject = new BehaviorSubject(false);

  @HostListener('focusout')
  onFocusout() {
    if (this.mouseDownSubject.value) {
      this._mouseRelease()
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.delayedFocusout.emit(true);
        });
    } else {
      this.delayedFocusout.emit(true);
    }
  }

  ngOnInit() {
    fromEvent(document.body, 'mousedown')
      .pipe(untilDestroyed(this))
      .subscribe(() => this.mouseDownSubject.next(true));
    fromEvent(document.body, 'mouseup')
      .pipe(untilDestroyed(this))
      .subscribe(() => this.mouseDownSubject.next(false));
  }

  _mouseRelease() {
    return this.mouseDownSubject.pipe(
      untilDestroyed(this),
      map(isDown => !isDown),
      filter(isReleased => isReleased),
      take(1)
    );
  }
}
