import { Component } from '@angular/core';
import { RegistrationFlowType } from '@app/registration/shared/registration.model';
import { AdaBaseComponent } from '@shared/base-component/ada-base.component';
import { CommonFlow, Flow } from '@shared/navigation/flow.enum';

@Component({ template: '' })
export abstract class CommonBaseComponent extends AdaBaseComponent {
  protected className = 'CommonBaseComponent';

  private _parentFlowBaseRoute: string;

  get isFupFlow(): boolean {
    return this.flow === Flow.FORGOT_CREDENTIALS;
  }

  get isRegistrationFlow(): boolean {
    return this.flow === Flow.REGISTRATION;
  }

  get isProxyRegistrationFlow(): boolean {
    return this.flow === Flow.PROXY_REGISTRATION;
  }

  get pageTitle(): string {
    switch (this.flow) {
      case Flow.REGISTRATION:
        return 'Sign up for online access';
      case Flow.FORGOT_CREDENTIALS:
        return 'Forgot username/password';
      default:
        return null;
    }
  }

  get baseRoute(): string {
    return `${this.parentFlowBaseRoute}/${this.commonFlow}`;
  }

  get parentFlowBaseRoute(): string {
    if (!this._parentFlowBaseRoute && this.routeSegments) {
      this._parentFlowBaseRoute = '';

      const commonFlowIndex = this.routeSegments.findIndex(segment => segment.path === this.commonFlow);
      if (commonFlowIndex >= 0) {
        this._parentFlowBaseRoute = this.routeSegments.slice(0, commonFlowIndex).join('/');
      }
    }

    return this._parentFlowBaseRoute;
  }

  get parentFlowType(): RegistrationFlowType {
    if (this.isRegistrationFlow && this.routeSegments && this.routeSegments.length > 1) {
      return this.routeSegments[1]?.path as RegistrationFlowType;
    }

    return null;
  }

  abstract get commonFlow(): CommonFlow | string;
}
