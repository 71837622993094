import { UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export const validateAccountNumberMatch: ValidatorFn = (formGroup: UntypedFormGroup): ValidationErrors | null => {
  const confirmAccountNumber = formGroup.get('confirmAccountNumber');

  if (formGroup.get('accountNumber').value !== '' && confirmAccountNumber.value === '') {
    confirmAccountNumber.setErrors({ required: true });
  } else if (confirmAccountNumber.value && confirmAccountNumber.value !== formGroup.get('accountNumber').value) {
    confirmAccountNumber.setErrors({ mismatch: true });
  } else {
    confirmAccountNumber.setErrors(null);
  }
  return null;
};
