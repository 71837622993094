import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ERROR_PATHS } from '@app/error/error-paths';
import { ENVIRONMENT } from '@environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ControlErrors, Controls } from '@shared/base-component/base.model';
import { ContentHeaderService } from '@shared/content-header/content-header.service';
import { Customer, SessionKey } from '@shared/storage/storage.model';
import { TimeoutService } from '@shared/timeout/timeout.service';
import { MFA_PATHS } from '../mfa-route-paths';
import { ContactPoint, ContactPointType } from '../shared/contact-point.model';
import { MfaBaseComponent } from '../shared/mfa-base.component';
import { MfaService } from '../shared/mfa.service';
import { CONTROLS, CONTROL_ERRORS } from './send-code.model';
import { validateNewPhone } from './validate-new-phone';

@UntilDestroy()
@Component({
  selector: 'ciam-send-code',
  templateUrl: './send-code.component.html',
  styleUrls: ['./send-code.component.scss']
})
export class SendCodeComponent extends MfaBaseComponent implements OnInit {
  @ViewChild('sendCodeFormParent', { static: true }) sendCodeFormParent: NgForm;
  sendCodeForm: UntypedFormGroup;
  sendCodeControls: Controls = CONTROLS;
  sendCodeControlErrors: ControlErrors = CONTROL_ERRORS;

  selectedContactPoint: ContactPoint;
  newPhoneOnly = false;
  allowedNewPhoneAttempts = ENVIRONMENT.features.mfa.maxAttempts.newPhone;
  newPhoneFailures: number;

  readonly emailTextHelpMessage =
    'We can send your code by email or text message, which typically takes less than 15 minutes. Enter this code on the next screen. Message and data rates may apply.';
  readonly textHelpMessage =
    'We can send your code by text message, which typically takes less than 15 minutes. Enter this code on the next screen. Message and data rates may apply.';
  readonly emailHelpMessage =
    'We can send your code by email, which typically takes less than 15 minutes. Enter this code on the next screen. Message and data rates may apply.';

  protected readonly className = 'SendCodeComponent';

  private readonly _spinnerMessage = 'Sending your code...';
  private readonly _textToNewNumber = 'Text to new number';
  private readonly _mailActivationKeyMessage = 'Mail to address on file';

  constructor(
    private _contentHeaderService: ContentHeaderService,
    private _timeoutService: TimeoutService,
    private _formBuilder: UntypedFormBuilder,
    public mfaService: MfaService
  ) {
    super();

    this.hideSpinnerOnLoad = false;
  }

  ngOnInit() {
    this._contentHeaderService.updateProgress({
      progressTitle: 'Verify identity',
      progressPercent: 33
    });

    this._initializeForm();

    if (this.mfaService.model.hasRetrievedContactPoints) {
      this._checkForNewPhoneOnly(this.mfaService.model.user.contactPoints);
      this.spinnerService.hide();
    } else {
      this._timeoutService.reset();
      this.mfaService.reset();
      this._retrieveContactPoints();
    }
  }
  handleContactPointSelection(selection: ContactPointType) {
    this.selectedContactPoint = this.mfaService.model.user.contactPoints.find(
      contactPoint => contactPoint.type === selection
    );
  }

  buildContactMethodText(contactPoint: ContactPoint): string {
    if (contactPoint) {
      switch (contactPoint.type) {
        case ContactPointType.TEXT:
          return `Text to number on file <span class="text-nowrap">(${contactPoint.value})</span>`;
        case ContactPointType.EMAIL:
          return `Email to email address on file <span class="text-nowrap">(${contactPoint.value})</span>`;
        case ContactPointType.NEW_PHONE:
          return this._textToNewNumber;
        case ContactPointType.POSTAL_ADDRESS:
          return `${this._mailActivationKeyMessage} <span class="text-nowrap">${
            contactPoint.value === '*****' ? '' : '(' + contactPoint.value + ')'
          }</span>`;
        default:
          return '';
      }
    }
  }

  findSingleValidContactPointText(): string {
    return this.buildContactMethodText(
      this.mfaService.model.user.contactPoints.find(element => element.type !== ContactPointType.NEW_PHONE)
    );
  }

  _retrieveContactPoints() {
    this.mfaService
      .getContactPoints((this.sessionService.get(SessionKey.CUSTOMER) as Customer)?.ecn)
      .pipe(untilDestroyed(this))
      .subscribe(
        contactPoints => {
          const sortedContactPoints = this._sortContactPoints(contactPoints);
          this.mfaService.model.user.contactPoints = sortedContactPoints;

          if (sortedContactPoints.length > 0) {
            this.logger.info(this.className, 'Successfully retrieved OTP contact options.', {
              availableContactOptions: sortedContactPoints.map(cp => cp.type).join(', ')
            });
            this._checkForNewPhoneOnly(sortedContactPoints);
            this.spinnerService.hide();
            this.mfaService.model.hasRetrievedContactPoints = true;
          } else {
            this.navigationService.navigate(`${this.baseRoute}/${MFA_PATHS.undeliverableCode}`);
          }
        },
        error => {
          this.navigationService.navigateToError(
            this.className,
            error,
            ERROR_PATHS.systemError,
            'MfaService getContactPoints failed.'
          );
        }
      );
  }

  _submitForm() {
    this.resetPageErrorAndFocus();

    if (this.hasOnlyOneValidContactPoint && !this.newPhoneOnly) {
      this._findAndSelectSingleValidContactPoint();
    }

    if (
      this.selectedContactPoint &&
      !(this.selectedContactPoint.type === ContactPointType.NEW_PHONE && this.sendCodeForm.get('phoneNumber').invalid)
    ) {
      this._sendCode();
    }
  }

  _sendCode() {
    this.logger.info(this.className, 'OTP contact point selected.', {
      selectedContactOption: this.selectedContactPoint.type,
      requestedNewCode: this.mfaService.model.user.requestedNewCode || false,
      previousContactOption: this.mfaService.model.user.previousContactPoint?.type
    });

    if (this.isRegistrationFlow && this.selectedContactPoint.type === ContactPointType.POSTAL_ADDRESS) {
      this.spinnerService.show();
      this.sessionService.setModifiedSessionObject(true, SessionKey.MFA, 'selectedPostalAddress');
      this.continueToRegistration();
    } else if (this.selectedContactPoint.type !== ContactPointType.POSTAL_ADDRESS) {
      this.spinnerService.show(this._spinnerMessage);

      if (this.selectedContactPoint.type === ContactPointType.NEW_PHONE) {
        this.selectedContactPoint.value = this.sendCodeForm.get('phoneNumber').value;
      }

      this.mfaService
        .sendOtp(this.selectedContactPoint)
        .pipe(untilDestroyed(this))
        .subscribe(
          () => {
            this.logger.info(this.className, 'Successfully sent OTP.', {
              selectedContactOption: this.selectedContactPoint.type
            });

            if (this.selectedContactPoint.type === ContactPointType.NEW_PHONE) {
              this.sessionService.setModifiedSessionObject(
                this.selectedContactPoint.value,
                SessionKey.MFA,
                'newMobileNumber'
              );
            }

            this.navigationService.navigate(`${this.baseRoute}/${MFA_PATHS.verifyCode}`);
          },
          error => {
            this.logger.warn(this.className, 'Failed to send OTP.', {
              selectedContactOption: this.selectedContactPoint.type
            });

            this._handleSendCodeError(error);
          }
        );
    } else {
      throw new Error(`Invalid contact point type selection for flow: ${this.selectedContactPoint.type}, ${this.flow}`);
    }
  }

  _findAndSelectSingleValidContactPoint() {
    const contactPoint = this.mfaService.model.user.contactPoints.find(
      element => element.type !== ContactPointType.NEW_PHONE
    );
    this.handleContactPointSelection(contactPoint.type);
  }

  _sortContactPoints(contactPoints: ContactPoint[]) {
    const customer: Customer = this.sessionService.get(SessionKey.CUSTOMER);
    const result: ContactPoint[] = [];
    const contactPointTypes = [ContactPointType.TEXT, ContactPointType.NEW_PHONE, ContactPointType.EMAIL];

    if (
      this.isRegistrationFlow &&
      contactPoints.some(cp => cp.type === ContactPointType.EMAIL) &&
      customer.guid == null
    ) {
      contactPointTypes.push(ContactPointType.POSTAL_ADDRESS);
    }

    for (const contactPointType of contactPointTypes) {
      const cp = contactPoints.find(element => element.type === contactPointType);
      if (cp) {
        result.push(cp);
      }
    }

    return result;
  }

  _checkForNewPhoneOnly(contactPoints: ContactPoint[]) {
    if (contactPoints.length === 1 && contactPoints[0].type === ContactPointType.NEW_PHONE) {
      this.newPhoneOnly = true;
      this.selectedContactPoint = contactPoints[0];
      this.sendCodeForm.controls.availableContactTypes.setValue(ContactPointType.NEW_PHONE);
    }
  }

  _initializeForm() {
    this.newPhoneFailures = this.sessionService.get(SessionKey.MFA)?.newPhoneFailures || 0;
    this.sendCodeForm = this._formBuilder.group(
      {
        availableContactTypes: ['', [Validators.required]],
        phoneNumber: ['']
      },
      {
        validators: validateNewPhone,
        updateOn: 'submit'
      }
    );
  }

  private _handleSendCodeError(error: any) {
    if (error instanceof HttpErrorResponse && (error.status === 422 || error.status === 500)) {
      if (error.error?.code === 4221) {
        this.navigationService.navigateToError(
          this.className,
          error,
          ERROR_PATHS.accessLocked,
          'MfaService sendOtp access locked.'
        );
      } else if (error.error?.code === 4227 || error.error?.code === 5100) {
        this.newPhoneFailures++;
        this.sessionService.setModifiedSessionObject(this.newPhoneFailures, SessionKey.MFA, 'newPhoneFailures');
        this.logger.warn(this.className, 'Failed to validate new phone.', {
          totalFailures: this.newPhoneFailures,
          newPhoneOnly: this.newPhoneOnly
        });

        if (this.newPhoneOnly && this.newPhoneFailures >= this.allowedNewPhoneAttempts) {
          this.navigationService.navigateToError(
            this.className,
            error,
            `${this.baseRoute}/${MFA_PATHS.invalidPhone}`,
            `MfaService sendOtp new phone validation failed ${this.newPhoneFailures} times; no other contact points available.`
          );
        } else if (this.newPhoneFailures > this.allowedNewPhoneAttempts) {
          this.sendCodeForm.get('phoneNumber').setValue('');
          this.sendCodeForm.get('availableContactTypes').setValue('');
        }

        this.spinnerService.hide();
      } else {
        this.navigationService.navigateToError(
          this.className,
          error,
          ERROR_PATHS.systemError,
          'MfaService sendOtp failed.'
        );
      }
    } else {
      this.navigationService.navigateToError(
        this.className,
        error,
        ERROR_PATHS.systemError,
        'MfaService sendOtp failed.'
      );
    }
  }

  get fullHelpMessage(): string {
    if (this.hasEmailAddress && (this.hasTextMessage || this.hasNewPhone)) {
      return this.emailTextHelpMessage;
    } else if (this.hasTextMessage || this.hasNewPhone) {
      return this.textHelpMessage;
    } else if (this.hasEmailAddress) {
      return this.emailHelpMessage;
    } else {
      return '';
    }
  }

  get hasOnlyOneValidContactPoint(): boolean {
    return (
      (this.mfaService.model.user.contactPoints.length === 2 &&
        this.newPhoneFailures >= this.allowedNewPhoneAttempts) ||
      this.mfaService.model.user.contactPoints.length === 1
    );
  }

  get hasEmailAddress(): boolean {
    return this.mfaService.model.user.contactPoints.some(contactPoint => contactPoint.type === ContactPointType.EMAIL);
  }

  get hasTextMessage(): boolean {
    return this.mfaService.model.user.contactPoints.some(contactPoint => contactPoint.type === ContactPointType.TEXT);
  }

  get hasNewPhone(): boolean {
    return (
      this.mfaService.model.user.contactPoints.some(contactPoint => contactPoint.type === ContactPointType.NEW_PHONE) &&
      this.newPhoneFailures < ENVIRONMENT.features.mfa.maxAttempts.newPhone
    );
  }

  get hasPostalAddress(): boolean {
    return this.mfaService.model.user.contactPoints.some(
      contactPoint => contactPoint.type === ContactPointType.POSTAL_ADDRESS
    );
  }

  get hasOnlyPostal(): boolean {
    return this.hasPostalAddress && !this.hasEmailAddress && !(this.hasTextMessage || this.hasNewPhone);
  }

  get hasOnlyNewPhone(): boolean {
    return this.hasNewPhone && !this.hasPostalAddress && !this.hasEmailAddress && !this.hasTextMessage;
  }

  get errorMessage(): string {
    if (this.sendCodeFormParent.submitted && this.sendCodeForm.invalid) {
      return 'Please correct the following to continue.';
    } else if (
      this.newPhoneFailures > 0 &&
      this.newPhoneFailures <= this.allowedNewPhoneAttempts &&
      !this.sendCodeForm.get('phoneNumber').errors &&
      this.sendCodeForm.get('phoneNumber').value
    ) {
      return "We're unable to validate your new phone number.";
    }

    return null;
  }

  get newPhoneType(): string {
    return ContactPointType.NEW_PHONE;
  }

  protected get formGroup(): UntypedFormGroup {
    return this.sendCodeForm;
  }

  protected get controls(): Controls {
    return this.sendCodeControls;
  }

  protected get controlErrors(): ControlErrors {
    return this.sendCodeControlErrors;
  }
}
