import { Injectable } from '@angular/core';
import { ENVIRONMENT } from '@environments/environment';
import { CookieKey } from '@shared/storage/storage.model';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CustomCookieService extends CookieService {
  /**
   * Returns a "flow" id, scoped strictly to CIAM and expected to be reset when a CIAM flow is started/restarted.
   */
  get flowId(): string {
    return this._manageIds(CookieKey.CIAM_FLOW_ID);
  }

  /**
   * Returns a "session" id, scoped to the Nationwide subdomain and only reset when the client (browser) terminates the session.
   */
  get sessionId(): string {
    return this._manageIds(CookieKey.CIAM_SESSION_ID);
  }

  /**
   * Encodes the DEVICE_TOKEN cookie, which is decoded by the CookieService's get().
   */
  get deviceToken(): string {
    return encodeURIComponent(this.get(CookieKey.DEVICE_TOKEN) || this.get(CookieKey.CIAM_DEVICE_TOKEN));
  }

  /**
   * Sets the DEVICE_TOKEN cookie without url-encoding. The CookieService's set() automatically url-encodes.
   */
  set deviceToken(deviceToken: string) {
    const expireDate = new Date();
    expireDate.setFullYear(expireDate.getFullYear() + 100);
    this._setDocumentCookie(
      `${CookieKey.DEVICE_TOKEN}=${deviceToken};path=/;domain=${ENVIRONMENT.features.general.cookieDomain};expires=${expireDate};secure`
    );

    // Manage a session "shadow" cookie to help mitigate issues where a user may have a leftover cookie from old IAM with "HttpOnly" set to true.
    this._setDocumentCookie(
      `${CookieKey.CIAM_DEVICE_TOKEN}=${deviceToken};path=/;domain=${ENVIRONMENT.features.general.cookieDomain};secure`
    );
  }

  _setDocumentCookie(cookie: string) {
    document.cookie = cookie;
  }

  private _manageIds(idCookieKey: CookieKey): string {
    const flowId = this.get(CookieKey.CIAM_FLOW_ID);
    const sessionId = this.get(CookieKey.CIAM_SESSION_ID);

    if (!flowId || !sessionId) {
      let id = '';

      // https://stackoverflow.com/a/44046703
      // IE doesn't like .join on the array created below via getRandomValues since it doesn't think the object returned is an array
      const idParts = (window.crypto || (window as { [key: string]: any })['msCrypto']).getRandomValues(
        new Uint32Array(4)
      );
      for (const part of idParts) {
        // IE also doesn't like += below since it somehow doesn't process the Math.abs correctly,
        // which results in negative (-) signs in the transactionId, which are not allowed in all of our services
        id = `${id}${Math.abs(part).toString()}`;
      }

      if (!flowId) {
        this.set(CookieKey.CIAM_FLOW_ID, id, null, '/', null, true);
      }

      if (!sessionId) {
        this.set(CookieKey.CIAM_SESSION_ID, id, null, '/', ENVIRONMENT.features.general.cookieDomain, true);
      }
    }

    return this.get(idCookieKey);
  }
}
