import { Inject, Injectable } from '@angular/core';
import { NavigationExtras, Params, Router } from '@angular/router';
import { AUTHORIZATION_PATHS } from '@app/authorization/authorization-paths';
import { AuthMethod, DestinationType } from '@app/authorization/shared/authorization.model';
import { LogLevel } from '@nw/eim-angular-logging';
import { ContentHeaderModel } from '@shared/content-header/content-header.model';
import { LoggingService } from '@shared/logging/logging.service';
import { NewRelic } from '@shared/new-relic/new-relic';
import { CustomSessionService, CUSTOM_SESSION } from '@shared/storage/custom-session.service';
import { Partner, SessionKey } from '@shared/storage/storage.model';
import { Flow } from './flow.enum';
import { NavigationModel, NavigationType } from './navigation.model';

declare let newrelic: NewRelic;

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  protected readonly className = 'NavigationService';

  private _model: NavigationModel;

  constructor(
    @Inject(CUSTOM_SESSION) private _sessionService: CustomSessionService,
    private _logger: LoggingService,
    private _router: Router
  ) {
    this.reset();
  }

  reset() {
    this.model = { expectedRoute: '' };
  }

  navigate(path: string, extras?: NavigationExtras): Promise<boolean> {
    this._logNavigation(NavigationType.INTERNAL, path);
    this._model.expectedRoute = path;
    return this._router.navigate([path], extras);
  }

  navigateExternal(url: string) {
    this._logNavigation(NavigationType.EXTERNAL, url);
    this._sessionService.clear();
    this._redirect(url);
  }

  navigateToAuthorization(
    authMethod: AuthMethod,
    authParams: Record<string, string>,
    destinationType?: DestinationType,
    contentHeader?: ContentHeaderModel
  ) {
    const partnerId = (this._sessionService.get(SessionKey.PARTNER) as Partner)?.id || 'CIAM';
    const queryParams: Params = {
      systemId: partnerId,
      method: authMethod
    };

    if (destinationType) {
      queryParams.destination = destinationType;
    }

    Object.keys(authParams).forEach(param => {
      queryParams[param] = authParams[param];
    });

    this._sessionService.setModifiedSessionObject(contentHeader, SessionKey.AUTHORIZATION, 'contentHeader');

    this.navigate(`${Flow.AUTHORIZATION}/${AUTHORIZATION_PATHS.authorize}`, { queryParams });
  }

  navigateToError(
    className: string,
    error: Error,
    path: string,
    errorMessage?: string,
    logLevel?: LogLevel
  ): Promise<boolean> {
    if (!logLevel) {
      logLevel = LogLevel.ERROR;
    }

    if (errorMessage) {
      this._logger[logLevel](className, errorMessage, error);

      if (logLevel === LogLevel.ERROR) {
        newrelic.noticeError(new Error(errorMessage));
      }
    } else {
      this._logger[logLevel](className, error.message, error);

      if (logLevel === LogLevel.ERROR) {
        newrelic.noticeError(error);
      }
    }

    return this.navigate(path);
  }

  _redirect(url: string) {
    window.location.href = url;
  }

  private _logNavigation(type: NavigationType, url: string) {
    this._logger.info(this.className, 'Navigation occurred.', { type, url });
  }

  get model(): NavigationModel {
    return this._model;
  }

  set model(model: NavigationModel) {
    this._model = model;
  }
}
