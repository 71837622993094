import { Component } from '@angular/core';
import { MfaBaseComponent } from '../shared/mfa-base.component';

@Component({
  selector: 'ciam-undeliverable-code',
  templateUrl: './undeliverable-code.component.html',
  styleUrls: ['./undeliverable-code.component.scss']
})
export class UndeliverableCodeComponent extends MfaBaseComponent {
  protected readonly className = 'UndeliverableCodeComponent';
}
