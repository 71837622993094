import { Component } from '@angular/core';
import { ModalEvent } from '@nw/bolt-angular';
import { LoggingService } from '@shared/logging/logging.service';

@Component({
  selector: 'ciam-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  protected readonly className = 'HeaderComponent';

  constructor(private _logger: LoggingService) {}

  handleContactUsSelection(event: ModalEvent) {
    this._logger.logElementClick(this.className, event.buttonEvent);
  }
}
