<div class="d-flex flex-column">
  <div class="d-flex flex-column justify-content-between page-content">
    <section id="update-login-content" class="container">
      <span #adaAutoFocus tabindex="-1" class="m-0 p-0 no-outline"></span>

      <div id="update-login-header" class="content-section no-outline" tabindex="-1">
        <bolt-alert-confirmation _id="alert-confirmation" _aria-describedby="progress-bar-label">
          <strong>Success!</strong>
          <p class="mb-0">
            We have found and attached <strong>{{ businessNames }}</strong> to your existing account.
          </p>
        </bolt-alert-confirmation>
      </div>

      <div class="button-standalone content-section">
        <bolt-button-primary ciamClickLogger _type="button" _id="update-login-continue" (click)="continue()">
          Login to view your account
        </bolt-button-primary>
      </div>
    </section>
  </div>
</div>
