import { ENVIRONMENT } from '@environments/environment';
import { AuthConfig } from 'angular-oauth2-oidc';

export const oauthConfig = (clientId: string): AuthConfig => ({
  issuer: ENVIRONMENT.apiConfig.services.oauth.basePath,
  clientId,
  scope: 'openid',
  strictDiscoveryDocumentValidation: false,
  // TODO: Re-enable this check if/when EUA is fixed.
  // https://github.nwie.net/Nationwide/EnterpriseUserAuth_2/issues/42
  skipIssuerCheck: true
});
